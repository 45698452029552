import React from 'react'
import { DataTablesReportTraining } from '../../components/tables/dataTablesReportTraining'

const ReportTraining = () => {
  return (
    <>
    <div className="page-wrapper">
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-md-12">
                    <DataTablesReportTraining />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ReportTraining