import React from 'react';
import { useHistory } from "react-router";
import image from '../../../assets/images/user/6.jpg'

const CourseComponent = ({data}) => {

    const history = useHistory()

    const renderStart = (number) => {
        const row = [];
        for (var i = 0; i < number; i++) {
          row.push(<i style={{color:'gold'}} className='fa fa-star' key={i}></i>);
        }
        return row;
    }

    const formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    return(
        <>
            <div className="col-sm-4">
                <div className="card">
                    <div className="blog-box blog-grid text-center product-box">                                
                        <div className="product-img">
                            <img style={{height:'250px'}} className="img-fluid top-radius-blog" src={data.cover !== null ? data.cover : image} alt="images-cover" />
                            <div className="product-hover">
                                <ul>
                                    <li style={{cursor:'pointer'}} onClick={() => history.push({
                                        pathname: `${process.env.PUBLIC_URL}/pages/course/detail-course/`+data.id,
                                        state: data
                                    })}><i className="icon-folder" onClick={() => this}></i></li>
                                    <li style={{cursor:'pointer'}} onClick={() => history.push({
                                        pathname: `${process.env.PUBLIC_URL}/pages/course/course-list/form-course-component/`+data.id,
                                        state: data,
                                    })} ><i className="icon-pencil"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="blog-details-main">
                           <ul className="blog-social">
                                <li className="digits">{data.category.category_name}</li>
                                <li className="digits">{renderStart(data.rating)}</li>
                                <li className="digits">{formatRupiah(data.price)}</li>

                            </ul>
                            <hr />
                            <h6 className="blog-bottom-details">{data.course_name}</h6>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    )

}

export default CourseComponent