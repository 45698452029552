/* eslint-disable eqeqeq, no-useless-concat */

import React, { Component } from "react"
import ReactTable from 'react-table'
import axios from 'axios'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'
import Loader from "../../common/loader"
import Moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from "../../../ApiUrl"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class StudentAlmostDone extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalUpdate: false,
            showLoading: false,
            code_students: '',
            code_konsultan: '',
            textUpdate: 'Update',
            disableBtn: false,
            leadsData: [],
            listConsultant: [],
        }
    }

    componentDidMount() {
        this.loadDataStudents()
    }

    loadConsultant = () => {
        axios.get(API_URL.superStudent + '/master/consultant/list-active', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
        })
            .then(response => this.setState({ listConsultant: response.data.data }))
    }

    editDetail = (code_students) => {
        this.loadConsultant()
        this.setState({
            code_students,
            code_konsultan:'',
            modalUpdate: true
        })
    }


    handleInput = (e) => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataStudents() {
        this.setState({ showLoading: true })
        let formData = new FormData()
        formData.append('code_konsultan', this.props.dataUser.code_konsultan)
        formData.append('id_area', this.props.dataUser.id_area)
        formData.append('code_roles', this.props.dataUser.code_roles)
        axios.post(API_URL.superStudent + '/student/last-six-month', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({ leadsData: response.data.data, showLoading: false })
            })
    }

    updateStudent() {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code_konsultan', this.state.code_konsultan)
        this.setState({
            disableBtn:true,
            textUpdate:'Updating...'
        })
        axios.post(API_URL.superStudent + '/student/set-consultant', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
        })
        .then(() => {
            this.setState({
                modalUpdate:false,
                disableBtn:false,
                textUpdate:'Update'
            })
            toast.success('Data successfully updated')
            this.loadDataStudents()
        })
        .catch((error) => {
            this.setState({
                modalUpdate:false,
                disableBtn:false,
                textUpdate:'Update'
            })
            toast.error('Something Wrong')
        })
    }

    closeModal() {
        this.setState({
            modalUpdate: false,
        });
    }

    renderModalUpdate() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalUpdate} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>
                    <h5 className='mb-3'>Update Data</h5>
                </ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.handleInput} name="code_konsultan" value={this.state.code_konsultan}>
                                        <option>--Select Konsultan--</option>
                                        {this.state.listConsultant.length > 0 ?
                                            this.state.listConsultant.map((item, index) => (
                                                <option key={index} value={item.code}>{item.name}</option>
                                            )) : ''}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
                    <Button disabled={this.state.disableBtn} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Code Student',
                accessor: 'code_students',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }),
                width: 140,
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)}>{row.original.code_students}</span>
                    </>
                )
            },
            {
                Header: 'Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
            },
            {
                Header: 'Whatsapp',
                accessor: 'no_hp',
                width: 140,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_hp"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.no_hp.substring(0, 2) == '62' ?
                        <a href={'https://wa.me/' + row.original.no_hp} rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
                        :
                        <a href={'https://wa.me/' + '62' + row.original.no_hp.substring(1)} rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
                )
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                width: 120,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original.code_students)}>{row.original.consultant.nama}</div>
                ),
            },
            {
                Header: 'Area',
                accessor: 'consultant.area.area',
                width: 120,
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Program',
                accessor: 'program_name',
                width: 250,
                filterable: false
            },
            {
                Header: 'Finish Study',
                accessor: 'date_program_finish',
                width: 150,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>{Moment(row.original.date_program_finish).format('D MMM y')}</>
                ),
                filterable: false
            },
        ];

        return (
            <>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-8">
                                    <h5>Finish Study in 6 months</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <ReactTable
                                        data={this.state.leadsData}
                                        filterable
                                        columns={columns}
                                        collapseOnDataChange={false}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                    <Loader showLoading={this.state.showLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalUpdate()}
            </>
        );
    }
}


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(StudentAlmostDone)
