import React, { Fragment, Component } from 'react';
import DataTableTalentMapping from '../common/datatable/datatableTalentMapping';
import { withRouter } from "react-router";

class DataTablesTalentMapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listRoles: []
        }
    }


    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DataTableTalentMapping   title="Talent Mapping Data" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

  
export default (withRouter(DataTablesTalentMapping));