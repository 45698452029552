import axios from 'axios';
import API_URL from '../ApiUrl';

const RootPath = API_URL.url;
const StudentsPath = API_URL.masterstudents;

const Get = (path, header = null) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`, header)
        .then((result) => {
            resolve(result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetStudents = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${StudentsPath}/${path}`,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.masterstudentsKey}`
            }
        }).then((result) => {
            resolve(result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetStudentsAmbassador = (path, data, headers) => {
    const promise = new Promise((resolve, reject) => {
        axios.post(`${StudentsPath}/${path}`, data,{ 
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.masterstudentsKey}`
            }
        }
        ).then((result) =>  {
            resolve(result.data);
        }, (err) => {
            reject(err);

        })
    })
    return promise;
}

const GetNewStudents = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${StudentsPath}/${path}`,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.masterstudentsKey}`
            }
        }).then((result) => {
            resolve(result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}


const GetById = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetByEmail = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const GetByToken = (path) => {
    const promise = new Promise((resolve, reject) => {
        axios.get(`${RootPath}/${path}`)
        .then((result) => {
            resolve(result.data);
            //console.log('testing: ', result.data);
        }, (err) => {
            reject(err);
        })
    })
    return promise;
}

const Post = (path, data) => {
    const promise = new Promise((resolve, reject) => {
        axios.post(`${RootPath}/${path}`, data).then((result) =>  {
            resolve(result);
        }, (err) => {
            reject(err);

        })
    })
    return promise;
}

const Post2 = (path, data, headers) => {
    const promise = new Promise((resolve, reject) => {
        axios.post(`${RootPath}/${path}`, data, headers).then((result) =>  {
            resolve(result);
        }, (err) => {
            reject(err);

        })
    })
    return promise;
}

//POST
// const login = (data, headers) => Post('member/login', data, headers);
const login = (data) => Post('login', data);
const logout = (data, headers) => Post2('logout', data, headers);
const resettoken = (data, headers) => Post2('resettoken', data, headers);
const updateUser = (path, data, headers) => Post2(path, data, headers)
const getStudents = () => GetStudents('v1/student');
const getStudentsAmbassador = (data) => GetStudentsAmbassador('v1/studentambassador', data);
const getNewStudents = (data) => GetNewStudents(`v1/student/${data}`);
// GET
// const getListTransfer = (data) => GetById(`getmutasidonasilistbynewmember/${data}`);
// const getDetailUser = (data) => GetById(`showmemberperid/${data}`);
// const getKonsultanMember = (data) => GetById(`konsultan/${data}`);
// const getListBooking = (data) => GetById(`tmbooking/findbymember/${data}`);
// const getDataProfile = (data, headers) => GetByEmail(`member/findbyemail/${data}`, headers);
// const checkTokenFgp = (data, headers) => GetByToken(`member/findbytoken/${data}`, headers);


const API = {
    Get,
    updateUser,
    login,
    logout,
    resettoken,
    getStudents,
    getStudentsAmbassador,
    getNewStudents,
    GetById,
    GetByEmail,
    GetByToken
}

export default API;
