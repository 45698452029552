import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import {matchSorter} from 'match-sorter';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class ListData extends Component {

    constructor(props){
        super(props)
        this.state = {
            listConsultant:[],
            listProvinsi:[],
            listKabupaten:[],
            listSekolah:[],
            listJenjang:[],
            listKelas:[],
            listPromo:[],
            listData:[],
            id_register:'',
            nama_lengkap:'',
            email:'',
            phone:'',
            address:'',
            code_consultant:'',
            id_provinsi:'',
            id_kabupaten:'',
            school_type:'',
            kelas:'',
            school_code:'',
            migrate_to: '',
            id_harga: '',
            errors:[],
            textSave:'Save Data',
            btnDisabled:false,
            displayHarga: 'none',
            modal:false,
            modal_consultant: false
        }
    }

    componentDidMount(){
        this.loadRegisterData()
    }

    loadRegisterData = () => {
        axios.get(API_URL.superStudent+'/socail-medias', {
            headers: {
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listData: result}))
        .catch(error => console.log(error))
    }

    loadConsultant = () => {
        axios.get(API_URL.superStudent+'/master/consultant/list-priotas', {
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listConsultant: result }))
        .catch(error => console.log(error))
    } 
    loadProvince = () => {
        axios.get(API_URL.superStudent+'/master/provinces')
        .then(response => response.data.data)
        .then(result => this.setState({ listProvinsi: result}))
        .catch(error => console.log(error))
    }

    loadKabupaten = () => {
        axios.get(API_URL.superStudent+'/master/kabupaten/'+this.state.id_provinsi)
        .then(response => response.data.data)
        .then(result => this.setState({ listKabupaten: result}))
        .catch(error => console.log(error))
    }

    loadJenjangPendidian = () => {
        axios.get(API_URL.superStudent+'/master/jenjang')
        .then(response => response.data.data)
        .then(result => this.setState({ listJenjang: result}))
        .catch(error => console.log(error))
    }

    loadKelas = () => {
        axios.get(API_URL.superStudent+'/master/kelas/'+this.state.school_type)
        .then(response => response.data.data)
        .then(result => this.setState({ listKelas: result}))
        .catch(error => console.log(error))
    }

    loadSekolah = () => {
        axios.get(API_URL.superStudent+'/sekolah-indonesia/'+this.state.id_provinsi)
        .then(response => response.data.data)
        .then(result => this.setState({ listSekolah: result}))
        .catch(error => console.log(error))
    }

    loadKodePromo = () => {
        axios.get(API_URL.superStudent+'/promo-member/active',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ listPromo: result }))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleJenjang = (e) => {
        this.setState({
            school_type: e.target.value
        },() => {
            this.loadKelas()
        })
    }

    handleProvince = (e) => {
        this.setState({
            id_provinsi: e.target.value
        }, () => {
            this.loadKabupaten() 
            this.loadSekolah()
        })
    }

    handleMigrate = (e) => {
        this.setState({
            migrate_to: e.target.value
        }, () => {
            if(this.state.migrate_to === 'member'){
                this.setState({
                    displayHarga:''
                })
                this.loadKodePromo()
            }
            else
                this.setState({ displayHarga: 'none'})
        })
    }

    setSekolah = (e) => {
        const newData = e.length > 0 ? e[0].kode_sekolah : "";
        this.setState(prevState => {  
          prevState.school_code = newData; 
          return { prevState };                   
        })
    }

    addRow = () => {
        this.loadProvince()
        this.loadJenjangPendidian()
        this.setState({
            modal:true
        })
    }

    setConsultant = (row) => {
        this.loadConsultant()
        this.setState({
            modal_consultant: true,
            id_register: row.id
        })
    }

    saveRow = () => {
        this.setState({
            btnDisabled:true,
            textSave:'Saving...'
        })
        let formData = new FormData()
        formData.append('name', this.state.nama_lengkap)
        formData.append('email', this.state.email)
        formData.append('phone',this.state.phone)
        formData.append('province', this.state.id_provinsi)
        formData.append('kabupaten', this.state.id_kabupaten)
        formData.append('address', this.state.address)
        formData.append('jenjang', this.state.school_type)
        formData.append('kelas', this.state.kelas)
        formData.append('school_code', this.state.school_code)
        axios.post(API_URL.superStudent+'/socail-medias',formData,{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                btnDisabled:false,
                textSave:'Save Data',
                modal: false,
            })
            toast.success("Data rep school berhasil diperbaharui")
            this.loadRegisterData()
        })
        .catch(error => {
            if(error.response.status === 422){
                this.setState({
                    errors:error.response.data.data,
                    btnDisabled:false,
                    textSave:'Save Data',
                })
            }
        })
    }

    saveConsultant = () => {
        this.setState({
            btnDisabled:true,
            textSave:'Saving...'
        })
        let formData = new FormData()
        formData.append('code_consultant', this.state.code_consultant)
        // formData.append('migrate_to', this.state.migrate_to)
        // formData.append('id_harga', this.state.id_harga)
        formData.append('id_register', this.state.id_register)

        axios.post(API_URL.superStudent+'/social-media/set-consultant', formData,{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data)
        .then(result => {
            console.log(result)
            toast.success("Data berhasil diperbaharui")
            this.loadRegisterData()
        })
        .catch(error => toast.error("Data gagal di proses"))
        this.setState({
            btnDisabled:false,
            textSave:'Save Data',
            modal_consultant: false,
        })
    }

    renderModal() {
        return (
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                <ModalHeader toggle={() => this.setState({ modal: false })}>Tambah Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group"> 
                            <label className='control-label'>Nama Lengkap<span className="text-danger">*</span></label>
                            <input className="form-control" value={this.state.nama_lengkap} onChange={this.handleInput} name="nama_lengkap" type="text" />
                            <span className='text-danger'>{this.state.errors.name}</span>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Email<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.email} onChange={this.handleInput} name="email" type="email" />
                                <span className='text-danger'>{this.state.errors.email}</span>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Telepon/WA<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.phone} onChange={this.handleInput} name="phone" type="text" />
                                <span className='text-danger'>{this.state.errors.phone}</span>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Provinsi<span className="text-danger">*</span></label>
                                <select name='id_provinsi' value={this.state.id_provinsi} className='form-control' onChange={this.handleProvince}>
                                    <option value=''> --Pilih Provinsi-- </option>
                                    {
                                        this.state.listProvinsi.length > 0 ?
                                        this.state.listProvinsi.map(province => {
                                            return <option key={province.id} value={province.id}>{province.province_name}</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                                <span className='text-danger'>{this.state.errors.province}</span>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Kabupaten<span className="text-danger">*</span></label>
                                <select name='id_kabupaten' value={this.state.id_kabupaten} className='form-control' onChange={this.handleInput}>
                                    <option value=''> --Pilih Kabupaten-- </option>
                                    {
                                        this.state.listKabupaten.length > 0 ?
                                        this.state.listKabupaten.map(kabupaten => {
                                            return <option key={kabupaten.id} value={kabupaten.id}>{kabupaten.kabupaten_name}</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                                <span className='text-danger'>{this.state.errors.province}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label className='control-label'>Alamat</label>
                                <textarea className='form-control' onChange={this.handleInput} name='address' value={this.state.address}></textarea>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Jenjang Pendidikan</label>
                                <select name='school_type' value={this.state.school_type} className='form-control' onChange={this.handleJenjang}>
                                    <option value=''> --Jenjang Pendidikan-- </option>
                                    {
                                        this.state.listJenjang.length > 0 ?
                                        this.state.listJenjang.map(jenjang => {
                                            return <option key={jenjang.code_jenjang} value={jenjang.code_jenjang}>{jenjang.jenjang_pendidikan }</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Kelas/Semester</label>
                                <select name='kelas' value={this.state.kelas} className='form-control' onChange={this.handleInput}>
                                    <option key={0} value=''> --Kelas-- </option>
                                    {
                                        this.state.listKelas.length > 0 ?
                                        this.state.listKelas.map(kelas => {
                                            return <option key={kelas.code} value={kelas.code}>{kelas.name }</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Asal Sekolah</label>
                                <Typeahead
                                    id="basic-typeahead"
                                    labelKey="nama_sekolah"
                                    name="school_code"  
                                    value={this.state.school_code}
                                    options={this.state.listSekolah}
                                    onChange={this.setSekolah}
                                    placeholder="--Asal Sekolah--"
                                />
                                <span className='text-danger'>{this.state.errors.school_type}</span>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>{this.state.textSave}</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          )
    }

    renderModalConsultant(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_consultant} toggle={() => this.setState({ modal_consultant: false })}>
                <ModalHeader toggle={() => this.setState({ modal_consultant: false })}>Set Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Consultant</label>
                                <select className='form-control' value={this.state.code_consultant} name='code_consultant' onChange={this.handleInput}>
                                    <option key={0} value=''> --Pilih Consultant-- </option>
                                    {
                                        this.state.listConsultant.length > 0 ? 
                                        this.state.listConsultant.map(consultant => {
                                            return <option key={consultant.code} value={consultant.code}>{consultant.name }</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                            {/* <div className='form-group'>
                                <label className='control-label'>Migrasi ke</label>
                                <select className='form-control'onChange={this.handleMigrate} name='migrate_to' value={this.state.migrate_to}>
                                    <option key={0} value=''> --Pilih-- </option>
                                    <option key={1} value='member'> Member </option>
                                    <option key={2} value='student'> Student </option>
                                </select>
                            </div> */}
                            {/* <div style={{display:this.state.displayHarga}} className='form-group'>
                                <label className='control-label'>Promo Member</label>
                                <select className='form-control' onChange={this.handleInput} name='id_harga' value={this.state.id_harga}>
                                    <option key={0} value=''> --Kode Promo-- </option>
                                    {
                                        this.state.listPromo.length > 0 ?
                                        this.state.listPromo.map(harga => {
                                            return <option key={harga.id_harga} value={harga.id_harga}>{harga.nama +' ( Rp.'+ harga.harga +' )' }</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                            </div> */}
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveConsultant()}>{this.state.textSave}</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modal_consultant: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){

        const columns = [
            {
                Header: 'Nama',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'phone',
                filterable: true,
            },
            {
                id: 'created_at',
                Header: 'Tanggal Register',
                accessor: a => {
                    return Moment(a.created_at)
                    .local()
                    .format("DD/MM/YYYY")
                },
                filterable: false,
                style: {textAlign: 'center'}
            },
            {
                Header: 'Consultant',
                accessor:'code_konsultan',
                Cell: (row) => (
                    row.original.consultant !== null ?  row.original.consultant.nama : <button className='btn btn-success btn-sm btn-successs' onClick={() => this.setConsultant(row.original)}>Set</button>
                  ),
                filterable: false,
                style: {textAlign: 'center'}
            }
            
        ]
        return(
            <Fragment>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='mb-2'>
                                        <button onClick={this.addRow} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                            <span>
                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                            </span>
                                            </div>
                                        </button>
                                    </div>
                                    <ReactTable
                                        data={this.state.listData}
                                        columns={columns}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        SubComponent={row => {
                                            const data = row.original;
                                            return (
                                              <div style={{ padding: "20px" }}>
                                                <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Provinsi</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                          <div align="left">{data.province.nama_propinsi}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Kabupaten</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                          <div align="left">{data.kabupaten.nama_kabkota}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Alamat</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                          <div align="left">{data.address}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Jenjang Pendidikan</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                          <div align="left">{data.jenjang !== null ? data.jenjang.jenjang_pendidikan : ''}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Kelas Semester</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                          <div align="left">{data.kelas !== null ? data.kelas.kelas : ''}</div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div align="right" style={{ color:"black" }}>Asal Sekolah</div>
                                                        </div>
                                                        <div className="col-md-1">
                                                          <div align="center">:</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                          <div align="left">{data.school !== null ? data.school.nama_sekolah : ''}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.renderModal()}
                {this.renderModalConsultant()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(ListData)