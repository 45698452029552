import React from 'react'
import DataTablesTrainings from '../../components/tables/dataTablesTrainings'

const EmployeeTraining = () => {
  return (
    <>
    <div className="page-wrapper">
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-md-12">
                    <DataTablesTrainings />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default EmployeeTraining