/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from "file-saver";
import CKEditors from "react-ckeditor-component";
import { UncontrolledTooltip } from 'reactstrap';
import setRangeDate from '../../helper/general-function';

class TaskListData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            taskList: [],
            filtered: [],
            statusTaskList: [],
            userList: [],
            internalUserList: [],
            IndikatorKPI: [],
            jobdesk: [],
            dateType: 'this_week',
            title: '',
            jobdeskType: '',
            description: '',
            due_date: '',
            handle_by: '',
            from: '',
            code_jobdesk: '',
            resources: '',
            filterStatus: '',
            filterHandler: '',
            filterRequestor: '',
            id_todo: '',
            status: '',
            noted: '',
            attachment: '',
            category_type: '',
            status_approval: '1',
            modal_update: false,
            modal_todo: false,
            btnAddDisabled: false,
            text_save: 'Save',
            btnTextSave: 'Add',
            category_display: 'none',
            from_display: '',
            startDate: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
        this.loadTodoList()
        this.loadUserList()
        this.loadTodoListStatus()
        this.loadInternalUser()
        this.loadJobdesk()
    }

    handleDate = (e) => {
        this.setState({
            dateType: e.target.value
        }, () => this.setDate())
    }

    loadJobdesk = () => {
        axios.get(API_URL.superStudent + '/consultant/list-jobdesk', {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ jobdesk: response.data.data }))
            .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleBy = (e) => {
        let jobdesk = this.state.userList.filter(function (user) {
            return user.code_konsultan == e.target.value
        })
        let category_display = jobdesk[0].code_jobdesk === 'JD010' || jobdesk[0].code_jobdesk === 'JD013' || jobdesk[0].code_jobdesk === 'JD012' || jobdesk[0].code_jobdesk === 'JD009' || jobdesk[0].code_jobdesk === 'JD011' || jobdesk[0].code_jobdesk === 'JD003' || jobdesk[0].code_jobdesk === 'JD014' || jobdesk[0].code_jobdesk === 'JD015' || jobdesk[0].code_jobdesk === 'JD018' ||  jobdesk[0].code_jobdesk === 'JD020' ? '' : 'none'
        this.setState({
            handle_by: e.target.value,
            code_jobdesk: jobdesk[0].code_jobdesk,
            category_display,
            from_display: jobdesk[0].code_jobdesk === 'JD003' && this.state.category_type == 58 ? 'none' : ''
        }, () => {
            this.loadIndikator()
        })
    }

    handleType = (e) => {
        this.setState({
            jobdeskType: e.target.value
        }, () => this.loadTodoList())
    }

    handleResources = (e) => {
        this.setState({
            resources: e.target.files[0]
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    setDate() {
        let data = setRangeDate(this.state.dateType);
        this.setState({
            startDate: data.start,
            endDate: data.end
        }, () => this.loadTodoList())
    }

    loadTodoList = () => {
        let formData = new FormData()
        formData.append('code_jobdesk', this.state.jobdeskType)
        formData.append('start_date', this.state.startDate)
        if (this.props.dataUser.code_roles === 'RL004')
            formData.append('filter', true)
        formData.append('end_date', this.state.endDate)
        axios.post(API_URL.superStudent + '/todo/list-todo', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ taskList: result }))
            .catch(error => console.log(error))
    }

    loadTodoListStatus = () => {
        axios.get(API_URL.superStudent + '/todo/status-todo', {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ statusTaskList: result }))
            .catch(error => console.log(error))
    }

    loadUserList = () => {
        let formData = new FormData()
        formData.append('jobdesk', JSON.stringify(['JD001', 'JD002', 'JD003', 'JD004', 'JD006', 'JD007', 'JD008', 'JD009', 'JD010', 'JD011', 'JD012', 'JD013', 'JD014', 'JD015', 'JD016', 'JD017', 'JD018','JD020']))
        axios.post(API_URL.superStudent + '/consultant/jobdesk', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ userList: result }))
            .catch(error => console.log(error))
    }

    loadInternalUser = () => {
        let formData = new FormData()
        formData.append('code_roles', JSON.stringify(['RL001', 'RL003', 'RL004', 'RL005', 'RL006', 'RL009', 'RL012', 'RL013', 'RL016', 'RL023', 'RL024','RL025','RL026']));
        axios.post(API_URL.superStudent + '/internal-user-active', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ internalUserList: result }))
            .catch(error => console.log(error))
    }

    loadIndikator = () => {
        let formData = new FormData()
        formData.append('code_jobdesk', this.state.code_jobdesk)
        formData.append('status', '1')
        axios.post(API_URL.superStudent + '/kpi/list-indikator', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ IndikatorKPI: result }))
            .catch(error => console.log(error))
    }

    saveUpdate() {
        let formData = new FormData()
        formData.append('id_todo', this.state.id_todo)
        formData.append('noted', this.state.noted)
        formData.append('status', this.state.status)
        formData.append('attachment', this.state.attachment)
        this.setState({
            text_save: 'Saving..',
            btnSave: true,
        })
        axios.post(API_URL.superStudent + '/todo/finish-todo', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                toast.success('Task list Updated successfully')
                this.setState({
                    modal_update: false,
                    text_save: 'Save',
                    btnSave: false,
                })
                this.loadTodoList()
            })
            .catch(error => {
                this.setState({
                    modal_update: false,
                    text_save: 'Save',
                    btnSave: false,
                })
                toast.error('there is an error')
            })
    }

    SetFilterStatus = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterStatus = newData;
            return { filterStatus };
        }, () => {
            this.onFilteredChangeCustom(newData, "status_id");
        })
    }

    SetFilterHandler = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterHandler = newData;
            return { filterHandler };
        }, () => {
            this.onFilteredChangeCustom(newData, "handle_by");
        })
    }

    SetFilterRequestor = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterRequestor = newData;
            return { filterRequestor };
        }, () => {
            this.onFilteredChangeCustom(newData, "code_user");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    saveData = () => {
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('description', this.state.description)
        formData.append('due_date', this.state.due_date)
        formData.append('todoType', this.state.category_type)
        formData.append('handle_by', this.state.handle_by)
        formData.append('status_id', '6')
        formData.append('status_approval', this.state.status_approval)
        formData.append('code_jobdesk', this.state.code_jobdesk)
        formData.append('from', this.state.from)
        formData.append('resources', this.state.resources)
        formData.append('detail', 'code_users')
        this.setState({
            btnAddDisabled: true,
            btnTextSave: 'Adding...'
        })
        axios.post(API_URL.superStudent + '/todo', formData, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                this.setState({
                    modal_todo: false,
                    btnAddDisabled: false,
                    btnTextSave: 'Add'
                })
                this.loadTodoList()
                toast.success('task list add successfully')
            })
            .catch(error => {
                this.setState({
                    modal_todo: false,
                    btnAddDisabled: false,
                    btnTextSave: 'Add'
                })
                toast.error('there is an error')
            })
    }

    handleCancel = (e) => {
        axios.get(API_URL.superStudent + '/todo/cancel-todo/' + e.id, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        }).then(response => {
            this.loadTodoList()
            toast.success('task list deleted successfully')
        })
            .catch(error => {
                toast.error('there is an error')
            })
    }

    handleCategory = (e) => {
        let category_type = e.target.value
        let from = ''
        let status_approval = '1'
        let from_display = ''
        let due_date = ''
        if (category_type == 58) {
            from = 'US0000006'
            status_approval = '0'
            from_display = 'none';
            due_date = Moment().add(2, 'months').format('YYYY-MM-DD')
        }
        this.setState({
            from,
            status_approval,
            category_type,
            from_display,
            due_date
        })
    }

    handleFrom = (e) => {
        let value = e.target.value
        let status_approval = '1'
        if (value !== this.props.dataUser.code_users) {
            status_approval = '0'
        }
        this.setState({
            status_approval,
            from: e.target.value
        })
    }

    renderModalTodo() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal_todo} toggle={() => this.setState({ modal_todo: false })} >
                    <ModalHeader toggle={() => this.setState({ modal_todo: false })}>Add Task List</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group'>
                                        <label className='control-label'>Title</label>
                                        <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Description</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.description}
                                            events={{
                                                "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                                toolbar: [
                                                    ["Bold", "Italic", 'Strike', 'RemoveFormat'],
                                                    ['Indent', 'Outdent'],
                                                    ['Link', 'Unlink'],
                                                    ['Styles', 'Format', 'Table', 'Blockquote', 'Maximize'],
                                                    ["Cut", "Copy", "Paste", "Pasteasplaintext", "Undo", "Redo"]
                                                ]
                                            }}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Handle By</label>
                                        <select name='handle_by' className='form-control' value={this.state.handle_by} onChange={this.handleBy}>
                                            <option key={0} value=''>--Select--</option>
                                            {
                                                this.state.userList.map(user => (
                                                    <option key={user.code_konsultan} value={user.code_konsultan}>{user.nama} ({user.jobdesk.jobdesk})</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div style={{ display: this.state.category_display }} className='form-group'>
                                        <label className='control-label'>Category</label>
                                        <select name='category_type' className='form-control' value={this.state.category_type} onChange={this.handleCategory}>
                                            <option key={0} value=''>--Select--</option>
                                            {
                                                this.state.IndikatorKPI.map(indikator => (
                                                    <option key={indikator.id} value={indikator.id}>{indikator.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div style={{ display: this.state.from_display }} className='form-group'>
                                        <label className='control-label'>From</label>
                                        <select name='from' className='form-control' onChange={this.handleFrom} value={this.state.from}>
                                            <option key={0} value=''>--Select--</option>
                                            {
                                                this.state.internalUserList.map(list => (
                                                    <option key={list.code_users} value={list.code_users}>{list.username}</option>
                                                ))
                                            }
                                        </select>
                                        <span style={{ fontSize: '10px', color: 'red' }}>Task perlu di approve yang bersangkuran ketika user yang  di pilih tidak sama dengan user yang login</span>
                                    </div>
                                    <div style={{ display: this.state.from_display }} className='form-group'>
                                        <label className='control-label'>Due date</label>
                                        <input className='form-control' type='datetime-local' name='due_date' value={this.state.due_date} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Resources</label>
                                        <input name='resources' className='form-control' type='file' onChange={this.handleResources} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                        <Button color="secondary" onClick={() => this.setState({ modal_todo: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderModalUpdate() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal_update} toggle={() => this.setState({ modal_update: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_update: false })}>Update Data</ModalHeader>
                    <ModalBody>
                        <form >
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5>{this.state.title}</h5>
                                    <div className='form-group'>
                                        <label className='control-label'>Status</label>
                                        <select name='status' value={this.state.status} className='form-control' onChange={this.handleInput}>
                                            <option key={0} value='0'>Select Status</option>
                                            <option key={1} value='2'>Pending</option>
                                            <option key={4} value='1'>Progress</option>
                                            <option key={2} value='3'>Done</option>
                                            <option key={6} value='5'>Cancel</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Noted</label>
                                        <textarea className='form-control' onChange={this.handleInput} name='noted' value={this.state.noted} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>File</label>
                                        <input name='attachment' className='form-control' type='file' onChange={this.handleAttachment} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnSave} color="primary" onClick={(e) => this.saveUpdate()}>{this.state.text_save}</Button>
                        <Button color="secondary" onClick={() => this.setState({ modal_update: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    handleUpdate = (e) => {
        this.setState({
            id_todo: e.id,
            status: e.status_id,
            noted: e.noted,
            modal_update: true,
            title: e.title
        })
    }

    handleApprove = (e) => {
        let formData = new FormData()
        formData.append('id_todo', e.id)
        axios.post(API_URL.superStudent + '/todo/approve-todo', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.loadTodoList()
                toast.success('successfully approved task list')
            })
            .catch(error => {
                toast.error('there is an error')
            })
    }

    addTaskList = () => {
        this.setState({
            modal_todo: true,
            title: '',
            description: '',
            due_date: '',
            handle_by: '',
            from: '',
            category_type: '',
            attachment: '',
            resources: ''
        })
    }
    downloadFile = (row) => {
        let filename = row.substring(row.lastIndexOf('/'))
        saveAs(row, filename);
    }

    render() {
        const columns_todo = [
            {
                Header: 'Crated at',
                accessor: 'created_at',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <span>{Moment(row.original.created_at).format('DD MMM YY')}</span>
                )
            },
            {
                Header: 'Title',
                accessor: 'title',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.status_approval === 0 ? <span>{row.original.title}<sup> <i style={{ color: 'red' }} className='fa fa-exclamation'></i></sup></span> : <span>{row.original.title}</span>
                )
            },
            {
                Header: 'Category',
                accessor: 'category.name',
                style: { textAlign: 'center' },
            },
            {
                Header: 'From',
                accessor: 'code_user',
                filterable: true,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>{row.original.user.username}</>
                ),
                Filter: () =>
                    <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestor(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.internalUserList.map(item => {
                                return <option key={item.code_user} value={item.code_users}>{item.username}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'To',
                accessor: 'handle_by',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <>{row.original.handler.nama}</>
                ),
                Filter: () =>
                    <select value={this.state.filterHandler || ''} onChange={(e) => this.SetFilterHandler(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.userList.map(item => {
                                return <option key={item.id_konsultan} value={item.code_konsultan}>{item.nama}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Status',
                accessor: 'status_id',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <> <span id={`code_status` + row.original.id} className={'badge bg-' + row.original.status.label}>{row.original.status.name}</span> <UncontrolledTooltip placement="top" target={`code_status` + row.original.id}>
                        {row.original.noted}
                    </UncontrolledTooltip> </>
                ),
                Filter: () =>
                    <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.statusTaskList.map(item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Due date',
                accessor: 'due_date',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.due_date !== null ?
                        <span>{Moment(row.original.due_date).format('DD MMM YY')}</span> : ''
                )
            },
            {
                Header: 'Finish',
                accessor: 'date_finish',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.date_finish !== null ?
                        <><span>{Moment(row.original.date_finish).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.date_finish).format('HH:mm')}</p></> : ''
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        {row.original.resources === null ? '' : <button
                            onClick={() => this.downloadFile(row.original.resources)}
                            className="btn btn-outline-primary btn-xs mr-1" type="button" style={{ border: 'none' }} ><i className="fa fa-archive fa-2x"></i>
                        </button>}
                        {row.original.attachment === null ? '' : <button
                            onClick={() => this.downloadFile(row.original.attachment)}
                            className="btn btn-outline-success btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-download fa-2x"></i>
                        </button>}
                        {
                            row.original.code_user === this.props.dataUser.code_users && row.original.status_approval === 0 ? <button onClick={() => this.handleApprove(row.original)} className='btn btn-danger btn-xs'><i className='fa fa-check'></i></button> : ''
                        }
                        &nbsp;
                        {
                            row.original.code_user !== this.props.dataUser.code_users ? '' : row.original.status_id === 5 ? '' : row.original.status_id === 3 ? '' : <button onClick={() => this.handleCancel(row.original)} className='btn btn-dark btn-xs' ><i className='fa fa-remove'></i></button>
                        }
                        {
                            row.original.status_id === 3 ? '' : row.original.status_id === 5 ? '' : this.props.dataUser.code_konsultan !== row.original.handle_by ? '' :
                                <button
                                    onClick={() => this.handleUpdate(row.original)}
                                    className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-edit fa-2x"></i>
                                </button>
                        }
                    </>
                ),
                style: { textAlign: 'center' },
                sortable: false
            },
        ]

        const columns_log = [
            {
                Header: 'Log Name',
                accessor: 'name',
                Cell: (row) => (
                    <span className={'badge bg-' + row.original.label}>{row.original.name}</span>
                ),
                style: { textAlign: 'center' },
            },
            {
                Header: 'Datetime',
                accessor: 'created_at',
                Cell: (row) => (
                    <span>{Moment(row.original.pivot.created_at).format('DD MMM YYYY HH:mm')}</span>
                ),
                style: { textAlign: 'center' },
            },
        ]
        return (
            <Fragment>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <button type='button' onClick={this.addTaskList} className='btn btn-success'><i className='fa fa-plus'></i> Add Task</button>
                            </div>
                            <div className='col-md-3'>
                                <select name='dateType' value={this.state.dateType} onChange={this.handleDate} className='form-control'>
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                    <option value='this_year'>This Year</option>
                                    <option value='last_year'>Last Year</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ReactTable
                                    data={this.state.taskList}
                                    filtered={this.state.filtered}
                                    columns={columns_todo}
                                    defaultPageSize={15}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    onFilteredChange={(filtered, column, value) => {
                                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                                    }}
                                    SubComponent={row => {
                                        const data = row.original;
                                        return (
                                            <div style={{ padding: "20px" }}>
                                                <div className='row m-b-25'>
                                                    <div className='col-md-12'>
                                                        <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                                    </div>
                                                </div>
                                                <ReactTable
                                                    data={row.original.status_log != null ? row.original.status_log : []}
                                                    columns={columns_log}
                                                    defaultPageSize={4}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={false}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalTodo()}
                {this.renderModalUpdate()}
                <ToastContainer />
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(TaskListData)