import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const EnglishCourse = (dataUser) => {
    return (
        <Fragment>
            <Breadcrumb title="English Course" parent="English Course" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs tabClassName="tab-link" activeTabClassName="active">
                            <div className="tabs tab-title">
                            <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-course/home`}>
                                    Home
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-course/register`}>
                                    Register
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-course/grup`}>
                                    Grup
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-course/attendance`}>
                                    Attendance
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`}>
                                    J1 Schedule
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/english-course/`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/english-course/home`} />}                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(EnglishCourse));