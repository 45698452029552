import React from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { List, FileText } from 'react-feather';

const Point = () => {

    return(
        <>
            <div className='page-wrapper'>
                <div className="container-fluid p-0">
                    <Breadcrumb title="Point" parent="Point" />
                    <div className='container-fluid'>
                        <div className="row theme-tab">
                            <div className="col-md-12">
                                <RoutedTabs>
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/point/data-point`} >
                                            <FileText/> List Point
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/point/get-point`}>
                                            <List/> Get Point
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/point`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/point/data-point`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/point/data-point`}/>
                                    <Route path={`${process.env.PUBLIC_URL}/pages/point/get-point`}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
 
}

export default Point;
