import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Loader from '../../../src/components/common/loader'
import { matchSorter } from 'match-sorter';
import { ExportCSV } from '../../../src/services/ExportCSV';
import setRangeDate from '../../helper/general-function';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ScheduleInvoiceStudentGranted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            showLoading: false,
            model: {
                nama: "",
                alamat: "",
                id_propinsi: "",
                id_kabkota: "",
                no_hp: "",
                email: "",
                kota_lahir: "",
                tgl_lahir: "",
                jenis_kelamin: "",
                code_jenpen: "",
                code_kelas: "",
                code_school: "",
                code_leads_source: "",
                code_konsultan: "",
                code_ambassador: "",
                code_students_category: "",
                code_kampus_tujuan: "",
                code_sekolah_asal: "",
                code_kampus_asal: "",
                ket_kampus: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            logName: '',
            toastId: null,
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataConsultant: [],
            dataProvinsi: [],
            dataKabKota: [],
            dataKabKotaFilter: [],
            dataJenPen: [],
            dataSekolah: [],
            dataKampus: [],
            dataKelasSemester: [],
            dataKelasSemesterFilter: [],
            dataLeadsSource: [],
            dataAmbassador: [],
            dataRoles: [],
            dataStatus: [],
            dataProgress: [],
            dataCategory: [],
            dataCampus: [],
            filtered: [],
            searchInput: "",
            checkemail: "",
            pesanEmailError: "",
            renderInputSekolah: "none",
            dropdownOpen: false,
            isOpen: false,
            filterDownload: [],
            id_filter: '',
            date: 'all_time',
            startDate: '',
            endDate: '',
            filterProgress: '',
            filterArea: '',
            filterStatusActive: '',
            listArea: [],
            dataUniversity: [],
            id_school_rep: "",
            row: [],
            modalUpdateStatus: false
        }
    }

    componentDidMount() {
        this.setRangeDate();
        this.getProgress();
        this.loadDataArea();
        this.loadDataUniversityGranted();
    }

    handleInput(e) {
        this.setState({
            [e.target.name] : e.target.value,
            showLoading: true
        }, () => {
            this.setRangeDate()
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    setRangeDate(){
        if(this.state.date === 'all_time') {
            this.setState({ 
                startDate: '2021-07-01',
                endDate: Moment().format('YYYY-MM-DD')
            },() => {
                this.getAllstudents();
            })
        } else {
            let data = setRangeDate(this.state.date);
            this.setState({ 
                startDate: data.start,
                endDate: data.end
            },() => {
                this.getAllstudents();
            })
        }
    }

    getAllstudents = () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate);
        formData.append('end_date', this.state.endDate); 
        formData.append('id_school_rep', this.state.id_school_rep); 

        const codeRoles = this.props.codeRoles
        let url = '/students/progresslog'
        if (codeRoles === 'RL008')
            url = '/ambassador/students-data/' + this.props.dataUser.code_ambassador
        this.setState({ showLoading: true })

        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                data: result,
                showLoading: false
            })
        })
        .catch(error => {
            this.setState({ showLoading: true })
            console.log(error)
        })
    }

    getProgress() {
        const url = API_URL.superStudent + '/master/progress';
        axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.props.dataToken}`
          }
        })
        .then(response => response.data.data)
        .then((data) => {
          this.setState({ dataProgress: data })
        })
    }

    loadDataArea() {
        axios.get(API_URL.superStudent + '/master/area', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ listArea: data })
        })
        .catch(error => console.log(error))
    }

    loadDataUniversityGranted() {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate);
        formData.append('end_date', this.state.endDate); 

        const url = API_URL.superStudent + '/university-has-student-granted';
        axios.post(url, formData, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.props.dataToken}`
          }
        })
        .then(response => response.data.data)
        .then((data) => {
          this.setState({ dataUniversity: data })
        })
    }

    SetFilterProgress = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
          let filterProgress = newData;
          return { filterProgress };
        }, () => {
          this.onFilteredChangeCustom(newData, "code_progress");
        })
    }

    SetFilterArea = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatusActive = newData;
          return { filterStatusActive };
        }, () => {
          this.onFilteredChangeCustom(newData, "area");
        })
    }

    SetFilterUniversity = (e) => {
        this.setState({
            showLoading: true,
            id_school_rep: e.target.value
        }, () => {
            this.getAllstudents();
        })
    }

    SetFilterStatusActive = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
        let filterProgress = newData;
        return { filterProgress };
        }, () => {
            this.onFilteredChangeCustom(newData, "status_invoice");
            this.setState({ filterStatusActive: newData })
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
        let params = '';
        filtered.forEach(element => {
          params += `&${element.id}=${element.value}`
        });
    
        this.setState({ filtered: filtered, filter_params: params }, () => {
          this.getAllstudents()
        });
    }

    updateRow = (e) => {
        this.setState({
            row: e,
            status_invoice: e.status_invoice,
            modalUpdateStatus: true
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('code_students', this.state.row.code_students);
        formData.append('columns', 'status_invoice');
        formData.append('value', this.state.status_invoice);

        let url = '/student/update-students-by-columns'
        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ 
                btnDisabled: false,
                modalUpdateStatus: false
            },() => {
                this.getAllstudents();
            })
        })
        .catch(error => {
            this.setState({ btnDisabled: false })
        })
    }

    renderDialogUpdate() {
        const { modalUpdateStatus, status_invoice } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modalUpdateStatus} toggle={() => this.setState({ modalUpdateStatus: false })}>
                    <ModalHeader toggle={() => this.setState({ modalUpdateStatus: false })}>Update Status Invoice</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status Invoice</label>
                                    <select className="form-control" name="status_invoice" type="text" id="status_invoice"  value={status_invoice} onChange={(e) => this.handleChange(e)}>
                                        <option key={0} value={0}>Active</option>
                                        {
                                            this.props.dataUser.code_roles === 'RL005' ? 
                                            <>
                                                <option key={1} value={1}>Waiting Cancel</option>
                                                <option key={2} value={2}>Cancel</option>
                                            </>
                                            :
                                            <option key={1} value={1}>Cancel</option>
                                        }
                                        
                                    </select>
                                    <span>{this.state.errors.inputStatusPay}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalUpdateStatus: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const columns = [
            {
                Header: 'Code',
                accessor: 'code_students',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }), 
                filterAll: true,
                Cell: (row) => (
                    <button style={{ border: 'none' }} className={`btn btn-outline-primary btn-sm btn-pill`} onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)}>{row.original.code_students}</button>
                ),
            },
            {
                Header: 'Member Code',
                accessor: 'code_member',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_member"] }),
                filterAll: true
            },
            {
                Header: 'Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true
            },
            {
                Header: 'Telp',
                accessor: 'no_hp',
                filterable: true
            },
            {
                Header: 'Area',
                accessor: 'area',
                filterable: true,
                Cell: (row) => (
                  <>{row.original.area}</>
                ),
                Filter: () => 
                <select className='form-control' value={this.state.filterArea || ''} onChange={(e) => this.SetFilterArea(e)}>
                    <option key={0} value="">All Branch</option>
                    {
                        this.state.listArea.length > 0 ?
                            this.state.listArea.map((item, index) => (
                                <option key={index} value={item.area}>{item.area}</option>
                            )) : <></>
                    }
                </select>
            },
            {
                Header: 'Progress',
                accessor: 'code_progress',
                filterable: true,
                Cell: (row) => (
                  <>{row.original.name_progress}</>
                ),
                Filter: () =>
                  <select className='form-control' value={this.state.filterProgress || ''} onChange={(e) => this.SetFilterProgress(e)}>
                    <option key={0} value="">Show All</option>
                    {
                      this.state.dataProgress.map(progress => {
                        return <option key={progress.code} value={progress.code}>{progress.name}</option>
                      })
                    }
                  </select>
            },
            {
                Header: 'Status',
                accessor: 'name_status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name_status"] }),
                filterAll: true,
            },
            {
                Header: 'Tgl Grandted',
                accessor: 'date_grandted',
                width: 150,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    Moment(row.original.date_grandted).format('DD MMMM YYYY')
                )
            },
            {
                Header: 'Tgl Start Study',
                accessor: 'programs[0].pivot.date_program_start',
                width: 150,
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <>
                    {
                        row.original.programs.length > 0 ?
                        Moment(row.original.programs[0].pivot.date_program_start).format('DD MMMM YYYY')
                        :
                        ''
                    }
                    </>
                )
            },
            {
                Header: 'University & Programs',
                filterable: true,
                Cell: (row) => (
                  <ul style={{ overflow: 'auto' }} className='row'>
                    {
                      row.original.programs.length > 0 ?
                        row.original.programs.map((item, index) => (
                          <li style={{ fontSize: '10px' }} className='col' key={index}>({item.university.countrycode}) {item.university.nama_sekolah} - {item.program_name}</li>
                        ))
                        : <></>
                    }
                  </ul>
                ),
                Filter: () => 
                <select className='form-control' value={this.state.id_school_rep || ''} onChange={(e) => this.SetFilterUniversity(e)}>
                    <option key={0} value="">All University</option>
                    {
                        this.state.dataUniversity.length > 0 ?
                            this.state.dataUniversity.map((item, index) => (
                                <option key={index} value={item.id_school_rep}>{item.nama_sekolah}</option>
                            )) : <></>
                    }
                </select>
              },
            {
                Header: 'Consultant',
                accessor: 'nama_konsultant',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_konsultant"] }),
                filterAll: true
            },
            {
                Header: 'Status Active',
                accessor: 'status_invoice',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                    <div style={{cursor: 'pointer'}} onClick={() => this.updateRow(row.original)}>
                    {
                        row.original.status_invoice === 0 ?
                        <>
                            <span className={'badge'} style={{backgroundColor: 'green', color: 'white'}}>Active</span>
                        </>
                        :
                        row.original.status_invoice === 1 ?
                        <>
                            <span className={'badge'} style={{backgroundColor: 'rgb(255, 152, 0)', color: 'white'}}>Waiting Cancel</span>
                        </>
                        :
                        <span className={'badge'} style={{backgroundColor: 'rgb(242, 60, 6)', color: 'white'}}>Cancel</span>
                    }
                    </div>
                ),
                Filter: () => 
                <select className='form-control' value={this.state.filterStatusActive || ''} onChange={(e) => this.SetFilterStatusActive(e)}>
                    <option key={''} value="">All Status</option>
                    <option key={0} value={0}>Active</option>
                    <option key={1} value={1}>Waiting Cancel</option>
                    <option key={2} value={2}>Cancel</option>
                </select>
            },
        ]

        const columns_invoice = [
            {
                Header: 'Code Invoice',
                accessor: 'code_invoice',
                filterable: false
            },
            {
                Header: 'Invoice Name',
                accessor: 'invoice_name',
                filterable: false
            }, 
            {
                Header: 'Due Date',
                accessor: 'due_date',
                filterable: false,
                Cell: (row) => (
                    <span>{Moment(row.original.due_date).format('DD MMMM YYYY')}</span>
                )
            }, 
            {
                Header: 'Invoice Name',
                accessor: 'invoice_amount',
                filterable: false,
                Cell: (row) => (
                    <span>{row.original.currency} {row.original.invoice_amount}</span>
                )
            },
            {
                Header: 'Status',
                accessor: 'status_payment',
                filterable: false,
                Cell: (row) => (
                    <div >
                        {
                            row.status_payment === '1' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'green', color: 'white'}}>Paid</span>
                            </>
                            :
                            row.status_payment === '2' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(255, 152, 0)', color: 'white'}}>Pending</span>
                            </>
                            :
                            row.status_payment === '3' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(242, 60, 6)', color: 'white'}}>Cancel</span>
                            </>
                            :
                            row.status_payment === '4' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(0, 91, 159)', color: 'white'}}>Sent</span>
                            </>
                            :
                            row.status_payment === '5' ?
                            <>
                                <span className={'badge'} style={{backgroundColor: 'rgb(255, 214, 0)', color: 'white'}}>Outstanding</span>
                            </>
                            :
                            <span className={'badge'} style={{backgroundColor: 'rgb(99, 204, 255)', color: 'white'}}>New</span>
                        }
                    </div>
                )
            }, 
            {
                Header: 'Note Payment',
                accessor: 'note_payment',
                filterable: false
            }, 
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className='row mb-3'>
                            <div className="col-md-12">
                                <div className="text-right">
                                    <ExportCSV csvData={this.state.data} fileName={"data-student-grandted-schedulle-invoice"} />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-3">
                                <select name='date' value={this.state.date} onChange={(e) => this.handleInput(e)} className='form-control'>
                                    <option value='all_time'>All Time</option>
                                    <option value='this_week'>This Week</option>
                                    <option value='last_week'>Last Week</option>
                                    <option value='this_month'>This Month</option>
                                    <option value='last_month'>Last Month</option>
                                    <option value='this_year'>This Year</option>
                                    <option value='last_year'>Last Year</option>
                                    <optgroup label='Quartal'>
                                        <option value='1'>Quartal I</option>
                                        <option value='2'>Quartal II</option>
                                        <option value='3'>Quartal III</option>
                                        <option value='4'>Quartal IV</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.data.length > 0 ?
                                            <ReactTable
                                                data={this.state.data}
                                                columns={columns}
                                                filtered={this.state.filtered}
                                                filterable
                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                                SubComponent={row => {
                                                    const data = row.original.loa;
                                                    return (
                                                        <div style={{ padding: "20px" }}>
                                                            <div className='row m-b-25'>
                                                                {
                                                                    data.length > 0 ?
                                                                    data.map(loa => (
                                                                        <div style={{ padding: "20px" }}>
                                                                            <div className='col-md-12 mb-5'>
                                                                                <table className='table table-bordered text-center' style={{border: '2px'}}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Code Student LOA</th>
                                                                                            <th>Date Start</th>
                                                                                            <th>Date Finish</th>
                                                                                            <th>Periode Study</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Commision</th>
                                                                                            <th>Total Invoice</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <th>{loa.code_student_loa}</th>
                                                                                            <th>{Moment(loa.date_start).format('DD MMMM YYYY')}</th>
                                                                                            <th>{Moment(loa.date_finish).format('DD MMMM YYYY')}</th>
                                                                                            <th>{loa.periode_study}</th>
                                                                                            <th>{loa.amount}</th>
                                                                                            <th>{loa.commission}</th>
                                                                                            <th>{loa.total_invoice}</th>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <h6>Detail Invoice</h6>
                                                                            <ReactTable
                                                                                data={loa.invoice}
                                                                                columns={columns_invoice}
                                                                                filterable
                                                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                                                defaultPageSize={15}
                                                                                className="-striped -highlight"
                                                                            />
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <div className='col-md-12' style={{ padding: "20px" }}>
                                                                        <span>Belum Ada Data Invoice</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                             />
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialogUpdate()}
            </Fragment>
        );
    }
};


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(ScheduleInvoiceStudentGranted));
