import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import Breadcrumb from '../../../components/common/breadcrumb';
import { withRouter } from "react-router";
import { getDataUserable } from '../../../actions/login.action';
import ProfileTeacher from './profile-teacher';


class DashboardEnglishDivision extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showComponent: false
        }
    }

    componentDidMount() {
        this.props.getUserableData(this.props.dataToken)
            .then(response => {
                if (this.props.userableData.userable !== undefined) {
                    this.setState({
                        showComponent: true
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb parent="User" title="Teacher Profile" />
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="row">
                                        <ProfileTeacher />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) => {
    return {
        getUserableData: (dataToken) => dispatch(getDataUserable(dataToken))
    }
}
export default connect(reduxState, reduxDispatch)(withRouter(DashboardEnglishDivision));