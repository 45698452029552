import React, { Component } from "react"
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import axios from 'axios'
import API_URL from '../../../ApiUrl'

class EnglishTeacherSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMapping: [],
            filtered: [],
            listProgram: [],
            listTeacher: [],
            listType: [],
            listArea: [],
            filterProgram: '',
            filterType: '',
            filterArea: '',
            filterSchedule: '',
            FilterClass: '',
            filterTeacher: '',
        }
    }

    componentDidMount() {
        this.loadDataMapping()
        this.getProgram()
        this.getTeacher()
        this.getType()
        this.getArea()
    }

    getProgram() {
        axios.get(API_URL.superStudent + '/english-course/list-program', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ listProgram: response.data.data }))
    }

    getArea() {
        const url = API_URL.superStudent + '/master/area';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => this.setState({ listArea: response.data.data }))
    }


    getTeacher() {
        const url = API_URL.superStudent + '/english-course/list-teacher';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => this.setState({ listTeacher: response.data.data }))
    }

    getType() {
        const url = API_URL.superStudent + '/english-course/list-type';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => this.setState({ listType: response.data.data }))
    }

    loadDataMapping() {
        axios.get(API_URL.superStudent + '/english-course/mapping-teacher', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ dataMapping: response.data.data }))
    }

    SetFilterProgram = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterProgram = newData;
            return { filterProgram };
        }, () => {
            this.onFilteredChangeCustom(newData, "nama_program");
        })
    }

    SetFilterTeacher = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterTeacher = newData;
            return { filterTeacher };
        }, () => {
            this.onFilteredChangeCustom(newData, "nama_lengkap");
        })
    }

    SetFilterType = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterType = newData;
            return { filterType };
        }, () => {
            this.onFilteredChangeCustom(newData, "description");
        })
    }

    SetFilterArea = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterArea = newData;
            return { filterArea };
        }, () => {
            this.onFilteredChangeCustom(newData, "area");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    render() {
        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                Filter: () =>
                    <select value={this.state.filterTeacher || ''} onChange={(e) => this.SetFilterTeacher(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listTeacher.map(item => {
                                return <option key={item.id_teacher} value={item.nama_lengkap}>{item.nama_lengkap}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Program',
                accessor: 'nama_program',
                Filter: () =>
                    <select value={this.state.filterProgram || ''} onChange={(e) => this.SetFilterProgram(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listProgram.map(item => {
                                return <option key={item.id_program} value={item.nama_program}>{item.nama_program}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Type',
                accessor: 'description',
                Filter: () =>
                    <select value={this.state.filterType || ''} onChange={(e) => this.SetFilterType(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listType.map(item => {
                                return <option key={item.id_type} value={item.description}>{item.description}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Area',
                accessor: 'area',
                Filter: () =>
                    <select value={this.state.filterArea || ''} onChange={(e) => this.SetFilterArea(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listArea.map(item => {
                                return <option key={item.id_area} value={item.area}>{item.area}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Kategori',
                accessor: 'schedule',
                filterable: false
            },
        ];

        return (
            <>
                <div className="card">
                    <div className="card-header">
                        <h5>Mapping Teacher</h5>
                    </div>
                    <div className="card-body">
                        <ReactTable
                            data={this.state.dataMapping}
                            filtered={this.state.filtered}
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            onFilteredChange={(filtered, column, value) => {
                                this.onFilteredChangeCustom(value, column.id || column.accessor);
                            }}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                        />
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(EnglishTeacherSchedule);
