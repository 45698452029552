
import React, { useEffect, useState } from "react"
import CardStatic from "../partition/card-static";
import Breadcrumb from "../../common/breadcrumb";
import TaskList from "./task-list";
import CalendarEvent from "./calendar-event";
import TaskListConsultant from '../../../pages/task-list/list-data-consultant';
import setRangeDate from "../../../helper/general-function";
import Moment from 'moment';

const DashboardCreative = () => {

    const[typeDate,setTypeDate] = useState('this_year')
    const[filterDate,setFilterDate] = useState({start_date: '', end_date: ''})

    useEffect(() => {
        let data = setRangeDate(typeDate);
        setFilterDate({start_date:data.start, end_date: data.end });
    },[typeDate])

    return(
        <>
            <div className="container-fluid">
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-3">
                        <select name='date' value={typeDate} onChange={e => setTypeDate(e.target.value)} className='form-control mb-1'>
                            <option value='this_week'>This Week</option>
                            <option value='last_week'>Last Week</option>
                            <option value='this_month'>This Month</option>
                            <option value='last_month'>Last Month</option>
                            <option value='this_year'>This Year</option>
                            <option value='last_year'>Last Year</option>
                            <optgroup label='Quartal'>
                                <option value='1'>Quartal I</option>
                                <option value='2'>Quartal II</option>
                                <option value='3'>Quartal III</option>
                                <option value='4'>Quartal IV</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-sm-12">
                        <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(filterDate.start_date).format('DD MMMM yyyy')} - {Moment(filterDate.end_date).format('DD MMMM yyyy')}</span>
                    </div>
                </div>
            </div>
            <Breadcrumb parent="Dashboard Creative" title = "Dashboard Creative" />
            <CardStatic/>
            <TaskListConsultant start_date={filterDate.start_date} end_date={filterDate.end_date} />
            <CalendarEvent/>
            <TaskList/>
            
        </>
    )

}

export default DashboardCreative;