/* eslint-disable eqeqeq, array-callback-return */

import React, { Component }  from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';

class ReportSosmed extends Component {

    constructor(props){
        super(props)
        this.state = {
            listSosmed:[],
            listParameter:[],
            dataReport:[],
            formParameter:[],
            dataParameter:[],
            id_sosmed:1,
            form_id_sosmed:'',
            id_parameter:'',
            modal_add:false
        }
    }

    loadSosmed = () => {
        axios.get(API_URL.superStudent+'/list-sosmed',{
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({listSosmed:result}))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        },() => this.loadReport())
    }

    handleChange = (id,index,e) => {
        let data = [...this.state.dataParameter]
        data[index].value = e.target.value
        data.push()
    }

    loadParameter = () => {
        axios.get(API_URL.superStudent+'/paramter-sosmed-report',{
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response =>  response.data.data)
        .then(result => this.setState({listParameter:result}))
        .catch(error => console.log(error))
    }

    loadReport = () =>{
        let formData = new FormData()
        formData.append('id_sosmed',this.state.id_sosmed)
        axios.post(API_URL.superStudent+'/sosmed-report',formData,{
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({dataReport: result})
        })
        .catch(error => console.log(error))
    }

    setParameter = (e) => {
        this.setState({ form_id_sosmed: e.target.value, dataParameter:[]},() => {
            let data = this.state.listParameter.filter(data => {
                return data.social_media_id == this.state.form_id_sosmed
            })
            data.map((item) => {
                this.setState(prevState => ({
                    dataParameter:[
                        ...prevState.dataParameter,
                        {
                            id:item.id,
                            value:0
                        }
                    ]
                }),() => this.setState({formParameter:data}))
            })
        })
    }

    saveData = (e) => {
        let formData = new FormData()
        formData.append('data_parameter', JSON.stringify(this.state.dataParameter))
        axios.post(API_URL.superStudent+'/store-report-sosmed',formData,{
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response =>{
            toast.success('data added successfully')
            this.loadReport()
            this.setState({modal_add:false})
        })
        .catch(error => {
            toast.error('there is an error')
        })
    }

    renderModalAdd(){
        return(
            <div className="card-body btn-showcase">
            <Modal isOpen={this.state.modal_add} toggle={() => this.setState({modal_add:false})}>
                <ModalHeader toggle={() => this.setState({modal_add:false})}>Add Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Social Media Type</label>
                                <select className='form-control' onChange={this.setParameter} name='form_id_sosmed' value={this.state.form_id_sosmed}>
                                        <option key={0} value='0'>--Select--</option>
                                    {
                                        this.state.listSosmed.length > 0 ?
                                        this.state.listSosmed.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                        :''
                                    }
                                </select>
                            </div>
                                <div className='row'>
                                {
                                    this.state.formParameter.length > 0 ?
                                    this.state.formParameter.map((parameter, index) => (
                                        <div key={parameter.id} className='col-md-6'>
                                            <div className='form-group'>
                                                <input className='form-control' onChange={e => this.handleChange(parameter.id,index,e)} placeholder={parameter.description}/>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    ''
                                }
                                </div>

                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={(e) => this.saveData()}>Save</Button>
                <Button  color="secondary" onClick={() => this.setState({modal_add:false})}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </div>
        )
    }

    componentDidMount(){
        this.loadSosmed()
        this.loadReport()
        this.loadParameter()
    }

    render(){    
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <button className='btn btn-success' onClick={() => this.setState({modal_add:true}) }>Add Data</button>
                                        </div>
                                        <div className='col-md-6'></div>
                                        <div className='col-md-3'>
                                            <div className="select2-drpdwn-project select-options">
                                                <select onChange={this.handleInput} value={this.state.id_sosmed} className="form-control form-control-primary btn-square" name="id_sosmed">
                                                    <option key={-1} value=''>--Select--</option>
                                                    {
                                                        this.state.listSosmed.length > 0 ?
                                                        this.state.listSosmed.map((list, index) => (
                                                            <option key={index} value={list.id}>{list.name}</option>
                                                        )):''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="500px"
                                    data={this.state.dataReport}
                                    options={{
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
                {this.renderModalAdd()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null)(ReportSosmed);
