import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from "file-saver";
import CKEditors from "react-ckeditor-component";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Progress } from 'reactstrap';

class ListPeriode extends Component{

    constructor(props){
        super(props)
        this.state = {
            userList:[],
            jobdesk: [],
            handle_by:[],
            title:'',
            jobdeskType: 'all',
            description:'',
            code_jobdesk:'',
            attachment:'',
            due_date:'',
            modal_update:false,
            modal_delete:false,
            modal_todo:false,
            btnAddDisabled:false,
            text_save: 'Save',
            btnTextSave:'Add',
            category_display:'none',
            showAll:false,
            startDate: Moment().startOf('isoWeek'),
            endDate: Moment().endOf('isoWeek'),
        }
    }

    componentDidMount(){
        this.loadTodoList()
        this.loadUserList()
        this.loadJobdesk()
    }   

    loadJobdesk = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({jobdesk:response.data.data}))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setHandleBy = (e) => {
        e.map(item => {
            return delete item.jobdesk
        })
        this.setState({
            handle_by:e
        });
    }

    handleType = (e) => {
        this.setState({
            jobdeskType: e.target.value
        }, () => this.loadTodoList())
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    loadTodoList = () =>{
        axios.get(API_URL.superStudent+'/todo/todo-periode/'+this.state.jobdeskType, {
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            if(this.state.showAll === false){
                result = result.filter(item => {
                    return item.progress !== 100;
                })
            }
            this.setState({taskList: result})
        })
        .catch(error => console.log(error))
    }

    loadUserList = () => {
        let formData = new FormData()
        formData.append('jobdesk',JSON.stringify(['JD002','JD003','JD004','JD006','JD007','JD008','JD009','JD010','JD011','JD012','JD015','JD016','JD017','JD018']))
        axios.post(API_URL.superStudent+'/consultant/jobdesk', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({userList: result}))
        .catch(error => console.log(error))
    }

    saveUpdate(){
        let formData = new FormData()
        formData.append('task_periode_id', this.state.id_todo)
        formData.append('handle_by', JSON.stringify(this.state.handle_by))
        formData.append('description', this.state.description)
        this.setState({
            text_save: 'Saving..',
            btnSave:true,
        })
        axios.post(API_URL.superStudent+'/todo/set-sub-task', formData,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            toast.success('Task list Updated successfully')
            this.setState({
                modal_update:false,
                text_save: 'Save',
                btnSave:false,
            })
            this.loadTodoList()
        })
        .catch(error => {
            this.setState({
                modal_update:false,
                text_save: 'Save',
                btnSave:false,
            })
            toast.error('there is an error')
        })  
    }

    saveData = () => {
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('description', this.state.description)
        formData.append('code_jobdesk', this.state.code_jobdesk)
        formData.append('attachment', this.state.attachment)
        formData.append('due_date',this.state.due_date)
        this.setState({
            btnAddDisabled:true,
            btnTextSave: 'Adding...'
        })
        axios.post(API_URL.superStudent+'/todo/todo-periode',formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
          })
          .then(response => {
              this.setState({
                  modal_todo:false,
                  btnAddDisabled:false,
                  btnTextSave: 'Add'
              })
              this.loadTodoList()
            toast.success('task list add successfully')
          })
          .catch(error => {
            this.setState({
                modal_todo:false,
                btnAddDisabled:false,
                 btnTextSave: 'Add'
            })
            toast.error('there is an error')
          })
    }

    saveDelete = () => {
        axios.delete(API_URL.superStudent+'/todo/delete-periode/'+this.state.id_todo,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.setState({ modal_delete:false})
            this.loadTodoList()
            toast.success('task list deleted successfully')
        })
        .catch(() => {
            toast.error('there is an error')
        })
    }

    handleAttachment = (e) => {
        this.setState({
            attachment: e.target.files[0]
        })
    }

    renderModalTodo(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_todo} toggle={() => this.setState({ modal_todo: false })} >
                <ModalHeader toggle={() => this.setState({ modal_todo: false })}>Add Task</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='form-group'>
                                    <label className='control-label'>Title</label>
                                    <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Divisions</label>
                                    <select className='form-control' type='text' name='code_jobdesk' value={this.state.code_jobdesk} onChange={this.handleInput}>
                                        <option key={0} value=''> --select-- </option>
                                        {
                                        this.state.jobdesk.map(job => (
                                            <option key={job.code_jobdesk} value={job.code_jobdesk}>{job.jobdesk}</option>
                                        ))
                                    }
                                    </select>
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Description</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.description}
                                        events={{
                                                "change": this.onChange.bind(this),
                                        }}
                                        config={{
                                            toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Due Date</label>
                                    <input type='date' name='due_date' value={this.state.due_date} onChange={this.handleInput} className='form-control' />
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Resources</label>
                                    <input name='attachment' className='form-control' type='file' onChange={this.handleAttachment}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_todo: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalUpdate(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_update} toggle={() => this.setState({ modal_update: false })}>
                <ModalHeader toggle={() => this.setState({ modal_update: false })}>Add Sub Task Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='f-w-600'>Description</label>
                                <textarea name='description' value={this.state.description} onChange={this.handleInput} className='form-control' />
                            </div>
                            <div className='form-group'>
                                    <label className='control-label'>Handle By</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama"
                                        multiple
                                        selected={this.state.handle_by}
                                        name="code_konsultan"  
                                        options={this.state.userList}
                                        onChange={this.setHandleBy}
                                        placeholder="can choose several users."
                                    />
                                </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnSave} color="primary" onClick={(e) => this.saveUpdate()}>{this.state.text_save}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_update: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalDelete(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_delete} toggle={() => this.setState({ modal_delete: false })}>
                <ModalHeader toggle={() => this.setState({ modal_delete: false })}>Delete Task</ModalHeader>
                <ModalBody>
                    yakin ingin menghapus data ?
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnSave} color="danger" onClick={(e) => this.saveDelete()}>Delete</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_delete: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    handleUpdate = (e) => {
        this.setState({
            id_todo: e.id,
            handle_by:'',
            description:'',
            modal_update:true
        })
    }

    handleDelete = (e) => {
        this.setState({
            id_todo: e.id,
            modal_delete:true
        })
    }

    addTaskList = () => {
        this.setState({
            modal_todo:true,
            title:'',
            description:'',
            due_date:'',
            category_type:'',
            attachment:'',
            resources:'',
        })
    }

    downloadFile = (row) => {
        let filename = row.substring(row.lastIndexOf('/'))
        saveAs(row,filename);
    }

    formatHandle = () => {
        let data = `<span className='badge badge-light'>Adi</span>`;
        return <div dangerouslySetInnerHTML={{__html:data}}></div>
    }

    setColor = (value) => {
        let color = '';
        if(value <= 25)
            color ='#ff0000';
        else if(value <= 50)
            color = '#ff9100';
        else if(value <= 75)
            color = '#15008a';
        else if (value <= 99)
            color = '#2200ff';
        else
            color = '#00610d';
        return color;
    }

    setStatus = (row) => {
        let formData = new FormData()
        formData.append('id', row.id)
        formData.append('status', row.status)
        axios.post(API_URL.superStudent+'/todo/set-sub-task-status', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.loadTodoList()
        })
        .catch(() => {
            toast.error('there is an error')
        })
    }

    setDelete = (row) => {
        axios.delete(API_URL.superStudent+'/todo/delete-sub-periode/'+row.id,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.loadTodoList()
        })
        .catch(() => {
            toast.error('there is an error')
        })
    }

    handleShow = (show) => {
        this.setState({
            showAll: !show
        }, () => this.loadTodoList())
    }

    render(){
        const columns_todo = [
            {
                Header: 'Crated at',
                accessor: 'created_at',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    <>
                        <span>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p>
                    </>
                )
            },
            {
                Header: 'Title',
                accessor: 'title',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    row.original.status_approval === 0 ? <span>{row.original.title}<sup> <i style={{color:'red'}} className='fa fa-exclamation'></i></sup></span> : <span>{row.original.title}</span>
                )
            },
            {
                Header: 'Progress',
                accessor: 'progress',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <Progress multi style={{ height: "15px"}}>
                        <Progress animated style={{backgroundColor:this.setColor(row.original.progress)}} bar value={row.original.progress}> {row.original.progress} %</Progress>
                        <Progress animated bar style={{backgroundColor:'#d6d6c2'}} value={100 - row.original.progress} />
                    </Progress>
                )
            },
            {
                Header: 'Due Date',
                accessor: 'due_date',
                style:{textAlign:'center'},
                width:90,
                Cell:(row) => (
                    <>
                        <span>{Moment(row.original.due_date).format('DD MMM YY')}</span>
                    </>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                width:120,
                Cell: (row) => (
                    <>
                        {
                        row.original.attachment === null ? '' : <button
                        onClick={()=> this.downloadFile(row.original.attachment)} 
                          className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-file-text-o fa-2x"></i>
                        </button> 
                        }
                        <button
                        onClick={()=> this.handleUpdate(row.original)} 
                          className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus fa-2x"></i>
                        </button>
                        <button className='btn btn-outline-danger btn-xs' type='button' style={{border:'none'}} onClick={() => this.handleDelete(row.original) }>
                            <i className="fa fa-trash fa-2x"></i>
                        </button>
                    </>
                ),
                style: {textAlign: 'center'},
                sortable: false
            },
        ]

        return(
            <Fragment>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <button type='button' onClick={this.addTaskList} className='btn btn-success'><i className='fa fa-plus'></i> Add Task</button>
                            </div>
                        </div>
                    
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                        <button onClick={() => this.handleShow(this.state.showAll)} style={{marginBottom:'5px',marginLeft:'15px'}} className='btn btn-primary'><i className={`fa fa-eye${this.state.showAll === true ? '' : '-slash'}`}></i></button>
                            <div className='col-md-12'>
                                <ReactTable
                                    data={this.state.taskList}
                                    columns={columns_todo}   
                                    defaultPageSize={15}
                                    collapseOnDataChange={false}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    SubComponent={row =>{
                                        const data = row.original;
                                        return (
                                        <>
                                        <div className='row m-5'>
                                            <div className='col-md-12'>
                                                <div dangerouslySetInnerHTML={{__html:data.description}}></div>
                                            </div>
                                        </div>
                                        <div className='row m-5'>
                                            <div className='col-md-12'>
                                                <div className='todo'>
                                                    <div className='todo-list-wrapper'>
                                                        <ul id="todo-list">
                                                    {
                                                    data.sub_tasks.length > 0 ?
                                                    data.sub_tasks.map(item => (
                                                        <li key={item.id} className={item.status === 0 ? 'task pending' : 'task completed'}>
                                                            <div className="task-container">
                                                                <h6 style={{fontSize:'13px'}}  className="task-label">{item.description} &nbsp;
                                                                {
                                                                    item.handle_by != null ?
                                                                    JSON.parse(item.handle_by).map(item => (
                                        <span key={item.code_konsultan} className='badge badge-dark'>{item.nama}</span>
                                                                    ))
                                                                    :<></>
                                                                }
                                                                </h6>
                                                                <span className="task-action-btn">
                                                                    <span disabled='disabled' className="action-box complete-btn" onClick={() => this.setStatus(item)} >
                                                                    <i className="icon"><i className="icon-check"></i></i>
                                                                    </span>
                                                                    <span style={{border:'none',color:'black'}} className="action-box complete-btn" onClick={() => this.setDelete(item)} >
                                                                    <i className="icon"><i className="icon-trash"></i></i>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </li>
                                                    ))
                                                    :
                                                    ''
                                                    }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalTodo()}
                {this.renderModalUpdate()}
                {this.renderModalDelete()}
                <ToastContainer/>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null)(ListPeriode)