import React, { Component,Fragment } from 'react';
import man from '../../../assets/images/logo192.png'
import { connect } from 'react-redux';
import { withRouter } from "react-router";

class UserPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        const currentUser = this.props.dataUser.username || this.props.dataUser.nama;
        const roles = this.props.dataUser.roles || 'Student';
        return (
            <Fragment>
                <div className="sidebar-user text-center">
                    <div>
                        <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
                    </div>
                    <h6 className="mt-3 f-14">{currentUser}</h6>
                    <p>{roles}</p>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
  })
  
  export default connect(reduxState, null)(withRouter(UserPanel));