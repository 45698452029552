import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { List, Home } from 'react-feather';

const SchoolRep = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <Breadcrumb title="Data Instansi" parent="SchoolRep" />
                    <div className='container-fluid'>
                        <div className="row theme-tab">
                            <div className="col-md-12">
                                <RoutedTabs>
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} >
                                            <Home/> University
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`}>
                                            <List/> Education Group
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/schoolrep`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`}/>
                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SchoolRep;