/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps */
import React ,{ useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import API_URL from '../../../ApiUrl'
import axios from 'axios'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import SweetAlert from 'react-bootstrap-sweetalert'

const AdmissionReport = ({start_date, end_date}) => {

    const[data, setData] = useState([])
    const[loader,setLoader] = useState(false)
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const loadAdmissionReport = () => {
        let formData = new FormData ()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)

        axios.post(API_URL.superStudent+'/granted-task', formData,{
            headers:{ Authorization: `Bearer `+ dataToken }
        })
        .then(response => response.data.data)
        .then(response => {
            setData(response);
        })
        .catch(error => {
            this.setState({showLoading:false})
        })
    }

    const normalisasi = (value) => {
        return value.toFixed(2);
    }

    useEffect(() => {
        loadAdmissionReport()
    },[start_date, end_date])

    return (
        <>
            <SweetAlert title="Loading..." showConfirm={false} showCancel={false} show={loader} />
            <div className='card'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Admission Peformance</h6>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table style={{fontSize:'12px'}} className='table table-striped table-hover table-borderedfor'>
                            <thead className='tbl-strip-thad-bdr'>
                                <tr className='table-secondary'>
                                    <th scope='col'>#</th>
                                    <th scope="col">Instansi</th>
                                    <th scope="col">Edu Group</th>
                                    <th scope="col">Application</th>
                                    <th scope="col">Apply Campus</th>
                                    <th scope="col">LoA</th>
                                    <th scope="col">School Fee</th>
                                    <th scope="col">Lodge Visa</th>
                                    <th scope="col">Granted</th>
                                    <th scope="col">Student Departure</th>
                                    <th scope="col">Start Study</th>
                                    <th scope="col">Rejected</th>
                                    <th scope="col">Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 ?
                                    data.map((items,index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td> 
                                            <td>{items.nama_sekolah}</td> 
                                            <td>{items.edu_group}</td> 
                                            <td><span style={{color:'#7c9ed9'}}>{items.total_todo}</span> | <span style={{color:'red'}}>{items.target_application}</span></td> 
                                            <td>{items.apply_campus}</td> 
                                            <td>{items.LoA}</td> 
                                            <td>{items.school_fee}</td> 
                                            <td>{items.lodge_visa}</td> 
                                            <td>{items.granted}</td> 
                                            <td>{items.student_departure}</td> 
                                            <td>{items.start_study}</td> 
                                            <td>{items.rejected}</td>
                                            <td>{normalisasi((items.granted / items.total_todo) * 100) + ' %'}</td>
                                        </tr>
                                    ))
                                    :
                                    <td colSpan={7}>Data Kosong</td>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdmissionReport;