/* eslint-disable  no-unused-vars */

import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, User } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const VisitSchool = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="School Visit" parent="School Visit" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs
                            tabClassName="tab-link"
                            activeTabClassName="active">
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/visitschool/home`}>
                                    <Home /> Home
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/visitschool/list`}>
                                    <User /> School Activities
                                </NavTab>
                            </div>
                        </RoutedTabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(VisitSchool));