import Moment from 'moment';

export const setRangeDate =(type_date) => {   
    let start_date = Moment();
    let end_date  = Moment();     
    switch(type_date){
        case 'this_week':
        case 1:
            start_date.startOf('isoWeek')
            end_date.endOf('isoWeek')
            break;
        case 'last_week':
            start_date.subtract(1, 'weeks').startOf('isoWeek')
            end_date.subtract(1, 'weeks').endOf('isoWeek')
            break;
        case 'this_month':
        case 2:
            start_date.startOf('month')
            end_date.endOf('month')
            break;
        case 'last_month':
            start_date.subtract(1, 'months').startOf('month')
            end_date.subtract(1, 'months').endOf('month')
            break;
        case 'this_year':
        case 4:
            start_date.startOf('year').add(-6,'months')
            end_date.endOf('year').add(-6,'months')
            break;
        case 'all_data':
            start_date.subtract(1, 'year').startOf('year').add(-6,'months')
            end_date.endOf('year').add(-6,'months')
            break;
        case 'last_year':
        case 5:
            start_date.subtract(1, 'year').startOf('year').add(-6,'months')
            end_date.subtract(1, 'year').endOf('year').add(-6,'months')
            break;
        case '1':
        case '2':
        case '3':
        case '4':
            start_date.quarter(type_date).add(2,'quarter').startOf('quarter')
            end_date.quarter(type_date).add(2, 'quarter').endOf('quarter')
            break;
        default:
            start_date.startOf('isoWeek')
            end_date.endOf('isoWeek')
    }
    let start = start_date.format('YYYY-MM-DD')
    let end =  end_date.format('YYYY-MM-DD')
    return {
        start,end
    }
}

export default setRangeDate;
