/* eslint-disable eqeqeq, jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react'
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import Countup from 'react-countup';
import { ExportCSV } from '../../../services/ExportCSV';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './styles/index.css'
import Moment from 'moment';
import { Button } from 'reactstrap'
import { connect } from 'react-redux';

class DataTableTalentMapping extends Component{

    constructor(props){
        super(props)
        this.state = {
            data:[],
            filtered:[],
            startDate:'',
            endDate:'',
            hasil_mbti_id: '',
            ccis_filter: '',
            holland_filter: '',
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setFilterMbti = (e) => { 
      const newData = e.target.value;
      this.setState(prevState => {
        let filterMbti = newData; 
        return { filterMbti };                   
        }, () => {
          this.onFilteredChangeCustom(newData,"hasil_mbti_id");
        })
    }

    setFilterCcis = (e) => { 
      const newData = e.target.value;
      this.setState(prevState => {
        let filterCcis = newData; 
        return { filterCcis };                   
        }, () => {
          this.onFilteredChangeCustom(newData,"hasil_ccis_id");
        })
    }

    setFilterHolland = (e) => { 
      const newData = e.target.value;
      this.setState(prevState => {
        let filterHolland = newData; 
        return { filterHolland };                   
        }, () => {
          this.onFilteredChangeCustom(newData,"hasil_holland_id");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
      console.log(value, accessor)
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
      };

    componentDidMount(){
        this.getDataTalentMapping()
    }

    getDataTalentMapping(data = null){
        axios.post(API_URL.superStudent+'/get-data-mapping',data,{
          headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                data: response.data.data
            })
        })
    }

    handleInput = (e) => {
      this.setState({
          [e.target.name] : e.target.value
      })  
    }
  
    filter = () => {
      var formData = new FormData();
      formData.append('start_date', this.state.startDate)
      formData.append('end_date', this.state.endDate)
      this.getDataTalentMapping(formData)
   }

    render(){
        const columns = [
            {
              Header: 'Code Member',
              accessor: 'member_id',
              style: {textAlign: 'center'},
              filterable: true
            },
            {
              Header: 'Nama Lengkap',
              accessor: 'nama_lengkap',
              minWidth:200,
              style: {textAlign: 'left'},
              filterable: true
            },
            {
              Header: 'Telepon',
              accessor: 'no_wa',
              style: {textAlign: 'center'},
              filterable: true
            },
            {
              Header: 'Email',
              accessor: 'email',
              style: {textAlign: 'center'},
              filterable: true
            },
            {
                Header: 'MBTI',
                accessor: 'hasil_mbti_id',
                filterable: true,
                Cell: (row) => (
                  row.original.hasil_mbti_id == '1' ? <i className="fa fa-check fa-2x success-icon"></i> : <i className="fa fa-remove fa-2x danger-icon"></i>
                ),
                Filter: () => 
                <select value={this.state.filterMbti || ''} onChange={(e) => this.setFilterMbti(e)}>
                    <option value="">Semua</option>
                    <option value="1">Sudah</option>
                    <option value="0">Belum</option>
                </select>,
                style: {textAlign: 'center'},
              },
              {
                Header: 'CCIS',
                accessor: 'hasil_ccis_id',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                  row.original.hasil_ccis_id == '1' ?  <i className="fa fa-check fa-2x success-icon"></i> : <i className="fa fa-remove fa-2x danger-icon"></i>
                ),
                Filter: () => 
                <select value={this.state.filterCcis || ''} onChange={(e) => this.setFilterCcis(e)}>
                    <option value="">Semua</option>
                    <option value="1">Sudah</option>
                    <option value="0">Belum</option>
                </select>,
              },
              {
                Header: 'Holland',
                accessor: 'hasil_holland_id',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                  row.original.hasil_holland_id == '1' ? <i className="fa fa-check fa-2x success-icon"></i> : <i className="fa fa-remove fa-2x danger-icon"></i>
                ),
                Filter: () => 
                <select value={this.state.filterHolland || ''} onChange={(e) => this.setFilterHolland(e)}>
                    <option value="">Semua</option>
                    <option value="1">Sudah</option>
                    <option value="0">Belum</option>
                </select>,
              },
          ]
        return (
            <Fragment>
                <div className="row m-1">
                  <div className="col-md-3 col-sm-6 mb-1">
                    <h4>Total Data : <span className="badge badge-info"> <Countup end={this.state.data.length}/> </span></h4>
                  </div>
                  <div className="col-md-3 col-sm-6 mb-1">
                    <ExportCSV csvData={this.state.data} fileName={"data-talent-mapping"} />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-1">
                    <div style={{marginBottom:'10px'}} className="row">
                      <div className="col-md-4 col-sm-6 mb-2">
                        <input className="form-control" type="date" onChange={this.handleInput} name="startDate"  value ={this.state.startDate} />
                      </div>
                      <div className="col-md-4 col-sm-6 mb-2">
                        <input className="form-control" type="date" onChange={this.handleInput} name="endDate"  value ={this.state.endDate} />
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <Button onClick={() => this.filter()}  color="primary"><i className="fa fa-filter"></i></Button>
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <a><Button color="primary"><i className="fa fa-file"></i></Button></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <ReactTable
                      data={this.state.data}
                      filtered={this.state.filtered}
                      filterable
                      columns={columns}
                      defaultPageSize={10}
                      className="-striped -highlight"
                      onFilteredChange={(filtered, column, value) => {
                        this.onFilteredChangeCustom(value, column.id || column.accessor);
                      }}
                      SubComponent={row => {
                        return (
                          <div style={{ padding: "20px" }}>
                          <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div align="right" style={{ color:"black" }}>Tanggal Tes MBTI</div>
                                  </div>
                                  <div className="col-md-1">
                                    <div align="center">:</div>
                                  </div>
                                  <div className="col-md-6">
                                    <div align="left">{row.original.mbti_tanggal != null ? Moment(row.original.mbti_tanggal).format('DD/MM/YYYY') : ''}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div align="right" style={{ color:"black" }}>Tanggal Tes CCIS</div>
                                  </div>
                                  <div className="col-md-1">
                                    <div align="center">:</div>
                                  </div>
                                  <div className="col-md-4">
                                    <div align="left">{row.original.ccis_tanggal != null ? Moment(row.original.ccis_tanggal).format('DD/MM/YYYY') : ''}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div align="right" style={{ color:"black" }}>Tanggal Tes Holland</div>
                                  </div>
                                  <div className="col-md-1">
                                    <div align="center">:</div>
                                  </div>
                                  <div className="col-md-4">
                                    <div align="left">{row.original.holland_tanggal != null ? Moment(row.original.holland_tanggal).format('DD/MM/YYYY') : ''}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                      }}
                    />
                  </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null)(DataTableTalentMapping)