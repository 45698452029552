import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import Moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DataRevenue extends Component{

    constructor(props){
        super(props)
        this.state = {
            title: '',
            amount:0,
            description:'',
            input_date:'',
            filtered:[],
            id_area:'',
            category:'',
            dataArea:[],
            revenueData:[],
            modalAdd:false,
            filterArea:'',
            filterCategory:''
        }
    }

    componentDidMount(){
        this.loadRevenueData()
        this.loadDataArea()
    }

    loadDataArea(){
        axios.get(API_URL.superStudent+'/master/area',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({dataArea: response.data.data}))
        .catch(error => console.log(error));
    }

    loadRevenueData = () => {
        axios.get(API_URL.superStudent+'/accounting/revenues',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({revenueData: response.data.data}))
        .catch(error => console.log(error));
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit = (e) => {
        let formData = new FormData ()
        formData.append('title', this.state.title)
        formData.append('description', this.state.description)
        formData.append('amount', this.state.amount)
        formData.append('category', this.state.category)
        formData.append('id_area', this.state.id_area)
        formData.append('input_date', this.state.input_date)
        axios.post(API_URL.superStudent+'/accounting/revenues', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.loadRevenueData()
            toast.success('successfully add data')
        })
        .catch(() => {
            this.closeModal()
            toast.error('there is an error')
        })

    }

    
    SetFilterArea = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterArea = newData; 
          return { filterArea };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"id_area");
          })
    }

    SetFilterCategory = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterCategory = newData; 
          return { filterCategory };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"category");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    addData = () => {
        this.setState({
            title:'',
            amount:0,
            description:'',
            input_date:'',
            id_area:'',
            category:'',
            modalAdd: true
        })
    }

    closeModal(){
        this.setState({
            modalAdd:false
        })
    }

    renderModalAdd(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalAdd} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Form Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Title</label>
                                <input type='text' className='form-control' onChange={this.handleInput} name='title' value={this.state.title} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Amount</label>
                                <input type='number' className='form-control' onChange={this.handleInput} name='amount' value={this.state.amount} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Area</label>
                                <select name='id_area' onChange={this.handleInput} value={this.state.id_area} className='form-control'>
                                    <option value=''>Select Area</option>
                                    {
                                    this.state.dataArea.length > 0 ?
                                    this.state.dataArea.map(item => (
                                        <option key={item.id_area} value={item.id_area}>{item.area}</option>
                                    ))
                                    :
                                    ''
                                }
                                </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Category</label>
                                <select name='category' onChange={this.handleInput} value={this.state.category} className='form-control'>
                                    <option value=''>Select Category</option>
                                    <option value='revenue'>Revenue</option>
                                    <option value='expense'>Expanse</option>
                                </select>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Description</label>
                                <textarea className='form-control' rows={5} value={this.state.description} onChange={this.handleInput} name='description'/>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Date</label>
                                <input type='date' className='form-control' onChange={this.handleInput} name='input_date' value={this.state.input_date} />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e) => this.handleSubmit()}>Add</Button>
                  <Button  color="secondary" onClick={() => this.closeModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currencyDisplay:'narrowSymbol',
            currency: 'IDR',
        }).format(nominal);
    }

    removeTransaction = (id) => {
        axios.delete(API_URL.superStudent+'/accounting/revenues/'+id, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.loadRevenueData()
            toast.success('successfully delete data')
        })
        .catch(() => {
            toast.error('there is an error')
        })
    }

    render(){
        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                filterable:false,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                style: {textAlign: 'center'},
                filterable:false,
                Cell:(row) => (
                    this.formatRupiah(row.original.amount)
                )
            },
            {
                Header: 'Area',
                accessor: 'id_area',
                style: {textAlign: 'center'},
                Filter: () => 
                <select value={this.state.filterArea || ''} onChange={(e) => this.SetFilterArea(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataArea.map( item => {
                            return <option key={item.id_area} value={item.id_area}>{item.area}</option>
                        })
                    } 
                </select>,
                Cell:(row) => (
                    <>{row.original.area.area}</>
                )
            },
            {
                Header: 'Category',
                accessor: 'category',
                style: {textAlign: 'center'},
                Filter: () => 
                <select value={this.state.filterCategory || ''} onChange={(e) => this.SetFilterCategory(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key='revenue' value='revenue'>Revenue</option>
                    <option key='expense' value='expense'>Expense</option>
                </select>,
                Cell:(row) => (
                    <>{row.original.category === 'revenue' ? <span className={'badge bg-info'}>Revenue</span> : <span className={'badge bg-danger'}>Expense</span>}</>
                )
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {textAlign: 'center'},
                filterable:false,
            },
            {
                Header: 'Tanggal',
                accessor: 'insert_date',
                style: {textAlign: 'center'},
                filterable:false,
                Cell: (row) =>(
                    <>{Moment(row.original.insert_date).format('DD MMM YYYY')}</>
                )
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                filterable:false,
                accessor: str => "delete",
                width:70,
                Cell: (row) => (
                    <>
                        <button  onClick={() => this.removeTransaction(row.original.id)} className="btn btn-danger btn-xs" type="button" ><i className="fa fa-remove"></i></button>
                    </>
                ),
                style: { textAlign: 'center' }
            }
        ]
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <button className='btn btn-success' onClick={this.addData}>Add Data</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.revenueData}
                                        filterable
                                        filtered={this.state.filtered}
                                        columns={columns}
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    /> 
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
                {this.renderModalAdd()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(DataRevenue);