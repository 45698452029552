import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import {  FileText, List } from 'react-feather';
import Breadcrumb from '../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
class Training extends Component{

    constructor(props){
        super(props)
        this.state = {
            
        }
    }
    render(){
        return(
            <>
            <Breadcrumb title="EMPLOYEE TRAINING" parent="Employee Data" />
                <div className='container-fluid'>
                    <div className='row theme-tab'>
                        <div className='col-sm-12'>
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/training/master`}>
                                    <FileText/> Master Training
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/training/employee-training`} >
                                    <List/> Training List
                                </NavTab>
                                {/* <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/training/report-training`} >
                                    <List/> Monthly Training Report
                                </NavTab> */}
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/training`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/training/master`} />}
                           />
                            <Route path={`${process.env.PUBLIC_URL}/pages/training/employee-training`}/>
                            {/* <Route path={`${process.env.PUBLIC_URL}/pages/training/report-training`}/> */}
                        </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
  })

export default connect(reduxState, null)(withRouter(Training));