import axios from 'axios';
import React, { Component, Fragment } from 'react';
import API_URL from '../../ApiUrl';
import Breadcrumb from '../../components/common/breadcrumb';
import ListAnnouncement from './list-announcement';
import { connect } from 'react-redux';
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

class Announcement extends Component {

    constructor(props){
        super(props)
        this.state ={
            dataAnncouncement:[],
            modal_data:false,
            title:'',
            description:'',
            image:'',
            btnTextSave: 'Save',
            btnAddDisabled:false,
            showComponent:false,

        }
    }

    componentDidMount(){
        this.loadAnnouncement()
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    handleFile = (e) => {
        this.setState({
            image:e.target.files[0]
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadAnnouncement = () => {
        axios.get(API_URL.superStudent+'/announcement',{
            headers: { Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                dataAnncouncement: response.data.data,
                showComponent:true
            })
        })
        .catch(error => {

        })
    }

    deleteAnnouncement = (id) => {
        axios.delete(API_URL.superStudent+'/announcement/'+id,{
            headers: { Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.loadAnnouncement()
            toast.success('Successfully deleted data')
        })
        .catch(error => {
            toast.error('there is an error')
        })
    }

    handleAddData = () => {
        this.setState({
            modal_data: true, 
            title:'',
            description:'',
            image:''
        })
    }

    saveData = () => {
        this.setState({
            btnTextSave:'Saving...',
            btnAddDisabled:true
        })
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('code_users', this.props.dataUser.code_users)
        formData.append('description', this.state.description)
        formData.append('image', this.state.image)
        axios.post(API_URL.superStudent+'/announcement', formData,{
            headers: { Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.loadAnnouncement()
            this.setState({
                modal_data:false,
                btnTextSave: 'Save',
                btnAddDisabled:false
            })
            toast.success('Successfully added data')
        })
        .catch(error => {
            this.setState({
                modal_data:false,
                btnTextSave: 'Save',
                btnAddDisabled:false
            })
            toast.error('there is an error')
        })
    }

    renderModalData(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modal_data} toggle={() => this.setState({ modal_data: false })} >
                <ModalHeader toggle={() => this.setState({ modal_data: false })}>Add Announcement</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='form-group'>
                                    <label className='control-label'>Title</label>
                                    <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Description</label>
                                    <CKEditors
                                        activeclassName="p10"
                                        content={this.state.description}
                                        events={{
                                                "change": this.onChange.bind(this),
                                        }}
                                        config={{
                                            toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className='control-label'>Images</label>
                                    <input name='file' className='form-control' type='file' onChange={this.handleFile}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modal_data: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <Breadcrumb title="Announcement" parent="" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card'>
                                    <div className='card-header'>
                                        <button onClick={this.handleAddData} className='btn btn-success'>Add Data</button>
                                    </div>
                                    <div className='card-body'>
                                    <ListAnnouncement data ={this.state.dataAnncouncement} deleteData={this.deleteAnnouncement} />
                                        {/* {
                                            this.showComponent === true ? <ListAnnouncement data ={this.state.dataAnncouncement}/> : ''
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalData()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser:state.Login.detailUser,
})


export default connect(reduxState, null)(Announcement);