import React, { Component } from 'react';
import CKEditors from "react-ckeditor-component";
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import { toast } from 'react-toastify';
import seven from '../../../assets/images/user/7.jpg';
import 'react-toastify/dist/ReactToastify.css';

class ProfileUniversity extends Component{

    constructor(props) {
        super(props);
        this.state = {
            code_users: '',
            id_users:'',
            id_school_rep:'',
            about: '',
            name:'',
            website:'',
            email:'',
            phone:'',
            postal_code:'',
            country:'',
            province:'',
            city:'',
            address:'',
            start_date:'',
            end_date:'',
            term_and_condition:'',
            provider_id:'',
            application_fee:0,
            living_cost:0,
            providerType:[],
            dataCountry:[],
            btnUpdate: 'Update',
            disabledBtn: false,
            errors:[],
            displayImage: 'none',
            saveLogo: 'Save',
            disableBtnLogo:false,
            input_logo:'',
            input_cover:'',
            image_logo: null,
            image_cover: null,
        }

    }

    componentDidMount(){
        this.getCountry()
        this.loadProviderType()
        let data = this.props.location.state
        console.log('data props', data)
        this.setState({
            id_school_rep: data.id,
            name: data.name,
            content:data.about,
            country:data.country.code,
            postal_code: data.zip_code,
            phone: data.phone,
            email:data.email,
            website:data.website,
            address:data.address,
            city:data.city,
            province:data.province,
            about:data.about,
            provider_id: data.provider_id,
            application_fee: data.application_fee,
            living_cost: data.living_cost,
            image_logo: data.logo,
            image_cover: data.cover,
            education_group_id: data.education_group !== null ? data.education_group.id :null
         });
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            about: newContent
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeLogo = (e) => {
        this.setState({
            input_logo:e.target.files[0]
        })
    }

    handleChangeCover = (e) => {
        this.setState({
            input_cover: e.target.files[0]
        })
    }

    getCountry = async () => {
        await axios.get(API_URL.superStudent+'/master/country')
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataCountry: result })
        })
    }

    loadProviderType = () => {
        axios.get(API_URL.superStudent+'/master/provider-type',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({providerType:response.data.data}))
        .catch(error => console.log(error))
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('country', this.state.country);
        formData.append('city', this.state.city);
        formData.append('province', this.state.province);
        formData.append('address', this.state.address);
        formData.append('website', this.state.website);
        formData.append('email', this.state.email);
        formData.append('phone', this.state.phone);
        formData.append('postal_code', this.state.postal_code);
        formData.append('term_and_condition', this.state.term_and_condition);
        formData.append('about', this.state.about);
        formData.append('application_fee', this.state.application_fee)
        formData.append('living_cost', this.state.living_cost)
        formData.append('provider_id', this.state.provider_id)
        formData.append('_method','PATCH');
        this.setState({
            btnUpdate: 'Updating...',
            disabledBtn:true
        })
        axios.post(API_URL.superStudent+'/universities/'+this.state.id_school_rep,formData,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            this.setState({
                disabledBtn:false,
                btnUpdate:'Update'
            })
            toast.success("Data Updated Successfully");
            this.props.history.push('/pages/education-group/university')
        })
        .catch(err => {
            if(err.response.status === 422){
                this.setState({
                    errors:err.response.data.data,
                    disabledBtn:false,
                    btnUpdate:'Update'
                })
            }
        })
    }

    handleSubmitLogo =  async (e) => {
        e.preventDefault()
        const body = new FormData()
        body.append('logo', this.state.input_logo)
        body.append('cover', this.state.input_cover)
        body.append('id', this.state.id_school_rep)
        this.setState({
            disableBtnLogo:true,
            saveLogo:'Saving..'
        })
        await axios.post(API_URL.superStudent+'/set-images', body, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(() => {
            toast.success("university data updated successfully");
            this.props.history.push('/pages/education-group/university')
        })
        .catch(err => console.log(err))
    }

    
    render(){
        return (
            <>
            <form onSubmit={this.handleSubmit} className="card">
                <div className="card-header">
                    <h4 className="card-title mb-0">Profile University</h4>
                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>University name<span className="text-danger">*</span></label>
                                <input className="form-control" onChange={this.handleInput} value={this.state.name} name="name" type="text" placeholder="example" />
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Website</label>
                                <input className="form-control" name='website' value={this.state.website} onChange={this.handleInput} type="text" placeholder="http://example.com" />
                            </div>
                        </div> 
                        <div className="col-md-5 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Email<span className="text-danger">*</span></label>
                                <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.handleInput} placeholder="example@university.com" />
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div> 
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label className='f-w-600'>Phone</label>
                                <input className="form-control" name='phone' value={this.state.phone} onChange={this.handleInput} type="text" placeholder="(021) 8080 8080" />
                            </div>
                        </div> 
                        <div className="col-sm-4 col-md-3">
                            <div className="form-group">
                                <label className='f-w-600'>Postal Code</label>
                                <input className="form-control" name='postal_code' value={this.state.postal_code} onChange={this.handleInput} type="number" placeholder="123456" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Country<span className="text-danger">*</span></label>
                                <select name='country' value={this.state.country} onChange={this.handleInput} className="form-control btn-square">
                                    <option value="0">--Select Country--</option>
                                    {
                                        this.state.dataCountry.length > 0 ?
                                        this.state.dataCountry.map(country => {
                                            return <option key={country.code} value={country.code}>{country.name}</option>
                                        })
                                        :
                                        ''
                                    }
                                </select>
                                <div className="text-danger">{this.state.errors.name}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Province</label>
                                <input className="form-control" name='province' value={this.state.province} onChange={this.handleInput} type="text" placeholder="Bali" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>City</label>
                                <input className="form-control" name='city' value={this.state.city} onChange={this.handleInput} type="text" placeholder="Denpasar" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className='f-w-600'>Address</label>
                                <input className="form-control" name='address' value={this.state.address} onChange={this.handleInput} type="text" placeholder="Pemecutan Kaja" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Provider Type</label>
                                <select name='provider_id' value={this.state.provider_id} onChange={this.handleInput} className='form-control'>
                                    <option key={0} value=''>--Select</option>
                                    {
                                    this.state.providerType.length > 0 ?
                                    this.state.providerType.map(item => (
                                        <option key={item.id} value={item.id}>{item.provider_name}</option>
                                    ))
                                    :
                                    ''
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Application Fee</label>
                                <input className="form-control" name='application_fee' value={this.state.application_fee} onChange={this.handleInput} type="text" placeholder="100" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className='f-w-600'>Living Cost</label>
                                <input className="form-control" name='living_cost' value={this.state.living_cost} onChange={this.handleInput} type="text" placeholder="10" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mb-0">
                                <label className='f-w-600'>About University</label>
                                <CKEditors
                                    activeclassName="p10"
                                    content={this.state.about}
                                    events={{
                                            "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                    toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                        }}     
                                />
                            </div>
                        </div>
                    </div>
                </div>
            <div className="card-footer text-right">
                <button disabled={this.state.disabledBtn} className="btn btn-primary btn-pill btn-air-info" type="submit">{this.state.btnUpdate}</button>
            </div>
            </form>
            <div className='row justify-content-center'>
            <div className='col-md-8'>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={this.handleSubmitLogo}>
                                <div className='row'>
                                    <div className="col-sm-6">
                                        <figure itemProp="associatedMedia" className="img-hover hover-15">
                                            <h4>Logo</h4>
                                            <img src={this.state.image_logo !== null ? this.state.image_logo : seven} className='img-thumbnail'  alt="logo" />
                                        </figure>
                                        <input type='file' onChange={this.handleChangeLogo} className='form-control' accept='image/*' />
                                    </div>
                                    <div className="col-sm-6">
                                        <figure itemProp="associatedMedia" className="img-hover hover-15">
                                            <h4>Cover</h4>
                                            <img src={this.state.image_cover !== null ? this.state.image_cover : seven} className='img-thumbnail'  alt="cover" />
                                        </figure>
                                        <input type='file' accept='image/*' onChange={this.handleChangeCover} className='form-control'/>
                                    </div>
                                </div>
                                <button style={{ border:'none'}}  disabled={this.state.disableBtnLogo} className='btn btn-primary mt-2 btn-block' type='submit'>{this.state.saveLogo}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
               
        </>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null) (ProfileUniversity);