import React, { Component } from "react";
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router"
import ReactTable from 'react-table';
import { toast } from 'react-toastify';

class ApprovalStudent extends Component{

    constructor(props){
        super(props);
        this.state = {
            listData:[]
        }
    }

    componentDidMount(){
        this.loadDataApproval()
    }

    loadDataApproval(){
        axios.get(API_URL.superStudent+'/student/approval-progress',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listData:response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    approveCancel(item){
        axios.get(API_URL.superStudent+'/student/approval-progress/'+item.id+'/edit',{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.loadDataApproval()
            toast.success('Data Student updated to cancel');
        })
        .catch(error => console.log(error))
    }

    render(){
        const columns_students = [
            {
                Header: 'Code',
                accessor: 'code_students',
                width:100,
                style: {textAlign: 'center'},
              },
              {
                Header: 'Nama',
                accessor: 'student.nama',
              },
              {
                Header: 'Consultant',
                accessor: 'student.consultant.nama',
              },
              {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <button onClick={() => this.approveCancel(row.original)}  style={{border:'none'}} className="btn btn-success btn-xs"><i className='fa fa-check' style={{fontSize:16}}></i></button>
                    </div>
                  </div>
                ),
                style: {
                  textAlign: 'center'
                },
                width:100,
              }
                
        ]
        return(
            <>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                        <h6 className='f-w-600'>Approval Student Cancel</h6>
                        </div>
                        <div className="card-body">
                        <ReactTable
                            multiSelectOption={false}
                            defaultPageSize={10}
                           data={this.state.listData}
                           columns={columns_students}
                       ></ReactTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(ApprovalStudent))