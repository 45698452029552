
import React, { Component } from "react"
import Breadcrumb from "../../common/breadcrumb";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from "../../../helper/general-function";
import StudentFollowup from "../partition/student-followup";
import ReportStudent from "../admission-supervisor/report-student";
import TaskListConsultant from '../../../pages/task-list/list-data-consultant';
import { connect } from 'react-redux';
import axios from 'axios'
import API_URL from "../../../ApiUrl";

class DashboardAdmission extends Component {

    constructor(props) {
        super(props)
        this.state = {
            formData: { start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'), end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'), jobdesk: JSON.stringify(['JD002', 'JD004', 'JD007']) },
            date: '',
            show: false,
            staticFilter: '',
            dinamicFilter: 'none',
            start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
            fcm_token: ''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.date === 'dinammic_date') {
                this.setState({
                    dinamicFilter: '',
                    staticFilter: 'none'
                })
            }
            else {
                this.setDate()
            }
        })
    }

    componentDidMount() {
        this.cekTokenLogin()
    }

    cekTokenLogin() {
        axios.get(API_URL.superStudent + '/userable', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
            })
            .catch(error => {
                this.props.history.push(`${process.env.PUBLIC_URL}/login`)
            })
    }

    handlePickerMonth = (e) => {
        this.setState({
            start_date: Moment(e).startOf('month').format('YYYY-MM-DD'),
            end_date: Moment(e).endOf('month').format('YYYY-MM-DD'),
        })
    }

    setDate() {
        let data = setRangeDate(this.state.date);
        let formData = new FormData();
        formData.append('start_date', data.start)
        formData.append('end_date', data.end)
        formData.append('jobdesk', JSON.stringify(['JD002', 'JD004', 'JD007']))
        this.setState({
            start_date: data.start,
            end_date: data.end,
            formData
        })
    }

    render() {
        return (
            <>
                <Breadcrumb parent="Dashboard Admission" title="Dashboard Admission" />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        {/* <div style={{ display: this.state.staticFilter }} className='col-md-3 col-sm-4 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                <optgroup label='Quartal'>
                                    <option value='1'>Quartal I</option>
                                    <option value='2'>Quartal II</option>
                                    <option value='3'>Quartal III</option>
                                    <option value='4'>Quartal IV</option>
                                </optgroup>
                                <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                        </div> */}
                        {/* <div style={{ display: this.state.dinamicFilter }} className='row justify-content-center'>
                            <div className='col-md-8 col-sm-6 col-xs-6 mb-2'>
                                <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.start_date)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                            </div>
                            <div className='col-md-2 col-sm-4 mb-2'>
                                <button className='btn btn-success btn-md' type='button' onClick={() => this.setState({ dinamicFilter: 'none', staticFilter: '' })}><i style={{ color: 'white' }} className='fa fa-refresh'></i></button>
                            </div>
                        </div> */}
                        {/* <div className='col-sm-12 mb-3'>
                            <span style={{ fontSize: '1.5em' }} className='f-w-600'>{Moment(this.state.start_date).format('DD MMMM yyyy')} - {Moment(this.state.end_date).format('DD MMMM yyyy')}</span>
                        </div> */}
                        <div className="col-xl-12">
                            <TaskListConsultant start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className="col-xl-12">
                            <ReportStudent />
                        </div>
                        <div className="col-xl-12">
                            <StudentFollowup />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(DashboardAdmission);
