/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import API_URL from '../../../ApiUrl'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useSelector } from 'react-redux'

const PeformanceBizdev = ({start_date, end_date}) => {

    const[data,setData] = useState([])
    const[loader,setLoader] = useState(false)
    const[modal,setModal] = useState(false);
    const[detail,setDetail] = useState([])

    const loadData = () => {
        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/consultant-peformance/bizdev-peformance', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setData(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const showDetail = (item) => {
        setLoader(true)
        let form = new FormData()
        form.append('id_area', item)
        form.append('start_date', start_date)
        form.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/consultant-peformance/detail-peformance', form,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setLoader(false)
            setModal(true)
            setDetail(response.data.data)
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadData()
    },[start_date, end_date])

    
    const modalDetail = () => {
        return ( 
            <Modal className="modal-dialog"  size="lg" isOpen={modal} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)}>Detail Data</ModalHeader>
            <ModalBody>
                <div className='row'> 
                {
                    detail.length > 0 ?
                    detail.map((items,index) => (
                        <div key={index} className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>{items.label}</li>
                                <ul style={{fontSize:'11px'}}>
                                    {
                                        items.data.map((item,i) => (
                                            <li key={i}> {i+1}. ({item.code}) {item.label}</li>
                                        ))
                                    }
                                </ul>
                            </ul>
                        </div>
                    ))
                    :
                    <div className='col-md-6'>
                        <label className='f-w-600'>Data kosong</label>
                    </div>
                }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
            </ModalFooter>
        </Modal>
        );
    }

    return(
        <>
            <SweetAlert title="Loading..."   showConfirm={false} showCancel={false} show={loader} />
            <div style={{height:'445px'}} className='card'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Bisdev Peformance</h6>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table style={{fontSize:'10px'}} className='table table-striped table-hover table-borderedfor'>
                            <thead className='tbl-strip-thad-bdr'>
                                <tr className='table-secondary'>
                                    <th scope='col'>#</th>
                                    <th scope="col">Nama</th>
                                    <th scope="col">School Visit</th>
                                    <th scope="col">School Meeting</th>
                                    <th scope="col">MoU</th>
                                    <th scope="col">Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 ?
                                    data.map((items,index) => (
                                        <tr key={index}>
                                            <th>{index+1}</th> 
                                            <th>{items.area}</th>
                                            <th style={{textAlign:'center'}}> <span style={{color:'#7c9ed9'}} > {items.total_achived} </span> | <span  style={{color:'red'}} > {items.total_target} </span></th>
                                            <th style={{textAlign:'center'}}> <span style={{color:'#7c9ed9'}} > {items.total_achived_meeting} </span> | <span style={{color:'red'}} > 0 </span></th>
                                            <th style={{textAlign:'center'}} ><span style={{color:'#7c9ed9'}} > {items.total_achived_MoU}</span> | <span style={{color:'red'}} > {items.target_MoU}</span> </th>
                                            <th>
                                            <button style={{border:'none'}} className='btn btn-primary btn-xs btn-pill' onClick={() => showDetail(items.id_area) }><i className='fa fa-eye'></i></button>
                                            </th>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={6}>Data Kosong</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {modalDetail()}
        </>
    )
}

export default PeformanceBizdev