import React, {useState} from 'react'
import { useHistory } from "react-router";
import image from '../../../assets/images/user/6.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DetailCourse = ({data}) => {

    const[modal,setModal] = useState(false)
    const[url,setUrl] = useState('');

    const history = useHistory()


    const showModal = (data) => {
        setModal(true)
        setUrl(data.video_url)
    }


    function showVideo(){
        return(
            <Modal className="modal-dialog" isOpen={modal} toggle={() => setModal(false)} size="md">
              <ModalHeader toggle={() => setModal(false)}>Video</ModalHeader>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto'
              }}>
                <video controls width="100%">
                    <source src={url} type="video/avi" />
                    <source src={url} type="video/wmv" />
                    <source src={url} type="video/mov" />
                    <source src={url} type="video/mpeg" />
                    <source src={url} type="video/mpg" />
                    <source src={url} type="video/webm" />
                    <source src={url} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                </video>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={e => setModal(false)}>Close</Button>
              </ModalFooter>
            </Modal>
          )
    }

    return(
        <>
        <div className="col-sm-4">
                <div className="card">
                    <div className="blog-box blog-grid text-center product-box">                                
                        <div className="product-img">
                            <img style={{height:'250px'}} className="img-fluid top-radius-blog" src={data.cover !== null ? data.cover : image} alt="images-cover" />
                            <div className="product-hover">
                                <ul>
                                    <li style={{cursor:'pointer'}} onClick={() => showModal(data)}><i className="icon-folder" onClick={() => this}></i></li>
                                    <li style={{cursor:'pointer'}} onClick={() => history.push({
                                        pathname: `${process.env.PUBLIC_URL}/pages/course/detail-course/${data.course_id}/form-detail-course/`+data.id,
                                        state: data,
                                    })} ><i className="icon-pencil"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div className="blog-details-main">
                            <h6 className="blog-bottom-details">{data.detail_course_name}</h6>
                        </div>
                    </div>
                </div>
            </div>
            {showVideo()}  
        </>
    )

}

export default DetailCourse;