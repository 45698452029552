/* eslint-disable eqeqeq */

import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { getDataUserable } from '../../../actions/login.action';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import Loader from '../../../components/common/loader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import excel from '../../../assets/images/document-logo/excel-logo.png';
import word from '../../../assets/images/document-logo/word-logo.png';
import pdf from '../../../assets/images/document-logo/pdf-logo.png';
import { saveAs } from "file-saver";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { toast } from 'react-toastify';
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import ZipDownload from '../../../services/jszip';

class TranslateList extends Component {

    constructor(props){
        super(props)
        this.state = {
            showComponent: false,
            modalPreview: false,
            modalListPreview:false,
            modalShowDetail:false,
            showLoading: false,
            modal_edit: false,
            modal_upload: false,
            modal_delete_temp: false,
            modalPembayaran:false,
            show: false,
            action_temp: '',
            total_lembar: "",
            total_lembar_temp: "",
            errors: "",
            image: "",
            filename: "",
            code_student_document: "",
            vendor: [],
            filtered:[],
            order: [],
            order_detail: [],
            order_doc: [],
            last_code_doc: "",
            nama_student: "",
            toastId: null,
            edit_translate: [],
            edit_temporary: [],
            urls:[],
            detailPreview:[],
            input_pembayaran:'',
            bukti_pembayaran:'',
            student:'',
            btnText:'',
            disabledBtn:false,
            filterStatus:''
        }
    }

    componentDidMount(){
        this.props.getUserableData(this.props.dataToken)
        .then(response => {
            if(this.props.userableData.userable !== undefined){
                this.setState({
                    showComponent:true,
                    vendor:this.props.userableData
                }, () => {
                    this.getOrderTranlateByIdVendor()
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatus = newData; 
          return { filterStatus };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"code_status_pembayaran");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    getOrderTranlateByIdVendor() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/vendor-translate/order/'+this.state.vendor.userable.code_vendor_translate;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading: false,
                order: data.data
            });
        });
    }

    detailDokumen = (data) => {
        let urls = data.translate.map(function (item) {
            return item.document.file_name
        })
        this.setState({
            modalShowDetail:true,
            urls,
            student: data.student,
            detailPreview:data.translate
        })
    }

    showBuktiPembayaran = (data) => {
        console.log(data)
        this.setState({
            btnText:'Upload',
            bukti_pembayaran : data,
            modalPembayaran:true
        })
    }

    getLastCodeDocument() {
        const url = API_URL.studentUrl+'last-code-document';
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ 
                last_code_doc:data
            })
        })
    }

    closeDialog() {
        this.setState({
            modal_edit: false,
            modalPreview: false,
            modal_upload: false,
            modal_delete_temp: false,
            show: false,
            modalShowDetail:false,
        })
    }

    closeDialogPreview() {
        this.setState({
            modalPreview: false,
            show: false,
        })
    }

    editRow(row) {
        this.setState({
            modal_edit: true,
            order_detail: row
        })
    }

    addDocument(row, student) {
        this.getLastCodeDocument();
        this.setState({
            btnText:'Upload',
            modal_upload: true,
            order_detail: row,
            nama_student: student
        })
    }

    deleteRowTemporay(row) {
        this.setState({
            modal_delete_temp: true,
            edit_temporary: row,
            action_temp: 'delete'
        })
    }
    
    filePreview(row) {
        this.setState({
            modalPreview:true,
            filename: row.file_name ?? row.bukti_pembayaran
        })
    }

    getStudentDocByCode(code_student, code_document) {
        const url = API_URL.studentUrl+'students-documents-code/'+code_student+'/'+code_document;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
        .then((result) => {
            console.log(result)
            this.setState({
                modalListPreview: true,
                order_doc: result
            })
        })
    }

    handleUpload = (e) => {
        this.setState({
            input_pembayaran:e.target.files[0]
        })
    }

    handleInput = (e, order_detail) => {
        this.setState({
            code_student: order_detail.code_student,
            edit_translate: order_detail.document,
            code_student_document: order_detail.document.code_students_document,
            total_lembar: e.target.value,
        })
    }

    handleInputTemp = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeFileName = (e) => { 
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }

    saveRow() {
        var formData = new FormData(); 
        formData.append('code_student_payment', this.state.order_detail.code_student_payment); 
        formData.append('code_student', this.state.order_detail.code_student);  
        formData.append('code_detail_items', this.state.order_detail.code_detail_items);  
        formData.append('code_vendor', this.state.vendor.userable.code_vendor_translate);
        formData.append('harga_vendor', this.state.vendor.userable.harga);
        formData.append('total_lmbr', this.state.total_lembar);
        formData.append('harga_office',this.state.vendor.userable.harga_super_student)

        axios.post(API_URL.superStudent+'/vendor-translate/update-harga-temporary',formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getOrderTranlateByIdVendor();
            this.setState({ 
                btnDisabled: false,
                image: "",
                flyerFile: ""
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    saveUpload() {
        this.setState({
            btnText:'Uploading...',
            disabledBtn:true
        })
        let datetime = Moment(Date()).format('DDMMYYYY');
        var formData = new FormData();
        formData.append('code_students', this.state.order_detail.code_student);
        formData.append('code_document', this.state.order_detail.document.code_document);
        formData.append('file_name', this.state.nama_student.replace(/\s/g, '')+'_'+this.state.order_detail.document.document.nama_document.replace(/\s/g, '')+'_'+this.state.last_code_doc+"_"+datetime); 
        formData.append('picFile', this.state.image);
        formData.append('vendor_translate', true)

        axios.post(API_URL.superStudent+'/student/students-documents',formData, { 
            headers: { 
                Authorization: `Bearer ${this.props.dataToken}`, 
                'Content-Type':'multipart/form-data' 
            },
            }).then((reply)=>{
            this.setState({ show: true, btnText:'Upload', disabledBtn:false });

        }).catch(function(reply){
            console.log(reply)
        });
    }

    finishEdit(row) {
        var formData = new FormData();
        formData.append('code_students', row[0].code_student);
        formData.append('code_vendor', this.state.vendor.userable.code_vendor_translate);
        formData.append('harga_vendor', this.state.vendor.userable.harga);
        formData.append('detail', JSON.stringify(row));

        axios.post(API_URL.superStudent+'/vendor-translate/update-harga-detail', formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            toast.success("Update Sucsessfully");
            this.getOrderTranlateByIdVendor();
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    downloadFile = (e,  filename) => {
        saveAs(filename,filename.substring(filename.lastIndexOf('/')));
    }

    saveRowTemp() {
        var formData = new FormData(); 

        formData.append('code_student_payment', this.state.edit_temporary.code_student_payment_detail);
        formData.append('harga_vendor', this.state.edit_temporary.harga_vendor);
        formData.append('total_lmbr', this.state.total_lembar_temp);

        let url = '/vendor-translate/edit-harga-temporary';

        if (this.state.action_temp == 'delete') {
            url = '/vendor-translate/delete-harga-temporary';
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getOrderTranlateByIdVendor();
            this.setState({ 
                btnDisabled: false,
                image: "",
                flyerFile: ""
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    saveBuktiPembayaran(){
        this.setState({
            btnText:'Uploading...',
            disabledBtn:true
        })
        var formData = new FormData();
        formData.append('code_pembayaran',this.state.bukti_pembayaran.code_pembayaran);
        formData.append('bukti_pembayaran',this.state.input_pembayaran);
        axios.post(API_URL.superStudent+'/vendor-translate/upload-bukti-pembayaran',formData, { 
            headers: { 
                Authorization: `Bearer ${this.props.dataToken}`, 
                'Content-Type':'multipart/form-data' 
            },
            }).then((reply)=>{
            this.setState({ show: true, btnText:'Upload', disabledBtn:false,modalPembayaran:false });
            this.getOrderTranlateByIdVendor();
            toast.success("Upload Sucsessfully");

        }).catch(function(reply){
            console.log(reply)
            toast.error("something wrong");
        });
    }

    totalHarga = (row) => {
        const totalNominal = row.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.harga_vendor;
        }, 0);
        return totalNominal;
    }

    renderModalEdit() {
        const { modal_edit } = this.state;
        
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_edit} toggle={() => this.setState({ modal_edit: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_edit: false })}>Tambahan Lembar</ModalHeader>
                    <ModalBody>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" name="total_lembar" type="number" id="total_lembar"  value={this.state.total_lembar}
                                            onChange={(e) => this.handleInput(e, this.state.order_detail)}
                                            ref={e => this.inputTotal_lembar = e}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderModalDetail() {        
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalShowDetail} toggle={() => this.setState({ modalShowDetail: false })}>
                    <ModalHeader toggle={() => this.setState({ modalShowDetail: false })}>Detail Dokumen Translate</ModalHeader>
                    <ModalBody>
                        {
                        this.state.detailPreview.map((doc, i) => (
                        <div className="media markets" key={i}>
                            {
                            doc.document.file_name.split('.').pop() == 'pdf' ? 
                            <img className='document-img' style={{maxWidth:'91px',cursor:'pointer'}} alt='images' onClick={() => this.filePreview(doc.document)} src={pdf}/> 
                            :
                            doc.document.file_name.split('.').pop() == 'docx' ?
                            <img className='document-img' style={{maxWidth:"91px",cursor:'pointer'}} alt='images' onClick={() => this.filePreview(doc.document)} src={word}/>
                            :
                            doc.document.file_name.split('.').pop() == 'xlsx' ?
                            <img className='document-img' style={{maxWidth:"91px",cursor:'pointer'}} alt='images' onClick={() => this.filePreview(doc.document)} src={excel}/>
                            :
                            <img className='document-img' style={{maxWidth:"91px",cursor:'pointer'}} alt='images' onClick={() => this.filePreview(doc.document)} src={doc.document.file_name}/>
                            }
                             <div className="media-body">
                                <h5 className="font-primary">({doc.code_detail_items}) {doc.document.document.nama_document}</h5>
                                    <div className="row">
                                        <div className="col-1">
                                            <button onClick={() => this.addDocument(doc, this.state.student.nama)} className="btn btn-outline-warning btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 30, padding: 9, fontSize: 14 }}></i></button>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                        ))
                        }
                <Modal isOpen={this.state.modal_upload} toggle={() => this.setState({ modal_upload: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_upload: false })}>Upload Document Translate</ModalHeader>
                    <ModalBody>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <input className="form-control" name="image" type="file" id="image"
                                            onChange={(e) => this.onChangeFileName(e)}
                                            ref={e => this.inputFileName = e}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.disabledBtn} color="primary" onClick={(e) => this.saveUpload()}>{this.state.btnText}</Button>
                        <Button  color="secondary" onClick={() => this.setState({ modal_upload:false})}>Cancel</Button>
                    </ModalFooter>
                </Modal>
     
                    </ModalBody>
                    <ModalFooter>
                        <ZipDownload urlDownload={this.state.urls} name={this.state.student || 'file_data'} />
                        <Button  color="secondary" onClick={() => this.closeDialog()}>Clode</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderPreview(){
        const modalPreview = this.state.modalPreview;
        return(
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closeDialogPreview()} size="lg">
              <ModalHeader toggle={() => this.closeDialogPreview()}>Preview</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                <DocViewer 
                    pluginRenderers={DocViewerRenderers} 
                    documents={[{uri: this.state.filename}]} 
                    config={{
                        header: {
                            disableHeader: false,
                            disableFileName: false,
                            retainURLParams: false
                        }
                    }}
                />
                <div className="form-row" style={{textAlign: "end"}}>
                    <div className="col-md-12 mt-2">
                        <button onClick={(e) => this.downloadFile(e, this.state.filename)} className="btn btn-success" type="button"><i className="fa fa-download"></i></button>
                    </div>
                 </div>                
              </ModalBody>
            </Modal>
        )
    }

    renderListPreview(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalListPreview} toggle={() => this.setState({ modalListPreview: false })} size="lg">
              <ModalHeader toggle={() => this.setState({ modalListPreview: false })}>List Dokumen</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                {this.state.order_doc.map((doc, i) => {
                    const url_doc = [{ uri:doc.file_name }]
                    return  <div key={i}>
                                <DocViewer 
                                    pluginRenderers={DocViewerRenderers} 
                                    documents={url_doc} 
                                    config={{
                                        header: {
                                        disableHeader: false,
                                        disableFileName: false,
                                        retainURLParams: false
                                        }
                                    }}
                                />
                                <div className="form-row mb-5">
                                </div>
                            </div>
                })}
                
              </ModalBody>
            </Modal>
        )
    }
    
    renderModalDeleteTemp() {
        const { modal_delete_temp } = this.state;

        return (
            <Modal isOpen={modal_delete_temp} toggle={() => this.setState({ modal_delete_temp: false })}>
                <ModalHeader toggle={() => this.setState({ modal_delete_temp: false })}>Delete Harga Temporary</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRowTemp()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderBuktiPembayaran(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalPembayaran} toggle={() => this.setState({modalPembayaran:false})} size="lg">
              <ModalHeader toggle={() => this.setState({modalPembayaran:false})}>Invoice Translate</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                <div className='row justify-content-center'>
                    <div className='col-md-3 mb-3'>
                        {
                            this.state.bukti_pembayaran.bukti_pembayaran != null && this.state.bukti_pembayaran.bukti_pembayaran.split('.').pop() == 'pdf' ? 
                            <img className='document-img' style={{maxWidth:'91px',cursor:'pointer'}} alt='images' onClick={() => this.filePreview(this.state.bukti_pembayaran)} src={pdf}/> 
                            :
                            <div>Tidak ada file yang diupload</div>
                        }
                    </div>
                    <div className='col-md-12'>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <input className="form-control" type='file' onChange={this.handleUpload} accept='application/pdf'/>
                            </div>
                        </div>
                    </div>
                </div>               
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.disabledBtn} color="primary" onClick={() => this.saveBuktiPembayaran()}>{this.state.btnText}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render(){

        let idr = Intl.NumberFormat('us-UD');

        const columns = [
            {
                Header: 'Kode Pembayaran',
                accessor: 'code_pembayaran',
                filterable: false,
                width:150
            },
            {
                Header: 'Nama Student',
                accessor: 'student.nama',
                filterable: false
            },
            {
                Header: 'Total Harga',
                accessor: 'rate',
                filterable: false,
                Cell: (row) => (
                    <h6>{idr.format(this.totalHarga(row.original.translate))}</h6>
                ),
                width:150
            },
            {
                Header: 'Status',
                accessor: 'code_status_pembayaran',
                filterable: true,
                width:120,
                style:{
                    textAlign:'center'
                },
                Cell: (row) => (
                    <div> 
                        {
                            row.original.status_pembayaran.status_pembayaran == 'Pending' ?
                            <span className='badge bg-secondary'>Pending</span>
                            :
                            row.original.status_pembayaran.status_pembayaran == 'Failure' ?
                            <span className='badge bg-danger'>Failure</span>
                            :
                            <span className='badge bg-success'>Paid</span>
                        }
                    </div>
                ),
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option value=''>Show All</option>
                    <option value='PM00001'>Paid</option>
                    <option value='PM00005'>Pending</option>
                    <option value='PM00006'>Failure</option>

                </select>,
            },
            {
                Header: 'action',
                accessor: str => "action",
                id:'action',
                width:100,
                Cell: (row) => (
                    <div className='row justify-content-center'>
                        <div className='col-md-3'>
                            <button onClick={() => this.detailDokumen(row.original)} className="btn btn-primary btn-xs" type="button"><i className="fa fa-eye"></i></button>
                        </div>
                        <div className='col-md-3'>
                            <button onClick={() => this.showBuktiPembayaran(row.original)} className="btn btn-secondary btn-xs" type="button"><i className="fa fa-file"></i></button>

                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false

            }
        ]
        return(
            <Fragment>
                <SweetAlert
                    show={this.state.show}
                    type="success"
                    title="Save Success"
                    onConfirm={() => this.setState({ show: false })}>
                </SweetAlert>
                <Breadcrumb title="Translate List" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.order.length > 0 ?
                                            <ReactTable
                                                data={this.state.order}
                                                columns={columns}
                                                collapseOnDataChange={false}
                                                filtered={this.state.filtered}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                defaultPageSize={25}
                                                className="-striped -highlight"
                                                SubComponent={row => {
                                                    const data = row.original;
                                                    return (
                                                        <div className="page-wrapper" style={{ padding: "30px" }}>
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-md-12 m-5">
                                                                        <table className='table'>
                                                                            <thead>
                                                                                <th>Nama Document</th>
                                                                                <th>Total Lembar</th>
                                                                                <th>Total Harga</th>
                                                                                <th>Action</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    data.translate.map((detail, keys) => {
                                                                                        return  <tr key={keys}>
                                                                                                    <td>{detail.document.document.nama_document}</td>
                                                                                                    <td>{detail.total_lembar}</td>
                                                                                                    <td>{idr.format(detail.harga_vendor)}</td>
                                                                                                    <td>
                                                                                                        <div className='row justify-content-center'>
                                                                                                            <button onClick={() => this.editRow(detail)} className="btn btn-secondary btn-xs" type="button" ><i className="fa fa-pencil"></i>{detail.id}</button>
                                                                                                            <button onClick={() => this.getStudentDocByCode(detail.code_student, detail.document.code_document)} className="btn btn-warning ml-1 btn-xs" type="button" ><i className="fa fa-list"></i>{detail.id}</button>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                {
                                                                    data.translate_temporary.length > 0 ? 
                                                                    <div>
                                                                        <h5>Additional Paper</h5>
                                                                        <div className="row mt-3">
                                                                            <div className="col-md-12 m-5">
                                                                                <table className='table'>
                                                                                    <thead>
                                                                                        <th>Nama Document</th>
                                                                                        <th>Total Lembar</th>
                                                                                        <th>Total Harga</th>
                                                                                        <th>Action</th>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            data.translate_temporary.map((detail, keys) => {
                                                                                                return  <tr key={keys}>
                                                                                                            <td>{detail.document.document.nama_document}</td>
                                                                                                            <td>{detail.total_lembar}</td>
                                                                                                            <td>{idr.format(detail.harga_vendor)}</td>
                                                                                                            <td>
                                                                                                            <button onClick={() => this.deleteRowTemporay(detail)} className="btn btn-danger btn-xs" type="button" ><i className="fa fa-trash"></i>{detail.id}</button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                <div className='row'>
                                                                    <div className='col-lg-11 pull-right'>
                                                                        {
                                                                            data.status_pembayaran.status_pembayaran == 'Paid' ?
                                                                            <div>
                                                                                {
                                                                                    data.translate_temporary.length > 0 ? 
                                                                                    <button className='btn-danger btn-sm pull-right mt-5' onClick={() => this.finishEdit(data.translate_temporary)}>Finish Edit</button>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            />
                                            :
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalDetail()}
                {this.renderModalEdit()}
                {this.renderPreview()}
                {this.renderListPreview()}
                {this.renderModalDeleteTemp()}
                {this.renderBuktiPembayaran()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

const reduxDispatch = (dispatch) =>{
    return {
        getUserableData:(token) => dispatch(getDataUserable(token))
    }
}

export default connect(reduxState, reduxDispatch)(TranslateList)