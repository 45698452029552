/* eslint-disable eqeqeq */


import React, { Component } from "react"
import { withRouter } from "react-router";
import axios from 'axios';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import API_URL from "../../../ApiUrl";


class DetailRegisterIELTS extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            detailAnswers: [],
            writing_score: 0,
            speaking_score: 0,
            btnDisabled: false,
            textUpdate: 'Update'
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.location.state,
            writing_score: this.props.location.state.writing_score,
            speaking_score: this.props.location.state.speaking_score,
        }, () => this.loadDetailAnswer())
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDetailAnswer() {
        axios.get(API_URL.superStudent + '/simulasi-ielts/user-answer/' + this.state.user.id, {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(response => this.setState({ detailAnswers: response.data.data }));
    }

    handleCheck = (params, kategori_id) => {
        let formData = new FormData()
        formData.append('id_jawaban', params.id)
        formData.append('id_kategori', kategori_id)
        formData.append('id_user', this.state.user.id)
        axios.post(API_URL.superStudent + '/simulasi-ielts/update-correct', formData, {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(() => this.loadDetailAnswer())
    }

    updatePoint = () => {
        let formData = new FormData()
        formData.append('writing_point', this.state.writing_score)
        formData.append('speaking_point', this.state.speaking_score)
        formData.append('id_user', this.state.user.id)
        this.setState({
            btnDisabled: true,
            textUpdate: 'Updating...'
        })
        axios.post(API_URL.superStudent + '/simulasi-ielts/update-point', formData, {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(() => {
                this.props.history.push('/pages/english-class/simulasi-ielts/register')
            })
    }

    render() {
        return (
            <>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Name : </th>
                                                        <td>{this.state.user.nama_lengkap}</td>
                                                        <th>Email : </th>
                                                        <td>{this.state.user.email}</td>
                                                        <th>Phone : </th>
                                                        <td>{this.state.user.no_wa}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                this.state.detailAnswers.length > 0 ?
                                                    this.state.detailAnswers.map((list, i) => {
                                                        return (
                                                            <div className="col-md-6">
                                                                <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '5px' }} key={i}>{list.kategori_soal}</li>
                                                                <ul>
                                                                    {list.part_soal.map((part) => {
                                                                        return (
                                                                            <>
                                                                                <li><h5>{part.part}</h5></li>
                                                                                <li>
                                                                                    <ul>
                                                                                        {
                                                                                            part.soal.map((e) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            i == 2 ?
                                                                                                                <>
                                                                                                                    <li>{e.number}. <div dangerouslySetInnerHTML={{ __html:(e.jawaban.user_answer) }} />
                                                                                                                    </li>
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <li>{e.number}.  <span style={{ fontWeight: 'bold', color: e.jawaban.is_correct === 1 ? '#7c9ed9' : 'red' }}>{e.jawaban.user_answer == null ? '(no answer)' : e.jawaban.user_answer}</span>
                                                                                                                        <span style={{ fontWeight: 'bold', display: list.kategori_soal === 'Writing' || e.jawaban.is_correct === 1 || e.jawaban.user_answer == null || e.input_type !== 'text' ? 'none' : '' }}> - {e.jawaban.correct_answer}&nbsp;</span>
                                                                                                                        {
                                                                                                                            e.input_type === 'text' && e.jawaban.is_correct === 0 && list.kategori_soal !== 'Writing' && e.jawaban.user_answer != null ?
                                                                                                                                <button onClick={() => this.handleCheck(e.jawaban, list.id)} className='btn btn-primary btn-xs'><i className='fa fa-check'></i></button>
                                                                                                                                : ''
                                                                                                                        }
                                                                                                                    </li>
                                                                                                                </>
                                                                                                        }

                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </li>
                                                                                <br></br>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )
                                                    }) : ''
                                            }
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='f-w-600'>Writing Score </label>
                                                    <input type='text' className='form-control' name='writing_score' value={this.state.writing_score} onChange={this.handleInput} />
                                                </div>
                                                <div className='form-group'>
                                                    <label className='f-w-600'>Speaking Score</label>
                                                    <input type='text' className='form-control' name='speaking_score' value={this.state.speaking_score} onChange={this.handleInput} />
                                                </div>
                                                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updatePoint()}>{this.state.textUpdate}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(withRouter(DetailRegisterIELTS));
