import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Card,CardHeader,CardBody,Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap'
import StructureTest from './pages/structure-test';
import ReadingTest from './pages/reading-test';
import ListeningTest from './pages/listening-test';

export class BankSoal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showTab: '1',
          activeTab: '1',
          title: 'Structure Test'
        }
    }
    
  render() {
    const {showTab, activeTab, title} = this.state
    return (
        <div className='container-fluid'>
            <Card>
                  <CardHeader>
                    <h5 className='mb-5'>{title}</h5>
                    <Nav className="border-tab" tabs>
                       <NavItem>
                           <NavLink className={showTab === '1' ? 'active' : ''} onClick={()=>this.setState({showTab: '1', activeTab: '1', title: 'Structure Test'})}><i className="icofont icofont-list"></i>Structure Test</NavLink>
                       </NavItem>
                       <NavItem>
                           <NavLink className={showTab === '2' ? 'active' : ''} onClick={()=>this.setState({showTab: '2', activeTab: '2', title: 'Reading Test'})}><i className="icofont icofont-list"></i>Reading Test</NavLink>
                       </NavItem>
                       <NavItem>
                           <NavLink className={showTab === '3' ? 'active' : ''} onClick={()=>this.setState({showTab: '3', activeTab: '3', title: 'Listening Test'})}><i className="icofont icofont-list"></i>Listening Test</NavLink>
                       </NavItem>
                     </Nav>
                  </CardHeader>
                  <CardBody>
                     <TabContent activeTab={activeTab}>
                       <TabPane  className="fade show" tabId="1">
                        <StructureTest />
                       </TabPane>
                       <TabPane  className="fade show" tabId="2">
                        <ReadingTest />
                       </TabPane>
                       <TabPane  className="fade show" tabId="3">
                        <ListeningTest />
                       </TabPane>
                     </TabContent> 
                  </CardBody>
                </Card>
        </div>
    )
  }
}

// export default BankSoal
const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
  codeUsers: state.Login.detailUser.code_users,
  codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(BankSoal));