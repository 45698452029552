/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import { ExportCSV } from '../../../services/ExportCSV';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import { toast } from 'react-toastify';
import { matchSorter } from 'match-sorter';
import 'react-toastify/dist/ReactToastify.css';
import setRangeDate from '../../../helper/general-function';

class EnglishCoureseComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listEnglish: [],
            listTeacher: [],
            listProgram: [],
            listHasPayment:[],
            dataGrupCode: [],
            listGroup:[],
            filtered: [],
            showAction: this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : 'none',
            modal_edit: false,
            modal_delete: false,
            modal_list:false,
            operation: "",
            detail_data: [],
            status_payment: 'Y',
            filterGrupCode: '',
            filterProgram:'',
            filterGuru:'',
            filterStatus:'',
            btnDisabled: false,
            date:'this_week',
            start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
            id_teacher: ''
        }
    }

    componentDidMount() {
        this.getDataEnglish()
        this.loadAllCodeGrup()
        this.getTeacher()
        this.getProgram()
    }

    loadAllCodeGrup() {
        const url = API_URL.superStudent+'/english-course/grupcode-english-course';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => this.setState({ dataGrupCode: response.data.data }))
    }

    getDataEnglish() {
        let formData = new FormData()
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)

        const url = API_URL.superStudent+'/english-course/register-english-course';
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => this.setState({ listEnglish: response.data.data }))
    }

    getTeacher() {
        const url = API_URL.superStudent+'/english-course/list-teacher';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => this.setState({ listTeacher: response.data.data }))
    }

    getProgram(){
        axios.get(API_URL.superStudent+'/english-course/list-program', { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => this.setState({ listProgram: response.data.data }))
    }

    getListHasPayment(){
        axios.get(API_URL.superStudent+'/english-course/has-payment',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => this.setState({listHasPayment: response.data.data }));
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    SetFilterGrupCode = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
            let filterGrupCode = newData; 
            return { filterGrupCode };                   
        }, () => {
            this.onFilteredChangeCustom(newData, "grup_code");
        })
    }

    SetFilterProgram = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
            let filterProgram = newData; 
            return { filterProgram };                   
        }, () => {
            this.onFilteredChangeCustom(newData, "id_program");
        })
    }

    SetFilterTeacher = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
            let filterGuru = newData; 
            return { filterGuru };                   
        }, () => {
            this.onFilteredChangeCustom(newData, "id_teacher");
        })
    }

    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
            let filterStatus = newData; 
            return { filterStatus };                   
        }, () => {
            this.onFilteredChangeCustom(newData, "status_payment");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    closeDialog() {
        this.setState({
            modal_edit: false,
            modal_delete: false,
            modal_list:false
        })
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            modal_edit: true,
            detail_data: row,
        })
    }

    deletRow(row) {
        this.setState({
            operation: 'delete',
            modal_delete :true,
            detail_data: row
        })
    }

    showListHasyPayment(){
        this.getListHasPayment()
        this.setState({
            modal_list:true,
            listGroup:[]
        })
    }

    saveRow() {
        var formData = new FormData();
        formData.append('code_order', this.state.detail_data.code_order);
        formData.append('id_teacher', this.state.id_teacher);
        formData.append('status', this.state.status_payment);

        let url = '/english-course-update';

        if (this.state.operation == 'delete') {
            url = '/english-course-delete';
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
            window.location.reload();
            this.getDataEnglish();
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setDate()
        })
    }

    setDate() {   
        let data = setRangeDate(this.state.date);   
        
        this.setState({ 
            start_date:data.start,
            end_date:data.end
        }, () => {
            this.getDataEnglish()
        })
    }

    renderDialog() {
        const { modal_edit } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_edit} toggle={() => this.setState({ modal_edit: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_edit: false })}>Update Status Payment</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className='form-control' name='status_payment' onChange={(e) => this.handleInput(e)}>
                                        <option value='Y'>Paid</option>
                                        <option value='F'>Finis Study</option>
                                        <option value='C'>Cancel</option>
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select md="3" className="form-control" name='id_teacher' onChange={(e) => this.handleInput(e)}>
                                        <option value="0">--Pilih Guru--</option>
                                        {this.state.listTeacher.map((data2)=> (
                                            <option key={data2.id_teacher} value={data2.id_teacher}>{data2.nama_lengkap}</option>
                                        ))}
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    handleCheckBox = (row) => {
        this.setState(prevState => ({
            listHasPayment:prevState.listHasPayment.map(checkbox =>
                checkbox.id_register=== row.id_register ? {...checkbox, isChecked: !checkbox.isChecked} :checkbox )
        }), () => console.log(this.state.listHasPayment))
    }

    createGroup(){
       var filtered = this.state.listHasPayment.filter(item => item.isChecked === true);
       let formData = new FormData();
       formData.append('peserta',JSON.stringify(filtered))
       axios.post(API_URL.superStudent+'/english-course/create-group',formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
       }).then(response =>{
        this.closeDialog()
        this.getListHasPayment()
        toast.success("data changed successfully...");
       }).catch(error => {
        toast.error("There is an error...");
    })


    }

    renderListDialog(){
        let columns = [
            {
                Header: '#',
                accessor: 'nama_lengkap',
                style: {textAlign: 'center'},
                width:50,
                Cell:(row) => (
                    <><input checked={row.original.isChecked} onChange={() => this.handleCheckBox(row.original)}  className='form-control' type='checkbox' /> </>
                )
            },
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
            },
            {
                Header: 'Jadwal',
                accessor: 'schedule',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Program',
                accessor: 'program.nama_program',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Guru',
                accessor: 'teacher.nama_lengkap',
            },
            {
                Header: 'Tipe',
                accessor: 'type.description',
                style: {textAlign: 'center'},
            }
        ];
        return (
            <Modal size='lg' isOpen={this.state.modal_list}  toggle={() => this.setState({ modal_list: false })}>
                <ModalHeader toggle={() => this.setState({ modal_list: false })}>Lit Data</ModalHeader>
                <ModalBody>
                    <ReactTable
                        data={this.state.listHasPayment}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        showPaginationBottom={true}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.createGroup()}>Create Group </Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        ) 
    }

    renderDeleteDialog() {
        const { modal_delete } = this.state;

        return (
            <Modal isOpen={modal_delete} toggle={() => this.setState({ modal_delete: false })}>
                <ModalHeader toggle={() => this.setState({ modal_delete: false })}>Delete Data {this.state.detail_data.nama_lengkap}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
    
    render() {
        const data = this.state.listEnglish;
        const dataCount = this.state.listEnglish.length;

        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                filterAll: true
            },
            {
                Header: 'Jadwal',
                accessor: 'schedule',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["schedule"] }),
                filterAll: true
            },
            {
                Header: 'Program',
                accessor: 'id_program',
                Cell: (row) => (
                    <>
                        {
                            row.original.program != null ?
                            <span>{row.original.program.nama_program}</span>
                            :
                            <span></span>
                        }
                    </>
                ),
                Filter: () => 
                <select value={this.state.filterProgram || ''} onChange={(e) => this.SetFilterProgram(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.listProgram.map( item => {
                            return <option key={item.id_program} value={item.id_program}>{item.nama_program}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Guru',
                accessor: 'id_teacher',
                Cell: (row) => (
                    <>
                        {
                        row.original.teacher != null ?  <span>{ row.original.teacher.nama_lengkap}</span> : ''
                        }
                    </>

                ),
                Filter: () => 
                <select value={this.state.filterGuru || ''} onChange={(e) => this.SetFilterTeacher(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.listTeacher.map( item => {
                            return <option key={item.id_teacher} value={item.id_teacher}>{item.nama_lengkap}</option>
                        })
                    } 
                </select>,
            }, 
            {
                Header: 'Type',
                accessor: 'id_type',
                Cell: (row) => (
                    <>
                        {
                            row.original.type != null ? 
                            <span>{row.original.type.types} {row.original.type.total_pertemuan}</span>
                            :
                            <spa></spa>
                        }
                    </>
                ),
            },
            {
                Header: 'Grup Code',
                accessor: 'grup_code',
                Filter: () => 
                <select value={this.state.filterGrupCode || ''} onChange={(e) => this.SetFilterGrupCode(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataGrupCode.map( item => {
                            return <option key={item.id_registrasi_grup} value={item.code_register_grup}>{item.code_register_grup}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Rekomender',
                accessor: 'consultant.nama',
                style: {textAlign: 'center'},
                filterable: false,
            }, 
            {
                Header: 'Status',
                accessor: 'status_payment',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.status_payment == 'N' ? 
                    <span className={'badge bg-primary'}>Not Paid</span>
                    :
                    row.original.status_payment == 'P' ? 
                    <span className={'badge bg-secondary'}>Pending</span>
                    :
                    row.original.status_payment == 'Y' ? 
                    <span className={'badge bg-success'}>Paid</span>
                    :
                    row.original.status_payment == 'S' ?
                    <span className={'badge bg-info'}>Start Study</span>
                    :
                    row.original.status_payment == 'F' ?
                    <span className={'badge bg-white'}>Finish Study</span>
                    :
                    row.original.status_payment == 'C' ?
                    <span className={'badge bg-dark'}>Cancel</span>
                    : ''
                ),
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option value=''>Show All</option>
                    <option value='N'>Not paid</option>
                    <option value='P'>Pending</option>
                    <option value='Y'>Paid</option>
                    <option value='S'>Start Study</option>
                    <option value='F'>Finish Study</option>
                    <option value='C'>Cancel</option>
                </select>,
            },
            {
                Header: 'Action',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div>
                        {
                            this.props.dataUser.code_roles == 'RL024' || this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL003' || this.props.dataUser.code_roles == 'RL005' ?
                            <button onClick={() => this.editRow(row.original)} className="btn btn-xs btn-primary" type="button" ><i className="fa fa-pencil"></i></button>
                            :
                            ""
                        }
                        <button onClick={() => this.deletRow(row.original)} className="ml-1 btn btn-xs btn-danger" type="button" ><i className="fa fa-trash" ></i></button>
                    </div>
                )
            },
        ];

        const csvData = data;

        return (
            <Fragment>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="text-left">
                                                <h5 className="mb-0">Total Registration {dataCount}</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                                <option value='this_week'>This Week</option>
                                                <option value='last_week'>Last Week</option>
                                                <option value='this_month'>This Month</option>
                                                <option value='last_month'>Last Month</option>
                                                <option value='this_year'>This Year</option>
                                                <option value='last_year'>Last Year</option>
                                                <optgroup label='Quartal'>
                                                    <option value='1'>Quartal I</option>
                                                    <option value='2'>Quartal II</option>
                                                    <option value='3'>Quartal III</option>
                                                    <option value='4'>Quartal IV</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <div style={{display:this.state.showAction}} className="col-sm-3">
                                            <div className="text-right">
                                                <ExportCSV csvData={csvData} fileName={"data-english-course"} />
                                            </div> 
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="text-right">
                                                <a href='https://kursusbahasa.niecindonesia.com/' rel='noreferrer noopener' target='_blank'><button className='btn btn-primary'>Landing Page</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className='col-md-2 m-2'>
                                    <button onClick={() => this.showListHasyPayment()} className='btn btn-success'>Create Group</button>
                                </div>
                                <div className="card-body">
                                    <ReactTable
                                        data={data}
                                        filtered={this.state.filtered}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        SubComponent={row => {
                                            const dataa = row.original
                                            return (
                                                <div className='container' style={{padding: '20px'}}>
                                                    <p>Phone   : <span>{dataa.no_wa}</span></p>
                                                    <p>Email   : <span>{dataa.email}</span></p>
                                                    <p>Address : <span>{dataa.address}</span></p>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
                {this.renderListDialog()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(EnglishCoureseComponent);