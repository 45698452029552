/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect } from "react";
import API_URL from "../../ApiUrl";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux'

const ReportMemberSource = ({start_date, end_date}) => {

    const[dataMember,setDataMember] = useState([{source:[], consultants:[]}]);
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const loadMemberSource = () => {
        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/members/total-by-source', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => setDataMember(response.data.data))
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadMemberSource()
    },[start_date, end_date])


    return(
        <>
            <Bar data={{
                labels: dataMember.consultants,
                datasets: dataMember.source,
                        }} options={{
                maintainAspectRatio: true,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                yAxes: [{
                    stacked: true,
                }]
                },
         }}  />
        </>
    );

}

export default ReportMemberSource;