import React, { Fragment } from 'react';
import DatatableStudents from '../common/datatable/datatableStudents';

const DataTablesStudentsData = ()=> {

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body datatable-react">
                                <DatatableStudents title="Users Data" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}      
 
export default DataTablesStudentsData;
