import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import API_URL from '../../../../ApiUrl'
import { toast } from 'react-toastify';


export class StructureTable2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addModal: false,
            title: 'Structure Question Part 2',
            data: {
                question: '',
                opt_a: '',
                opt_b: '',
                opt_c: '',
                opt_d: '',
                id_question_type: 1,
                id_structure_type: 2,
                id_question: 'null'
            }
            }
        }

    onChange(evt){
        var newQuestion = evt.editor.getData();
        this.setState({data:{
            ...this.state.data, question: newQuestion
        }})
    }
    handleChange(e){
        const {name, value} = e.target
        this.setState({data:{
            ...this.state.data, [name]: value
        }})
    }
    
    addSoal(){
        this.setState({addModal: true})
    }

    editSoal(row){
        this.setState({
            addModal: true, 
            data:{...this.state.data, 
                question: row.question,
                opt_a: row.opt_a,
                opt_b: row.opt_b,
                opt_c: row.opt_c,
                opt_d: row.opt_d,
                id_question: row.id
            }
            })
    }

    closeModal(){
        this.setState({addModal: false})
    }

    sendData(){
        // console.log(this.state.data)
        axios({
            method: 'POST',
            url: API_URL.placementTest + 'updateStructure',
            data: this.state.data,
            headers: {
                Authorization: `Bearer ${API_URL.placementTestToken}`
            }
        }).then(res=>{
            toast.success("Question Added")
            this.props.loadStructure()
            this.closeModal()
            // console.log(res);    
        }).catch(err => console.log(err))
    }

    renderAddModal(){
        const {addModal,title, data} = this.state
        return (
            <>
            <Modal isOpen={addModal} toggle={() => this.closeModal()} size='lg'>
                <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody>
                <form>
                    <div className="form-group">
                        <label for="exampleFormControlTextarea1">Question</label>
                        <textarea name='question' className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>this.handleChange(e)}>{data?.question}</textarea>
                    </div>
                    {/* <div className="form-group">
                        <label className=''>Question</label>
                        <CKEditors
                            activeclassName="p10"
                            content={data?.question}
                            events={{
                                    "change": this.onChange.bind(this),
                            }}
                            config={{
                            toolbar:[
                                        ["Bold","Italic",'Strike','RemoveFormat'],
                                        ['NumberedList','BulletedList','Indent','Outdent'],
                                        ['Link','Unlink'],
                                        ['Styles','Format','Table','Blockquote','Maximize'],
                                        ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                    ]
                                }}     
                        />
                    </div> */}
                    <div class="form-group">
                        <label htmlFor="exampleInputEmail1">Correct Answer</label>
                        <input type="text" name='opt_a' className="form-control" id="exampleInputEmail1" placeholder="Enter correct answer" value={data?.opt_a} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_b' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_b} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_c' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_c} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_d' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_d} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.sendData()}>Add</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </>
        )
    }

  render() {
    const {data} = this.props
    return (
        <>
        {/* <button type="button" className='btn btn-success mb-2'>Add Question</button> */}
        <p>"Direction: Choose the option to complete these sentence below"</p>
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Question</th>
                        <th scope="col">Correct Answer</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Other Options</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data?.map((s, i)=>{
                        return(
                            <tr key={i}>
                                {/* <th scope="row">1</th> */}
                                <td>{s.question}</td>
                                <td>{s.opt_a}</td>
                                <td>{s.opt_b}</td>
                                <td>{s.opt_c}</td>
                                <td>{s.opt_d}</td>
                                <td><button onClick={() => this.editSoal(s)} class="btn btn-outline-success btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-pencil"></i></button></td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
        {this.renderAddModal()}
        </>
      )
  }
}

export default StructureTable2