import React ,{Component} from 'react'
import DataTablesSchoolRep from '../../components/tables/dataTablesSchoolRep'

class DataSchoolRep extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <>
                <DataTablesSchoolRep/>
            </>
        )
    }
}

export default DataSchoolRep;