/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import Moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class KPIAccounting extends Component{

    constructor(props){
        super(props)
        this.state = {
            valueData:[],
            data:[],
            dataEdit:null,
            listIndikator:[],
            listFinanceIndikator:[],
            id:'',
            date:'',
            code_jobdesk:'',
            code_employee:'',
            modalAdd:false,
            modalEdit:false,
        }
    }

    handleInput = (index, value) => {
        let modal = [...this.state.valueData]
        modal[index] = value
        modal.push()
        this.setState({
            valueData: modal
        })
    }

    componentDidMount(){
        this.loadDataTempKPI()
    }

    loadDataTempKPI = () => {
        axios.get(API_URL.superStudent+'/kpi/load-temp-data',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({ data: response.data.data })
        })
        .catch(error => console.log(error))
    }

    handleChangeType = (e) => {
        this.setState({
            valueData:[],
            code_jobdesk: e.target.value
        },() => {
            this.loadDataIndikator()
            this.setCodeEmployee()

        }) 
    }

    setCodeEmployee = () => {
        let formData = new FormData()
        formData.append('jobdesk', JSON.stringify([this.state.code_jobdesk]))
        axios.post(API_URL.superStudent+'/consultant/jobdesk', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ code_employee: response.data.data[0].code_konsultan }))
    }

    handleSubmit = (e) => {
        let data = [];
        this.state.valueData.forEach(function(item,index){
            if(item != undefined){
                data.push({
                    'id': index,
                    'item': item 
                })
            }
        })
        let formData = new FormData ()
        formData.append('code_employee', this.state.code_employee)
        formData.append('input_value',JSON.stringify(data))
        formData.append('periode',Moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/accounting/set-kpi-finance', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.loadDataTempKPI()
            toast.success('successfully add data')
        })
        .catch(() => {
            this.closeModal()
            toast.error('there is an error')
        })
    }

    handleEdit = () => {
        let data = [];
        this.state.valueData.forEach(function(item,index){
            if(item != undefined){
                data.push({
                    'id': index,
                    'item': item 
                })
            }
        })
        let formData = new FormData ()
        formData.append('id', this.state.id)
        formData.append('code_employee', this.state.code_employee)
        formData.append('input_value',JSON.stringify(data))
        formData.append('date', this.state.date)
        axios.post(API_URL.superStudent+'/accounting/approve-kpi-finance', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then((response) => {
            console.log(response.data.data)
            this.closeModal()
            this.loadDataTempKPI()
            toast.success('successfully approved data')
        })
        .catch(() => {
            this.closeModal()
            toast.error('there is an error')
        })
    }

    viewDetail = (row) => {
        axios.get(API_URL.superStudent+'/kpi/load-temp-detail/'+row.id,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            let model = []
            response.data.data.targets.forEach(item => {
                model[item.value.id] = item.value.item
                model.push()
            })
            this.setState({
                id:response.data.data.id,
                modalEdit:true,
                valueData:model,
                date: response.data.data.periode,
                code_employee: response.data.data.code_employee,
                dataEdit: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    addData = () => {
        this.setState({
            title:'',
            amount:0,
            description:'',
            modalAdd: true
        })
    }

    closeModal(){
        this.setState({
            modalAdd:false,
            modalEdit:false
        })
    }

    loadDataIndikator = () => {
        let formData = new FormData()
        formData.append('code_jobdesk', this.state.code_jobdesk)
        axios.post(API_URL.superStudent+'/kpi/list-indikator', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({ listIndikator: response.data.data })
        })
        .catch(error => console.log(error))
    }

    renderModalAdd(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalAdd} size="lg" toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Form KPI</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='f-w-600'>Jobdesk</label>
                                <select className='form-control' onChange={this.handleChangeType} name='code_jobdesk' value={this.state.code_jobdesk}>
                                    <option>-- Pilih Jobdesk -- </option>
                                    <option value='JD010'>Finance Controller</option>
                                    <option value='JD013'>Accounting</option>
                                    <option value='JD014'>Accounting IHMS</option>
                                </select>
                            </div>
                            <div className='row'>
                            {
                                this.state.listIndikator.length > 0 ?
                                this.state.listIndikator.map(item => (
                                    <>
                                        <div className='col-md-10'>
                                            <div className='form-group'>
                                                <label className='f-w-600'>{item.name}.</label>
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <input type='number' onChange={e => this.handleInput(item.id, e.target.value)}className='form-control' />
                                        </div>
                                    </>
                                   
                                ))
                                :
                                ''
                            }
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e) => this.handleSubmit()}>Save</Button>
                  <Button  color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalEdit(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalEdit} size="lg" toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Form KPI</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                {
                                      this.state.dataEdit !== null ?
                                    this.state.dataEdit.targets.map(item => (
                                        <>
                                            <div className='col-md-10'>
                                                <div className='form-group'>
                                                    <label className='f-w-600'>{item.indikator.name}.</label>
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <input type='text' placeholder={item.value.item} onChange={e => this.handleInput(item.value.id, e.target.value)}className='form-control' />
                                            </div>
                                        </>
                                    ))
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                    {
                        this.props.dataUser.code_roles === 'RL003' ? 
                        <Button color="primary" onClick={(e) => this.handleEdit()}>Approve</Button>
                        :
                        ''
                    }
                  <Button  color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){
        const columns = [
            {
                Header: 'Nama',
                accessor: 'employee.nama_lengkap',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Periode',
                accessor: 'employee',
                style: {textAlign: 'center'},
                Cell: (row) =>(
                    <>{Moment(row.original.periode).format('MMM YYYY')}</>
                )
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                accessor: str => "delete",
                width:100,
                Cell: (row) => (
                    <button onClick={() => this.viewDetail(row.original)} className="btn btn-primary btn-xs" type="button" ><i className="fa fa-eye"></i></button>
                ),
                style: { textAlign: 'center' }
            }
        ]
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <button className='btn btn-success' onClick={this.addData}>Create Data</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.data}
                                        columns={columns}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    /> 
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
                {this.renderModalAdd()}
                {this.renderModalEdit()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(KPIAccounting);