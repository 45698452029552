/* eslint-disable eqeqeq, array-callback-return, no-useless-concat */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { 
     Row, Col, Nav, NavItem, NavLink, TabContent,
    TabPane, Card
} from "reactstrap";
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { ExportCSV } from '../../../services/ExportCSV';
import "react-datepicker/dist/react-datepicker.css";
import {matchSorter} from 'match-sorter';

class RegisterEventExpoNew extends Component {

    constructor(props){
        super(props)
        this.state = {
            event_id: 0,
            total_register: 0,
            registrasi_data: [],
            register_kota: [],
            event_data:[],
            data_register:[],
            id_kabkota:'146',
            register_by_kota:[],
            currentActiveTab:"1",
            activeTab:"1",
            filtered:[],
        }
    }

    componentDidMount(){
        this.getDataEvent();
    }

    getDataEvent = () => {
        const url = API_URL.superStudent+'/event/jenis-event/3';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    getDataRegisterEventByKota = () => {
        axios.get(API_URL.eventUrl+'load-data-kabkota/'+this.state.event_id+'/3', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({
            total_register: result.register_count,
            registrasi_data: result,
            register_kota: result.event_lokasi
        }, () => {
            this.handleChangeKabkota(this.state.id_kabkota);
        }))
        .catch(error => console.log(error))
    }

    handleChangeEvent = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => {
            this.getDataRegisterEventByKota()
        })
    }

    handleChangeKabkota = (e) => {
        this.setState({
            register_by_kota: []
        },() => {
            this.state.register_kota.map(item => {
                if (item.kode_kabkota == e) {
                    this.setState(prevState => ({
                        register_by_kota: [
                            ...prevState.register_by_kota,
                            item.kabupaten.event_register
                        ]
                    }))
                }
            })
        })
    }

    setTab(tab) {
        this.setState({ currentActiveTab: tab });
    }

    broadcastMail(e) {
        let formData = new FormData()

        formData.append('member', JSON.stringify(e))

        axios.post(API_URL.eventUrl+'world-education-festival/broadcast-mail-event', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.eventToken}`
            }
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => console.log(error))
    }

    certivateMail(e) {
        let formData = new FormData()

        formData.append('member', JSON.stringify(e))

        axios.post(API_URL.eventUrl+'world-education-festival/certivicate-mail-event', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.eventToken}`
            }
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => console.log(error))
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
        filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
            if (value === "" || !value.length) filtered.splice(i, 1);
            else filter["value"] = value;

            insertNewFilter = 0;
            }
        });
        }

        if (insertNewFilter) {
        filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }
    
    render(){
        const columns = [
            {
                Header: 'Kode Regristasi',
                accessor: 'register_code',
                filterMethod: (filter, rows) => 
                matchSorter(rows, filter.value, { keys: ["register_code"] }),
                filterAll: true
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'register_nama',
                filterMethod: (filter, rows) => 
                matchSorter(rows, filter.value, { keys: ["register_nama"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'register_email',
                filterMethod: (filter, rows) => 
                matchSorter(rows, filter.value, { keys: ["register_email"] }),
                filterAll: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'register_phone',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["register_phone"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.register_phone.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.register_phone} rel='noopener noreferrer' target='_blank'>{row.original.register_phone}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.register_phone.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.register_phone}</a>
                )
            },
            {
                Header: 'Sekolah',
                accessor: 'register.sekolah.nama_sekolah',
                filterMethod: (filter, rows) =>
                 matchSorter(rows, filter.value, { keys: ["register.sekolah.nama_sekolah"] }),
                filterAll: true
            },
            {
                Header: 'Provinsi',
                accessor: 'province_name',
                filterAll: true
            },
            {
                Header: 'Kabupaten',
                accessor: 'kabupaten_name',
                filterAll: true
            },
            {
                Header: 'Progress',
                accessor: 'register.student.progress.name_progress',
                filterAll: true
            },
            {
                Header: 'Consultant',
                accessor: 'register.student.consultant.nama',
                filterAll: true
            },
        ];

        let member = [];
        let general = [];

        let checkin = [];
        let attandance = [];

        let sosmed = [];

        if (this.state.register_by_kota[0] != undefined) {
            this.state.register_by_kota[0].map(item => {
                if (item.register.code_member != null) {
                    member.push(item)
                } else {
                    general.push(item)
                }
            })
    
            this.state.register_by_kota[0].map(item2 => {
                switch (item2.attandance) {
                    case 1:
                        checkin.push(item2)
                        break;
                    case 2:
                        attandance.push(item2)
                        break;
                
                    default:
                        break;
                }
            })

            this.state.register_by_kota[0].map(item2 => {
                if (item2.code_leads_source == 'LS013') {
                    sosmed.push(item2)
                }
            })
        }

        const tab = this.state.currentActiveTab;
        const Activetab = this.state.currentActiveTab;

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-left">
                                                    <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.total_register} /></span> </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-right">
                                                    <select className="form-control" onChange={this.handleChangeEvent} name="event_id" value={this.state.event_id}>
                                                        <option value='0'> --Filter Peserta-- </option>
                                                        {
                                                            this.state.event_data.map(event => {
                                                                return <option value={event.id} key={event.id}>{event.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.register_kota.length > 0 ?
                            <div className="row m-1 justify-content-center">
                                {
                                    this.state.register_kota.map((kota) => (
                                        <div className="col-xl-4 col-sm-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h5 className="mb-0" >{kota.kabupaten.nama_kabkota}</h5>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h1 className="font-primary counter">
                                                            <Countup end={kota.kabupaten.event_register_count} />
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    ))
                                }
                            </div>
                            :
                            ''
                        }
                        {
                            this.state.register_kota.length > 0 ?
                            <div className='row m-1 mb-5 justify-content-center'>
                                <div className='col-xl-3 col-sm-3'>
                                    <select className='form-control' onChange={(e) => this.handleChangeKabkota(e.target.value)} name="id_kabkota">
                                        {
                                            this.state.register_kota.map((kota) => (
                                                <option value={kota.kabupaten.id_kabkota}>{kota.kabupaten.nama_kabkota}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Ads</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={sosmed.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Member</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={member.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total General </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={general.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Attendance</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={checkin.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-12 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Nav justified pills className='mb-5'>
                                            <NavItem>
                                                <NavLink className={Activetab === "1" ? 'active' : ''} onClick={() => this.setTab("1")}>All Register</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "2" ? 'active' : ''} onClick={() => this.setTab("2")}>Member</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "4" ? 'active' : ''} onClick={() => this.setTab("4")}>General</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={Activetab === "5" ? 'active' : ''} onClick={() => this.setTab("5")}>Attandance</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={tab}>
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            {
                                                                this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL009' ?
                                                                <Row>
                                                                    <Col lg='6'>
                                                                        <div style={{display: this.state.showAction}}>
                                                                            <ExportCSV csvData={this.state.register_by_kota[0]} fileName={"data-register-edufest"} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg='6' style={{display: this.state.showActionBroadcast}}>
                                                                        <button className='btn btn-primary pull-right' onClick={() => this.broadcastMail(this.state.register_by_kota)}>Broadcast</button>
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                ''
                                                            }
                                                            <br/>
                                                            <ReactTable
                                                                data={this.state.register_by_kota[0]}
                                                                columns={columns}
                                                                filtered={this.state.filtered}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                                filterable
                                                                onFilteredChange={(filtered, column, value) => {
                                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                                }}
                                                                defaultFilterMethod={(filter, row) =>
                                                                String(row[filter.id]) === filter.value}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <ReactTable
                                                                data={member}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                                filterable
                                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            <ReactTable
                                                                data={general}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                                filterable
                                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="5">
                                                <Row>
                                                    <Col sm="12">
                                                        <Card body>
                                                            {
                                                                this.props.dataUser.code_roles == 'RL001' ?
                                                                <Row>
                                                                    <Col lg='12'>
                                                                        <button className='btn btn-primary pull-right' onClick={() => this.certivateMail(checkin)}>Certivicate</button>
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                ''
                                                            }
                                                            <br/>
                                                            <ReactTable
                                                                data={checkin}
                                                                columns={columns}
                                                                defaultPageSize={10}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                                filterable
                                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (RegisterEventExpoNew);