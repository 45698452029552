import React, { useEffect, Fragment, useState, useRef } from 'react';
import './index.scss';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";
import { connect } from 'react-redux';

import IdleTimer from 'react-idle-timer'


// Advance
// import Bridge from './pages/bridge';
import ForgetPwd from './pages/forgetPwd';
import ResetPwdOut from './pages/resetPwd';
import ResetPwdIn from './pages/resetPwd';
import Office from './pages/office';
import Otp from './pages/otpPwd';

import Consultant from './pages/consultant';
import DataEmployee from './pages/consultant/data-employee';
import CutiList from './pages/consultant/cuti-list';
import conselingList from './pages/consultant/conseling-list';
import Appraisal from './pages/consultant/appraisal';

import DetailEmployee from './pages/consultant/detail-employee'
import Document from './pages/document';

import SchoolRep from './pages/schoolrep';
import DataSchoolRep from './pages/schoolrep/data-schoolrep';
import DataEduGroup from './pages/schoolrep/data-edugroup';
import FormEduGroup from './pages/schoolrep/form-edugroup';
import AddSchoolRep from './pages/schoolrep/add-school-rep';
import FormProgramRep from './pages/schoolrep/form-program'


import Students from './pages/students';
import Roles from './pages/roles';
import Modules from './pages/modules';
import DetailStudents from './pages/students/detailStudents';

import StudentsHomeComponent from './pages/students/students-home-component';
import StudentsPersonalComponent from './pages/students/students-personal-component';
import StudentsDataComponent from './pages/students/students-data-component';
import StudentsFollowupComponent from './pages/students/students-followup-component';
import DataTaskList from './pages/students/data-task-list';
import StudentPeformance from './pages/students/students-peformance';

import TalentMapping from './pages/talentmapping';

import MembershipHomeComponent from './pages/membership/membership-home-component';
import MembershipDataComponent from './pages/membership/membership-data-component';
import MembershipApprovalComponent from './pages/membership/membership-approval-component';

import Membership from './pages/membership';

import GlobalAmbassador from './pages/ambassador';
import GlobalAmbassadorDataComponent from './pages/ambassador/ambassador-data-component';
import GlobalAmbassadorHome from './pages/ambassador/ambassador-report-component';
import DetailProfileGa from './pages/ambassador/detailProfileGa';

import SocialMedia from './pages/social-media'
import ListData from './pages/social-media/list-data'
import ReportSosmed from './pages/social-media/report'
import ReportPost from './pages/social-media/report-post'
import KeyOpinionLeader from './pages/social-media/key-opinion-leader';

import TaskList from './pages/task-list'
import HomeTaskList from './pages/task-list/home'
import TaskListData from './pages/task-list/list-data';
import ListPeriode from './pages/task-list/list-periode';

import SimulasiBiaya from './pages/simulasibiaya';
import Currency from './pages/simulasibiaya/finance-currency-data';
import TipePembayaran from './pages/simulasibiaya/finance-tipepembayaran-data';
import DetailPembayaran from './pages/simulasibiaya/finance-detailpembayaran-data';
import SimBiayaSetting from './pages/simulasibiaya/finance-simulasibiaya-data';
import SimBiayaStorage from './pages/simulasibiaya/finance-simulasibiaya-storage';

import Englishcourse from './pages/english-class/englishcourse';
import EnglishCourseHome from './pages/english-class/englishcourse/english-course-home';
import EnglishcourseRegister from './pages/english-class/englishcourse/english-course-register';
import EnglishcourseGrup from './pages/english-class/englishcourse/english-course-grup';
import EnglishCourseAttandance from './pages/english-class/englishcourse/english-course-attandance';
import EnglishCourseAttendance from './pages/english-class/englishcourse/english-course-attendance';

import School from './pages/school';
import SchoolDetail from './pages/school/schoolDetail';
import Activities from './pages/school/schoolDetail/schooldetail-activities-data';
import Feedback from './pages/school/schoolDetail/schooldetail-feedback-data';
import SchedulleActivities from './pages/school/schoolDetail/schooldetail-schedulle-data';
import SchoolGrandted from './pages/school/schoolDetail/schooldetail-granted-data';
import SchoolMember from './pages/school/schoolDetail/schooldetail-member-data';

import Campus from './pages/campus';

import UniversityProfile from './pages/university/profile';
import UniversityProgram from './pages/university/program';
import FormProgram from './pages/university/program/form-program';
import UniversityMedia from './pages/university/media';
import UniversityEvent from './pages/university/event/';
import UniversityEventList from './pages/university/event/list-event';
import UniversityEventListDetail from './pages/university/particion/event-detail';
import UniversityEventCart from './pages/university/event/list-event-cart';
import UniversityEventOrder from './pages/university/event/list-event-order';

import TranslateProfile from './pages/vendor-translate/profile';
import TranslateList from './pages/vendor-translate/list';
import TranslateRegistered from './pages/vendor-translate/register-vendor';
import TranslateRegisteredIncome from './pages/vendor-translate/register-vendor/vendor-income';

import VisaAdmission from './pages/visa-admission'
import HomeAdmission from './pages/visa-admission/home-admission'
import VisaAdmissionData from './pages/visa-admission/data-admission'

// Import Applications Components
import Signin from './auth/signin';
// import Signup from './auth/signup';
import Logout from './auth/Logout';

//config data
import configDB from './data/customizer/config';

import DashboardBranch from './components/dashboard/branch/dashboard-branch';
import DashboardConsultant from './components/dashboard/consultant/dashboard-consultant'
import DashboardBisDev from './components/dashboard/bisdev/dashboard-bisdev';
import DashboardSupervisorAdmission from './components/dashboard/admission-supervisor/dashboard_supervisor_admission'
import DashboardCreative from './components/dashboard/social-media-design/dashboard-sosmed-event'
import DashboardCEO from './components/dashboard/ceo/dashboard-ceo';
import DashboardAdmission from './components/dashboard/admission/dashboard-admission';

import DashboardEnglishDivision from './pages/english-division/profile/index';
import EnglishStudentHome from './pages/english-division/english-student/home-students';
import IndexEnglishStudent from './pages/english-division/english-student';
import EnglishStudentData from './pages/english-division/english-student/data-student';
import EnglishGroupClass from './pages/english-division/english-student/grup-class'
import EnglishStudentAttendance from './pages/english-division/english-student/data-attendance'

// dashboard default
import DashboardLastYear from './components/dashboard/last-year'
import Default from './components/dashboard/default';
import visitschool from './pages/visitschool';
import visitschooldetail from './pages/visitschool/visitschooldetail';
import visitschoolmeeting from './pages/visitschool/visitschoolmeeting';

import visitcampus from './pages/visitcampus';
import schedulleinvoice from './pages/schedulleinvoice';
import ScheduleInvoiceHome from './pages/schedulleinvoice/schedulle-invoice-home';
import ScheduleInvoiceData from './pages/schedulleinvoice/schedulle-invoice-data';
import ScheduleInvoiceStudentGranted from './pages/schedulleinvoice/schedulle-invoice-studentgranted';
import UniversityExpo from './pages/university/expo';
import UniversityExpoAttandance from './pages/university/expo/university-attandance';
import UniversityExpoQueue from './pages/university/expo/university-queue';
import UniversityExpoPotential from './pages/university/expo/university-potentisal';

import SchoolVisitHome from './pages/visitschool/school-visit-home';
import schoolVisitList from './pages/visitschool/school-visit-list';

import KPI from './pages/indikator-kpi'
import HomeKPI from './pages/indikator-kpi/home-kpi';
import ReportKPI from './pages/indikator-kpi/report-kpi';
import DataIndikatorKPI from './pages/indikator-kpi/data-indikator-kpi'

import WebsiteStudyTour from './pages/study-tour';
import WebsiteStudyTourList from './pages/study-tour/study-tour-list';
import WebsiteStudyTourRegister from './pages/study-tour/study-tour-register';
import WebsiteScholarship from './pages/scholarship';
import WebstieArtikel from './pages/artikel';
import WebstieArtikelKategori from './pages/artikel/artikel-kategori';
import WebstieArtikelList from './pages/artikel/artikel-list';
import WebsiteKonsultasi from './pages/konsultasi-ceo';

import BisdevPerform from './pages/bisdev-list-perform';
import BisdevTipe from './pages/bisdev-list-perform/bisdev-tipe';
import BisdevProduk from './pages/bisdev-list-perform/bisdev-produk';
import BisdevPerformList from './pages/bisdev-list-perform/bisdev-perform';

import PriceList from './pages/pricelist';

import Resources from './pages/resources'

import IndexEmployee from './pages/employee';
import ProfileEmployee from './pages/employee/profile';
import IndexKPI from './pages/employee/index-kpi';
import CutiData from './pages/employee/cuti-data';

import Announcement from './pages/announcement';

import MasterEvent from './pages/events/list-events/';
import FormEvent from './pages/events/list-events/form-event';
import MasterEventList from './pages/events/list-events/master-event-list';
import MasterEventOrder from './pages/events/list-events/master-event-order';

import RegisterEvent from './pages/events/list-register-event';
import RegisterEventExpo from './pages/events/list-register-event/event-expo';
import RegisterEventSeason from './pages/events/list-register-event/event-season';
import RegisterEventYouth from './pages/events/list-register-event/event-youth';
import RegisterEventExpoNew from './pages/events/list-register-event/event-expo-new';
import RegisterEventPartner from './pages/events/list-register-event/event-partner';

import PotensialEvent from './pages/events/list-potensial-event';
import PotensialEventRegister from './pages/events/list-potensial-event/potensial-register';
import PotensialEventRegisterNew from './pages/events/list-potensial-event/potensial-register-new';

import AmbassadorEvent from './pages/events/list-register-event/event-ambassador';

import UniversityData from './pages/education-group/university'
import DetailUniversityData from './pages/education-group/university/detail-university'
import ProgramData from './pages/education-group/program'
import DetailProgramData from './pages/education-group/program/detail-program'

import Accounting from './pages/accounting';
import DataRevenue from './pages/accounting/data-revenue';
import kpiAccounting from './pages/accounting/kpi-accounting';
import DataBudgeting from './pages/accounting/data-budgeting';
import DataProfit from './pages/accounting/data-profit';
import DataIncome from './pages/accounting/data-income';
import DataIndikator from './pages/accounting/data-indikator';
import DataBudgetingJobdesk from './pages/accounting/data-budgeting-jobdesk';

import VendorCourseProfile from './pages/course/profile'
import CourseList from './pages/course/course-list'
import FormCourse from './pages/course/course-list/form-course-component'
import DetailCourseComponent from './pages/course/detail-course'
import FormDetailCourse from './pages/course/detail-course/form-detail-course-component';

import Donate from './pages/donate';
import DataDonate from './pages/donate/data-donate'
import UserDonate from './pages/donate/user-donate'

import Point from './pages/point'
import DataPoint from './pages/point/data-point'
import GetPoint from './pages/point/get-point'
import FormPoint from './pages/point/form-point'

import SekolahMapping from './pages/sekolah-mapping';
import IhmsRegister from './pages/ihms-register';

import PaymentAll from './pages/payment-all';
import Intership from './pages/intership';

import EnglishCourseTeacher from './pages/english-class/englishcourse/english-course-teacher';

import LocationLog from './pages/location-log'

import SimulasiIELTS from './pages/english-class/simulasi-ielts/'
import SimulasiIELTSHome from './pages/english-class/simulasi-ielts/simulasi-ielts-home';
import SimulasiIELTSRegister from './pages/english-class/simulasi-ielts/simulasi-ielts-register';
import DetailRegisterIELTS from './pages/english-class/simulasi-ielts/detail-register';

import DataRegisterTOEFL from './pages/english-class/simulasi-toefl/data-register';

import PaymentCoworking from './pages/coworking-space'
import DataBooking from './pages/coworking-space/data-booking';
import HargaBooking from './pages/coworking-space/harga-booking';
import ArnantyoScholarship from './pages/arnantyo-scholarship';
import DataRegister from './pages/arnantyo-scholarship/data-register';
import PlacementTestHome from './pages/english-class/placement-test';
import BankSoal from './pages/english-class/placement-test/bank-soal';
import TestResult from './pages/english-class/placement-test/result';
import DetailResult from './pages/english-class/placement-test/detail-result';
import DetailResultWriting from './pages/english-class/placement-test/detail-writing';
import JobVacancy from './pages/job-vacancy';
import DataLowongan from './pages/job-vacancy/data-lowongan';
import DataKandidat from './pages/job-vacancy/data-kandidat';
import DetailKandidat from './pages/job-vacancy/detail-kandidat';

import Training from './pages/training';
import MasterTraining from './pages/training/master-training';
import EmployeeTraining from './pages/training/employee-training';
import ReportTraining from './pages/training/report-training';

import RewardHome from './pages/reward';
import RewardIndikator from './pages/reward/reward-indikator';
import RewardGift from './pages/reward/reward-gift';
import RewardKategori from './pages/reward/reward-kategori';

import RewardSubmitHome from './pages/reward-submit';
import RewardSubmit from './pages/reward-submit/reward-submit';
import RewardRedeem from './pages/reward-submit/reward-redeem';
import RewardPoint from './pages/reward-submit/reward-point';
import Merchandise from './pages/merchandise';
import DataProduct from './pages/merchandise/data-product';
import DataOrder from './pages/merchandise/data-order';

import leadsAll from './pages/leads-all';

import StatementLetter from './pages/statement-letter';
import DashboardFolowUp from './components/dashboard/consultant/dashboard-follow-up';

//firebase Auth only then un-comment this current User code
// function Root() {
const RouterApp = ({ loginStatus, history, codeRoles, codeUser, detailUser }) => {
    const [isTimedOut, setIsTimedOut] = useState(false);
    // const [url] = useState(window.location.pathname.split("/").pop());
    const idleTimer = useRef();

    const onAction = () => {
        setIsTimedOut(false);
    }

    const onActive = (e) => {
        setIsTimedOut(false);
    }

    const onIdle = (e) => {
        if (!isTimedOut) {
            handleLogout()
            idleTimer.current.reset();
            setIsTimedOut(true);
        }

    }

    const handleLogout = () => {
        window.location.href = `${process.env.PUBLIC_URL}/logout`;
    }

    const currentUser = loginStatus;
    const roles = codeRoles;
    const abortController = new AbortController();

    useEffect(() => {
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        document.body.classList.add(layout);

        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;

        return function cleanup() {
            abortController.abort();
        }

    }, [abortController]);

    return (

        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollContext>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/resetPwdOut`} component={ResetPwdOut} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/otpPwd`} component={Otp} />
                            {currentUser === true ?
                                <Fragment>
                                    <IdleTimer
                                        ref={idleTimer}
                                        onActive={onActive}
                                        onIdle={onIdle}
                                        onAction={onAction}
                                        debounce={250}
                                        timeout={1000 * 60 * 120}
                                    />
                                    <App>
                                        {
                                            detailUser.userable_type === "App\\Models\\Consultant" ?
                                                <>
                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/employee`} render={() => {
                                                        return (<Redirect to={`${process.env.PUBLIC_URL}/pages/employee/profile`} />)
                                                    }} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/employee`} component={IndexEmployee} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/employee/profile`} component={ProfileEmployee} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/employee/index-kpi`} component={IndexKPI} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/employee/cuti-data`} component={CutiData} />
                                                </>
                                                :
                                                ''
                                        }
                                        {
                                            roles === 'RL021' ?
                                                <>
                                                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Default} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />
                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/program-university/:universityId`} component={FormProgramRep} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />

                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />

                                                </>
                                                :
                                                roles === 'RL015' ?
                                                    <>
                                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/pages/university/profile`} />)
                                                        }} />
                                                        <Route path={`${process.env.PUBLIC_URL}/pages/university/profile`} component={UniversityProfile} />
                                                        <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/:idEvents`} component={UniversityExpo} />
                                                        <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/:idEvents/attandance`} component={UniversityExpoAttandance} />
                                                        <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/:idEvents/queue`} component={UniversityExpoQueue} />
                                                        <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/:idEvents/potential`} component={UniversityExpoPotential} />
                                                    </>
                                                    :
                                                    roles === 'RL018' ?
                                                        <>
                                                            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                return (<Redirect to={`${process.env.PUBLIC_URL}/pages/course/profile`} />)
                                                            }} />
                                                            <Route path={`${process.env.PUBLIC_URL}/pages/course/profile`} component={VendorCourseProfile} />
                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/course/course-list`} component={CourseList} />
                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/course/course-list/form-course-component`} component={FormCourse} />
                                                            <Route path={`${process.env.PUBLIC_URL}/pages/course/course-list/form-course-component/:codeCourse`} component={FormCourse} />
                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/course/detail-course/:courseId`} component={DetailCourseComponent} />
                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/course/detail-course/:courseId/form-detail-course`} component={FormDetailCourse} />
                                                            <Route path={`${process.env.PUBLIC_URL}/pages/course/detail-course/:courseId/form-detail-course/:id`} component={FormDetailCourse} />
                                                        </>
                                                        :
                                                        roles === 'RL011' ?
                                                            <>
                                                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                    return (<Redirect to={`${process.env.PUBLIC_URL}/pages/university/profile`} />)
                                                                }} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/profile`} component={UniversityProfile} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/program`} component={UniversityProgram} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/form-program`} component={FormProgram} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/media`} component={UniversityMedia} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/event`} component={UniversityEvent} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/event/home`} component={UniversityEventList} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/eventdetail/:id`} component={UniversityEventListDetail} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/event/cart`} component={UniversityEventCart} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/event/history`} component={UniversityEventOrder} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/expo`} component={UniversityExpo} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/attandance/:idEvents`} component={UniversityExpoAttandance} />
                                                                <Route path={`${process.env.PUBLIC_URL}/pages/university/expo/potential/:idEvents`} component={UniversityExpoPotential} />
                                                            </>
                                                            :
                                                            roles === 'RL019' ?
                                                                <>
                                                                    <Route exact path={`${process.env.PUBLIC_URL}/`}
                                                                        render={() => {
                                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/pages/translate/profile`} />)
                                                                        }}
                                                                    />
                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/translate/profile`} component={TranslateProfile} />
                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/translate/list`} component={TranslateList} />
                                                                </>
                                                                :
                                                                roles === 'RL008' ?
                                                                    <>
                                                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/pages/students/consultant`} />)
                                                                        }} />

                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                    </>
                                                                    :
                                                                    roles === 'RL006' ?
                                                                        <>
                                                                            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/consultant`} />)
                                                                            }} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/consultant`} component={DashboardConsultant} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/talentmapping`} component={TalentMapping} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership`} component={Membership} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course`} component={Englishcourse} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/register`} component={EnglishcourseRegister} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/home`} component={EnglishCourseHome} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/grup`} component={EnglishcourseGrup} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course-teacher`} component={EnglishCourseTeacher} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`} component={EnglishCourseAttandance} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ihms-register`} component={IhmsRegister} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/sekolah-mapping`} component={SekolahMapping} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />


                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/campus`} component={Campus} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship`} component={ArnantyoScholarship} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship/data-register`} component={DataRegister} />
                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-toefl`} component={DataRegisterTOEFL} />

                                                                        </>
                                                                        :
                                                                        roles === 'RL013' ?
                                                                            <>
                                                                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                    return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/bisdev`} />)
                                                                                }} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/dashboard/bisdev`} component={DashboardBisDev} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership`} component={Membership} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/talentmapping`} component={TalentMapping} />
                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform`} component={BisdevPerform} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`} component={BisdevTipe} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/produk`} component={BisdevProduk} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/perform`} component={BisdevPerformList} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/campus`} component={Campus} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschoolmeeting/:codeSchool/:codeActivities`} component={visitschoolmeeting} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/sekolah-mapping`} component={SekolahMapping} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course`} component={Englishcourse} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/register`} component={EnglishcourseRegister} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/home`} component={EnglishCourseHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/grup`} component={EnglishcourseGrup} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course-teacher`} component={EnglishCourseTeacher} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`} component={EnglishCourseAttandance} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test`} component={PlacementTestHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`} component={BankSoal} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/result`} component={TestResult} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-result/:hashid`} component={DetailResult} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-writing/:hashid`} component={DetailResultWriting} />


                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts`} component={SimulasiIELTS} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/home`} component={SimulasiIELTSHome} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/register`} component={SimulasiIELTSRegister} />
                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/detail/:code_order`} component={DetailRegisterIELTS} />

                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-toefl`} component={DataRegisterTOEFL} />
                                                                            </>
                                                                            :
                                                                            roles === 'RL016' || roles === 'RL009' ?
                                                                                <>
                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/creative`} />)
                                                                                    }} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/creative`} component={DashboardCreative} />


                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`} component={DataIndikator} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`} component={DataProfit} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-income`} component={DataIncome} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`} component={DataRevenue} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`} component={DataBudgeting} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />

                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitschoolmeeting/:codeSchool/:codeActivities`} component={visitschoolmeeting} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/merchandise`} component={Merchandise} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/merchandise/produk`} component={DataProduct} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/merchandise/order`} component={DataOrder} />

                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design`} component={SocialMedia} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/list-register`} component={ListData} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/report-sosmed`} component={ReportSosmed} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/post-sosmed`} component={ReportPost} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/key-opinion-leader`} component={KeyOpinionLeader} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />

                                                                                </>
                                                                                :
                                                                                roles === 'RL025' ?
                                                                                    <>
                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/admission-supervisor`} />)
                                                                                        }} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/admission-supervisor`} component={DashboardSupervisorAdmission} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />

                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />


                                                                                    </>
                                                                                    :
                                                                                    roles === 'RL023' ?
                                                                                        <>
                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/branch`} />)
                                                                                            }} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/branch`} component={DashboardBranch} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/follow-up`} component={DashboardFolowUp} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership`} component={Membership} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/talentmapping`} component={TalentMapping} />
                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform`} component={BisdevPerform} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`} component={BisdevTipe} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/produk`} component={BisdevProduk} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/perform`} component={BisdevPerformList} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/campus`} component={Campus} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitschoolmeeting/:codeSchool/:codeActivities`} component={visitschoolmeeting} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course`} component={Englishcourse} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/register`} component={EnglishcourseRegister} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/home`} component={EnglishCourseHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/grup`} component={EnglishcourseGrup} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course-teacher`} component={EnglishCourseTeacher} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`} component={EnglishCourseAttandance} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/ihms-register`} component={IhmsRegister} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/sekolah-mapping`} component={SekolahMapping} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy`} component={JobVacancy} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/loker`} component={DataLowongan} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/:id_job_vacancy`} component={DataKandidat} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/detail-candidate/:id_hire_employee`} component={DetailKandidat} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/training`} component={Training} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/training/master`} component={MasterTraining} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/training/employee-training`} component={EmployeeTraining} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/training/report-training`} component={ReportTraining} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/placement-test`} component={PlacementTestHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`} component={BankSoal} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/result`} component={TestResult} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-result/:hashid`} component={DetailResult} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-writing/:hashid`} component={DetailResultWriting} />

                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`} component={DataIndikator} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`} component={DataProfit} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-income`} component={DataIncome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`} component={DataRevenue} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`} component={DataBudgeting} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />


                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts`} component={SimulasiIELTS} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/home`} component={SimulasiIELTSHome} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/register`} component={SimulasiIELTSRegister} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/detail/:code_order`} component={DetailRegisterIELTS} />
                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-toefl`} component={DataRegisterTOEFL} />

                                                                                        </>
                                                                                        :
                                                                                        roles === 'RL026' ?
                                                                                            <>
                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                    return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/ceo`} />)
                                                                                                }} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/dashboard/ceo`} component={DashboardCEO} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship`} component={ArnantyoScholarship} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship/data-register`} component={DataRegister} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy`} component={JobVacancy} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/loker`} component={DataLowongan} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/:id_job_vacancy`} component={DataKandidat} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/detail-candidate/:id_hire_employee`} component={DetailKandidat} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/roles`} component={Roles} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/modules`} component={Modules} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/:code_konsultan`} component={DetailEmployee} />
                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/program-university/:universityId`} component={FormProgramRep} />
                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/location-log`} component={LocationLog} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`} component={DataRevenue} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/kpi`} component={kpiAccounting} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`} component={DataBudgeting} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`} component={DataProfit} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-income`} component={DataIncome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`} component={DataIndikator} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />


                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/dashboard/follow-up`} component={DashboardFolowUp} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership`} component={Membership} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/talentmapping`} component={TalentMapping} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya`} component={SimulasiBiaya} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/currency`} component={Currency} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/tipepembayaran`} component={TipePembayaran} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/detailpembayaran`} component={DetailPembayaran} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayasetting`} component={SimBiayaSetting} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayastorage`} component={SimBiayaStorage} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/`} component={schedulleinvoice} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/home`} component={ScheduleInvoiceHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/data`} component={ScheduleInvoiceData} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/studentgranted`} component={ScheduleInvoiceStudentGranted} />

                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/campus`} component={Campus} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/sekolah-mapping`} component={SekolahMapping} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitschoolmeeting/:codeSchool/:codeActivities`} component={visitschoolmeeting} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts`} component={SimulasiIELTS} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/home`} component={SimulasiIELTSHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/register`} component={SimulasiIELTSRegister} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/detail/:code_order`} component={DetailRegisterIELTS} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course`} component={Englishcourse} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/register`} component={EnglishcourseRegister} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/home`} component={EnglishCourseHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/grup`} component={EnglishcourseGrup} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course-teacher`} component={EnglishCourseTeacher} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`} component={EnglishCourseAttandance} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/website/konsultasi`} component={WebsiteKonsultasi} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform`} component={BisdevPerform} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`} component={BisdevTipe} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/produk`} component={BisdevProduk} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/perform`} component={BisdevPerformList} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/ihms-register`} component={IhmsRegister} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/payment-all`} component={PaymentAll} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test`} component={PlacementTestHome} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`} component={BankSoal} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/result`} component={TestResult} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-result/:hashid`} component={DetailResult} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-writing/:hashid`} component={DetailResultWriting} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />

                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/statement-letter`} component={StatementLetter} />
                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-toefl`} component={DataRegisterTOEFL} />


                                                                                            </>
                                                                                            :
                                                                                            roles === 'RL027' ?
                                                                                                <>
                                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/english-division`} />)
                                                                                                    }} />
                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/english-division`} component={DashboardEnglishDivision} />
                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/english-student`} component={IndexEnglishStudent} />
                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/english-student/home`} component={EnglishStudentHome} />
                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/english-student/data`} component={EnglishStudentData} />
                                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/english-student/group`} component={EnglishGroupClass} />
                                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/english-student/attendance`} component={EnglishStudentAttendance} />
                                                                                                </>
                                                                                                :
                                                                                                roles === 'RL012' ?
                                                                                                    <>
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/admission`} />)
                                                                                                        }} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/admission`} component={DashboardAdmission} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />

                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/default`} />)
                                                                                                        }} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/follow-up`} component={DashboardFolowUp} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/membership`} component={Membership} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant`} component={Consultant} />

                                                                                                        {
                                                                                                            roles === 'RL001' ?
                                                                                                                <>
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/branch`} component={DashboardBranch} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/consultant`} component={DashboardConsultant} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/bisdev`} component={DashboardBisDev} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/ceo`} component={DashboardCEO} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/admission-supervisor`} component={DashboardSupervisorAdmission} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/admission`} component={DashboardAdmission} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/coworking-space`} component={PaymentCoworking} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/coworking-space/data-booking`} component={DataBooking} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/coworking-space/harga`} component={HargaBooking} />

                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship`} component={ArnantyoScholarship} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship/data-register`} component={DataRegister} />

                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy`} component={JobVacancy} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/loker`} component={DataLowongan} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/:id_job_vacancy`} component={DataKandidat} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/detail-candidate/:id_hire_employee`} component={DetailKandidat} />

                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/training`} component={Training} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/training/master`} component={MasterTraining} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/training/employee-training`} component={EmployeeTraining} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/training/report-training`} component={ReportTraining} />

                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/roles`} component={Roles} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/modules`} component={Modules} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/office`} component={Office} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/document`} component={Document} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/:code_konsultan`} component={DetailEmployee} />
                                                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/program-university/:universityId`} component={FormProgramRep} />
                                                                                                                    <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />

                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/location-log`} component={LocationLog} />

                                                                                                                </>
                                                                                                                :
                                                                                                                roles === 'RL004' ?
                                                                                                                    <>
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/:code_konsultan`} component={DetailEmployee} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy`} component={JobVacancy} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/loker`} component={DataLowongan} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/:id_job_vacancy`} component={DataKandidat} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/detail-candidate/:id_hire_employee`} component={DetailKandidat} />

                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training`} component={Training} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training/master`} component={MasterTraining} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training/employee-training`} component={EmployeeTraining} />
                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training/report-training`} component={ReportTraining} />
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    roles === 'RL003' ?
                                                                                                                        <>
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/dashboard/last-year`} component={DashboardLastYear} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/office`} component={Office} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/:code_konsultan`} component={DetailEmployee} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/document`} component={Document} />
                                                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/program-university/:universityId`} component={FormProgramRep} />
                                                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />
                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/location-log`} component={LocationLog} />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        roles === 'RL005' ?
                                                                                                                            <>
                                                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/form-school-rep`} component={AddSchoolRep} />
                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/form-school-rep/:codeSchoolRep`} component={AddSchoolRep} />
                                                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/form-edugroup`} component={FormEduGroup} />
                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/form-edugroup/:id`} component={FormEduGroup} />

                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/program-university/:universityId`} component={FormProgramRep} />
                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/consultant/detail-employee/:code_konsultan`} component={DetailEmployee} />
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            roles === 'RL006' ?
                                                                                                                                <>
                                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />

                                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship`} component={ArnantyoScholarship} />
                                                                                                                                    <Route path={`${process.env.PUBLIC_URL}/pages/arnantyo-scholarship/data-register`} component={DataRegister} />
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                roles === 'RL013' ?
                                                                                                                                    <>
                                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/membership/approval`} component={MembershipApprovalComponent} />
                                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    roles === 'RL007' ?
                                                                                                                                        <>
                                                                                                                                            <Route exact path={`${process.env.PUBLIC_URL}/dashboard/default`} render={() => {
                                                                                                                                                return (<Redirect to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} />)
                                                                                                                                            }} />
                                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                                                                            <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />
                                                                                                                                        </>
                                                                                                                                        :
                                                                                                                                        roles === 'RL017' ?
                                                                                                                                            <>
                                                                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                                                                                                                                    return (<Redirect to={`${process.env.PUBLIC_URL}/pages/education-group/university`} />)
                                                                                                                                                }} />
                                                                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/education-group/university`} component={UniversityData} />
                                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/education-group/university/:codeSchoolRep`} component={DetailUniversityData} />
                                                                                                                                                <Route exact path={`${process.env.PUBLIC_URL}/pages/education-group/program`} component={ProgramData} />
                                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/education-group/program/form-program`} component={DetailProgramData} />
                                                                                                                                                <Route path={`${process.env.PUBLIC_URL}/pages/education-group/program/form-program/:programId`} component={DetailProgramData} />
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            ''
                                                                                                        }

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting`} component={Accounting} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`} component={DataRevenue} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/kpi`} component={kpiAccounting} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`} component={DataBudgeting} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`} component={DataProfit} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-income`} component={DataIncome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`} component={DataIndikator} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} component={DataBudgetingJobdesk} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`} component={DataEmployee} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`} component={CutiList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`} component={conselingList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`} component={Appraisal} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/merchandise`} component={Merchandise} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/merchandise/produk`} component={DataProduct} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/merchandise/order`} component={DataOrder} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/sekolah-mapping`} component={SekolahMapping} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi`} component={KPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/home`} component={HomeKPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/report-kpi`} component={ReportKPI} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/indikator-kpi/data-indikator-kpi`} component={DataIndikatorKPI} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/announcement`} component={Announcement} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/resources`} component={Resources} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission`} component={VisaAdmission} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} component={HomeAdmission} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} component={VisaAdmissionData} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep`} component={SchoolRep} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-edugroup`} component={DataEduGroup} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schoolrep/data-schoolrep`} component={DataSchoolRep} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students`} component={Students} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`} component={StudentsPersonalComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/home`} component={StudentsHomeComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} component={StudentsDataComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} component={StudentPeformance} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} component={DataTaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/students/follow-up`} component={StudentsFollowupComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/studentsdetail/:codeStudent`} component={DetailStudents} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/membership/home`} component={MembershipHomeComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/membership/data`} component={MembershipDataComponent} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassador`} component={GlobalAmbassador} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/home`} component={GlobalAmbassadorHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassador/data`} component={GlobalAmbassadorDataComponent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ambassadordetail/:codeAmbassador/:idCategory`} component={DetailProfileGa} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event`} component={MasterEvent} />
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event`} component={FormEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/form-event/:codeEvent`} component={FormEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/list`} component={MasterEventList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/master-event/order`} component={MasterEventOrder} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event`} component={RegisterEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo`} component={RegisterEventExpo} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`} component={RegisterEventSeason} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`} component={RegisterEventYouth} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} component={RegisterEventExpoNew} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} component={RegisterEventPartner} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event`} component={PotensialEvent} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial`} component={PotensialEventRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/potensial-event/list-potensial-new`} component={PotensialEventRegisterNew} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/events/ambassador-event`} component={AmbassadorEvent} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/talentmapping`} component={TalentMapping} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya`} component={SimulasiBiaya} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/currency`} component={Currency} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/tipepembayaran`} component={TipePembayaran} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/detailpembayaran`} component={DetailPembayaran} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayasetting`} component={SimBiayaSetting} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/simulasibiaya/simbiayastorage`} component={SimBiayaStorage} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/`} component={schedulleinvoice} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/home`} component={ScheduleInvoiceHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/data`} component={ScheduleInvoiceData} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/scheduleinvoice/studentgranted`} component={ScheduleInvoiceStudentGranted} />

                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/school`} component={School} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool`} component={SchoolDetail} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/activities`} component={Activities} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/feedback`} component={Feedback} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/schedulle`} component={SchedulleActivities} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/granted`} component={SchoolGrandted} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/schooldetail/:codeSchool/member`} component={SchoolMember} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/campus`} component={Campus} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitschool`} component={visitschool} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/home`} component={SchoolVisitHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitschool/list`} component={schoolVisitList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitschooldetail/:codeSchool/:codeActivities`} component={visitschooldetail} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitschoolmeeting/:codeSchool/:codeActivities`} component={visitschoolmeeting} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/visitcampus`} component={visitcampus} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts`} component={SimulasiIELTS} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/home`} component={SimulasiIELTSHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/register`} component={SimulasiIELTSRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/detail/:code_order`} component={DetailRegisterIELTS} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course`} component={Englishcourse} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course/register`} component={EnglishcourseRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course/home`} component={EnglishCourseHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course/grup`} component={EnglishcourseGrup} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course-teacher`} component={EnglishCourseTeacher} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-course/j1-schedule`} component={EnglishCourseAttandance} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design`} component={SocialMedia} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/list-register`} component={ListData} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/report-sosmed`} component={ReportSosmed} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/post-sosmed`} component={ReportPost} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/key-opinion-leader`} component={KeyOpinionLeader} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list`} component={TaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`} component={HomeTaskList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-data`} component={TaskListData} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`} component={ListPeriode} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour`} component={WebsiteStudyTour} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/list`} component={WebsiteStudyTourList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/studytour/register`} component={WebsiteStudyTourRegister} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/scholarship`} component={WebsiteScholarship} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel`} component={WebstieArtikel} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/kategori`} component={WebstieArtikelKategori} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/artikel/list`} component={WebstieArtikelList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/website/konsultasi`} component={WebsiteKonsultasi} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform`} component={BisdevPerform} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/tipe`} component={BisdevTipe} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/produk`} component={BisdevProduk} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/bisdev-perform/perform`} component={BisdevPerformList} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/pricelist`} component={PriceList} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/ihms-register`} component={IhmsRegister} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/payment-all`} component={PaymentAll} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/intership`} component={Intership} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/point`} component={Point} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/point/data-point`} component={DataPoint} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/point/get-point`} component={GetPoint} />
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/point/form-point`} component={FormPoint} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/point/form-point/:id`} component={FormPoint} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/donate`} component={Donate} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/donate/data-donate`} component={DataDonate} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/donate/user-donate`} component={UserDonate} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/translate-registered`} component={TranslateRegistered} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/translate-registered/income`} component={TranslateRegisteredIncome} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/reward`} component={RewardHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/reward/kategori`} component={RewardKategori} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/reward/indikator`} component={RewardIndikator} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/reward/gift`} component={RewardGift} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/rewards`} component={RewardSubmitHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/rewards/submit`} component={RewardSubmit} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/rewards/redeem`} component={RewardRedeem} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/rewards/point`} component={RewardPoint} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/placement-test`} component={PlacementTestHome} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`} component={BankSoal} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/result`} component={TestResult} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-result/:hashid`} component={DetailResult} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/placement-test/detail-writing/:hashid`} component={DetailResultWriting} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/leads-all`} component={leadsAll} />
                                                                                                        <Route exact path={`${process.env.PUBLIC_URL}/pages/english-course/attendance`} component={EnglishCourseAttendance} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/statement-letter`} component={StatementLetter} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/english-class/simulasi-toefl`} component={DataRegisterTOEFL} />

                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training/master`} component={MasterTraining} />
                                                                                                        <Route path={`${process.env.PUBLIC_URL}/pages/training/employee-training`} component={EmployeeTraining} />

                                                                                                    </>
                                        }
                                        <Route path={`${process.env.PUBLIC_URL}/resetpwd`} component={ResetPwdIn} />
                                    </App>
                                </Fragment>
                                :
                                <Fragment>
                                    <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                                </Fragment>
                            }
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    codeRoles: state.Login.detailUser.code_roles,
    codeUser: state.Login.detailUser.code_students || '',
    detailUser: state.Login.detailUser,
    userableData: state.Login.userableData
})

export default connect(reduxState, null)(RouterApp);