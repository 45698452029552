import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTablesProgram from './dataTablesProgram';

class UniversityProgram extends Component {

    render(){
        return(
            <Fragment>
                <Breadcrumb parent="User" title="University Program" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className='col-md-12'>
                                <DataTablesProgram/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UniversityProgram;