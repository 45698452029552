/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Loader from '../../components/common/loader';

class RewardProfile extends Component {

    constructor(props){
        super(props)
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            modalUpdate: false,
            listConsultant: [],
            dataGift: [],
            dataRewardRedeem: [],
            dataConsultantPoint: [],
            id_redeem: "",
            code_konsultan: this.props.data.code_konsultan,
            code_gift: ""
        }
    }

    componentDidMount() {
        this.getDataGift()
        this.getDataRedeem()
        this.getDataTotalPoint()
    }

    getDataGift() {
        const url = API_URL.superStudent+'/reward/gift';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataGift:data.data
            });
        });
    }

    getDataRedeem() {
        const url = API_URL.superStudent+'/reward/redeem/'+this.state.code_konsultan;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataRewardRedeem:data.data
            });
        });
    }

    getDataTotalPoint() {
        const url = API_URL.superStudent+'/reward/total-point/'+this.state.code_konsultan;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataConsultantPoint:data.data
            });
        });
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Redeem Reward',
            operation: 'add',
            logName: 'ADD-REDEEM-REWARD',
            id_redeem: "",
            code_gift: "",
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Edit Redeem Reward',
            operation: 'edit',
            logName: 'EDIT-REDEEM-REWARD',
            id_redeem: row.id_redeem,
            code_gift: row.code_gift,
        })
    }

    deleteRow(row) {
        this.setState({
            modalDelete: true,
            title: 'Delete Submit Reward',
            operation: 'delete',
            logName: 'DELETE-SUBMIT-REWARD',
            id_redeem: row.id_redeem,
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            modalUpdate: false,
            id_redeem: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_redeem', this.state.id_redeem);
        formData.append('code_konsultan', this.state.code_konsultan);
        formData.append('code_gift', this.state.code_gift);
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/reward/redeem';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/reward/redeem/'+this.state.id_redeem
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/reward/redeem/'+this.state.id_redeem
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataRedeem();
            this.setState({ btnDisabled: false });
            
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Gift</label>
                                    <select className="form-control" name="code_gift" type="text" id="code_gift"  value={this.state.code_gift}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputcode_gift = e}>
                                        <option>--Pilih Gift--</option>
                                        {
                                            this.state.dataGift.map((kat) => (
                                                <option value={kat.code_gift}>{kat.gift_name} - {kat.harga}</option>
                                            ))
                                        }
                                    </select>
                                    <span>{this.state.errors.code_gift}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Code Redeem',
                accessor: 'code_redeem',
                filterable: false
            },
            {
                Header: 'Employee',
                accessor: 'consultant.nama',
                filterable: false
            },
            {
                Header: 'Indikator',
                accessor: 'gift.gift_name',
                filterable: false
            },
            {
                Header: 'Point',
                accessor: 'gift.harga',
                style: {textAlign: "center"},
                filterable: false
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == '1' ? 
                            <span className={'badge bg-info'}>New</span>
                            :
                            row.original.status == '2' ? 
                            <span className={'badge bg-success'}>Approve</span>
                            : 
                            <span className={'badge bg-danger'}>Cancel</span>
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        {
                            row.original.status == 1 ? 
                            <div>                     
                                <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                                <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                            </div>
                            :
                            ""
                        }
                    </>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <h4>Reward</h4>
                            </div>
                            <div className='col-lg-4'>
                                <h4>Total Point Reward : {this.state.dataConsultantPoint != null ? this.state.dataConsultantPoint.total_point : 0}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                            <div>
                                <span>
                                    <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                </span>
                            </div>
                        </button>
                        <br/>
                        {
                            this.state.dataRewardRedeem.length > 0 ?
                            <ReactTable
                                data={this.state.dataRewardRedeem}
                                columns={columns}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            /> 
                            : 
                            <Loader showLoading={this.state.showLoading} />
                        }
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (RewardProfile)