import React, { Fragment,Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import API_URL from '../../../ApiUrl';
import axios from 'axios';

class DatatableModules extends Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            id_modules:'',
            title:'',
            component:'',
            path: '',
            sub_modules:'',
            dataModules: [],
            operation: '',
            modal:false,
            modalDelete:false,
            btnDisabled: false,
        }
    }

    addRow(){
        this.setState({
            modal:true
          })
    }

    editRow(row) {
        this.setState({
          operation: 'update',
          id_modules: row.id_modules,
          title: row.title,
          component: row.component,
          path: row.path,
          sub_modules: row.sub_modules,
          modal: true
        })
      }

    getParentModules(){
        axios.get(API_URL.superStudent+'/module/parents',{
          headers: { Authorization: `Bearer ` +this.props.dataToken }
        })
        .then(response => {
            this.setState({
                dataModules:response.data.data
            })
        })
    }

    sendData(){
        this.setState({
            btnDisabled: true
        });
        var formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('path', this.state.path)
        formData.append('component', this.state.component)
        formData.append('sub_modules', this.state.sub_modules)

        let url = '/modules'

        if (this.state.operation === 'update'){
            formData.append('_method','PATCH');
            url = '/modules/'+this.state.id_modules
        }

        axios({
            method: 'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
        })
        .then(() => {
          toast.success('Data Update Successfully')
          window.location.reload(false)
        })
        
    }

    closeDialog(){
        this.setState({
          modal: false,
          modalDelete: false
        });
        this.resetState();
      }

    handleInput = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    resetState(){
        this.setState({
          id_modules:'',
          title:'',
          component:'',
          path: '',
          sub_modules:''
        })
    }

    renderDialog(){

        return (
          <div className="card-body btn-showcase">
            <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
              <ModalHeader toggle={() => this.setState({ modal: false })}>Form Module</ModalHeader>
              <ModalBody>
              <form className="needs-validation" noValidate="" >
                  <div className="col-md-12 mb-3"> 
                      <label htmlFor="roles">Title</label>
                      <input className="form-control" onChange={this.handleInput} value={this.state.title} name="title" type="text" />
                  </div>
                  <div className="col-md-12 mb-3"> 
                      <label htmlFor="roles">Component</label>
                      <input className="form-control" onChange={this.handleInput} value={this.state.component}  name="component" type="text" />
                  </div>
                  <div className="col-md-12 mb-3"> 
                      <label htmlFor="roles">Path</label>
                      <input className="form-control" onChange={this.handleInput} value={this.state.path} name="path" type="text" />
                  </div>
                  <div className="col-md-12 mb-3">
                  <label htmlFor="roles">Sub Modules</label>
                    <select md="3" className="form-control" name="sub_modules"  placeholder="Sub Modules"
                      onChange={this.handleInput} value={this.state.sub_modules}>
                      <option value="">Parent Module</option>
                        {this.state.dataModules.length > 0 ?  this.state.dataModules.map((module)=>(
                            <option key={module.code_modules} value={module.code_modules}>{module.title}</option>
                        )) : <option></option> }
                    </select>
                </div>
              </form>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Data</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </div>
        )
      }
    
    componentDidMount(){
        this.getParentModules()
    }

    deleteRow(row) {
        this.setState({
          id_modules: row,
          modalDelete: true
        });
      }
    
      setDeleteRow() {
          return (
            <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
              <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Modules</ModalHeader>
              <ModalBody>
                Delete Module ?
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Delete Data</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }

      saveDeleteRow(){
        this.setState({
          btnDisabled: true
        })
        axios.delete(API_URL.superStudent+'/modules/'+ this.state.id_modules,{
            headers: {  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
          window.location.reload(false)
        })
      }

    render(){
        const { pageSize, myData } = this.props;
        const columns = [
        {
          Header: 'Code Modules',
          accessor: 'code_modules',
          style: {textAlign: 'center'},
          filterable: false
        },
        {
            Header: 'Component',
            accessor: 'component',
            style: {textAlign: 'center'},
            filterable: false
        },
        {
            Header: 'Path',
            accessor:'path',
            style: {textAlign: 'center'},
            filterable: false
        },
        {
            Header: 'Title',
            accessor:'title',
            style: {textAlign: 'center'},
            filterable: false
        },
        {
          Header: <b>Action</b>,
          id:'delete',
          accessor: str => 'delete',
          Cell:  (row) => (
            <div>                     
                  <button onClick={() => this.deleteRow(row.original.id_modules)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" ></i></button> &nbsp;
                  <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil"></i>{row.id}</button>
            </div>
          ),
          style: {
            textAlign: 'center'
          },
          sortable: false,
          filterable: false
        }
      ]
        return (
            <Fragment>
            <div>
              <button onClick={() => this.addRow()} className="btn btn-success btn-xs" type="button" style={{border:'none'}} >
                <div>
                  <span>
                    <i className="fa fa-plus fa-2x"></i>
                  </span>
                </div>
              </button>
            </div>
            <ReactTable
            data={myData}
            columns={columns}
            pageSize={pageSize}
            showPagination={true}
            defaultPageSize={10}
          />
          <ToastContainer />
          {this.renderDialog()}
          {this.setDeleteRow()}
        </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(DatatableModules))