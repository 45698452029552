import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';
import { withRouter } from "react-router";
import Breadcrumb from '../../../components/common/breadcrumb';
import scoreTOEFL from '../../../helper/score_toefl';


class DataRegisterTOEFL extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataRegister: [],
            filtered: []
        }
    }

    componentDidMount() {
        this.getAllRegisterData()
    }

    getAllRegisterData = () => {
        axios.get(API_URL.superStudent + '/simulasi-toefl/data-peserta', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ dataRegister: response.data.data }))
    }

    render() {
        const columns = [
            {
                Header: 'Kode Register',
                accessor: 'code_register',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_register"] }),
                filterAll: true
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true
            },
        ]

        return (
            <>
                <Breadcrumb title="Simulasi TOEFL" parent="Simulasi TOEFL" />
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReactTable
                                        data={this.state.dataRegister}
                                        columns={columns}
                                        filterable
                                        collapseOnDataChange={false}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        showPageSizeOptions={true}
                                        SubComponent={row => {
                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <div className='row m-b-25'>
                                                        <div className='col-md-12'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Kategori</th>
                                                                        <th>Nilai</th>
                                                                        <th>Score</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Listening</td>
                                                                        <td>{row.original.listening_score}</td>
                                                                        <td>{scoreTOEFL.scoreListening(row.original.listening_score)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Structure</td>
                                                                        <td>{row.original.structure_score}</td>
                                                                        <td>{scoreTOEFL.scoreStructure(row.original.listening_score)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reading</td>
                                                                        <td>{row.original.reading_score}</td>
                                                                        <td>{scoreTOEFL.scoreReading(row.original.listening_score)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{textAlign:'center'}} colSpan='2'>Total Score</th>
                                                                        <th>{parseInt((scoreTOEFL.scoreListening(row.original.listening_score) + scoreTOEFL.scoreStructure(row.original.listening_score) + scoreTOEFL.scoreReading(row.original.listening_score))*10/3)}</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(withRouter(DataRegisterTOEFL))