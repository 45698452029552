/* eslint-disable react-hooks/exhaustive-deps */

import React,{ Fragment } from 'react';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import moment from 'moment';

const Notification = ({dataToken, dataUser, notification, triggerLoadNotifikasi}) => {
    const textTitle = {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        lineClamp: 1,
        WebkitBoxOrient: "vertical",
    };

    const textDesc = {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        lineClamp: 1,
        WebkitBoxOrient: "vertical",
    };

    const updateStatusNotifikasi = (detail) => {
        let formData  = new FormData()
        formData.append('_method', 'PATCH');

        axios.post(API_URL.superStudent+'/master/notifikasi/'+detail.id_notifikasi, formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            triggerLoadNotifikasi()
        })
        .catch(error => console.log)
    }

    const updateAllStatusNotifikasi = () => {
        let formData  = new FormData()
        formData.append('code_consultant', dataUser.code_konsultan);

        axios.post(API_URL.superStudent+'/notifikasi/update-read-all', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            triggerLoadNotifikasi()
        })
        .catch(error => console.log)
    }

    return (
        <Fragment>
            <ul className="notification-dropdown onhover-show-div p-0" style={{ overflow: 'scroll', height: '400px' }}>
                <li>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <span>Notification</span>
                        </div>
                        <div className='col-lg-6' style={{display:'-webkit-line-clamp: 1'}}>
                            <span onClick={() => updateAllStatusNotifikasi()} style={{color: 'blue'}}>Mark As Read All</span>
                        </div>
                    </div>
                </li>
                {
                    notification.map((notifikasi, i) => (
                        <li key={i} onClick={() => updateStatusNotifikasi(notifikasi)}>
                            <div className="media">
                                <div className="media-body">
                                    <div className='row'>
                                        <div className='col-lg-10' style={textTitle}>
                                            <h6 style={{color: 'black', fontWeight: 'bold'}}>{notifikasi.title}</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            {
                                                notifikasi.status === 0 ? 
                                                <div style={{float: 'inline-end'}}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        width="20"
                                                        height="20"
                                                    >
                                                        <circle cx="10" cy="10" r="10" fill="#4466f2" />
                                                    </svg>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                    <div style={textDesc}>
                                        <span>{notifikasi.body}</span>
                                    </div>
                                    <span><i>{moment(notifikasi.created_at).format('DD MMMM YYYY')}</i></span>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </Fragment>
    );
};


export default Notification;