import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
class JobVacancy extends Component{

    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return(
            <>
            <Breadcrumb title="RECRUITMENT" />
                <div className='container-fluid'>
                    <div className='row theme-tab'>
                        <div className='col-sm-12'>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/job-vacancy`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/job-vacancy/loker`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/job-vacancy/list-kandidat/:id_job_vacancy`}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default JobVacancy