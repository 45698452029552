/* eslint-disable eqeqeq */

import React, { Component } from "react";
import ReactTable from 'react-table';
import axios from 'axios';
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExportCSV } from "../../../services/ExportCSV";
import Moment from 'moment';

import API_URL from "../../../ApiUrl";


class ReportStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listStudent: [],
            filtered: [],
            type: '10',
            dataNoted: '',
            code_students: '',
            modalNoted: false
        }
    }

    componentDidMount() {
        this.loadDataReport();
    }

    loadDataReport() {
        this.setState({ listStudent: [] })
        let formData = new FormData()
        formData.append('type', this.state.type)
        formData.append('code_user', this.props.dataUser.code_users)
        formData.append('code_roles', this.props.dataUser.code_roles)
        axios.post(API_URL.superStudent + '/todo/report-done-admission', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({ listStudent: response.data.data })
            })
            .catch(error => console.log(error))
    }

    closeModal() {
        this.setState({
            modalNoted: false,
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.loadDataReport())
    }

    editNoted = (row) => {
        this.setState({
            code_students: row.code_students,
            dataNoted: row.student.progress_log.length > 0 ? row.student.progress_log[0].code : '',
            modalNoted: true,
        })
    }

    updateNoted() {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code', this.state.dataNoted)
        formData.append('code_users', this.props.dataUser.code_users)
        formData.append('code_lpt', 'CMNT')
        axios.post(API_URL.studentUrl + 'progress-log', formData, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
            .then(() => {
                this.loadDataReport()
                this.closeModal();
            })
    }

    renderModalNote() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalNoted} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Update Note</ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Edit Noted</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='dataNoted' value={this.state.dataNoted} onChange={(e) => this.setState({ dataNoted: e.target.value })}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateNoted()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {

        const columns = [
            {
                Header: 'Code Student',
                accessor: 'code_students',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }),
                width: 120,
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.student.token_students)}>{row.original.code_students}</span>
                    </>
                )
            },
            {
                Header: 'Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span className={`${row.original.total_day > 7 && this.state.type == '10' ? 'blink' : ''}`}  >{row.original.nama}</span>
                    </>
                )
            },
            {
                Header: 'Consultant',
                accessor: 'consultant_name',
                width: 120,
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
                filterAll: true,
                Cell: (row) => (
                    <span>{row.original.consultant_name}</span>
                )
            },
            {
                Header: 'Total day',
                accessor: 'total_day',
                width: 100,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        <div style={{ fontSize: '11px', color: 'grey' }}>{row.original.total_day} days ago</div>
                    </>
                ),
            },
        ];

        if (this.state.type == '10') {
            columns.push(
                {
                    Header: 'Program',
                    accessor: 'programs',
                    filterable: false,
                    Cell: (row) => (

                        row.original.student_program != null ?
                            <span style={{ fontSize: '10px' }} >({row.original.student_program.university_program.university.nama_sekolah}) - {row.original.student_program.university_program.program_name} - ({Moment(row.original.student_program.date_program_start).format('MM/YY')}-{Moment(row.original.student_program.date_program_finish).format('MM/YY')})</span>
                            : ''
                    ),
                },
            )
        }
        return (
            <>
                <div className='card'>
                    <div className="card-header">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <h5>Leads Student Admission</h5>
                            </div>
                            <div className="col-md-3">
                                <ExportCSV csvData={this.state.listStudent} fileName={"data-students"} />
                            </div>
                            <div className="col-md-3">
                                <select name="type" value={this.state.type} className="form-control" onChange={this.handleChange}>
                                    <option value='10' >Apply Campus</option>
                                    <option value='11' >Lodge Visa</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <ReactTable
                                    data={this.state.listStudent}
                                    filterable
                                    columns={columns}
                                    collapseOnDataChange={false}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    SubComponent={row => {
                                        return (
                                            <div style={{ padding: "20px" }}>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.student.progress_log.length > 0 ?
                                                            <>
                                                                {row.original.student.progress_log[0].code}
                                                            </>
                                                            : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalNote()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(ReportStudent)
