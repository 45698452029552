/* eslint-disable eqeqeq, no-useless-concat */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Moment from 'moment';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class StudyTourList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            modal: false,
            detail: '',
            dataIntership: [],
            status: 1,
            btnDisabled: false
        }
    }

    componentDidMount() {
        this.getDataIntership();
    }

    getDataIntership() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/intership/list';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataIntership:data.data
            });
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            detail: row,
            status: 1
        })
    }

    closeDialog() {
        this.setState({
            modal: false
        })
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        })

        var formData = new FormData();
        formData.append('status', this.state.status);
        formData.append('_method', 'PATCH');

        const url = API_URL.superStudent+'/intership/list/'+this.state.detail.kode_register;
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ modal:false, btnDisabled: false });
            this.getDataIntership();
            toast.success("Update Sucsessfully");
        });

    }

    renderDialog() {
        const { modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Update Status</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className="form-control" name="status" type="text" id="status" onChange={(e) => this.handleInput(e)}>
                                        <option value={1}>Follow Up</option>
                                        <option value={2}>Progress</option>
                                        <option value={3}>Document</option>
                                        <option value={4}>Cancel</option>
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const dataCount = this.state.dataIntership.length;
        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterable: false,
                Cell: (row) => ( 
                    row.original.no_wa.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.no_wa} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_wa.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                )
            },
            {
                Header: 'Tanggal Lahir',
                accessor: 'tanggal_lahir',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {Moment(row.original.tanggal_lahir).format('DD MMMM YYYY')}
                    </div>
                )
            },
            {
                Header: 'Propinsi',
                accessor: 'provinsi.nama_propinsi',
                filterable: false
            },
            {
                Header: 'Kabupaten',
                accessor: 'kabupaten.nama_kabkota',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == 1 ? 
                            <span className={'badge bg-secondary'}>Follow Up</span>
                            :
                            row.original.status == 2 ? 
                            <span className={'badge bg-info'}>Progress</span>
                            :
                            row.original.status == 3 ? 
                            <span className={'badge bg-success'}>Document</span>
                            :
                            row.original.status == 4 ? 
                            <span className={'badge bg-danger'}>Cancel</span>
                            :
                            <span className={'badge bg-primary'}>New</span>
                        }
                    </div>
                )
            },
            {
                Header: 'Recomender',
                accessor: 'consultant.nama',
                filterable: false
            },
            {
                Header: 'Sekolah / Kampus',
                accessor: 'sekolah.nama_sekolah',
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <Breadcrumb title="Intership" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="mb-0">Total Registration {dataCount}</h5>
                                        <div className="text-right">
                                            <a href='https://prioritas.niecindonesia.com/' rel="noopener noreferrer" target='_blank'><button className='btn btn-primary'>Landing Page</button></a>
                                        </div>
                                        <br/>
                                        {
                                            this.state.dataIntership.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataIntership}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(StudyTourList));