/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Swal from 'sweetalert2';
import {matchSorter} from 'match-sorter';

class RewardSubmit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            modalUpdate: false,
            filtered: [],
            listConsultant: [],
            dataKategori: [],
            dataIndikator: [],
            dataRewardSubmit: [],
            id_submit: "",
            code_consultant: "",
            code_kategori: "",
            code_indikator: "",
            status: "",
            point: "",
            filterRequestor: ""
        }
    }

    componentDidMount() {
        this.getDataConsultant()
        this.getDataKategori()
        this.getDataRewardSubmit()
    }

    getDataConsultant(){
        let url
        if(this.props.dataUser.code_roles === 'RL004')
            url = API_URL.superStudent+'/consultant/all-data'
        else
            url = API_URL.superStudent+'/consultant/all-employee'  
            
          axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
          .then(response => response.data.data)
          .then(result => this.setState({ listConsultant: result}))
          .catch(error => console.log(error))
    }

    getDataKategori() {
        const url = API_URL.superStudent+'/reward/kategori';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataKategori:data.data
            });
        });
    }

    getDataIndikatorByKategori() {
        const url = API_URL.superStudent+'/reward/indikator/'+this.state.code_kategori;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                dataIndikator:data.data
            });
        });
    }

    getDataRewardSubmit() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/reward/submission';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataRewardSubmit:data.data
            });
        });
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Submit Reward',
            operation: 'add',
            logName: 'ADD-SUBMIT-REWARD',
            id_submit: "",
            code_consultant: "",
            code_indikator: "",
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Edit Submit Reward',
            operation: 'edit',
            logName: 'EDIT-SUBMIT-REWARD',
            id_submit: row.id_submission,
            code_consultant: row.code_konsultan,
            code_indikator: row.code_indikator,
        })
    }

    deleteRow(row) {
        this.setState({
            modalDelete: true,
            title: 'Delete Submit Reward',
            operation: 'delete',
            logName: 'DELETE-SUBMIT-REWARD',
            id_submit: row.id_submission,
        })
    }

    handleUpdate(row) {
        this.setState({
            modalUpdate: true,
            title: "Update Point Employee",
            id_submit: row.id_submission,
            code_consultant: row.code_konsultan,
            point: row.point,
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            modalUpdate: false,
            id_submit: "",
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleChangeKategori = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.getDataIndikatorByKategori();
        })
    }

    SetFilterRequestor = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
        let filterRequestor = newData; 
        return { filterRequestor };   
        }, () => {
          this.onFilteredChangeCustom(newData, "code_konsultan");
        })
    }

    SetFilterRequestorKategori = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterRequestor = newData; 
        return { filterRequestor };
        }, () => {
          this.onFilteredChangeCustom(newData, "code_kategori");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_indikator', this.state.id_submit);
        formData.append('code_konsultan', this.state.code_consultant);
        formData.append('code_indikator', this.state.code_indikator);
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/reward/submission';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/reward/submission/'+this.state.id_submit
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/reward/submission/'+this.state.id_submit
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataRewardSubmit();
            this.setState({ 
                btnDisabled: false,
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    sendUpdateData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_submission', this.state.id_submit);
        formData.append('code_konsultan', this.state.code_consultant);
        formData.append('point', this.state.point);
        formData.append('type_point', "submit");
        formData.append('status', this.state.status);
        
        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/reward/update_point';

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataRewardSubmit();
            this.setState({ 
                btnDisabled: false,
            }, () => {
                Swal.fire({
                    title: "Process Point",
                    text: reply.data.data
                });
            });
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Consultant</label>
                                    <select className="form-control" name="code_consultant" type="text" id="code_consultant"  value={this.state.code_consultant}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputcode_consultant = e}>
                                        <option>--Pilih Consultant--</option>
                                        {
                                            this.state.listConsultant.map((kat) => (
                                                <option value={kat.code_konsultan}>{kat.nama}</option>
                                            ))
                                        }
                                    </select>
                                    <span>{this.state.errors.code_consultant}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Kategori</label>
                                    <select className="form-control" name="code_kategori" type="text" id="code_kategori"  value={this.state.code_kategori}
                                        onChange={(e) => this.handleChangeKategori(e)}
                                        ref={e => this.inputkategori = e}>
                                        <option>--Pilih Kategori--</option>
                                        {
                                            this.state.dataKategori.map((kat) => (
                                                <option value={kat.code_kategori}>{kat.kategori}</option>
                                            ))
                                        }
                                    </select>
                                    <span>{this.state.errors.kategori}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Indikator</label>
                                    <select className="form-control" name="code_indikator" type="text" id="code_indikator"  value={this.state.code_indikator}
                                        onChange={(e) => this.handleInput(e)}
                                        ref={e => this.inputkategori = e}>
                                        <option>--Pilih Indikator--</option>
                                        {
                                            this.state.dataIndikator.map((kat) => (
                                                <option value={kat.code_indikator}>{kat.indikator}</option>
                                            ))
                                        }
                                    </select>
                                    <span>{this.state.errors.kategori}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data {this.state.indikator} ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderUpdateDialog() {
        const { title, modalUpdate } = this.state;

        return (
            <Modal isOpen={modalUpdate} toggle={() => this.setState({ modalUpdate: false })}>
                <ModalHeader toggle={() => this.setState({ modalUpdate: false })}>{title}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="name">Status</label>
                                <select className="form-control" name="status" type="text" id="status"  value={this.state.status}
                                    onChange={(e) => this.handleInput(e)}
                                    ref={e => this.inputkategori = e}>
                                    <option>--Status--</option>
                                    <option value="2">Approve</option>
                                    <option value="3">Cancel</option>
                                </select>
                                <span>{this.state.errors.kategori}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendUpdateData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }


    render() {

        const columns = [
            {
                Header: 'Code Submission',
                accessor: 'code_submission',
                filterable: false
            },
            {
                Header: 'Employee',
                accessor: 'code_konsultan',
                filterable: true,
                Cell:(row) => (
                    row.original.consultant_name
                ),
                Filter: () => 
                <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestor(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.listConsultant.map(item => {
                        return <option key={item.code_konsultan} value={item.code_konsultan}>{item.nama}</option>
                        })
                    }
                </select>,
            },
            {
                Header: 'Indikator',
                accessor: 'indikator_name',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["indikator_name"] }),
                filterAll: true
            },
            {
                Header: 'Point',
                accessor: 'point',
                style: {textAlign: "center"},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["point"] }),
                filterAll: true
            },
            {
                Header: 'Kategori',
                accessor: 'code_kategori',
                filterable: true,
                Cell:(row) => (
                    row.original.kategori_name
                ),
                Filter: () => 
                <select value={this.state.filterRequestor || ''} onChange={(e) => this.SetFilterRequestorKategori(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataKategori.map( item => {
                        return <option key={item.code_kategori} value={item.code_kategori}>{item.kategori}</option>
                        })
                    }
                </select>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == '1' ? 
                            <span className={'badge bg-info'}>New</span>
                            :
                            row.original.status == '2' ? 
                            <span className={'badge bg-success'}>Approve</span>
                            : 
                            <span className={'badge bg-danger'}>Cancel</span>
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        {
                            row.original.status == 1 ? 
                            <div>                     
                                <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                                <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                                <button onClick={() => this.handleUpdate(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11 }}></i></button>
                            </div>
                            :
                            ""
                        }
                    </>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
                <Fragment>
                    <div className="page-wrapper">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                                <div>
                                                    <span>
                                                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                    </span>
                                                </div>
                                            </button>
                                            <br/>
                                            <ReactTable
                                                data={this.state.dataRewardSubmit}
                                                filtered={this.state.filtered}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>String(row[filter.id]) === filter.value}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                            />
                                            <Loader showLoading={this.state.showLoading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderDialog()}
                    {this.renderDeleteDialog()}
                    {this.renderUpdateDialog()}
                </Fragment>
            </>
        )

    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(RewardSubmit));