/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Breadcrumb from '../../components/common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';
import { matchSorter } from 'match-sorter';
import { ExportCSV } from '../../services/ExportCSV';

class Pricelist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: {},
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataPricelist: [],
            id_pricelist: "",
            produk: "",
            keterangan: "",
            currency: "",
            units: "",
            harga: "",
        }
    }

    componentDidMount() {
        this.getDataPricelist();
    }

    getDataPricelist() {
        const url = API_URL.superStudent + '/price-list';
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataPricelist: data })
            })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Pricelist',
            operation: 'add',
            logName: 'ADD-PRICLIST',
            name: ''
        });
    }

    editRow(row) {
        axios.get(API_URL.superStudent + '/price-list/' + row.id_pricelist, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then(result => {
                this.setState({
                    modal: true,
                    title: 'Edit Price List',
                    operation: 'edit',
                    logName: 'EDIT-PRICELIST',
                    id_pricelist: result.id_pricelist,
                    produk: result.produk,
                    keterangan: result.keterangan,
                    currency: result.currency,
                    units: result.units,
                    harga: result.harga,
                })
            })
    }

    deleteRow(row) {
        this.setState({
            id_pricelist: row.id_pricelist,
            modalDelete: true,
            title: 'Delete Price List',
            operation: 'delete',
            logName: 'DELETE-PRICELIST'
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('produk', this.state.produk);
        formData.append('keterangan', this.state.keterangan);
        formData.append('currency', this.state.currency);
        formData.append('units', this.state.units);
        formData.append('harga', this.state.harga);

        formData.append('user', this.props.dataId);
        formData.append('token', this.props.dataToken);

        let url = '/price-list';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/price-list/' + this.state.id_pricelist
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/price-list/' + this.state.id_pricelist
        }

        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((reply) => {
            if (reply.data.status == 'Success') {
                this.closeDialog();
                this.getDataPricelist();
                this.setState({ btnDisabled: false });
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }
            }
        }).catch(err => {
            if (err.response.status === 422 || err.response.status === 409) {
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Produk</label>
                                    <input className="form-control" name="produk" type="text" id="produk" onChange={(e) => this.handleInput(e)} value={this.state.produk} />
                                    <span>{this.state.errors.produk}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Keterangan</label>
                                    <input className="form-control" name="keterangan" type="text" id="keterangan" onChange={(e) => this.handleInput(e)} value={this.state.keterangan} />
                                    <span>{this.state.errors.keterangan}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Currency</label>
                                    <input className="form-control" name="currency" type="text" id="currency" onChange={(e) => this.handleInput(e)} value={this.state.currency} />
                                    <span>{this.state.errors.currency}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Units</label>
                                    <input className="form-control" name="units" type="text" id="units" onChange={(e) => this.handleInput(e)} value={this.state.units} />
                                    <span>{this.state.errors.units}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Harga</label>
                                    <input className="form-control" name="harga" type="text" id="harga" onChange={(e) => this.handleInput(e)} value={this.state.harga} />
                                    <span>{this.state.errors.harga}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let idr = Intl.NumberFormat('id-ID');

        const columns = [
            {
                Header: 'Produk',
                accessor: 'produk',
                width: 250,
                filterable: true,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["produk"] }),
                filterAll: true
            },
            {
                Header: 'Keterangan',
                accessor: 'keterangan',
                filterable: false
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                filterable: false
            },
            {
                Header: 'Units',
                accessor: 'units',
                filterable: false
            },
            {
                Header: 'Harga',
                accessor: 'harga',
                filterable: false,
                Cell: (row) => (
                    <h6>{idr.format(row.original.harga)}</h6>
                )
            },
            {
                Header: 'Last Update',
                accessor: 'updated_at',
                filterable: false,
                style: { textAlign: 'center' },
                width: 120,
                Cell: (row) => (
                    <h6>{Moment(row.original.updated_at).format('D MMM Y')}</h6>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width: 100,
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {
                            this.props.codeRoles == 'RL001' || this.props.codeRoles == 'RL003' || this.props.codeRoles == 'RL005' ?
                                <div>
                                    <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs mr-1" type="button" style={{ border: 'none', display: this.props.display }}><i className="fa fa-pencil"></i></button>
                                    <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button"><i className="fa fa-trash"></i></button>
                                </div>
                                :
                                ''
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <Breadcrumb title="Pricelist" parent="Pricelist" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className='row m-1 justify-content-between'>
                                            <div className='col-sm-3'>
                                                <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} >
                                                    <div>
                                                        <span>
                                                            <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                        </span>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className='col-sm-2'>
                                                <ExportCSV csvData={this.state.dataPricelist} fileName={"data-price-list"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.dataPricelist.length > 0 ?
                                                <ReactTable
                                                    data={this.state.dataPricelist}
                                                    columns={columns}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) =>
                                                        String(row[filter.id]) === filter.value}
                                                    defaultPageSize={50}
                                                    className="-striped -highlight"
                                                />
                                                :
                                                <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(Pricelist));