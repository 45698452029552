import React from "react"
import CountUp from 'react-countup';

const CardCountDetail = ({backgroundColor, color='#4466f2' ,icon, label, amount }) => {

    return (
        <div style={{cursor:'pointer'}} 
          className="card o-hidden">
            <div style={{color}} className="card-body">
                <div className="media static-top-widget">
                    {/* <div className="align-self-center text-center">
                        <i className={`fa fa-2x ${icon}`} ></i>
                    </div> */}
                    <div className="media-body">
                        <span className="m-0" style={{fontSize: '11px',color:'grey'}}>{label}</span>
                        <h4 className="mb-0 counter">
                            <CountUp className="counter" end={amount} />
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCountDetail;

