/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Loader from '../../components/common/loader';

class DataIndikator extends Component {

    constructor(props){
        super(props)
        this.state = {
            showLoading: false,
            btnDisabled: false,
            action: '',
            modalIndikator: false,
            modalDelete: false,
            modalDetail: false,
            modalDetailEdit: false,
            modalDetailDelete: false,
            indiktorBudget:[],
            dataDivisi:[],
            dataIndikatorDetail:[],
            id_indikator: "",
            indikator: "",
            tipe_waktu: "",
            code_jobdesk: "",
            id_indikator_budget: "",
        }
    }

    componentDidMount() {
        this.loadDivisi();
        this.loadIndikator();
    }

    handleForm = () =>  {
        this.setState({ 
            action: 'add',
            modalIndikator:true 
        })
    }

    closeModal = () => {
        this.setState({ 
            modalIndikator:false,
            modalDelete:false,
            modalDetail:false,
            modalDetailDelete:false,
            modalDetailEdit:false,
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    setDivisi = (e) => {
        this.setState({
            code_jobdesk: e
        })
    }

    loadDivisi = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({dataDivisi:response.data.data}))
        .catch(error => console.log(error))
    }

    loadIndikator = () => {
        axios.get(API_URL.superStudent+'/accounting/indikator-budget',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({indiktorBudget:response.data.data}))
        .catch(error => console.log(error))
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('name', this.state.indikator);
        formData.append('tipe_budget', this.state.tipe_waktu);

        let url = '/accounting/create-indikator-budget';

        if (this.state.operation == 'edit') {
            url = '/accounting/update-indikator-budget/'+this.state.id_indikator
        } else if (this.state.operation == 'delete') {
            url = '/accounting/delete-indikator-budget/'+this.state.id_indikator
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeModal();
            this.loadIndikator();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            modalIndikator: true,
            id_indikator: row.id,
            indikator: row.name,
            tipe_waktu: row.tipe_budget,
        })
    }

    deleteRow(row) {
        this.setState({
            operation: 'delete',
            modalDelete: true,
            id_indikator: row.id,
            indikator: row.name,
        })
    } 

    handleFormDetail = (row) => {
        this.setState({
            code_jobdesk: '',
            id_indikator: row.id,
            indikator: row.name,
            operation: 'add',
            modalDetailEdit: true,
        })
    }

    editDetailRow(row) {
        this.setState({
            id_indikator_budget: row.pivot.id_budget_indikator_jobdesk,
            indikator: row.name,
            code_jobdesk: row.code_jobdesk,
            operation: 'edit',
            modalDetailEdit: true,
        })
    }

    deleteDetailRow(row) {
        this.setState({
            id_indikator_budget: row.pivot.id_budget_indikator_jobdesk,
            indikator: row.name,
            operation: 'delete',
            modalDetailDelete: true,
        })
    }

    saveRowDetail() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_indikator', this.state.id_indikator);
        formData.append('code_jobdesk', this.state.code_jobdesk);

        let url = '/accounting/create-indikator-budget-jobdesk';

        if (this.state.operation == 'edit') {
            url = '/accounting/update-indikator-budget-jobdesk/'+this.state.id_indikator_budget
        } else if (this.state.operation == 'delete') {
            url = '/accounting/delete-indikator-budget-jobdesk/'+this.state.id_indikator_budget
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeModal();
            this.loadIndikator();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderEditIndikator() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalIndikator} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Create Budget</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <form className="needs-validation tooltip-validation" noValidate="" >
                        <div className='form-group'>
                            <label className='control-label'>Indikator</label>
                            <input className='form-control' name='indikator' value={this.state.indikator} type='text' onChange={this.handleChange} />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Tipe Budget</label>
                            <select className='form-control' name='tipe_waktu' value={this.state.tipe_waktu} type='number' onChange={this.handleChange}>
                                <option value="">--Choose Tipe Budget</option>
                                <option value={1}>Bulanan</option>
                                <option value={2}>Tahunan</option>
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.saveRow()}>Save</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDialog() {
        return (
            <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete {this.state.indikator}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditDetailIndikator() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalDetailEdit} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>{this.state.operation} Detail Indikator</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <h4>{this.state.indikator}</h4>
                    <form className="needs-validation tooltip-validation" noValidate="" >
                        <div className='form-group'>
                            <label className='control-label'>Jobdesk</label>
                            <select className='form-control' name='code_jobdesk' value={this.state.code_jobdesk} type='number' onChange={this.handleChange}>
                                <option value="">--Choose Jobdesk</option>
                                {
                                    this.state.dataDivisi != "" ? 
                                    this.state.dataDivisi.map((jobdesk) => (
                                        <option value={jobdesk.code_jobdesk}>{jobdesk.jobdesk}</option>
                                    ))
                                    :
                                    ""
                                }
                            </select>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.saveRowDetail()}>Save</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDetailIndikator() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalDetailDelete} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Delete Detail Indikator</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <h4>{this.state.indikator}</h4>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.saveRowDetail()}>Save</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Indikator Name',
                accessor: 'name',
                filterable: false
            },
            {
                Header: 'Tipe Budget',
                accessor: 'tipe_budget',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.tipe_budget == 1 ?
                            'Bulanan'
                            :
                            row.original.tipe_budget == 2 ?
                            'Tahunan'
                            :
                            ''
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        const column_detail = [
            {
                Header: 'Jobdesk',
                accessor: 'jobdesk',
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editDetailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteDetailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row m-2'>
                                        <div className='col-sm-3'>
                                            <button className='btn btn-success' onClick={this.handleForm}>Tambah</button>
                                        </div>
                                    </div>
                                    <br/>
                                    {
                                        this.state.indiktorBudget.length > 0 ?
                                        <ReactTable
                                            data={this.state.indiktorBudget}
                                            columns={columns}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                            defaultPageSize={100}
                                            className="-striped -highlight"
                                            collapseOnDataChange={false}
                                            SubComponent={row =>{
                                                return (
                                                    <>
                                                        <div className='row mt-3 ml-3'>
                                                            <div className='col-sm-3'>
                                                                <button className='btn btn-success' onClick={() => this.handleFormDetail(row.original)}>Tambah</button>
                                                            </div>
                                                        </div>
                                                        <div className='row m-2'>
                                                            <div className='col-sm-12'>
                                                                <ReactTable
                                                                    data={row.original.jobdesk}
                                                                    columns={column_detail}
                                                                    defaultPageSize={10}
                                                                    className="-striped -highlight"
                                                                    showPaginationBottom={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        /> 
                                        : 
                                        <Loader showLoading={this.state.showLoading} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderEditIndikator()}
                {this.renderDeleteDialog()}
                {this.renderEditDetailIndikator()}
                {this.renderDeleteDetailIndikator()}
            </>
        );
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(DataIndikator);