import axios from 'axios'
import React, { Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import API_URL from '../../../ApiUrl'
import { UncontrolledTooltip } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DataTablesProgram extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataProgram: [],
        }
    }

    componentDidMount(){
        this.getDataProgram()
    }

    getDataProgram = async () => {
        axios.get(API_URL.superStudent+'/programs-by-university/'+this.props.userableData.userable.id,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataProgram: result })
        })
        .catch(error => {
        })
    }

    handleStatus(data){
        var formData = new FormData();
        formData.append('code_users',this.props.dataUsers.code_users);
        formData.append('id',data.id);
        formData.append('status',data.status);
        axios.post(API_URL.superStudent+'/university-programs-status', formData ,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.getDataProgram()
        })
        .catch(error => {
            toast.error("Opps,something error");
        })
    }

    render(){

        const columns = [
            {
                Header: 'Program name',
                accessor: 'program_name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Program Length',
                accessor: 'program_length',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    row.original.status === 'Active' ? 
                    <button style={{border:'none', fontSize: 18 }} onClick={() => this.handleStatus(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className="fa fa-check success-icon"></i><UncontrolledTooltip placement="top" target="TooltipTop">Click to deactivate
                    </UncontrolledTooltip> </button> 
                    :
                    <button onClick={() => this.handleStatus(row.original)} style={{border:'none',fontSize: 18 }} className="btn btn-outline-primary btn-xs" id="TooltipBottom" data-placement="bottom"><i className="fa fa-remove danger-icon"></i><UncontrolledTooltip placement="bottom" target="TooltipBottom">Click to activate
                    </UncontrolledTooltip> </button> 
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button 
                            onClick={() => this.props.history.push({
                                pathname: `${process.env.PUBLIC_URL}/pages/university/form-program`,
                                state: row.original,
                            })}
                            className="btn btn-info btn-pill btn-sm" type="button" style={{border:'none'}} >edit
                        </button>
                      </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]
        return (
            <Fragment>
                <div className='card'>
                    <div className="card-header">
                        <Link className="btn btn-success mb-3" style={{color:'white'}} to={'/pages/university/form-program'}> Add new Program</Link>
                    </div>
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.dataProgram}
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                        /> 
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null) (withRouter(DataTablesProgram))