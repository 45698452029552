import React, { Fragment } from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = ({progress_data}) => {

    return(
        <Fragment>
            <Progress className='mb-5' style={{ height: "30px"}} multi>
                <Progress bar  animated style={{backgroundColor:'#7c9ed9'}} value={progress_data.tercapai}>{progress_data.tercapai} %</Progress>
                <Progress bar style={{backgroundColor:'red'}} value={progress_data.target}/>
            </Progress>
        </Fragment>
    )
}

export default ProgressBar;