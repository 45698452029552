/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';

class VisitSchoolMeeting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            operation: '',
            modal: false,
            modalDelete: false,
            modalCheck: false,
            logName: '',
            notes: '',
            errors: '',
            detailNote: '',
            idNote: '',
            check: '',
            dataDetailActivities: [],
            listMeettingNote: [],
        }
    }

    componentDidMount() {
        this.getDetailSchoolVisit();
        this.getListMeetingNotes();
    }

    getDetailSchoolVisit() {
        var formData = new FormData();
        formData.append('code_activities', this.props.match.params.codeActivities);
        formData.append('code_consultant', this.props.match.params.codeConsultan);

        const url = API_URL.superStudent+'/school-activities/visit-by-detail';
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ dataDetailActivities:data.data });
        });
    }

    getListMeetingNotes() {
        var formData = new FormData();
        formData.append('code_activities', this.props.match.params.codeActivities);
        formData.append('code_consultant', this.props.match.params.codeConsultan);

        const url = API_URL.superStudent+'/school-activities/list-all-note';
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ listMeettingNote:data.data });
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    closeDialog() {
        this.setState({
            title: '',
            operation: '',
            modal: false,
            modalDelete: false,
            modalCheck: false,
            logName: ''
        });
    }

    addRow() {
        this.setState({
            title: 'Add Notes',
            operation: 'add',
            modal: true,
            logName: 'ADD-NOTES'
        });
    }

    saveRow() {
        var formData = new FormData();
        formData.append('code_activities', this.props.match.params.codeActivities);
        formData.append('code_consultant', this.props.match.params.codeConsultan);
        formData.append('notes', this.state.notes);
        formData.append('id_school_meeting_note', this.state.idNote);
        formData.append('status_check', this.state.check);

        let url = API_URL.superStudent+'/school-activities/list-notes';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = API_URL.superStudent+'/school-activities/list-notes/'+this.state.idNote
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = API_URL.superStudent+'/school-activities/list-notes/'+this.state.idNote
        } else if (this.state.operation == 'check') {
            url = API_URL.superStudent+'/school-activities/check-list-notes'
        }
        
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                modal: false 
            },() => {
                this.getListMeetingNotes()
                this.closeDialog()
            });
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data
                })
            }
        });
    }

    editRow(row) {
        this.setState({
            title: 'Edit Notes',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-NOTES',
            detailNote: row,
            idNote: row.id_school_meeting_note,
            notes: row.meeting_note
        });
    }

    deleteRow(row) {
        this.setState({
            title: 'Delete Notes',
            operation: 'delete',
            modalDelete: true,
            logName: 'DELETE-NOTES',
            detailNote: row,
            idNote: row.id_school_meeting_note,
            notes: row.meeting_note
        });
    }

    checkRow(row, status) {
        this.setState({
            title: 'Check Notes',
            operation: 'check',
            modalCheck: true,
            logName: 'CHECK-NOTES',
            detailNote: row,
            idNote: row.id_school_meeting_note,
            notes: row.meeting_note,
            check: status
        });
    }

    renderDialog() {
        const { title, modal, } = this.state;
        return (
            <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="notes">Notes</label>
                                <input className="form-control" name="notes" type="text" id="notes"  value={this.state.notes} onChange={(e) => this.handleChange(e)} />
                                <span>{this.state.errors.notes}</span>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderCheckDialog() {
        const { title, modalCheck } = this.state;

        return (
            <Modal isOpen={modalCheck} toggle={() => this.setState({ modalCheck: false })}>
                <ModalHeader toggle={() => this.setState({ modalCheck: false })}>{title}</ModalHeader>
                <ModalBody>
                    <h4>{this.state.detailNote.meeting_note}</h4>
                    <br/>
                    Are You Sure To Check This Note ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const columns = [
            {
                Header: 'Notes',
                accessor: 'meeting_note',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["meeting_note"] }),
                filterAll: true,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == 0 ?
                            <span>{row.original.meeting_note}</span>
                            :
                            <strike>{row.original.meeting_note}</strike>
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11 }}></i>{row.id}</button>
                        {
                            row.original.status == 0 ?
                            <button onClick={() => this.checkRow(row.original, 1)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-check" style={{ width: 35, fontSize: 16, padding: 11 }}></i>{row.id}</button>
                            :
                            <button onClick={() => this.checkRow(row.original, 0)} className="btn btn-outline-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-minus" style={{ width: 35, fontSize: 16, padding: 11 }}></i>{row.id}</button>
                        }
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11 }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]
        return(
            <Fragment>
                <Breadcrumb title="School Meeting Notes" parent="School Meeting Notes" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            {
                                                this.state.dataDetailActivities.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>Activities Name</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{this.state.dataDetailActivities[0].name_activities}</td>
                                                                <td>Consultant</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{this.state.dataDetailActivities[0].nama}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Judul</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{this.state.dataDetailActivities[0].feedback_meeting.judul}</td>
                                                                <td>Start Date</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{Moment(this.state.dataDetailActivities[0].feedback_meeting.start_date).format('DD MMMM YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sekolah</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{this.state.dataDetailActivities[0].sekolah.nama_sekolah}</td>
                                                                <td>End Date</td>
                                                                <td style={{ textAlign: 'center' }}>:</td>
                                                                <td>{Moment(this.state.dataDetailActivities[0].feedback_meeting.end_date).format('DD MMMM YYYY')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-sm-2">
                                            <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                                <div>
                                                    <span>
                                                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                        <ReactTable 
                                            data={this.state.listMeettingNote}
                                            columns={columns}
                                            pageSize={50}
                                            showPagination={true}
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                            className="-striped -highlight"
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
                {this.renderCheckDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(VisitSchoolMeeting);