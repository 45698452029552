/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import { Link } from 'react-router-dom'

class MasterEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            filtered:[],
            id:'',
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataListEvent: [],
            filedata:'',
        }
    }

    componentDidMount() {
        this.getDataMasterEvent();
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onChangeFileName = (e) => { 
        this.setState({
            filedata: e.target.files[0]
        })
    }

    deleteRow(row) {
        this.setState({
            id: row.id,
            modalDelete: true,
        })
    }

    updateStatusFull(row, status) {
        let formData = new FormData()
        formData.append('code_events', row.code_event)
        formData.append('status_full', status)

        axios.post(API_URL.superStudent+'/event/update-status-full', formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(() => {
            this.getDataMasterEvent()
            toast.success("Data Event Berhasil Di "+ status == 0 ? 'Open' : 'Close');
        })
        .catch((error) => {
            console.log(error)
        })
    }

    saveRow() {
        let formData = new FormData()
        formData.append('filedata', this.state.filedata)
        formData.append('id', this.state.id)
        axios.post(API_URL.superStudent+'/event/upload-slider', formData, {
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(() => {
            this.getDataMasterEvent()
            this.closeDialog()
            toast.success("Data slider berhasil ditambahkan");
        })
        .catch((error) => {
            console.log(error)
            toast.error(' Something wrong')
            this.closeDialog()
        })
    }

    deleteData(){
        axios.delete(API_URL.superStudent+'/master-event/'+this.state.id,{
            headers: {  'Authorization': `Bearer ${this.props.dataToken}`, }
        })
        .then(() => {
            this.closeDialog()
            this.getDataMasterEvent()
            toast.success('Delete Successfully')
        })
        .catch(() => {
            this.closeDialog()
            toast.error('something en error')
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            id:''
        });
    }

    getDataMasterEvent() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/master-event';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEvent:data.data
            });
        });
    }

    renderDialog() {
        const { modal } = this.state;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Tambah slider</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" onChange={this.onChangeFileName} type="file"/>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}> Delete data</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.deleteData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    SetFilterCategory = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterEvent = newData; 
        return { filterEvent };                   
        }, () => {
            this.onFilteredChangeCustom(newData,"jenis_event");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    handleModalImage(row){
        this.setState({ modal: true, id: row.id })
    }

    render() {

        const columns = [
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} alt='gambar-event' src={row.original.image}/>
                    </div>
                ),
                style: { textAlign: 'center' },
                sortable: true,
                width:110,
                filterable: false
            },
            {
                Header: 'Nama',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Category',
                accessor: 'category_event',
                filterable: false,
                style: { textAlign: 'center' },
                width:100,
            },
            {
                Header: 'Jenis Event',
                accessor: 'jenis_event',
                width:140,
                Filter: () => 
                <select value={this.state.filterEvent || ''} onChange={(e) => this.SetFilterCategory(e)}>
                    <option value=''>Show All</option>
                    <option value='1'>Global Youth</option>
                    <option value='2'>Event Season</option>
                    <option value='3'>Event Expo</option>
                </select>,
                Cell:(row) => (
                    <>
                        {row.original.jenis_event == '1' ? 'Global Youth' : row.original.jenis_event == '2' ? 'Event Season' : 'Event Expo'}
                    </>
                )
            },
            {
                Header: 'Periode',
                accessor: 'start_date',
                filterable: false,
                style: { textAlign: 'center' },
                width:110,
                Cell: (row) => (
                    <div className='row'>
                    <span className='col'>{Moment(row.original.start_date).format('DD MMM YYYY')}</span> <span className='col'><i className="fa fa-exchange"></i></span> <span className='col'>{Moment(row.original.end_date).format('DD MMM YYYY')}</span>
                    </div>
                  ),
            },
            {
                Header: 'Target Peserta',
                accessor: 'target_peserta',
                filterable: false,
                style: { textAlign: 'center' },
                width:120,
            },
            {
                Header: <b>Action</b>, 
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_full == 0 ?
                            <label className='badge bg-success' onClick={() => this.updateStatusFull(row.original, 1)}>Open</label>
                            :
                            <label className='badge bg-danger' onClick={() => this.updateStatusFull(row.original, 0)}>Close</label>
                        }
                        <br/>
                        <button style={{border:'none', fontSize: 16 }} onClick={() => this.handleModalImage(row.original)} className="btn btn-outline-primary btn-xs" type="button"><i className="fa fa-image primary-icon"></i>{row.id}</button>
                        <button style={{border:'none', fontSize: 16 }} onClick={() => this.props.history.push({
                             pathname: `${process.env.PUBLIC_URL}/pages/events/master-event/form-event/`+row.original.code_event,
                             state: row.original,
                        })} className="btn btn-outline-secondary btn-xs" type="button" ><i className="fa fa-pencil warning-icon" ></i></button>                   
                        <button style={{border:'none', fontSize: 16 }} onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" ><i className="fa fa-trash danger-icon" ></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]

        const columns_lokasi = [
            {
                Header: 'Kota',
                accessor: 'kabupaten.nama_kabkota',
                filterable: false,
                style: { textAlign: 'center' },
            },
            {
                Header: 'Alamat',
                accessor: 'alamat',
                filterable: false,
                style: { textAlign: 'center' },
            },
            {
                Header: 'Place',
                accessor: 'place',
                filterable: false,
                style: { textAlign: 'center' },
            },
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <Link className="btn btn-success mb-3" style={{color:'white'}} to={'/pages/events/master-event/form-event'}> Tambah Data</Link>
                                        </div>
                                    </div>

                                </div>
                                    <div className="card-body">
                                        {
                                            this.state.dataListEvent.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataListEvent}
                                                columns={columns}
                                                filterable
                                                filtered={this.state.filtered}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={15}
                                                showPaginationBottom={true}
                                                className="-striped -highlight"
                                                SubComponent={row =>{
                                                    const data = row.original
                                                    return(
                                                        <div style={{ padding: "20px" }}>
                                                            <div style={{paddingLeft:"10px"}}>
                                                                {
                                                                    row.original.category_event == 'Online' || row.original.category_event == 'Hybrid' ?  
                                                                    <div className="row">
                                                                        <div className="" align="left" style={{ color:"black" }}>Link Zoom/Meet</div>
                                                                        <div className="col-md-9" align="left">{data.zoom_link}</div>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                <br/>
                                                                {
                                                                    row.original.category_event == 'Offline' || row.original.category_event == 'Hybrid' ? 
                                                                      <>
                                                                      <div className='col-sm-12'><h5>Lokasi</h5></div>
                                                                          <ReactTable 
                                                                          data={row.original.event_lokasi}
                                                                          columns={columns_lokasi}
                                                                          className="-striped -highlight"
                                                                          defaultPageSize={5}
                                                                          />
                                                                      </> : ''
                                                                }
                                                                <div className="my-gallery row">
                                                                    <div className='col-sm-12 mt-2'><h5>Slider</h5></div>
                                                                    
                                                                {
                                                                    row.original.event_slider.length > 0 ?
                                                                    row.original.event_slider.map(item => (
                                                                        <>
                                                                             <figure className="col-xl-3 col-sm-6">
                                                                            <img
                                                                                src={item.url_image}
                                                                                alt="Gallery"
                                                                                className="img-thumbnail"
                                                                                onClick={() =>
                                                                                    this.setState({ photoIndex: 0, isOpen: true })
                                                                                }
                                                                            />
                                                                        </figure>
                                                                        </>
                                                                    ))
                                                                    : 'Slider Kosong'
                                                                }
                                                                 </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(MasterEvent));