export const dataScoreIELTS = (total_score) => {
    switch (parseInt(total_score)) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 2.5;
        case 4:
        case 5:
        case 6:
            return 3;  
        case 7:
        case 8:
        case 9:
            return 3.5;
        case 10:
        case 11:
        case 12:
            return 4; 
        case 13:
        case 14:
            return 4.5;  
        case 15:
        case 16:
        case 17:
        case 18:
            return 5; 
        case 19:
        case 20:
        case 21:
        case 22:
            return 5.5;
        case 23:
        case 24:
        case 25:
        case 26:
            return 6;
        case 27:
        case 28:
        case 29:
            return 6.5;
        case 30:
        case 31:
        case 32:
            return 7; 
        case 33:
        case 34:
            return 7.5;
        case 35:
        case 36:
            return 8;
        case 37:
        case 38:
            return 8.5;
        case 39:
        case 40:
            return 9;                            
        default:
            return 1;
    }
} 