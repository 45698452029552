/* eslint-disable eqeqeq, react/no-direct-mutation-state */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ScrollBar from "react-perfect-scrollbar";
import StudentProfileData from '../detailStudents/new-detail-student/StudentProfileData';
import DocumentProgress from '../detailStudents/new-detail-student/DocumentProgress';
import DataOrangTua from '../detailStudents/new-detail-student/DataOrangTua';
import VisaStudent from '../detailStudents/new-detail-student/VisaStudent';
import ProgramStudent from '../detailStudents/new-detail-student/ProgramStudent';
import SimulasiBiaya from '../detailStudents/new-detail-student/SimulasiBiaya';
import PaymentStudent from '../detailStudents/new-detail-student/PaymentStudent';
import ProgressLog from '../detailStudents/new-detail-student/ProgressLog';
import four from '../../../assets/images/avtar/3.jpg';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../detailStudents/style/style.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../detailStudents/style/style.css';
import DataLoa from './new-detail-student/DataLoa';
import StudentKategori from './new-detail-student/StudentKategori';
import HistoryKonsultan from './new-detail-student/HistoryKonsultan';

class StudentsDetail extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            code_students: '',
            code_progress: '',
            profile_pic: '',
            dataProfile: [],
            dataParent: [],
            dataVisa: [],
            dataPayment: [],
            dataProgram: [],
            listDocument: [],
            progressStep: [],
            statusStep: [],
            dataLoa: [],
            dataProgress: [],
            dataLogKonsultan: [],
            testing: "",
            display: this.props.dataUser.code_roles === 'RL006' || 'RL001' ? 'block' : 'none',
            displayInline: this.props.dataUser.code_roles === 'RL006' || 'RL001' ? 'inline' : 'none',
            displayProgress: 'none',
            showProfile: true,
            showProgram: false,
            showVisa: false,
            showSimulasiBiaya: false,
            showPayment: false,
            showLOA: false,
            modalProgress: false,
            modalKategori: false,
            msgAlertText: '',
            btnDisabled: false
        }
    }

    componentDidMount() {
        this.getCodeByToken();
    }

    // async componentWillMount() {
    //     this._isMounted = true;

    //     if (this._isMounted) {
    //         await this.getDataProfile();
    //         await this.getDataParent();
    //         await this.getDataVisa();
    //         await this.getDataPayment();
    //         await this.getDataProgram();
    //         await this.getListDocument();
    //         await this.getProgressStep();
    //         await this.getStatusStep();
    //         await this.getDataLoa();
    //     }
    // }

    getCodeByToken() {
        const url = API_URL.studentUrl + 'token-student/' + this.props.match.params.codeStudent;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({
                    code_students: data.code_students
                }, () => {
                    this.getDataProfile();
                    this.getProfilePic();
                    this.getDataParent();
                    this.getDataProgram();
                    this.getDataPayment();
                    this.getDataVisa();
                    this.getDataLoa();
                    this.getListDocument();
                    this.getProgressStep();
                    this.getStatusStep();
                    this.getDataProgress();
                    this.getDataLogKonsultan();
                })
            })
    }

    showProfile() {
        this.setState({
            showProfile: true,
            showProgram: false,
            showVisa: false,
            showSimulasiBiaya: false,
            showPayment: false,
            showLOA: false
        })
    }

    showProgram() {
        this.setState({
            showProfile: false,
            showProgram: true,
            showVisa: false,
            showSimulasiBiaya: false,
            showPayment: false,
            showLOA: false
        })
    }

    showVisa() {
        this.setState({
            showProfile: false,
            showProgram: false,
            showVisa: true,
            showSimulasiBiaya: false,
            showPayment: false,
            showLOA: false
        })
    }

    showSimulasiBiaya() {
        this.setState({
            showProfile: false,
            showProgram: false,
            showVisa: false,
            showSimulasiBiaya: true,
            showPayment: false,
            showLOA: false
        })
    }

    showPayment() {
        this.setState({
            showProfile: false,
            showProgram: false,
            showVisa: false,
            showSimulasiBiaya: false,
            showPayment: true,
            showLOA: false
        })
    }

    showLOA() {
        this.setState({
            showProfile: false,
            showProgram: false,
            showVisa: false,
            showSimulasiBiaya: false,
            showPayment: false,
            showLOA: true
        })
    }

    getProfilePic() {
        const url = API_URL.studentUrl + 'students-documents-code/' + this.state.code_students + '/DT001';
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                if (data.length > 0)
                    this.setState({ profile_pic: data[0].file_name })
                else
                    this.setState({ profile_pic: four })
            })
    }

    getDataProfile() {
        const url = API_URL.studentUrl + 'profile-student/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataProfile: data, selectedProgress: data.progress })
            })
    }

    getDataParent() {
        const url = API_URL.studentUrl + 'parents/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataParent: data })
            })
    }

    getDataProgram() {
        const url = API_URL.studentUrl + 'programs/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
            .then(response => response.data.data)
            .then((result) => {
                this.setState({ dataProgram: result })
            })
            .catch(error => console.log(error))
    }

    getDataPayment() {
        axios.get(API_URL.superStudentV2 + '/students-payment/' + this.state.code_students, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => response.data.data)
            .then((result) => {
                this.setState({ dataPayment: result })
            })
    }

    getDataVisa() {
        const url = API_URL.studentUrl + 'visa/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataVisa: data })
            })
    }

    getListDocument() {
        const url = API_URL.studentUrl + 'document-student/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ listDocument: data })
            })
    }

    getProgressStep() {
        const url = API_URL.superStudent + '/master/progress';
        axios.get(url).then(response => response.data.data)
            .then((data) => {
                this.setState({ progressStep: data })
            })
    }

    getStatusStep() {
        const url = API_URL.superStudent + '/master/status';
        axios.get(url).then(response => response.data.data)
            .then((data) => {
                this.setState({ statusStep: data })
            })
    }

    getDataLoa() {
        const url = API_URL.superStudent + '/student-loa/loa/' + this.state.code_students;
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data.data)
            .then((data) => {
                this.setState({ dataLoa: data })
            })
    }

    getDataProgress() {
        const url = API_URL.studentUrl + 'progress-log/' + this.state.code_students;
        axios.get(url, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
            .then((data) => {
                let dataShow = data
                if (this.props.dataUser.code_roles === "RL010") {
                    dataShow = data.filter(function (item) {
                        return item.code_lpt !== 'CMNT';
                    })
                }
                this.setState({ dataProgress: dataShow })
            })
    }

    getDataLogKonsultan() {
        axios.get(API_URL.superStudent + '/student/history-konsultan/' + this.state.code_students, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.setState({
                    dataLogKonsultan: response.data.data
                })
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleGetDocument = () => {
        this.getListDocument();
    }

    handleGetProfile = () => {
        this.getDataProfile();
        this.getProfilePic();
        this.getDataLogKonsultan()
    }

    handleGetParents = () => {
        this.getDataParent();
    }

    handleGetProgram = () => {
        this.getDataProgram();
    }

    handleGetPayment = () => {
        this.getDataPayment();
    }

    handleGetVisa = () => {
        this.getDataVisa();
    }

    handleGetLoa = () => {
        this.getDataLoa();
    }

    handleGetStatusStep = () => {
        this.getStatusStep();
    }

    handleGetProgressStep = () => {
        this.getProgressStep();
    }

    handleGetProgressData = () => {
        this.getDataProgress();
    }

    setProgress(progress) {
        if (this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL003' || this.props.dataUser.code_roles == 'RL012' || this.props.dataUser.code_roles == 'RL025') {
            this.setState({ modalProgress: true, code_progress: progress.code, msgAlertText: progress.name })
        }
    }

    setKategori() {
        this.setState({ modalKategori: true })
    }

    closeModalProgress() {
        this.setState({ modalProgress: false })
    }

    closeModalKategori() {
        this.setState({ modalKategori: false })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });
        var formData = new FormData();
        formData.append('code_students', this.state.code_students);
        formData.append('code_progress', this.state.code_progress);
        axios.post(API_URL.superStudent + '/student/update-progress', formData,
            {
                headers: { 'Authorization': `Bearer ${this.props.dataToken}` },

            }).then((reply) => {
                toast.success('Student Progress Updated')
                this.closeModalProgress();
                this.handleGetProfile();
                this.handleGetProgressData()
                this.setState({ btnDisabled: false })
            }).catch(function (reply) {
                console.log(reply)
            });
    }

    renderModalProgress() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalProgress} toggle={() => this.closeModalProgress()} size="md">
                <ModalHeader toggle={() => this.closeModalProgress()}>Edit Progress</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                    <h6>Are you sure you want to change progress to <b>{this.state.msgAlertText}</b></h6>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalProgress()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { dataProfile, showProfile, showProgram, showVisa, showSimulasiBiaya, showPayment, progressStep, showLOA, displayInline } = this.state;
        switch (this.props.dataUser.code_roles) {
            case 'RL001':
                this.state.displayProgress = 'block'
                break;
            case 'RL003':
                this.state.displayProgress = 'block'
                break;
            case 'RL005':
                this.state.displayProgress = 'block'
                break;
            case 'RL012':
                this.state.displayProgress = 'block'
                break;
            default:
                this.state.displayProgress = 'none'
                break;
        }
        return (
            <Fragment>
                <div className="header">
                    <div className="breadcumb">
                        <h4>STUDENT PROFILE</h4>
                    </div>
                    <div className="progress-student">
                        <ScrollBar className="scroll"
                            options={{ suppressScrollY: true }}
                        >
                            <div className='table-responsive scrool'>
                                <div className="stepper-wrapper">
                                    {
                                        progressStep.map((m, i) => {
                                            return (
                                                <div onClick={() => this.setProgress(m)} className={
                                                    (m.code === 'PG017' && dataProfile.code_progress === 'PG017') ||
                                                        (m.code === 'PG018' && dataProfile.code_progress === 'PG018') ||
                                                        (m.code === 'PG021' && dataProfile.code_progress === 'PG021')
                                                        ? 'stepper-item completed-only' :
                                                        (m.urutan <= dataProfile.progress?.urutan) && (dataProfile.code_progress !== 'PG017' && dataProfile.code_progress !== 'PG018' && dataProfile.code_progress !== 'PG021')
                                                            ? (m.code === 'PG017' || m.code === 'PG018') ? 'stepper-item' :
                                                                'stepper-item completed' :
                                                            'stepper-item'} key={i}>
                                                    <div className="step-counter"><i className='fa fa-circle'></i></div>
                                                    <div className="step-name">{m.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </ScrollBar>
                    </div>
                </div>
                <div className="profile">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 profile-1">
                            <div className="img-profile">
                                <img src={this.state.profile_pic} height={50} alt="" />
                            </div>
                            <div className="profile-name ml-3">
                                <h6><b>{dataProfile.nama}</b></h6>
                                <StudentKategori data={dataProfile} displayBtn={displayInline}
                                    status={this.state.statusStep}
                                    display={this.state.displayProgress}
                                    code_students={this.state.code_students}
                                    onReloadProfil={this.handleGetProfile}
                                    onReloadStatus={this.handleGetStatusStep}
                                    handleReloadProgressData={this.handleGetProgressData} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mx-auto col-sm-12 d-flex align-items-center justify-content-center">
                            <div className="btn-nav-menu">
                                <button onClick={() => this.showProfile()} className={`btn-menu ${this.state.showProfile && 'bg-red text-white'}`}><i className="fa fa-user"></i>Profile</button>
                                <button onClick={() => this.showProgram()} className={`btn-menu ${this.state.showProgram && 'bg-red text-white'}`}><i className="fa fa-graduation-cap"></i>Program</button>
                                <button onClick={() => this.showVisa()} className={`btn-menu ${this.state.showVisa && 'bg-red text-white'}`}><i className="fa fa-book"></i>VISA</button>
                                <button onClick={() => this.showSimulasiBiaya()} className={`btn-menu ${this.state.showSimulasiBiaya && 'bg-red text-white'}`}><i className="fa fa-money"></i>Simulasi Biaya</button>
                                <button onClick={() => this.showPayment()} className={`btn-menu ${this.state.showPayment && 'bg-red text-white'}`}><i className="fa fa-credit-card"></i>Payment</button>
                                <button onClick={() => this.showLOA()} className={`btn-menu ${this.state.showLOA && 'bg-red text-white'}`}><i className="fa fa-envelope"></i>Schedule Invoice </button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    showProfile && (
                        <section id='profile'>
                            <div className="profile-wrapper">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <StudentProfileData data={this.state.dataProfile} onReloadProfil={this.handleGetProfile} />
                                                <DataOrangTua data={this.state.dataParent} code_students={this.state.code_students} onReloadParents={this.handleGetParents} />
                                            </div>
                                            <div className="col-lg-6">
                                                <DocumentProgress datalist={this.state.listDocument} codeStudents={this.state.code_students} profile={this.state.dataProfile} onGetListDocument={this.handleGetDocument} onReloadProfil={this.handleGetProfile} programs={this.state.dataProgram} onReloadProgram={this.handleGetProgram} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="row">
                                            <div className="col-lg-12" style={{}}>
                                                <ProgressLog handleReloadProgressData={this.handleGetProgressData} data={this.state.dataProgress} code_students={this.state.code_students} profile={this.state.dataProfile} onReloadProfil={this.handleGetProfile} />
                                            </div>
                                            <div className="col-lg-12">
                                                <HistoryKonsultan log={this.state.dataLogKonsultan} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
                {
                    showProgram && (
                        <section id='program'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ProgramStudent display={this.state.display} codeStudents={this.state.code_students} data={this.state.dataProgram} onReloadProgram={this.handleGetProgram} />
                                </div>
                            </div>
                        </section>
                    )
                }
                {
                    showVisa && (
                        <section id='visa'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <VisaStudent codeStudents={this.state.code_students} display={this.state.display} data={this.state.dataVisa} onReloadVisa={this.handleGetVisa} />
                                </div>
                            </div>
                        </section>
                    )
                }
                {
                    showSimulasiBiaya && (
                        <section id='program'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <SimulasiBiaya display={this.state.display} data={this.state.dataProfile} />
                                </div>
                            </div>
                        </section>
                    )
                }
                {
                    showPayment && (
                        <section id='program'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <PaymentStudent codeStudents={this.state.code_students} display={this.state.display} profile={this.state.dataProfile} data={this.state.dataPayment} onReloadPayment={this.handleGetPayment} onReloadProfil={this.handleGetProfile} />
                                </div>
                            </div>
                        </section>
                    )
                }
                {
                    showLOA && (
                        <section id='program'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <DataLoa
                                        displayBtn={displayInline}
                                        data={this.state.dataLoa}
                                        profile={this.state.dataProfile}
                                        program={this.state.dataProgram}
                                        onReloadLoa={this.handleGetLoa}
                                    />
                                </div>
                            </div>
                        </section>

                    )
                }
                {this.renderModalProgress()}
            </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(withRouter(StudentsDetail));