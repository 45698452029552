import React, { useState } from 'react'
import { Card, CardHeader, CardBody, Button, Collapse } from 'reactstrap'
import { Accordion } from 'react-bootstrap';

const AccordionPlacementTest = ({title, component}) => {
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  return (
    <>
    <Accordion defaultActiveKey="0" className='mb-2'>
      <div className="default-according" id="accordion">
        <Card>
          <CardHeader onClick={() => toggle(1)} className='bg-primary'>
            <h5 className="mb-0">
              <Button as={Card.Header} className='btn btn-link txt-white' color='default'  >
                {title}
              </Button>
            </h5>
          </CardHeader>
          <Collapse isOpen={isOpen === 1}>
            <CardBody>
              {component}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </Accordion>
    </>
  )
}

export default AccordionPlacementTest