/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import { withRouter } from "react-router";
import { saveAs } from "file-saver";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import API_URL from '../../../ApiUrl'
import { toast } from 'react-toastify';

class DataTableKandidat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filtered:[],
      filterArea:'',
      reviewModal: false,
      previewModal: false,
      modalTitle: '',
      url_preview: '',
      rating_kandidat: '',
      review_kandidat: '',
      id_hire_employee: ''
    }
  }

  openReviewModal(dataKandidat){
    this.setState({
      reviewModal: true,
      rating_kandidat: dataKandidat.rating_kandidat, 
      review_kandidat: dataKandidat.review_kandidat,
      id_hire_employee: dataKandidat.id_hire_employee
    });
  }

  openPreviewModal(file, title){
    this.setState({previewModal: true, url_preview: file, modalTitle: title})
  }

  closeModalReview(){
    this.setState({reviewModal: false});
  }

  closeModalPreview(){
    this.setState({previewModal: false, url_preview: '', modalTitle: ''});
  }

  handleChange(e){
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  downloadFile = (e, url) => {
    saveAs(url,url.substring(url.lastIndexOf('/')));
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }
    this.setState({ filtered: filtered });
  }

  saveReview(){
    this.setState({btnLoading: true})
    let formData = new FormData();

    formData.append('rating_kandidat', this.state.rating_kandidat)
    formData.append('review_kandidat', this.state.review_kandidat)
    formData.append('id_hire_employee', this.state.id_hire_employee)
    axios.post(API_URL.superStudent + '/niec-career-candidate/add-review-candidate', formData, {
      headers: { 
        'Authorization': `Bearer ${this.props.dataToken}` 
      }
    })
    .then(res => {
      this.setState({btnLoading: false})
      toast.success("Candidate Review Saved")
      this.props.loadDataKandidat();
      this.closeModalReview();
    })
    .catch(error => console.log(error))
  }

  renderReviewModal(){
    return(
      <Modal isOpen={this.state.reviewModal} toggle={() => this.closeModalReview()} size='lg'>
        <ModalHeader toggle={() => this.closeModalReview()}>Review Kandidat</ModalHeader>
          <ModalBody>
            <form>  
              <div className="form-group">
                  <label htmlFor="rating_kandidat">Rate Kandidat</label>
                  <select name="rating_kandidat" className='form-control' defaultValue={this.state.rating_kandidat} id="rating_kandidat" onChange={(e) => this.handleChange(e)}>
                      <option value="">-- Pilih Rate --</option>
                      <option value="Bad">Bad</option>
                      <option value="Not Bad">Not that Bad</option>
                      <option value="Good">Good</option>
                      <option value="Good">Excellent</option>
                  </select>
              </div>
              <div className="form-group">
                  <label htmlFor="review_kandidat">Review</label>
                  <textarea name='review_kandidat' defaultValue={this.state.review_kandidat} className="form-control" id="review_kandidat" rows="7" onChange={(e) => this.handleChange(e)}></textarea>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveReview()}>Save Review</Button>
            <Button color="secondary" onClick={() => this.closeModalReview()}>Cancel</Button>
          </ModalFooter>
      </Modal>
    )
  }

  renderPreviewModal(){
    return(
      <Modal className="modal-dialog" isOpen={this.state.previewModal} toggle={() => this.closeModalPreview()} size="lg">
        <ModalHeader toggle={() => this.closeModalPreview()}>{this.state.modalTitle}</ModalHeader>
        <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
              <DocViewer 
                  pluginRenderers={DocViewerRenderers} 
                  documents={[{uri: this.state.url_preview}]} 
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                      retainURLParams: true
                    }
                  }}
              />
        </ModalBody>
        <ModalFooter>
              <button onClick={(e)=>this.downloadFile(e, this.state.url_preview)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
          </ModalFooter>
      </Modal>
    )
  }

  render() {
    const {myData } = this.props;
    const columns = [
      {
        Header: 'Nama',
        accessor: 'nama_lengkap',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
          filterAll: true
      },
      {
        Header: 'Email',
        accessor: 'email',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["email"] }),
        filterAll: true
      },
      {
        Header: 'Nomor HP',
        accessor: 'no_hp',
        style: {textAlign: 'center'},
        filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["no_hp"] }),
        filterAll: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {textAlign: 'center'},
        filterable: false,
        // Filter: () => 
        //         <select onChange={(e) => this.SetFilterStatusPembayaran(e)}>
        //             <option value='' >Show All</option>
        //             {
        //                 this.state.status_pembayaran?.map( item => {
        //                     return <option key={item.id} value={item.status}>{item.status}</option>
        //                 })
        //             } 
        //         </select>,
        Cell: (row) => (
            <span className={`badge badge-${
                row.original.status === 'Belum Direview' ? 'danger' : 'success'
            }`}>
                {row.original.status}</span>
        )
      },
      {
        Header: <b>#</b>,
        id: 'action',
        accessor: str => "action",
        width:90,
        filterable: false,
        Cell: (row) => (
            <>
                {
                    <>
                    {/* <Link className='mr-1' to={`${process.env.PUBLIC_URL}/pages/job-vacancy/detail-candidate/` + row.original.id_hire_employee}><i className="fa fa-eye success-icon"></i></Link> */}
                    <button  style={{border:'none', fontSize: 15 }} onClick={()=> this.openReviewModal(row.original)} className="btn btn-outline-primary btn-xs"><i className="fa fa-comment"></i></button>
                    </>
                }
            </>
        ),
        style: { textAlign: 'center' }
    }
    ]

    return (
      <Fragment>
        <ReactTable
          data={myData}
          filtered={this.state.filtered}
          columns={columns}
          pageSize={15}
          filterable
          showPagination={true}
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
          }}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          SubComponent={row => {
            const data = row.original
            return (
              <>
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Email</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.email}</div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Tempat Tanggal Lahir</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.tempat_tgl_lahir}</div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Status</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.status_perkawinan == 'M' ? 'Menikah' : 'Belum Menikah'}</div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Jenis Kelamin</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.jenis_kelamin == 'L' ? 'Laki-laki' : 'Wanita'}</div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Social Media</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.sosial_media}</div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className='row'>
                        <div className='col-lg-5 d-flex justify-content-start'>Kontak Darurat</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.kontak_darurat}</div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-lg-5 d-flex justify-content-start'>Ijazah</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'><button className='btn-xs btn-primary' onClick={()=>this.openPreviewModal(data.file_ijazah, 'Ijazah Kandidat')}>Lihat Ijazah Kandidat <i className='fa fa-eye'></i></button></div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-lg-5 d-flex justify-content-start'>CV</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'><button className='btn-xs btn-primary' onClick={()=>this.openPreviewModal(data.file_cv, 'CV Kandidat')}>Lihat CV Kandidat <i className='fa fa-eye'></i></button></div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col-lg-5 d-flex justify-content-start'>English Certivicate</div>
                        <div className='col-lg-1 d-flex justify-content-center'>:</div>
                        <div className='col-lg-6 d-flex justify-content-start'>{data.file_english === null ? ('Dokumen tidak disertakan') : (<button className='btn-xs btn-primary' onClick={()=>this.openPreviewModal(data.file_english, 'English Test Kandidat')}>Lihat English Test <i className='fa fa-eye'></i></button>)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>Domisili :</label>
                        <br/>
                        {data.alamat_sekarang}
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>Asal :</label>
                        <br/>
                        {data.alamat_ktp}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className='col-lg-6'>
                      <label>About Kandidat :</label>
                      <br/>
                      <div dangerouslySetInnerHTML={{__html: data.text_promosi_diri}} />
                    </div>
                    <div className='col-lg-6'>
                      <label>Review Kandidat :</label>
                      <br/>
                      <div className="card-body scroll recent-notification">
                        <div className="media">
                          {
                            data.review_kandidat ? (
                              <>
                                <div className="">
                                  <h6>{data.rating_kandidat}</h6>
                                </div>
                                <div className="media-body">
                                  <div>
                                    <span>Hasil review kandidat <h6 className='text-justify'>{data.review_kandidat}</h6></span>
                                  </div>
                                </div>
                              </>
                            ) 
                            :
                            (
                              <div className="text-center">
                                <h5>Kandidat Belum Direview</h5>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }} 
        />
        {this.renderReviewModal()}
        {this.renderPreviewModal()}
      </Fragment>
    );
  }
};

const reduxState = (state) => ({
  dataUser: state.Login.detailUser,
  dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null) (withRouter(DataTableKandidat));