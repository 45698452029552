import React, { Fragment } from 'react';
import DataTablesMemberData from '../../components/tables/dataTablesMemberData';


const MembershipDataComponent = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- Page Body Start--> */}
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <DataTablesMemberData />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>


    );
};

export default MembershipDataComponent;