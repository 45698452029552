import React from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { NavTab, RoutedTabs } from 'react-router-tabs'
import { Switch, Route, Redirect} from 'react-router-dom'
import { Home, FileText } from 'react-feather'

const VisaAdmission = () => {

    return(
        <>
        <Breadcrumb parent="Visa Admission" title="Visa Admission" />
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                <div className="row theme-tab">
                    <div className="col-md-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/visa-admission/home`}>
                                    <Home/> Home
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/visa-admission/data`} >
                                    <FileText/> Data Admission
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/visa-admission`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/visa-admission/home`} />}
                           />
                            <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/home`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/visa-admission/data`}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default VisaAdmission;

