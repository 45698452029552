
export const color1 = '#514f52';
export const color2 = '#9b7f7f';
export const color3 = '#9fb1bd';
export const color4 = '#b65f56';
export const color5 = '#e0b068';

export const barChartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    plugins: {
        datalabels: {
            display: false,
        }
    },
    scales:{
        yAxes:[
            {
                ticks:{
                    callback:function(label, index, labels){
                        return new Intl.NumberFormat('id-ID', {}).format(label);
                    }
                },
            }
        ]
    },
    tooltips:{
        enabled:true,
        mode:'single',
        callbacks:{
            label:function(tooltipItems,data){
                return new Intl.NumberFormat('id-ID', {}).format(tooltipItems.yLabel);
            }
        }
    }
}
