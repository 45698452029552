/* eslint-disable no-unused-vars */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import OfficechoolVisit from '../../../src/components/dashboard/partition/progress-school-visit';
import ConsultanSchoolVisit from '../../../src/components/dashboard/partition/progress-school-visit-consultant';
import Moment from 'moment';
import moment from 'moment';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import setRangeDate from '../../helper/general-function';

class SchoolVisitHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            date:'',
            formData:{start_date:Moment().startOf('isoWeek'), end_date: Moment().endOf('isoWeek')},
            start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
            code_category: 'office',
            totalOffice: 0,
            ofc_schoolVisit: [],
            const_schoolVisit: [],
            schedulle_all: [],
            office_all: [],
            consultant: [],
            id_kantor: '',
            code_consultant: ''
        }
    }

    componentDidMount() {
        this.getOfficeAll();
        this.getActivities();
        this.getOfficeSchoolVisit();
        this.getTotalStudentRegister();
        this.getTotalStudentRegisterByConsultan();
    }

    getOfficeAll() {
        axios.get(API_URL.superStudent+'/master/office', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then((data) => {
            this.setState({office_all:data.data.data});
        })
        .catch(error => {
            console.log(error)
        })
    }

    getActivities(){
        this.setState({schedulle_all: [] });

        var formData = new FormData();
        formData.append('id_kantor',this.state.id_kantor);
        formData.append('code_consultant',this.state.code_consultant);
        formData.append('start_date',this.state.start_date);
        formData.append('end_date',this.state.end_date);

        const url = API_URL.superStudent+'/school-activities/visit-all';
        axios.post(url, formData, 
            { 
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}`, 
                    'Content-Type': 'multipart/form-data' 
                }
            })
        .then(response => response.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
                var formData = new FormData();
                formData.append('id_users',this.props.dataId);
                formData.append('api_token',this.props.dataToken);
                this.props.resettoken(formData, config);
            }else{
                data.data.map(async (jadwal, i) => {
                    this.state.schedulle_all.push({
                        id: i+1, 
                        title: jadwal.title,
                        detail: jadwal.detail,
                        allDay: true,
                        start: new Date(jadwal.start_date),
                        end: new Date(jadwal.end_date),
                        borderColor: jadwal.border
                    })
                });
            }
        });
    }
    
    getOfficeSchoolVisit() {
        let formData = new FormData();
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        axios.post(API_URL.superStudent+'/activities/total-all', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then((data) => {
            this.setState({totalOffice:data.data.data});
        })
        .catch(error => {
            console.log(error)
        })
    }

    getTotalStudentRegister() {
        let formData = new FormData();
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        axios.post(API_URL.superStudent+'/activities/total-register-office', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then((data) => {
            this.setState({ofc_schoolVisit:data.data.data});
            this.getOfficeSchoolVisit();
        })
        .catch(error => {
            console.log(error)
        })
    }

    getTotalStudentRegisterByConsultan() {
        let formData = new FormData();
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        axios.post(API_URL.superStudent+'/activities/total-register-consultant', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then((data) => {
            this.setState({const_schoolVisit:data.data.data});
        })
        .catch(error => {
            console.log(error)
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeDates()
        })
    }

    handleChangeOffice = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setConsultantByOffice();
            this.getActivities();
        })
    }

    handleChangeConsultant = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.getActivities();
        })
    }

    setRangeDates() {   
        let data = setRangeDate(this.state.date);
        this.setState({
            start_date: data.start,
            end_date: data.end,
        }, () => {
            this.getTotalStudentRegister();
            this.getTotalStudentRegisterByConsultan();
            this.getOfficeSchoolVisit();
            this.getActivities();
        })
    }

    setConsultantByOffice() {
        axios.get(API_URL.superStudent+'/consultant/office/'+this.state.id_kantor, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then((data) => {
            this.setState({consultant:data.data.data});
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    render() {
        const columns = [
            {
                Header: 'Office',
                accessor: 'nama_kantor',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Total Register',
                accessor: 'total_register',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Total Member',
                accessor: 'total_member',
                style: {textAlign: 'center'},
            }
        ];

        const columns_consultant = [
            {
                Header: 'Nama Consultant',
                accessor: 'nama',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Total Register',
                accessor: 'total_register',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Total Member',
                accessor: 'sekolah_peserta_count',
                style: {textAlign: 'center'},
            }
        ];

        const event = [
            {
                id: 0,
                title: 'All Day Event very long title',
                allDay: true,
                start: new Date(2022, 6, 12, 12, 0, 0, 0),
                end: new Date(2022, 6, 12, 13, 0, 0, 0),
            },
        ]

        const localizer = momentLocalizer(moment);
        let allViews = Object.keys(Views).map(k => Views[k])

        return (
            <Fragment>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1 mb-5">
                        <div className='col-sm-12'>
                            {/* <span style={{fontSize:'1.5em'}} className='f-w-600'>{this.state.start_date != '' ? this.state.start_date.format('DD MMMM yyyy') : ''} - {this.state.end_date != '' ? this.state.end_date.format('DD MMMM yyyy') : ''}</span> */}
                        </div>
                    </div>
                    <div className="row justify-content-center m-1 mb-5">
                        <div className="col-xl-4 col-sm-4">
                            <select name='id_kantor' value={this.state.id_kantor} onChange={this.handleChangeOffice} className='form-control'>
                                <option value=''>--Choose Office--</option>
                                {
                                    this.state.office_all.map((office, i) => (
                                        <option value={office.id_kantor}>{office.nama_kantor}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-xl-4 col-sm-4">
                            <select name='code_consultant' value={this.state.code_consultant} onChange={this.handleChangeConsultant} className='form-control'>
                                <option value=''>--Choose Consultant--</option>
                                {
                                    this.state.consultant.map((consultant, i) => (
                                        <option value={consultant.code}>{consultant.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-xl-4 col-sm-4">
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value=''>--Choose Time--</option>
                                <option value='all_time'>All Time</option>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                <optgroup label='Quartal'>
                                    <option value='1'>Quartal I</option>
                                    <option value='2'>Quartal II</option>
                                    <option value='3'>Quartal III</option>
                                    <option value='4'>Quartal IV</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xs-6 col-sm-6 col-lg-6 text-center">
                            <div className="card" style={{backgroundColor:'blue',color:'white'}}>
                                <div className="card-body">
                                    <div className="media static-top-widget">
                                        <div className="media-body">
                                            <span>Total School Visit</span>
                                            <h4> <Countup className="counter" end={this.state.totalOffice} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-xs-6 col-sm-6 col-lg-6 text-center'>
                            <OfficechoolVisit start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                        <div className='col-xs-6 col-sm-6 col-lg-6 text-center'>
                            <ConsultanSchoolVisit start_date={this.state.start_date} end_date={this.state.end_date} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xs-12 col-sm-12 col-lg-12 text-center">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media static-top-widget">
                                        <div className="media-body">
                                            <Calendar
                                                localizer={localizer}
                                                scrollToTime={new Date("2019-01-01")}
                                                defaultDate={new Date()}
                                                onSelectEvent={event => alert(event.detail)}
                                                views={allViews}
                                                events={this.state.schedulle_all}
                                                eventOverlap
                                                dragRevertDuration={500}
                                                dragScroll
                                                showMultiDayTimes
                                                step={30}
                                                startAccessor="start"
                                                endAccessor="end"
                                                eventPropGetter={myList =>({
                                                    style:{
                                                        color: '#fff',
                                                        backgroundColor: myList.borderColor
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className='col-sm'>
                                    <div className='form-group'>
                                        <label className='f-w-600'>Schedulled</label>
                                        <input disabled type='color' className='form-control' value='#4466f2'/>
                                    </div>
                                </div>
                                <div className='col-sm'>
                                    <div className='form-group'>
                                        <label className='f-w-600'>Visited</label>
                                        <input disabled type='color' className='form-control' value='#0BA22B'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className='card-header'>
                                            <div className='row'>
                                                <div className='col-md-8'></div>
                                                <div className='col-md-4'>
                                                    <select name='code_category' onChange={this.handleInput} className='form-control'>
                                                        <option value="office">Office</option>
                                                        <option value="consultant">Consultant</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body datatable-react">
                                            { this.state.code_category === 'office' ? 
                                                <ReactTable
                                                    data={this.state.ofc_schoolVisit}
                                                    columns={columns}
                                                    defaultPageSize={10}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                    SubComponent={row => {
                                                        const data = row.original.consultant;
                                                        return (
                                                            <div style={{ padding: "25px" }}>
                                                                <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <table className='table'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Nama</th>
                                                                                        <th>Email</th>
                                                                                        <th>No. WA</th>
                                                                                        <th>Nama Sekolah</th>
                                                                                        <th>Member Date</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        data.map((detail) => (
                                                                                            detail.sekolah_peserta.map((peserta) => (
                                                                                                <tr>
                                                                                                    <td>{peserta.nama_siswa}</td>
                                                                                                    <td>{peserta.email_siswa}</td>
                                                                                                    <td>{peserta.no_wa_siswa}</td>
                                                                                                    <td>{peserta.sekolah !== null ? peserta.sekolah.nama_sekolah : ''}</td>
                                                                                                    <td>{peserta.member !== null ? peserta.member.date_approval : ''}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ))
                                                                                    }
                                                                                </tbody>            
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                : 
                                                <ReactTable
                                                    data={this.state.const_schoolVisit}
                                                    columns={columns_consultant}
                                                    defaultPageSize={10}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                    SubComponent={row => {
                                                        const data = row.original.sekolah_peserta;
                                                        return (
                                                            <div style={{ padding: "25px" }}>
                                                                <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <table className='table'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Nama</th>
                                                                                        <th>Email</th>
                                                                                        <th>No. WA</th>
                                                                                        <th>Nama Sekolah</th>
                                                                                        <th>Member Date</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        data.map((detail) => (
                                                                                            <tr>
                                                                                                <td>{detail.nama_siswa}</td>
                                                                                                <td>{detail.email_siswa}</td>
                                                                                                <td>{detail.no_wa_siswa}</td>
                                                                                                <td>{detail.sekolah !== null ? detail.sekolah.nama_sekolah : ''}</td>
                                                                                                <td>{detail.member !== null ? detail.member.date_approval : ''}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>            
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataId: state.Login.detailUser.id_users,
    codeUsers: state.Login.detailUser.code_users,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(SchoolVisitHome);