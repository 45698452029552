import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Moment from 'moment';

class SchoolMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSchoolMember: []
        }
    }

    componentDidMount() {
        this.getSchoolMember()
    }

    getSchoolMember() {
        const url = API_URL.superStudent+'/school-activities/member/'+this.props.match.params.codeSchool;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({dataSchoolMember:data[0].students});
        });
    }

    render() {
        const columns = [
            {
                Header: 'Code',
                accessor: 'code_students',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) =>(
                    <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => 
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)    
                    }>{row.original.code_students}</button>
                )
            },
            {
                Header: 'Nama',
                accessor: 'nama',
                filterable: false,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Progress',
                accessor: 'progress.name_progress',
                filterable: false,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Konsultan',
                accessor: 'consultant.nama',
                filterable: false,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Tgl Input',
                accessor: 'date_created',
                filterable: false,
                style: {textAlign: 'center'},
                Cell:(row) => (
                    <>{Moment(row.original.date_created).format('D MMM Y')}</>
                )
            }
        ]

        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <ReactTable 
                                        data={this.state.dataSchoolMember}
                                        columns={columns}
                                        pageSize={25}
                                        showPagination={true}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}


const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(SchoolMember);