import React from 'react'
import DataTablesOrder from '../../components/tables/dataTablesOrder'

const DataOrder = () => {
  return (
    <>
    <div className="page-wrapper">
        <div className="container-fluid p-0">
            <div className="row"> 
                <div className="col-md-12">
                    <DataTablesOrder />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default DataOrder