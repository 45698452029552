/* eslint-disable eqeqeq,no-useless-concat */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';

class BisdevPerform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: {},
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            searchInput: '',
            id_propinsi: 7,
            filteredData:[],
            dataPerform: [],
            listSchool: [],
            list_propinsi: [],
            list_bisdev: [],
            list_tipe: [],
            list_produk: [],
            id_perform: "",
            kode_bisdev_perform: "",
            kode_sekolah: "",
            kode_bisdev: "",
            kode_tipe: "",
            kode_produk: "",
            tanggal: "",
            date_payment:'',
            jumlah: "",
            harga: "",
            notes: "",
            status: "",
            judul: "",
            image: "",
            filename: ""
        }
    }

    componentDidMount() {
        this.getDataBisdevPerform();
        this.getDataPropinsi();
        this.getDataSchool();
        this.loadUserList();
        this.getDataBisdevTipe();
        this.getDataBisdevProduk();
    }

    getDataBisdevPerform(){
        const url = API_URL.superStudent+'/bisdev-perform/bisdev-perform-list';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataPerform: data })
        })
    }

    getDataPropinsi() {
        const url = API_URL.superStudent+'/master/provinces';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({list_propinsi:data.data});
        })
        .catch(error => {
            console.log(error)
        }); 
    } 

    getDataSchool() {
        const url = API_URL.superStudent+'/sekolah-indonesia/'+this.state.id_propinsi;
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
                var formData = new FormData();
                formData.append('id_users',this.props.dataId);
                formData.append('api_token',this.props.dataToken);
                this.props.resettoken(formData, config);
            }else{
                this.setState({listSchool:data.data});
            }
        });
    }

    loadUserList = () => {
        let formData = new FormData()
        formData.append('jobdesk',JSON.stringify(['JD007','JD016']))
        axios.post(API_URL.superStudent+'/consultant/jobdesk', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({list_bisdev: result}))
        .catch(error => console.log(error))
    }

    getDataBisdevTipe(){
        const url = API_URL.superStudent+'/bisdev-perform/bisdev-tipe';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ list_tipe: data })
        })
    }

    getDataBisdevProduk(){
        const url = API_URL.superStudent+'/bisdev-perform/bisdev-produk';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ list_produk: data })
        })
    }

    handleChangePropinsi = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setState({
                searchInput: '',
                filteredData:[]
            }, () => {
                this.getDataSchool()
            })
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Name Perform Data',
            operation: 'add',
            logName: 'ADD-Name-Perform',
            name: ''
        });
    }

    editRow(row) {
        axios.get(API_URL.superStudent+'/bisdev-perform/bisdev-perform-list/'+row.id_bisdev_perform, {
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            console.log(result)
            this.setState({
                modal: true,
                title: 'Edit Bisdev Tipe Data',
                operation: 'edit',
                logName: 'EDIT-BISDEV_TIPE',
                kode_bisdev_perform: result.kode_bisdev_perform,
                id_perform: result.id_bisdev_perform,
                kode_sekolah: result.kode_sekolah,
                kode_bisdev: result.kode_bisdev,
                kode_tipe: result.kode_bisdev_tipe_produk,
                kode_produk: result.kode_bisdev_produk,
                jumlah: result.jumlah,
                harga: result.harga,
                notes: result.notes,
                tanggal:result.tanggal,
                date_payment:result.date_payment,
                status: result.status,
                judul: result.judul,
            })
        })
    }

    deleteRow(row) {
        this.setState({
            id_perform: row.id_bisdev_perform,
            modalDelete: true,
            title: 'Delete Bisdev Perform Data',
            operation: 'delete',
            logName: 'DELETE-Perform-DATA'
        })
    }

    setSekolah = (e) => {
        this.setState({
            kode_sekolah:e[0].kode_sekolah
        });
    }

    setBisdev = (e) => {
        this.setState({
            kode_bisdev:e[0].code_konsultan
        })
    }

    setTipe = (e) => {
        this.setState({
            kode_tipe:e[0].kode_bisdev_tipe_produk
        })
    }

    setProduk = (e) => {
        this.setState({
            kode_produk:e[0].kode_bisdev_produk
        })
    }

    onChangeFileName = (e) => { 
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let datetime = Moment(Date()).format('DDMMYYYY');

        let fileExtension = '';
        let newFileName = '';
        let nama = '';

        if (this.state.image.name != undefined) {
            fileExtension = this.state.image.name.split('.').pop();
            newFileName = "studytour"+"_"+datetime+"."+fileExtension;
            nama = "studytour"+"_"+datetime;
        }

        var formData = new FormData();
        formData.append('kode_bisdev_perform',this.state.kode_bisdev_perform);
        formData.append('kode_sekolah',this.state.kode_sekolah);
        formData.append('kode_bisdev',this.state.kode_bisdev);
        formData.append('kode_tipe',this.state.kode_tipe);
        formData.append('kode_produk',this.state.kode_produk);
        formData.append('tanggal',this.state.tanggal);
        formData.append('date_payment',this.state.date_payment);
        formData.append('jumlah',this.state.jumlah);
        formData.append('harga',this.state.harga);
        formData.append('notes',this.state.notes);
        formData.append('status',this.state.status);
        formData.append('judul',this.state.judul);
        formData.append('image', nama);

        if(this.state.image.name != undefined) {
            formData.append('file_image', this.state.image, newFileName)
        }

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/bisdev-perform/bisdev-perform-list';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/bisdev-perform/bisdev-perform-list/'+this.state.id_perform
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/bisdev-perform/bisdev-perform-list/'+this.state.id_perform
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.status == 'Success') {
                this.closeDialog();
                this.getDataBisdevPerform();
                this.setState({ btnDisabled: false });
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }   
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Propinsi</label>
                                    <select className="form-control" onChange={this.handleChangePropinsi} name="id_propinsi" value={this.state.id_propinsi}>
                                        <option>Filter Provinsi</option>
                                        {
                                            this.state.list_propinsi.map(propinsi => {
                                                return <option value={propinsi.id} key={propinsi.id}>{propinsi.province_name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Tanggal</label>
                                    <input className="form-control" name="tanggal" type="date" id="tanggal" onChange={(e) => this.handleInput(e)} value={this.state.tanggal} />
                                    <span>{this.state.errors.tanggal}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Sekolah</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama_sekolah"
                                        name="kode_sekolah"  
                                        options={this.state.listSchool}
                                        onChange={this.setSekolah}
                                        placeholder="Pilih Sekolah"
                                    />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Nama Penanggung Jawab</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama"
                                        name="kode_bisdev"  
                                        options={this.state.list_bisdev}
                                        onChange={this.setBisdev}
                                        placeholder="Pilih Bisdev"
                                    />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Tipe</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama_bisdev_tipe_produk"
                                        name="kode_tipe"  
                                        options={this.state.list_tipe}
                                        onChange={this.setTipe}
                                        placeholder="Pilih Tipe"
                                    />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Produk</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama_bisdev_produk"
                                        name="kode_produk"  
                                        options={this.state.list_produk}
                                        onChange={this.setProduk}
                                        placeholder="Pilih Produk"
                                    />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Jumlah</label>
                                    <input className="form-control" name="jumlah" type="text" id="jumlah" onChange={(e) => this.handleInput(e)} value={this.state.jumlah} />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Tanggal Income</label>
                                    <input className="form-control" name="date_payment" type="date" id="date_payment" onChange={(e) => this.handleInput(e)} value={this.state.date_payment} />
                                    <span>{this.state.errors.date_payment}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Harga</label>
                                    <input className="form-control" name="harga" type="text" id="harga" onChange={(e) => this.handleInput(e)} value={this.state.harga} />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Notes</label>
                                    <textarea className="form-control" name="notes" type="text" id="notes" onChange={(e) => this.handleInput(e)} value={this.state.notes} />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Judul</label>
                                    <input className="form-control" name="judul" type="text" id="judul" onChange={(e) => this.handleInput(e)} value={this.state.judul} />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                        ref={e => this.inputFileName = e}
                                    />
                                    <span>{this.state.errors.image}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let idr = Intl.NumberFormat('id-ID');

        const columns = [
            {
                Header: 'Tanggal ',
                accessor: 'tanggal',
                filterable: false,
                Cell: (row) => (
                    Moment(row.original.tanggal).format('DD/MM/YYYY')
                )
            },
            {
                Header: 'Penanggung Jawab',
                accessor: 'bisdev.nama',
                filterable: false
            },
            {
                Header: 'Nama Sekolah',
                accessor: 'sekolah.nama_sekolah',
                filterable: false
            },
            {
                Header: 'Tipe',
                accessor: 'tipe.nama_bisdev_tipe_produk',
                filterable: false
            },
            {
                Header: 'Produk',
                accessor: 'produk.nama_bisdev_produk',
                filterable: false
            },
            {
                Header: 'Jumlah',
                accessor: 'jumlah',
                filterable: false
            },
            {
                Header: 'Tanggal Payment',
                accessor: 'date_payment',
                filterable: false,
                Cell: (row) => (
                    Moment(row.original.date_payment).format('DD/MM/YYYY')
                )
            },
            {
                Header: 'Harga',
                accessor: 'harga',
                filterable: false,
                Cell: (row) => (
                    <h6>{'Rp '+idr.format(row.original.harga)}</h6>
                )
            },
            {
                Header: 'Notes',
                accessor: 'notes',
                filterable: false
            },
            {
                Header: 'Judul',
                accessor: 'judul',
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img alt='imagess' style={{width: '100px'}} src={row.original.image}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                            <span>
                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                            </span>
                                            </div>
                                        </button>
                                        <br/>
                                        {
                                            this.state.dataPerform.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataPerform}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={50}
                                                className="-striped -highlight"
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(BisdevPerform));