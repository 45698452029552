import React, { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { List } from 'react-feather';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const RegisterEvent = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Register Event" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                {
                                    roles !== 'RL007' ?
                                    <>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`}>
                                         <List /> Event Edufest
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-season`}>
                                            <List /> Event Season
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-youth`}>
                                            <List /> Event Global Youth
                                        </NavTab>
                                    </>
                                    :
                                    ''
                                }
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`}>
                                    <List /> Event Partner
                                </NavTab>
                            </div>
                        </RoutedTabs>
                        {
                            roles === 'RL007' ? 
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/events/register-event`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/events/register-event/list-partner`} />
                            </Switch>
                            :
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/events/register-event`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/events/register-event/list-expo-new`} />}
                                />
                            </Switch>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const reduxState = (state) => ({
     dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(RegisterEvent);