/* eslint-disable eqeqeq, no-useless-concat, react/no-direct-mutation-state  */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Moment from 'moment';

class StudyTourRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            dataStudyTour: [],
            id_web_study_tour: "",
            code_study_tour: "",
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
            image:'',
            btnDisabled: false
        }
    }

    componentDidMount() {
        this.getDataStudyTourList();
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Study Tour Data',
            operation: 'add',
            logName: 'ADD-STUDYTOUR',
            code_study_tour: "",
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
            image:'',
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    validateCity = () => {
        const city = this.state.city;
        if(city === "" || city === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.city = 'City Harus Diisi'; 
                errors.cityStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.city = '';    
                errors.cityStatus = true;                             
                return { errors };                   
            })
        }
    }

    validateCountry = () => {
        const country = this.state.country;
        if(country === "" || country === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.country = 'country Harus Diisi'; 
                errors.countryStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.country = '';    
                errors.countryStatus = true;                             
                return { errors };                   
            })
        }
    }

    validateHarga = () => {
        const harga = this.state.harga;
        if(harga === "" || harga === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.harga = 'harga Harus Diisi'; 
                errors.hargaStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.harga = '';    
                errors.hargaStatus = true;                             
                return { errors };                   
            })
        }
    }

    validateItenary = () => {
        const itenary = this.state.itenary;
        if(itenary === "" || itenary === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.itenary = 'itenary Harus Diisi'; 
                errors.itenaryStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.itenary = '';    
                errors.itenaryStatus = true;                             
                return { errors };                   
            })
        }
    }

    validateMinPax = () => {
        const min_pax = this.state.min_pax;
        if(min_pax === "" || min_pax === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.min_pax = 'min_pax Harus Diisi'; 
                errors.min_paxStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.min_pax = '';    
                errors.min_paxStatus = true;                             
                return { errors };                   
            })
        }
    }

    validateFileName = () => {
        const image = this.state.image;
        if(image === "" || image === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.image = 'image Harus Diisi'; 
                errors.imageStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.image = '';    
                errors.imageStatus = true;                             
                return { errors };                   
            })
        }
    }

    onChangeFileName = (e) => { 
        this.state.image = e.target.files[0];
        this.setState({
            filename: e.target.value
        })
    }

    handleValidation() {
        let formIsValid = true;
        if(!this.state.errors.cityStatus){
            formIsValid = false;
            alert('Kolom City Masih Kosong');
        }
        if(!this.state.errors.countryStatus){
            formIsValid = false;
            alert('Kolom Country Masih Kosong');
        }
        if(!this.state.errors.hargaStatus){
            formIsValid = false;
            alert('Kolom Harga Masih Kosong');
        }
        if(!this.state.errors.itenaryStatus){
            formIsValid = false;
            alert('Kolom Itenary Masih Kosong');
        }
        if(!this.state.errors.min_paxStatus){
            formIsValid = false;
            alert('Kolom Min Pax Masih Kosong');
        }
        if(!this.state.errors.imageStatus){
            formIsValid = false;
            alert('Kolom Gambar Masih Kosong');
        }
        return formIsValid;
    }

    editRow(row) {
        axios.get(API_URL.superStudent+'/website/study-tour/'+row.id_study_tour,{
            headers: {
              'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                modal: true,
                title: 'Edit Study Tour Data',
                operation: 'edit',
                logName: 'EDIT-STUDYTOUR',
                id_web_study_tour: result.id_web_study_tour,
                code_study_tour: result.code_study_tour,
                city: result.city,
                country: result.country,
                harga: result.harga,
                itenary: result.itenary,
                min_pax: result.min_pax
            })
        })
    }

    deleteRow(row) {
        this.setState({
            id_web_study_tour: row.id_study_tour,
            modalDelete: true,
            title: 'Delete Study Tour Data',
            operation: 'delete',
            logName: 'DELETE-STUDYTOUR'
        })
    }

    saveRow() {
        if(this.handleValidation()) {
          this.sendData();
        } else {
            this.validateCity();
            this.validateCountry();
            this.validateHarga();
            this.validateItenary();
            this.validateMinPax();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let fileExtension = this.state.image === undefined ? "" : this.state.image.name.split('.').pop();
        let datetime = Moment(Date()).format('DDMMYYYY');
        let newFileName = this.state.image === undefined ? "" : "studytour"+"_"+datetime+"."+fileExtension;
        let nama = this.state.image === undefined ? "" : "studytour"+"_"+datetime;

        var formData = new FormData();
        formData.append('code_study_tour', this.state.code_study_tour); 
        formData.append('city', this.state.city); 
        formData.append('country', this.state.country);
        formData.append('harga', this.state.harga);
        formData.append('itenary', this.state.itenary);
        formData.append('min_pax', this.state.min_pax);
        formData.append('image', nama);
        formData.append('file_image', this.state.image, newFileName);

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/website/study-tour';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/website/study-tour/'+this.state.id_web_study_tour
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/website/study-tour/'+this.state.id_web_study_tour
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataStudyTour();
            this.setState({ btnDisabled: false });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
        });
    }

    getDataStudyTourList() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/website/list-register-studytour';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataStudyTour:data.data
            });
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">City</label>
                                    <input className="form-control" name="city" type="text" id="city"  value={this.state.city}
                                        onChange={(e) => this.handleInput(e)}
                                        onBlur={(e) => this.validateCity(e)}
                                        ref={e => this.inputCity = e}
                                    />
                                    <span>{this.state.errors.city}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Country</label>
                                    <input className="form-control" name="country" type="text" id="country"  value={this.state.country}
                                        onChange={(e) => this.handleInput(e)}
                                        onBlur={(e) => this.validateCountry(e)}
                                        ref={e => this.inputCountry = e}
                                    />
                                    <span>{this.state.errors.country}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Harga</label>
                                    <input className="form-control" name="harga" type="text" id="harga"  value={this.state.harga}
                                        onChange={(e) => this.handleInput(e)}
                                        onBlur={(e) => this.validateHarga(e)}
                                        ref={e => this.inputHarga = e}
                                    />
                                    <span>{this.state.errors.harga}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Itenary</label>
                                    <input className="form-control" name="itenary" type="text" id="itenary"  value={this.state.itenary}
                                        onChange={(e) => this.handleInput(e)}
                                        onBlur={(e) => this.validateItenary(e)}
                                        ref={e => this.inputItenary = e}
                                    />
                                    <span>{this.state.errors.itenary}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Min. Pax</label>
                                    <input className="form-control" name="min_pax" type="text" id="min_pax"  value={this.state.min_pax}
                                        onChange={(e) => this.handleInput(e)}
                                        onBlur={(e) => this.validateMinPax(e)}
                                        ref={e => this.inputMinPax = e}
                                    />
                                    <span>{this.state.errors.min_pax}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                        onBlur={(e) => this.validateFileName(e)}
                                        ref={e => this.inputFileName = e}
                                    />
                                    <span>{this.state.errors.image}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Nama',
                accessor: 'name',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterable: false
            },
            {
                Header: 'Kota Asal',
                accessor: 'kota_asal',
                filterable: false
            },
            {
                Header: 'Negara Tujuan',
                accessor: 'negara_tujuan',
                filterable: false
            },
            {
                Header: 'Tanggal Berangkat',
                accessor: 'tanggal_pergi',
                filterable: false
            },
            {
                Header: 'Tanggal Pulang',
                accessor: 'tanggal_pulang',
                filterable: false
            },
        ]

        const columns_detail = [
            {
                Header: 'Total Peserta',
                accessor: 'total_peserta',
                filterable: false
            },
            {
                Header: 'Total Pendamping',
                accessor: 'total_pendamping',
                filterable: false
            },
            {
                Header: 'Tipe Akomodasi',
                accessor: 'tipe_akomodasi',
                filterable: false
            },
            {
                Header: 'Tipe Akomodasi',
                accessor: 'tipe_akomodasi',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.kegiatan === '1' ?
                            <>
                                <p>Kursus Bahasa Inggris</p>
                            </>
                            :
                            row.original.kegiatan === '2' ?
                            <>
                                <p>City Tour</p>
                            </>
                            :
                            row.original.kegiatan === '3' ?
                            <>
                                <p>Kunjungan Sekolah/Kampus</p>
                            </>
                            :
                            row.original.kegiatan === '4' ?
                            <>
                                <p>Partnership</p>
                            </>
                            :
                            row.original.kegiatan === '5' ?
                            <>
                                <p>Short Course</p>
                            </>
                            :
                            <>
                                <p>Aktivitas Lainnya</p>
                            </>
                        }
                    </div>
                )
            },
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <br/>
                                        {
                                            this.state.dataStudyTour.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataStudyTour}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                                SubComponent={row =>{
                                                    return (
                                                        <div style={{ padding: "20px"}}>
                                                            <ReactTable
                                                                data={this.state.dataStudyTour}
                                                                columns={columns_detail}
                                                                defaultPageSize={5}
                                                                className="-striped -highlight"
                                                                showPaginationBottom={true}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(StudyTourRegister));