/* eslint-disable eqeqeq,react/no-direct-mutation-state, no-useless-concat, jsx-a11y/alt-text */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import Breadcrumb from '../../../components/common/breadcrumb';
import Moment from 'moment';

class UniversityListEventOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataListEventOrder: [],
            id: "",
            image: "",
            order: "",
        }
    }

    componentDidMount() {
        this.getDataEventListOrder();
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    validateFileName = () => {
        const image = this.state.image;
        if(image === "" || image === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.image = 'image Harus Diisi'; 
                errors.imageStatus = false;                                 
                return { errors };                   
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.image = '';    
                errors.imageStatus = true;                             
                return { errors };                   
            })
        }
    }

    onChangeFileName = (e) => {
        this.state.image = e.target.files[0];
        this.setState({
            filename: e.target.value
        })
    }

    handleValidation() {
        let formIsValid = true;
        if(!this.state.errors.imageStatus){
            formIsValid = false;
            alert('File Masih Kosong');
        }
        
        return formIsValid;
    }

    updatePayment(row) {
        this.setState({
            modal: true,
            id_order: row.id_event_order,
            order: row,
            title: 'Payment Confirmation',
            operation: 'edit',
            logName: 'PAYMENT-CONFIRMATION',
        })
    }

    saveRow() {
        if(this.handleValidation()) {
          this.sendData();
        } else {
            this.validateFileName();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true,
            showLoading: true
        });

        let fileExtension = this.state.image === undefined ? "" : this.state.image.name.split('.').pop();
        let datetime = Moment(Date()).format('DDMMYYYY');
        let newFileName = this.state.image === undefined ? "" : "payment"+"_"+this.state.order.code_school_rep+datetime+"."+fileExtension;
        let nama = this.state.image === undefined ? "" : "payment"+"_"+datetime;

        var formData = new FormData();
        formData.append('status', 1);
        formData.append('file_image', this.state.image, newFileName);
        formData.append('nama', nama);
        formData.append('code_school_rep', this.state.order.code_school_rep); 
        formData.append('code_event_order', this.state.order.code_event_order);
        formData.append('datetime', datetime);

        let url = '/event/order';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/event/order/'+this.state.id_order
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/event/order/'+this.state.id_order
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.setState({
                showLoading:false
            });
            this.closeDialog();
            this.getDataEventListOrder();
            this.setState({ btnDisabled: false });
            toast.success("Confirmation Sucsessfully");
            console.log(reply);
        }).catch(err => {
            console.log(err)
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            id: "",
            name: "",
            description: "",
            zoom_link: "",
            image: "",
            harga: "",
            jenis_event: "",
            start_date: "",
            end_date: ""
        });
    }

    getDataEventListOrder() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/event/order/'+this.props.userableData.userable.code;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEventOrder:data.data
            });
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <h6>Upload Your Transfer Paid Here</h6>
                        <br/>
                        <Loader showLoading={this.state.showLoading} />
                        <br />
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                        onBlur={(e) => this.validateFileName(e)}
                                        ref={e => this.inputFileName = e}
                                    />
                                    <span>{this.state.errors.bukti_transfer}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        let dollarUSLocale = Intl.NumberFormat('en-US');

        const columns = [
            {
                Header: 'Code Order',
                accessor: 'code_event_order',
                filterable: false
            },
            {
                Header: 'Total Amount',
                accessor: 'amount',
                filterable: false
            },
            {
                Header: 'Diskon',
                filterable: false,
                Cell: (row) => (
                    <p>
                        {row.original.tipe_diskon} {row.original.diskon}
                    </p>
                )
            },
            {
                Header: 'Grand Total',
                filterable: false,
                Cell: (row) => (
                    <p>
                        AUD {row.original.total_amount != null ? dollarUSLocale.format(row.original.total_amount) : ''}
                    </p>
                )
            },
            {
                Header: 'Status Order',
                Cell: (row) => (
                    <div>
                        { row.original.order_status == 0 ? <span className={'badge bg-info'}>Waiting Payment</span> : row.original.order_status == 1 ? <span className={'badge bg-secondary'}>Payment Confirmed</span> : <span className={'badge bg-success'}>Success</span>}
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Confirmation</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.updatePayment(row.original)} className="btn btn-outline-primary" type="button" style={{border:'none'}} ><i className='fa fa-bars'></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        const columns_event = [
            {
                Header: 'Event Name',
                accessor: 'event.name',
                filterable: false
            },
            {
                Header: 'Category Event',
                Cell: (row) => (
                    <div>
                        { row.original.event.jenis_event == 1 ? row.original.event.jenis_event == 2 ? 'Info Session' : 'Education Expo' : 'Education Festival' }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Online Link',
                accessor: 'event.zoom_link',
                filterable: false
            },
            {
                Header: 'City',
                Cell: (row) => (
                    <div>                     
                        { 
                            row.original.kabkota != null ? row.original.kabkota.kota[0].nama_kabkota : ''
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={row.original.event.image}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Price',
                filterable: false,
                Cell: (row) => (
                    <p>
                        AUD {row.original.event.harga != null ? dollarUSLocale.format(row.original.event.harga) : ''}
                    </p>
                )
            },

            
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <Breadcrumb title="History Event" parent="History Event" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.dataListEventOrder.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataListEventOrder}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                                showPaginationBottom={false}
                                                SubComponent={ row => {
                                                    return(
                                                        <ReactTable
                                                            data={row.original.detail}
                                                            columns={columns_event}
                                                            defaultPageSize={4}
                                                            className="-striped -highlight"
                                                            showPaginationBottom={false}
                                                        />
                                                    );
                                                }}
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState)(withRouter(UniversityListEventOrder));