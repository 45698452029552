
import React, { Component } from 'react'
import { matchSorter } from 'match-sorter'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { withRouter } from "react-router";
import Moment from 'moment'
import axios from 'axios'

import setRangeDate from '../../../helper/general-function'
import API_URL from '../../../ApiUrl'
import Loader from '../../../components/common/loader'


class EnglishStudentData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            students: [],
            listProgram: [],
            listType: [],
            filtered: [],
            loading: false,
            date: 'this_month',
            start_date: Moment().startOf('month').format('YYYY-MM-DD'),
            end_date: Moment().endOf('month').format('YYYY-MM-DD'),
            filterProgram: '',
            filterType: ''

        }
    }

    componentDidMount() {
        this.loadDataStudents()
        this.getProgram()
        this.getType()
    }

    handleChangeDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setDate()
        })
    }

    setDate() {
        let data = setRangeDate(this.state.date)
        this.setState({
            start_date: data.start,
            end_date: data.end
        }, () => {
            this.loadDataStudents()
        })
    }

    loadDataStudents = () => {
        let formData = new FormData()
        this.setState({
            loading: true
        })
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        formData.append('id_teacher', this.props.userableData.userable.id_teacher)
        axios.post(API_URL.superStudent + '/english-division/list-student', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.setState({
                    students: response.data.data,
                    loading: false
                })
            })
    }

    getProgram() {
        axios.get(API_URL.superStudent + '/english-course/list-program', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ listProgram: response.data.data }))
    }

    getType() {
        axios.get(API_URL.superStudent + '/english-course/list-type', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => this.setState({ listType: response.data.data }))
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    SetFilterProgram = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterProgram = newData;
            return { filterProgram };
        }, () => {
            this.onFilteredChangeCustom(newData, "id_program");
        })
    }

    SetFilterType = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterType = newData;
            return { filterType };
        }, () => {
            this.onFilteredChangeCustom(newData, "id_type");
        })
    }


    render() {

        const columns = [
            {
                Header: 'Name',
                accessor: 'nama_lengkap',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                filterAll: true
            },
            {
                Header: 'Schedule',
                accessor: 'schedule',
                style: { textAlign: 'center' },
                width: 90,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["schedule"] }),
                filterable: false
            },
            {
                Header: 'Program',
                accessor: 'id_program',
                Cell: (row) => (
                    <>
                        {
                            row.original.program != null ?
                                <span>{row.original.program.nama_program}</span>
                                :
                                <span></span>
                        }
                    </>
                ),
                Filter: () =>
                    <select value={this.state.filterProgram || ''} onChange={(e) => this.SetFilterProgram(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listProgram.map(item => {
                                return <option key={item.id_program} value={item.id_program}>{item.nama_program}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Type',
                accessor: 'id_type',
                Cell: (row) => (
                    <>
                        {
                            row.original.type != null ?
                                <span> {row.original.type.description}</span>
                                :
                                <span></span>
                        }
                    </>
                ),
                Filter: () =>
                    <select value={this.state.filterType || ''} onChange={(e) => this.SetFilterType(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listType.map(item => {
                                return <option key={item.id_type} value={item.id_type}>{item.description}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                filterable: false,
                style: { textAlign: 'center' },
                width: 110,
            },
            {
                Header: 'Group Code',
                accessor: 'grup_code',
                style: { textAlign: 'center' },
                width: 110,
                filterable: false,
                Cell: (row) => (
                    <>
                        {
                            row.original.grup_code != null ?
                                <span className='badge badge-primary'> {row.original.grup_code}</span>
                                :
                                <span></span>
                        }
                    </>
                )
            },
        ];

        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="row justify-content-between">
                                <div className="col-sm-6">
                                    <div className="text-left">
                                        <span style={{ fontSize: '1.3em' }} className='f-w-600'>{Moment(this.state.start_date).format('DD MMM yyyy')} - {Moment(this.state.end_date).format('DD MMM yyyy')}</span>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <select name='date' value={this.state.date} onChange={this.handleChangeDate} className='form-control'>
                                        <option value='this_week'>This Week</option>
                                        <option value='last_week'>Last Week</option>
                                        <option value='this_month'>This Month</option>
                                        <option value='last_month'>Last Month</option>
                                        <option value='this_year'>This Year</option>
                                        <option value='last_year'>Last Year</option>
                                        <optgroup label='Quartal'>
                                            <option value='1'>Quartal I</option>
                                            <option value='2'>Quartal II</option>
                                            <option value='3'>Quartal III</option>
                                            <option value='4'>Quartal IV</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className='card-header'>
                            <h5 className="mb-0">Total Data <span className='badge badge-success'>{this.state.students.length}</span></h5>
                        </div>
                        <div className="card-body">
                            <div className="row m-1">
                                <div className="col-sm-12">
                                    <ReactTable
                                        data={this.state.students}
                                        filtered={this.state.filtered}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        filterable
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        SubComponent={row => {
                                            const dataa = row.original
                                            return (
                                                <div className='container' style={{ padding: '20px' }}>
                                                    <div>Phone   : <span>{dataa.no_wa}</span></div>
                                                    <div>Email   : <span>{dataa.email}</span></div>
                                                    <div>Address : <span>{dataa.address}</span></div>
                                                </div>
                                            )
                                        }}
                                    />
                                    <Loader showLoading={this.state.loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})


export default connect(reduxState, null)(withRouter(EnglishStudentData));
