import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import Moment from 'moment';


class LocationLog extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataLog: [],
            showLoading: false,
            latitude:-8.641444366569226,
            longitude: 115.20921562203851
        }
    }

    componentDidMount(){
        this.loadDataLog()

    }

    loadDataLog = () => {
        axios.get(API_URL.superStudent+'/log-location',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            console.log(response.data)
            this.setState({ dataLog: response.data.data})
        })
        .catch(() => console.log('error'))
    }

    showCordinat  = (item) => {
        this.setState({
            latitude : item.latitude,
            longitude: item.longitude
        })
    }

    render(){

        const columns = [
            {
                Header: '#',
                accessor: 'created_at',
                style:{textAlign:'center'},
                width:100,
                Cell:(row) => (
                    <><span>{Moment(row.original.created_at).format('DD MMM YY')}</span><p className='f-12 text-center'>{Moment(row.original.created_at).format('HH:mm')}</p></>
                )
            },
            {
                Header: 'Time',
                accessor: 'code_users',
                style:{textAlign:'center'},
                Cell:(row) => (
                    <span style={{cursor:'pointer'}} onClick={() => this.showCordinat(row.original) }>{row.original.user.nama_lengkap}</span>
                )
            },
        ]
        return(
            <Fragment>
            <div className="page-wrapper">
    
                <div className="container-fluid p-0">
                    <Breadcrumb title="Log Location" parent="Modules" />
                    <div className="row">
                        <div className="col-md-6">
                            <div className='card'>
                                <div className="card-body">
                                    <ReactTable
                                        data={this.state.dataLog}
                                        columns={columns}
                                        defaultPageSize={20}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <iframe frameBorder='0' height='300px' scrolling='no' width='100%' title='google-maps' src={`https://maps.google.com/maps?q=${this.state.latitude},${this.state.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser:state.Login.detailUser,
})


export default connect(reduxState, null)(LocationLog)