import axios from 'axios';
import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import API_URL from '../../ApiUrl';
import DataTableTraining from '../common/datatable/dataTableTraining';
import { connect } from 'react-redux';

export class DataTablesTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list_training: [],
            addModal: false,
            editModal: false,
            addSubModal: false,
            editSubModal: false,
            id_training_category: '',
            sub_category: '',
            id_sub_category: '',
            category: ''
        }
    }

    openSubModal = (row) => {
        this.setState({
            addSubModal: true,
            id_training_category: row.id_training_category
        })
    }
    openSubEditModal = (row) => {
        this.setState({
            editSubModal: true,
            sub_category: row.sub_category,
            id_training_category: row.id_training_category,
            id_sub_category: row.id_sub_category,
        })
    }
    openAddModal(){
        this.setState({addModal: true})
    }
    openEditModal = (row) => {
        this.setState({
            addModal: false,
            id_training_category: row.id_training_category
        })
    }

    closeAddModal(){
        this.setState({addModal: false})
    }
    closeEditModal(){
        this.setState({addModal: true})
    }
    closeSubModal(){
        this.setState({addSubModal: false})
    }
    closeSubEditModal(){
        this.setState({editSubModal: false})
    }

    handleChange(e){
        const {name, value} = e.target
        this.setState({[name]: value});
    }

    componentDidMount(){
        this.getDataTrainingMaster();
    }

    saveTrainingCategory(method){
        this.setState({btnLoading: true})
        const {category, id_training_category} = this.state
        let formData = new FormData();
        formData.append('category', category)
        formData.append('id_training_category', id_training_category)
        formData.append('method', method)
        
        axios.post(API_URL.superStudent + '/training', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(res => {
            this.setState({btnLoading: false})
            if (method === 'add') {
                toast.success(`Training Category Added Successfully`)
                this.closeSubModal();
            } else {
                toast.success(`Training Category Edited Successfully`)
                this.closeSubEditModal();
            }
            this.getDataTrainingMaster();
        })
        .catch(err => console.log(err))
    }

    saveSubTraining(method){
        this.setState({btnLoading: true})
        const {sub_category, id_training_category, id_sub_category} = this.state
        let formData = new FormData();
        formData.append('sub_category', sub_category)
        formData.append('id_training_category', id_training_category)
        formData.append('id_sub_category', id_sub_category)
        formData.append('method', method)
        
        axios.post(API_URL.superStudent + '/training/sub-training', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
            })
        .then(res => {
            this.setState({btnLoading: false})
            if (method === 'add') {
                toast.success(`Sub Training Added Successfully`)
                this.closeSubModal();
            } else {
                toast.success(`Sub Training Edited Successfully`)
                this.closeSubEditModal();
            }
            this.getDataTrainingMaster();
        })
        .catch(err => console.log(err))
    }

    getDataTrainingMaster(){
        axios.get(API_URL.superStudent + '/training',{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
              }
        })
          .then(response => response.data)
          .then(result => {
            this.setState({ list_training: result})
        })
          .catch(error => console.log(error))
    }

    renderAddModal(){
        return(
            <Modal isOpen={this.state.addModal} toggle={() => this.closeAddModal()} size='lg'>
                <ModalHeader toggle={() => this.closeAddModal()}>Add Training Category</ModalHeader>
                <ModalBody>
                    <form>  
                        <div className="form-group">
                            <label htmlFor="category">Training Category</label>
                            <input autoFocus type="text" name='category' onChange={(e) => this.handleChange(e)} className="form-control" id="category" placeholder='Category Training' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveTrainingCategory('add')}>Add Training</Button>
                    <Button color="secondary" onClick={() => this.closeAddModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderSubModal(){
        return(
            <Modal isOpen={this.state.addSubModal} toggle={() => this.closeSubModal()} size='lg'>
                <ModalHeader toggle={() => this.closeSubModal()}>Sub Training</ModalHeader>
                <ModalBody>
                    <form>  
                        <div className="form-group">
                            <label htmlFor="sub_category">Sub Cateegory Training</label>
                            <input autoFocus type="text" name='sub_category' onChange={(e) => this.handleChange(e)} className="form-control" id="sub_category" placeholder='Sub Category Training' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveSubTraining('add')}>Add Training</Button>
                    <Button color="secondary" onClick={() => this.closeSubModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderSubEditModal(){
        return(
            <Modal isOpen={this.state.editSubModal} toggle={() => this.closeSubEditModal()} size='lg'>
                <ModalHeader toggle={() => this.closeSubEditModal()}>Sub Training</ModalHeader>
                <ModalBody>
                    <form>  
                        <div className="form-group">
                            <label htmlFor="sub_category">Sub Cateegory Training</label>
                            <input autoFocus type="text" value={this.state.sub_category} name='sub_category' onChange={(e) => this.handleChange(e)} className="form-control" id="sub_category" placeholder='Sub Category Training' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveSubTraining('edit')}>Update Sub Training</Button>
                    <Button color="secondary" onClick={() => this.closeSubEditModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
  render() {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body datatable-react">
                                {/* <h3 className='text-center'>{listKandidat[0]?.job_vacancy.title}</h3> */}
                                <button onClick={() => this.openAddModal()} className='btn btn-success mb-4'>Add Training Category +</button>
                                <DataTableTraining 
                                    myData={this.state.list_training}
                                    addSubTraining={this.openSubModal}
                                    editSubTraining={this.openSubEditModal}
                                    title="Kategori Training" 
                                    pageSize={15}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.renderAddModal()}
            {this.renderSubModal()}
            {this.renderSubEditModal()}
        </Fragment>
    )
  }
}


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })
  
export default connect(reduxState, null)(DataTablesTraining);
