/* eslint-disable eqeqeq, no-unused-vars, array-callback-return, jsx-a11y/scope, react/no-direct-mutation-state */

import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import excel from '../../../../assets/images/document-logo/excel-logo.png';
import word from '../../../../assets/images/document-logo/word-logo.png';
import pdf from '../../../../assets/images/document-logo/pdf-logo.png';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ZipDownload from '../../../../services/jszip';
import { saveAs } from "file-saver";
import Swal from 'sweetalert2';
import market1 from '../../../../assets/images/bitcoin/market-1.jpg';
import Moment from 'moment';

class DocumentProgress extends Component {    
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            documentList:[],
            profileStudent:[],
            codeStudents:"",
            codeStudentDocument:"",
            modalDetail: false,
            modal: false,
            modalNested: false,
            modalDelete:false,
            modalPreview:false,
            title: '',
            titleDelete: '',
            logName:'',
            editingRow: null,
            deletingRow: null,
            model: {              
                code_students_document: "",
                code_students: "",
                code_document: "",
                file_name: "",
            },
            documentName:"",
            documentImage:"",
            documentText:"",
            documentType:"",
            documentCode:"",
            selectedDocument:undefined,
            errors: {},
            operation: '',
            toastId: null,
            btnDisabled: false,
            modules:"Students",
            urlPreview:"",
            data_document: [],
            student_doc: [],
            last_code_doc: "",
            show: false,
            urls: [],
            code_student_program: ''
        };
    }

    async componentWillMount() {
        this._isMounted = true;

        if (this._isMounted) {
            await this.getDocumentList();
        }
    }

    countProgress(data){
        const docStudent = data.length
        const countDocStudent = data.filter((dd)=>{
            if (dd.student_document.length > 0) {
                return dd;
            }
        })
        return Math.round((countDocStudent.length/docStudent)*100)
    }

    componentDidMount(){
        this.getCodeByToken();
    }

    getDocumentList() {
        const url = API_URL.studentUrl+'token-student/'+this.props.match.params.codeStudent;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({
                documentList: this.props.datalist,
                codeStudents: data.code_students,
            });
        })
    }

    getStudentDocByCode(code_document) {
        const url = API_URL.studentUrl+'students-documents-code/'+this.props.codeStudents+'/'+code_document;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((result) => {
            this.setState({ 
                title:"Preview",
                modalPreview: true,
                student_doc: result
            })
        })
    }

    getLastCodeDocument() {
        const url = API_URL.studentUrl+'last-code-document';
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ 
                last_code_doc:data
            })
        })
    }

    getCodeByToken() {
        const url = API_URL.studentUrl+'token-student/'+this.props.match.params.codeStudent;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({
                documentList: this.props.datalist,
                profileStudent: this.props.profile,
                codeStudents: data.code_students,
            },() => {
                this.getAlldocumentLinks()
            })
        })
    }

    getAlldocumentLinks(){
        axios.get(API_URL.studentUrl+'students-documents/'+this.state.codeStudents, {
            headers:{  Authorization: `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then(results => {
            let urls = results.map(function (result){
                return result.file_name
            })
            this.setState({
                urls
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    addDocument(list) {
        const newData = list.code_document;
        this.getLastCodeDocument();
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model.code_document = newData;
            return { model };
        }, () => {
            this.setCodeStudents();
            this.setState({
                title:"Add Document",
                modalNested: true,
                operation: 'add',
                codeStudents: this.props.codeStudent,
                logName: 'ADD-DOCUMENT',
                documentName:list.nama_document,
                documentImage:list.image_description,
                documentText:list.text_description,
                documentCode:list.code_document,
                data_document: []
            });  
        })
    }
    
    filePreview(list) {
        this.setCodeStudents();
        this.getStudentDocByCode(list.code_document)
    }

    setCodeStudents = () => { 
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.code_students = this.props.codeStudent; 
        return { model };                   
        }, () => {
        
        })
    }

    deleteDocument(row) {
        this.setState({
          deletingRow: row,
          model: Object.assign({}, row),
          titleDelete: 'Delete Document Student',
          modalDelete: true,
          operation: 'delete',
          logName: 'DELETE-DOCUMENT-STUDENT'
        });
    }

    renderDeleteDialog() {
        const { titleDelete } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{titleDelete}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogDelete()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    closeDialogDelete(){
        this.setState({
            modalDelete: false
        });

    }

    detailDocument(row) {
        setTimeout(() => {
          this.setState({
            title:"List Document",
            modal: true,
          });
        }, 100);
    }

    closeModal(){
        this.setState({
            modal: false,
            modalDelete: false,
            modalNested: false,
        });
    }

    closePreview(){
        this.setState({
            modalPreview: false,
        });
    }

    closeAlert = () => {
        this.props.onGetListDocument();
        this.setState({ 
            show: false,
            modalNested: false, 
            btnDisabled: false
        });
    }
    
    closeModalNested(){
        this.setState({
            modalNested: false,
        });
    }

    onChangeFileName = (e, i) => {
        const newData = e.target.value;
        this.state.selectedDocument = e.target.files[0];
        this.setState(prevState => {
          let model = Object.assign({}, prevState.model);  
          model.file_name = newData; 
          return { model };                   
        })
    }

    handleChange(i, e) {
        const files = e.target.files[0];
        let data_document = [...this.state.data_document];
        data_document[i] = {...data_document[i], [e.target.name]:files};
        this.setState({
            data_document
        },() => {
            console.log(data_document);
        });
    }

    handleChangeNote(i, e) {
        const notes = e.target.value;
        let data_document = [...this.state.data_document];
        data_document[i] = {...data_document[i], [e.target.name]:notes};
        this.setState({
            data_document
        },() => {
            console.log(data_document);
        });
    }

    setChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addClick(){
        this.setState(prevState => ({ 
            data_document: [...prevState.data_document, {}]
        }))
    }

    removeClick(i){
        let data_document = [...this.state.data_document];
        data_document.splice(i, 1);
        this.setState({ data_document });
    }

    setJenisDocument = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.code_document = newData; 
        return { model };                   
        }, () => {
        this.validateJenisDocument();
        })
    }

    validateJenisDocument = () => {
        const data = this.state.model;
        if(data.code_document === "" || data.code_document === 0 ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_document = 'Jenis Dokumen Harus Diisi'; 
                errors.code_documentStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_document = '';    
                errors.code_documentStatus = true;                             
                return { errors };                   
            })
        }
    }

    saveData() {
        if(this.state.documentCode == 'DT023') {
            if(this.state.code_student_program != '') {
                this.sendData();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Pilih Program Terlebih Dahulu",
                });
            }
        } else {
            this.sendData();
        }
    }

    async sendData() {
        this.setState({
          btnDisabled: true
        });

        let nama = this.props.profile || this.props.dataUser;
        let data = this.state.model;
        let documentName = this.state.documentName;
        let datetime = Moment(Date()).format('DDMMYYYY');
    
        this.state.data_document.map(async (gambar, i) => {
            let fileExtension = gambar.file_name.name === undefined ? "" : gambar.file_name.name.split('.').pop();
            let newFileName = gambar.file_name.name === undefined ? "" : nama.nama.replace(/\s/g, '')+'_'+documentName.replace(/\s/g, '')+'_'+this.state.last_code_doc+"_"+i+"_"+datetime+'.'+fileExtension;
            let name = gambar.file_name.name === undefined ? "" : nama.nama.replace(/\s/g, '')+'_'+documentName.replace(/\s/g, '')+'_'+this.state.last_code_doc+"_"+i+"_"+datetime;

            var formData = new FormData();
                formData.append('code_student_program', this.state.code_student_program);
                formData.append('status', 3);
                formData.append('code_students_document', data.code_students_document);
                formData.append('code_students', this.props.codeStudents);
                formData.append('code_document', data.code_document);
                formData.append('file_name', name);
                formData.append('file_notes', gambar.file_note);
                formData.append('picFile', gambar.file_name, newFileName);
        
                formData.append('user',this.props.codeUsers);
                formData.append('token',this.props.dataToken);

            await axios.post(API_URL.superStudent+'/student/students-documents',formData, { 
                headers: { 
                    Authorization: `Bearer ${this.props.dataToken}`, 
                    'Content-Type':'multipart/form-data' 
                },
                onUploadStatus: p => {
                    const status = p.loaded / p.total;
                    if(this.state.toastId === null){
                        this.state.toastId = toast('Save in progress', {
                            autoClose: true
                        });
                    } else {
                        toast.update(this.state.toastId, {
                            render: 'Save in progress',
                            progress: status,
                            autoClose: true
                        })
                    }
            }}).then((reply)=>{
                toast.success('Student Document Added')
                this.setState({ show: true, btnDisabled: false });
                this.props.onGetListDocument();
                this.props.onReloadProgram();
                this.props.onReloadProfil();
                this.closeModalNested();
            }).catch(function(reply){
                console.log(reply)
            });
        });
    }

    saveDeleteRow() {
        this.setState({
            btnDisabled: true
        });
        axios.delete(API_URL.superStudent+'/student/students-documents/'+this.state.deletingRow.id_students_document, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
            onUploadStatus: p => {
                const status = p.loaded / p.total;
                if(this.state.toastId === null) {
                    this.state.toastId = toast('Save in progress', {
                        autoClose: true
                    });
                } else {
                    toast.update(this.state.toastId, {
                        render: 'Save in progress',
                        progress: status,
                        autoClose: true
                    })
                }
                toast.success('Student Document Deleted')
            }
        }).then((reply)=>{
            this.props.onGetListDocument();
            this.setState({ 
                show: false,
                modalNested: false, 
                btnDisabled: false,
                modalPreview: false,
                modalDelete: false
            });
            
        }).catch(function(reply){
            console.log(reply)
        });
    }

    downloadFile = (e, url, filename) => {
        saveAs(url,filename);
    }

    renderPreview(){
        const modalPreview = this.state.modalPreview;
        const { title } = this.state;
        return(
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
              <ModalHeader toggle={() => this.closePreview()}>{title}</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                {this.state.student_doc.map((doc, i) => {
                    const url_doc = [{ uri:doc.file_name }]
                    const url_download = doc.file_name;
                    return  <div key={i} className='mt-3'>
                                <h6><strong><li>{url_download.split('/').pop()}</li></strong></h6>
                                {
                                    url_download.split('.').pop().toLowerCase == 'png' || url_download.split('.').pop().toLowerCase == 'jpg' || 
                                    url_download.split('.').pop().toLowerCase == 'jpeg' ?
                                    <img className='image' src={this.state.urlPreview} alt="" /> 
                                    :
                                    <DocViewer 
                                        pluginRenderers={DocViewerRenderers} 
                                        documents={url_doc}
                                        config={{
                                            header: {
                                                disableHeader: true,
                                                disableFileName: false,
                                                retainURLParams: false
                                            }
                                        }}
                                    />
                                }
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <h6 className="mt-1">Notes :</h6>
                                        <p>{doc.file_notes}</p>
                                    </div>
                                    <div className="col-md-6 mt-2" style={{textAlign: "end"}}>
                                        <button onClick={(e) => this.downloadFile(e, url_download, doc.nama_file)} className="btn btn-outline-success mr-1" type="button"><i className="fa fa-download"></i></button>
                                        <button onClick={() => this.deleteDocument(doc)} className="btn btn-outline-danger" type="button"><i className="fa fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                })}
              </ModalBody>
            </Modal>
        )
    }

    renderEditDialog() {
        const row = this.props.datalist;
        const modal = this.state.modal;
        const { title } = this.state;
        return(
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    {row.map((row, index)=>(
                        <div className="media markets" key={index}>
                            { row.student_document.length > 0 ? 
                                row.student_document[0].file_name.split('.').pop() == 'pdf' ? 
                                <img className='document-img' style={{maxWidth:"91px"}} alt='images' onClick={() => this.filePreview(row)} src={pdf}/> 
                                :
                                row.student_document[0].file_name.split('.').pop() == 'docx' ?
                                <img className='document-img' style={{maxWidth:"91px"}} alt='images' onClick={() => this.filePreview(row)} src={word}/>
                                :
                                row.student_document[0].file_name.split('.').pop() == 'xlsx' ?
                                <img className='document-img' style={{maxWidth:"91px"}} alt='images' onClick={() => this.filePreview(row)} src={excel}/>
                                :
                                <img className='document-img' style={{maxWidth:"91px"}} alt='images' onClick={() => this.filePreview(row)} src={row.student_document[0].file_name}/>
                                
                            : <img className='document-img' src={market1} alt="" />                     
                            }  
                            <div className="media-body">
                                <h5 className="font-primary">{row.nama_document}</h5>
                                <div className="row">
                                    <div className="col-1">
                                        <button onClick={() => this.addDocument(row)} className="btn btn-outline-warning btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 35, padding: 11, fontSize: 15 }}></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderAddDialog() {
        const row = this.props.datalist;
        const modalNested = this.state.modalNested;
        const { title } = this.state;
        return (
            <Modal isOpen={modalNested} toggle={() => this.closeModalNested()} >
                <ModalHeader toggle={() => this.closeModalNested()}>{title} {this.state.documentName}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="" >
                        <div className="form-row">
                            <div className="col-md-12">
                                <label>Format Document :</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <img alt="imagess" style={{width: '400px'}} src={this.state.documentImage}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Description :</label>
                                <p style={{textAlign: "justify"}}>{this.state.documentText}</p>
                            </div>
                        </div>
                        {
                            this.state.documentCode == 'DT023' ?
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <select className='form-control' name='code_student_program' value={this.state.code_student_program} onChange={(e) => this.setChange(e)}>
                                        <option>-- Pilih Program --</option>
                                        {
                                            this.props.programs.map((program) => (
                                                <option value={program.pivot.code_student_program}>{program.program_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="file_name">Upload File</label>
                                <button onClick={this.addClick.bind(this)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 35, padding: 11, fontSize: 15 }}></i></button>
                                {this.state.data_document.map((arr, i) => {
                                    return  <div key={i}>
                                                <div className="form-row mb-3">
                                                    <input className="form-control col-sm-12mb-3" name="file_name" type="file" id="file_name" onChange={this.handleChange.bind(this, i)} />
                                                    <input className="form-control col-sm-10" name="file_note" type="text" id="text" placeholder="Note Document" onChange={this.handleChangeNote.bind(this, i)} />
                                                    <button onClick={this.removeClick.bind(this, i)} className="btn btn-outline-primary btn-xs form-control col-sm-2" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                                                </div>
                                            </div>
                                })}

                                { row.file_name ? 
                                    <img alt="imagess" style={{maxWidth:"150px"}} src={API_URL.masterstudents+"/uploads/"+row.code_students+"/"+row.code_document+"/"+row.file_name}/>  
                                    : ""
                                }
                                <span>{this.state.errors.file_name}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalNested()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const data = this.props.datalist
        return (
          <>
            <Card style={{height:'1130px'}}  className="card-progress">
                <CardHeader className='pb-0 d-flex justify-content-between mb-0'>
                    <h5>Document Progress</h5>
                    <h6>{this.countProgress(data)}%</h6>
                </CardHeader>
                <CardBody className="scroll scrool" style={{paddingBottom: '20px'}}>
                    <table className='table'>
                        <tbody>
                        {
                            data.map((m,i)=>{
                                return(
                                    <tr key={i}>
                                        <td scope='col'><i className={m?.student_document.length > 0 ? 'fa fa-check-square text-success' : 'fa fa-minus-square color-red'}></i></td>
                                        <td scope="col" className='w-75'><b>{m.nama_document}</b></td>
                                        {
                                        m?.student_document.length > 0 ?
                                        <>
                                            <td scope='col' style={{whiteSpace: 'nowrap'}}> 
                                                <button onClick={() => this.filePreview(m)} className='btn btn-success btn-xs'><i className='fa fa-eye'></i></button>
                                                <button onClick={() => this.addDocument(m)} className='btn bg-red btn-xs ml-2'><i className='fa fa-plus'></i></button>
                                            </td>
                                        </>
                                        :
                                        <td scope='col'><button onClick={() => this.addDocument(m)} className='btn bg-red btn-xs'><i className='fa fa-plus'></i></button></td>
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter className="text-center">
                    <ZipDownload urlDownload={this.state.urls} name={this.props.profile || this.props.dataUser} />
                </CardFooter>
            </Card>
            {this.renderAddDialog()}
            {this.renderEditDialog()}
            {this.renderPreview()}
            {this.renderDeleteDialog()}
          </>
        )
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users || state.Login.detailUser.code_students,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(DocumentProgress));