import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import Moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DataProfit extends Component{

    constructor(props){
        super(props)
        this.state = {
            date: '',
            amount:0,
            description:'',
            profitData:[],
            modalAdd:false
        }
    }

    componentDidMount(){
        this.loadProfitData()
    }

    loadProfitData = () => {
        axios.get(API_URL.superStudent+'/accounting/profits',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({profitData: response.data.data}))
        .catch(error => console.log(error));
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleSubmit = (e) => {
        let formData = new FormData ()
        formData.append('insert_date', this.state.date)
        formData.append('description', this.state.description)
        formData.append('amount', this.state.amount)
        axios.post(API_URL.superStudent+'/accounting/profits', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(() => {
            this.closeModal()
            this.loadProfitData()
            toast.success('successfully add data')
        })
        .catch(() => {
            this.closeModal()
            toast.error('there is an error')
        })

    }

    addData = () => {
        this.setState({
            date:'',
            amount:0,
            description:'',
            modalAdd: true
        })
    }

    closeModal(){
        this.setState({
            modalAdd:false
        })
    }

    renderModalAdd(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalAdd} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Form Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Persentase</label>
                                <input type='number' className='form-control' onChange={this.handleInput} name='amount' value={this.state.amount} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Description</label>
                                <textarea className='form-control' rows={5} value={this.state.description} onChange={this.handleInput} name='description'/>
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Date</label>
                                <input type='month' className='form-control' value={this.state.date} onChange={this.handleInput} name='date'/>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e) => this.handleSubmit()}>Add</Button>
                  <Button  color="secondary" onClick={() => this.closeModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){
        const columns = [
            {
                Header: 'Presentace',
                accessor: 'amount',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.amount+' %'
                )
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {textAlign: 'center'}
            },
            {
                Header: 'Tanggal',
                accessor: 'insert_date',
                style: {textAlign: 'center'},
                Cell: (row) =>(
                    <>{Moment(row.original.insert_date).format('MMMM YYYY')}</>
                )
            }
        ]
        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <button className='btn btn-success' onClick={this.addData}>Add Data</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.profitData}
                                        columns={columns}
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    /> 
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
                {this.renderModalAdd()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(DataProfit);