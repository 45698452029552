import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";


import "./styles/react-router-tabs.css";

const Campus = (dataUser) => {
    return (
        <Fragment>
            <Breadcrumb title="Campus" parent="Campus" />
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(Campus));