import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';


class DataTableOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:false,
            detail_order: [],
            btnDisabled: false,
        }
    }

    detailModal(row) {
        this.setState({modal: true, detail_order: row})
    }
    closeModal(){
        this.setState({modal: false})
    }

    formatRupiah = (nominal) => {
      return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currencyDisplay:'narrowSymbol',
          currency: 'IDR',
      }).format(nominal);
    }

    handlePic = (e) =>{
        const {files} = e.target
        this.setState({file_pic: files[0], file_pic_name: files[0].name})
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
          })
    }

    sendData(){
        this.setState({ btnDisabled: true });
        const {detail_order} = this.state
        let order = {
          origin_contact_name: "NIEC INDONESIA", 
          origin_contact_phone: "081238431234", 
          origin_address: "Jl. Buluh Indah No.56, Pemecutan Kaja, Denpasar, Kota Denpasar, Bali 80111", 
          origin_postal_code: 80111,
          origin_note: "Depan rumah makan kedaton",
          destination_contact_name: detail_order.nama_lengkap,
          destination_contact_phone: detail_order.no_telp,
          destination_address: detail_order.alamat, 
          destination_postal_code: parseInt(detail_order.postal_code), 
          courier_company: detail_order.courier_company, 
          courier_type: detail_order.courier_type, 
          courier_insurance: 500000, 
          delivery_type:"now",
          items: detail_order.item
        }
        axios.post('https://api.biteship.com/v1/orders', order,{
            headers: {
              'Authorization': `Bearer ${API_URL.biteshipToken}`,
          }
        })
        .then(res => {
          let {id, courier} = res.data
          const order = { kode_order: detail_order.kode_order, order_id: id, no_resi: courier.tracking_id}
          this.updateOrderId(order);
        })
        .catch(error => console.log(error))
    }

    updateOrderId(order){
      axios.post(API_URL.superStudent+'/niec-merchandise/update-orderid', order)
      .then(res => {
        this.setState({ btnDisabled: false });
        this.closeModal();
        toast.success(`Order Berhasil dibuat`);
      })
      .catch(err => console.log(err))
    }

    renderDetailModal() {
      const {detail_order} = this.state
      const items = detail_order.item
        return (
          <div className="card-body btn-showcase">
            <Modal isOpen={this.state.modal} size='lg' toggle={() => this.setState({ modal: false })}>
              <ModalHeader toggle={() => this.setState({ modal: false })}>Detail Order</ModalHeader>
              <ModalBody>
              <div className="row">
                        <div className="col-6 p-5">
                          <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Kode Order</td>
                                        <td>{detail_order.kode_order}</td>
                                    </tr>
                                  {
                                    detail_order.no_resi && (
                                      <tr>
                                        <td>Nomor Resi</td>
                                        <td>{detail_order.no_resi}</td>
                                    </tr>
                                    )
                                  }
                                    <tr>
                                        <td>Nama Lengkap</td>
                                        <td>{detail_order.nama_lengkap}</td>
                                    </tr>
                                    <tr>
                                        <td>Alamat Pemesan</td>
                                        <td>{detail_order.alamat}</td>
                                    </tr>
                                    <tr>
                                        <td>No Telepon Pemesan</td>
                                        <td>{detail_order.no_telp}</td>
                                    </tr>
                                    <tr>
                                        <td>Kode Pos</td>
                                        <td>{detail_order.postal_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Kurir</td>
                                        <td>{detail_order.courier_company}</td>
                                    </tr>
                                    <tr>
                                        <td>Layanan Pengiriman</td>
                                        <td>{detail_order.courier_type}</td>
                                    </tr>
                                </tbody>
                              </table>
                          </div>
                        </div>
                        <div className="col-6 p-5">
                        <h4 className='mb-3'>Item</h4>
                        {
                            items?.map((item) => {
                                return (
                                    <>
                                    <div className="border mb-2 p-3">
                                        <p>{item.name} ({item.quantity}x) - Rp. {item.value}</p>
                                    </div>
                                    </>
                                )
                            })
                        }
                        </div>
                      </div>
              </ModalBody>
              <ModalFooter>
              {
                (detail_order.status_order === 'Menunggu diproses') || (detail_order.status_order === 'courier_not_found') ?
                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Proses Order</Button>
                : ''
              }
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Close</Button>
              </ModalFooter>
            </Modal>
          </div>
        )
      }

    render() {
      const { pageSize, myData } = this.props;
      const columns = [
        {
          Header: 'Kode Order',
          accessor: 'kode_order',
          width: 130,
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["kode_order"] }),
            filterAll: true
        },
        {
          Header: 'Nama Pemesan',
          accessor: 'nama_lengkap',
          width: 180,
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
            filterAll: true
        },
        {
          Header: 'Telepon',
          accessor: 'no_telp',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["no_telp"] }),
            filterAll: true
        },
        {
          Header: 'Harga Total',
          accessor: 'total_amount',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["total_amount"] }),
          filterAll: true,
          Cell: (row) => (
            this.formatRupiah(row.original.total_amount)
          )
        },
        {
          Header: 'Status Order',
          accessor: 'status_order',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["status_order"] }),
            filterAll: true
        },
        {
          Header: <b>Action</b>,
          id: 'delete',
          accessor: str => "delete",
          Cell: (row) => (
            <div>
            {
              (row.original.status_order === 'Menunggu diproses') || (row.original.status_order === 'courier_not_found') ?
              <button onClick={() => this.detailModal(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-truck"></i></button> : ''
            }                 
            {
              (row.original.status_order !== 'Menunggu diproses') ?
              <button onClick={() => this.detailModal(row.original)} className="btn btn-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-eye"></i></button> : ''
            }                 
                 &nbsp;
                {/* <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash"></i></button> */}
            </div>
          ),
          style: {
              textAlign: 'center'
          },
          sortable: false,
          filterable: false
      }
      ]

        return (
            <Fragment>
              <ReactTable
                data={myData}
                columns={columns}
                pageSize={pageSize}
                showPagination={false}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value}
                SubComponent={row => {
                    const data = row.original
                    const item = row.original.item
                    return (
                      <div className="row">
                        <div className="col-6 p-5">
                          <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Nama Lengkap</td>
                                        <td>{data.nama_lengkap}</td>
                                    </tr>
                                    <tr>
                                        <td>Alamat Pemesan</td>
                                        <td>{data.alamat}</td>
                                    </tr>
                                    <tr>
                                        <td>No Telepon Pemesan</td>
                                        <td>{data.no_telp}</td>
                                    </tr>
                                    <tr>
                                        <td>Kode Pos</td>
                                        <td>{data.postal_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Kurir</td>
                                        <td>{data.courier_company}</td>
                                    </tr>
                                    <tr>
                                        <td>Layanan Pengiriman</td>
                                        <td>{data.courier_type}</td>
                                    </tr>
                                </tbody>
                              </table>
                              <div className="text-center profile-details m-3">
                                  {/* <h4><button className='btn btn-primary' onClick={()=>this.openPreviewModal(data.file_cv)}>Lihat CV <i className='fa fa-eye'></i></button></h4> */}
                                  {/* <button className='btn btn-primary' onClick={(e)=>this.downloadFile(e, data.file_cv)}>Lihat CV <i className='fa fa-eye'></i></button> */}
                              </div>
                          </div>
                        </div>
                        <div className="col-6 p-5">
                        <h4 className='mb-3'>Item</h4>
                        {
                            item?.map((item) => {
                                return (
                                    <>
                                    <div className="border mb-2 p-3">
                                        <p>{item.name} ({item.quantity}x) - Rp. {this.formatRupiah(item.value)}</p>
                                    </div>
                                    </>
                                )
                            })
                        }
                        <h4>Total : {this.formatRupiah(row.original.total_amount)}</h4>
                        </div>
                      </div>
                    )
                  }} 
              />
              <ToastContainer />
              {this.renderDetailModal()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
  dataId: state.Login.detailUser.id_users,
  dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(DataTableOrder);