import React, { Fragment, Component } from 'react';
import DatatableConsultant from '../common/datatable/datatableConsultant';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listConsultant: []
        }
    }

    componentDidMount() {
        this.getDataConsultant();
    }

    getDataConsultant(){
        let url
        if(this.props.dataUser.code_roles === 'RL004')
            url = API_URL.superStudent+'/consultant/all-data'
        else
            url = API_URL.superStudent+'/consultant/all-employee'  
            
          axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
          .then(response => response.data.data)
          .then(result => this.setState({ listConsultant: result}))
          .catch(error => console.log(error))
    }

    render() {
        const data = this.state.listConsultant;
        const pgsize = data.length;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableConsultant 
                                        myData={this.state.listConsultant}
                                        title="Consultant Data" 
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
   })
  
export default connect(reduxState, reduxDispatch)(withRouter(DataTablesConsultant));