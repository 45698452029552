import React, { Component } from 'react'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import Moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class ConselingList extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataConseling:[],
            filtered:[],
            dataEmployee:[],
            code_employee:'',
            date:'',
            disabledBtn:false,
            text_save:'Save',
            filterStatus:'',
            filterEmploye:'',
            modalJadwal:false,

        }
    }

    componentDidMount(){
        this.loadDataEmployee()
        this.loadDataCounseling()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataCounseling = () => {
        axios.get(API_URL.superStudent+'/counseling',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => { this.setState({ dataConseling: result }) })
        .catch(error => console.log(error))
    }

    loadDataEmployee = () => {
        axios.get(API_URL.superStudent+'/consultant/list-all',{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataEmployee: response.data.data }))
        .catch(error => console.log(error))
    }

    createJadwal = () => {
        this.setState({
            modalJadwal:true,
            schedule_date:'',
            code_employee:'',
            status:'',
        })
    }

    saveData = () => {
        this.setState({
            disabledBtn:true,
            text_save:'Saving...'
        })
        let formData = new FormData()
        formData.append('code_employee', this.state.code_employee)
        formData.append('schedule_date', this.state.schedule_date)
        axios.post(API_URL.superStudent+'/counseling', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                disabledBtn:false,
                text_save:'Save',
                modalJadwal:false
            })
        })
    }

    handleCounseling = (id, status) => {
        let formData = new FormData()
        formData.append('status', status)
        formData.append('_method','PATCH')
        axios({
            method:'POST',
            url: API_URL.superStudent+'/counseling/'+id,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}`, }
        })
        .then(() => {
            this.loadDataCounseling()
        })
        .catch(error => console.log(error))

    }
    
    renderModalJadwal(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalJadwal} toggle={() => this.setState({ modalJadwal: false })}>
                <ModalHeader toggle={() => this.setState({ modalJadwal: false })}>Buat Jadwal</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Tanggal Conseling</label>
                                <input className='form-control' type='date' name='schedule_date' value={this.state.schedule_date} onChange={this.handleInput} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Nama Karyawan</label>
                                <select className='form-control' name='code_employee' value={this.state.start_date} onChange={this.handleInput}>
                                    <option value='' >--Pilih--</option>
                                    {
                                        this.state.dataEmployee.length > 0 ?
                                        this.state.dataEmployee.map(item => (
                                            <option key={item.code} value={item.code}>{item.name}</option>
                                        ))
                                        :
                                        ''
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.disabledBtn} color="primary" onClick={(e) => this.saveData()}>{this.state.text_save}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modalJadwal: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    SetFilterStatus = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterStatus = newData; 
          return { filterStatus };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"status");
          })
    }

    SetFilterEmployee = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterEmploye = newData; 
          return { filterEmploye };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"code_employee");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
  }

    render(){
        const columns = [
            {
                Header: 'Jadwal',
                accessor: 'schedule_date',
                style:{textAlign:'center'},
                Cell:(row) => (
                    <span>{Moment(row.original.schedule_date).format('DD MMM YY')}</span>
                )
            },
            {
                Header: 'Nama',
                accessor: 'code_employee',
                style:{textAlign:'center'},
                filterable: true,
                Cell:(row) => (
                    <span>{row.original.employee.nama}</span>
                ),
                Filter: () => 
                <select value={this.state.filterEmploye || ''} onChange={(e) => this.SetFilterEmployee(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataEmployee.map( item => {
                            return <option key={item.code} value={item.code}>{item.name}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: <b>Status</b>,
                accessor: 'status',
                Cell: (row) => (
                    row.original.status === 1 ? <span className='badge bg-success'>Done</span> : row.original.status === 0 ? <span className='badge bg-danger'>Cancel</span> : <span className='badge bg-primary'>Scheduled</span>
                ),
                style: { textAlign: 'center' },
                filterable: true,
                Filter: () => 
                <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                    <option key={0} value=''>Show All</option>
                    <option key={1} value={1}>Done </option>
                    <option key={2} value={0}>Cancel</option>
                    <option key={5} value='null'>Scheduled</option>
                </select>,
            },
            {
                Header: 'Terlaksana',
                accessor: 'schedule_date',
                style:{textAlign:'center'},
                Cell:(row) => (
                    row.original.finish_date === null ?
                    ''
                    :
                    <span>{Moment(row.original.finish_date).format('DD MMM YY')}</span>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                style: {textAlign: 'center'},
                sortable: false,
                Cell:(row) => (
                    row.original.status === null && this.props.dataUser.code_roles === 'RL004' ?
                    <div>
                        <Button onClick={() => this.handleCounseling(row.original.id,1)} className='btn btn-info btn-xs'><i className='fa fa-check'></i></Button> &nbsp;
                        <Button onClick={() => this.handleCounseling(row.original.id,0)} className='btn btn-dark btn-xs' ><i className='fa fa-remove'></i></Button>
                    </div>
                    :
                    ''
                )
            }
        ]
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    {
                                        this.props.dataUser.code_roles === 'RL004' ?
                                        <button className='btn btn-success' onClick={this.createJadwal}>Buat Jadwal</button>
                                        :
                                        ''
                                    }
                                   
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.dataConseling}
                                        columns={columns}   
                                        filtered={this.state.filtered}
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalJadwal()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser:state.Login.detailUser,
})


export default connect(reduxState, null)(ConselingList)