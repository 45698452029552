/* eslint-disable eqeqeq */

import React, { Component } from "react";
import Moment from 'moment';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import API_URL from "../../../ApiUrl";

class EnglishCourseAttandance extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataAttandace: [],
            dataJ1: [],
            selectedData: [],
            modalStudent: false,
            tgl: '',
            category: '',
            btnDisabled: false,
            textSubmit: 'Submit'
        }
    }

    componentDidMount() {
        this.loadDataTableAttandance()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setHandleStudent = (e) => {
        this.setState({
            selectedData: e
        })
    }

    showModalDialog = async () => {
        await this.loadListJ1()
        this.setState({
            category:'',
            tgl:'',
            selectedData:[],
            modalStudent: true
        })
    }

    closeModal = () => {
        this.setState({
            modalStudent: false
        })
    }

    loadDataTableAttandance = () => {
        axios.get(API_URL.superStudent + '/english-course/list-attandance-j1', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    dataAttandace: response.data.data
                })
            })
    }

    loadListJ1 = async () => {
        await axios.get(API_URL.superStudent + '/english-course/list-student-j1', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    dataJ1: response.data.data
                })
            })
    }

    handleSubmit = () => {
        let kode_registers = []
        this.state.selectedData.map(item => (
            kode_registers.push(item.kode_register)
        ))
        var formData = new FormData();
        formData.append('tanggal', this.state.tgl);
        formData.append('category', this.state.category);
        formData.append('kode_registers', JSON.stringify(kode_registers))
        axios.post(API_URL.superStudent + '/english-course/submit-attandance', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.closeModal()
                this.loadDataTableAttandance()
            })
    }

    deleteData(id){
        console.log(id)
        axios.delete(API_URL.superStudent + '/english-course/delete-attandance/' + id, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.loadDataTableAttandance()
            })
    }

    handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            type:'question',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result) {
                this.deleteData(id)
            }
        });
    };

    handleFinish = (kode_register) => {
        Swal.fire({
            title: 'Are you sure?',
            type:'question',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, finish it!'
        }).then((result) => {
            if(result){
                axios.get(API_URL.superStudent+'/english-course/finish-attandance/'+kode_register,{
                    headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
                })
                .then(() => {
                    this.loadDataTableAttandance()
                })
            }
        })
    }

    renderDialog() {
        const { modalStudent } = this.state;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modalStudent} toggle={() => this.setState({ modalStudent: false })}>
                    <ModalHeader toggle={() => this.setState({ modalStudent: false })}>Create Schedule</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-group">
                                <label htmlFor="name">Category</label>
                                <select value={this.state.category} className='form-control' name='category' onChange={this.handleInput}>
                                    <option value=''>-Select Category</option>
                                    <option value='dh'>Deep Hire</option>
                                    <option value='visa'>Visa</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Date</label>
                                <input type="date" className="form-control" onChange={this.handleInput} name="tgl" value={this.state.tgl} />

                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Student</label>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="nama_lengkap"
                                    multiple
                                    selected={this.state.selectedData}
                                    options={this.state.dataJ1}
                                    onChange={this.setHandleStudent}
                                    placeholder="can choose several data."
                                />
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.handleSubmit()}>{this.state.textSubmit}</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <button onClick={() => this.showModalDialog()} className='btn btn-success'>Create Schedule</button>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <div className='form-group'>
                                                <label className='f-w-600'>Deep Hire</label>
                                                <input disabled type='color' className='form-control' value='#1EA6EC' />
                                            </div>
                                        </div>
                                        <div className='col-sm'>
                                            <div className='form-group'>
                                                <label className='f-w-600'>Visa</label>
                                                <input disabled type='color' className='form-control' value='#0BA22B' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="text-center bg-primary">
                                                <tr>
                                                    <th className="text-white" style={{ verticalAlign: 'middle' }} rowSpan={2}>Nama</th>
                                                    <th className="text-white" colSpan={10}>Schedule</th>
                                                </tr>
                                                <tr>
                                                    <th className="text-white">1</th>
                                                    <th className="text-white">2</th>
                                                    <th className="text-white">3</th>
                                                    <th className="text-white">4</th>
                                                    <th className="text-white">5</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataAttandace.length > 0 ?
                                                        this.state.dataAttandace.map((item, index) => {
                                                            return <tr key={index}>
                                                                <th>{item.attandance_j1.length >= 4 ? <i onClick={() => this.handleFinish(item.kode_register)} className="fa fa-remove text-danger"></i> : ''} {item.nama_lengkap}</th>
                                                                {
                                                                    item.attandance_j1.map((item, i) => {
                                                                        return <td className={`text-center ${item.category == 'dh' ? 'bg-secondary' : 'bg-success'}`} key={'in' + i}>{Moment(item.tgl_kehadiran).format('DD/MM/YYYY')} <i onClick={() => this.handleDelete(item.id)} className="fa fa-trash"></i> </td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td>Empty Data</td>
                                                        </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </>
        )
    }
}


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(EnglishCourseAttandance);