import React, { Component, Fragment } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'moment';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DataIndikatorKPI extends Component {

    constructor(props){
        super(props)
        this.state = {
            IndikatorKPI:[],
            masterPersonal:[],
            userList:[],
            filtered:[],
            jobdesk: [],
            timeType:[
                {
                    'id' : '1',
                    'name' : 'Weekly'
                },
                {
                    'id' : '2',
                    'name' : 'Monthly'
                },
                {
                    'id' : '3',
                    'name' : 'Quarterly'
                },
                {
                    'id' : '4',
                    'name' : 'Yearly'
                },

            ],
            jobdeskType:'JD002',
            indikatorId:'24',
            filterTime:'',
            filterEmployee:'',
            id_target:'',
            bobot:'',
            target_value:'',
            modalEdit:false,
            modalRevisi:false,
            time:2,
            buttonDisaled:false,
            buttonText:'Update',
            filterStartDate:Moment().startOf('month'),
            filterEndDate:Moment().endOf('month'),
        }
    }

    componentDidMount(){
        this.loadIndikatorKPI()
        this.loadUserList()
        this.loadMasterPernosalKPI()
        this.loadJobdesk()
    }

    handleJobDesk = (e) => {
        this.setState({
            jobdeskType: e.target.value,
            indikatorId:'0'
        },() => {
            this.loadIndikatorKPI()
            this.loadUserList()
            this.loadMasterPernosalKPI()
        })
    }

    handleIndikator = (e) => {
        this.setState({
            indikatorId:e.target.value
        }, () => this.loadMasterPernosalKPI())
    }

    loadJobdesk = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({jobdesk:response.data.data}))
        .catch(error => console.log(error))
    }

    loadIndikatorKPI = () => {
        let formData = new FormData()
        formData.append('code_jobdesk', this.state.jobdeskType)
        axios.post(API_URL.superStudent+'/kpi/list-indikator',formData,{
            headers:{Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => this.setState({ IndikatorKPI: result }))
        .catch(error => console.log(error))
    }

    loadMasterPernosalKPI = () => {
        let formData = new FormData()
        formData.append('type_target', this.state.indikatorId);
        formData.append('jobdesk', this.state.jobdeskType )
        axios.post(API_URL.superStudent+'/kpi/list-personal', formData,{
            headers:{Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ masterPersonal: response.data.data}))
        .catch(error => console.log(error))
    }

    loadUserList = () => {
        let formData = new FormData()
        formData.append('jobdesk',JSON.stringify([this.state.jobdeskType]))
        axios.post(API_URL.superStudent+'/consultant/jobdesk', formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({userList: response.data.data}))
        .catch(error => console.log(error))
    }

    saveUpdate = () => {
        let formData = new FormData()
        formData.append('id', this.state.id_target)
        formData.append('bobot', this.state.bobot)
        formData.append('total_target', this.state.target_value)
        axios.post(API_URL.superStudent+'/kpi/update-target',formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                modalEdit:false
            })
            this.loadMasterPernosalKPI()
            toast.success('data updated successfully')
        })
        .catch(error => {
            this.setState({
                modalEdit:false
            })
            toast.error('there is an error')
        })
    }

    updateDataKPI = () => {
        this.setState({
            buttonDisaled:true,
            buttonText:'Updating...'
        })
        let formData = new FormData ();
        formData.append('start_date', this.state.filterStartDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndDate.format('YYYY-MM-DD'))
        formData.append('time_type', this.state.time)
        formData.append('reference_type', 1)
        axios.post(API_URL.superStudent+'/kpi/recount',formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                 modalRevisi:false,
                 buttonDisaled:false,
                 buttonText:'Update' 
                })
                toast.success('data updated successfully')
        })
        .catch(error => {
            toast.error('there is an error')
        })

    }

    SetFilterTime = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterTime = newData; 
          return { filterTime };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"time_type");
          })
    }

    SetFilterEmployeer = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
          let filterEmployee = newData; 
          return { filterEmployee };                   
          }, () => {
            this.onFilteredChangeCustom(newData,"reference_id");
          })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ filtered: filtered });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('month'),
            filterEndDate: Moment(e).endOf('month'),
            time:2
        })
    }

    showModalRevisi = () => {
        this.setState({
            modalRevisi:true
        })
    }

    handleEditBobot = (row) => {
        this.setState({
            modalEdit:true,
            id_target:row.id,
            bobot:row.bobot,
            target_value:row.total_target,
        })
    }

    renderModalRevisi(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalRevisi} toggle={() => this.setState({ modalRevisi: false })}>
                <ModalHeader toggle={() => this.setState({ modalRevisi: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Periode diubah</label>
                                <DatePicker dateFormat='MMMM yyyy' selected={this.state.filterStartDate._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.buttonDisaled} color="primary" onClick={(e) => this.updateDataKPI()}>{this.state.buttonText}</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modalRevisi: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderModalEdit(){
        return(
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalEdit} toggle={() => this.setState({ modalEdit: false })}>
                <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label className='control-label'>Bobot</label>
                                <input type='text' className='form-control' onChange={this.handleInput} name='bobot' value={this.state.bobot} />
                            </div>
                            <div className='form-group'>
                                <label className='control-label'>Target</label>
                                <input type='text' className='form-control' onChange={this.handleInput} name='target_value' value={this.state.target_value} />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={(e) => this.saveUpdate()}>Update</Button>
                  <Button  color="secondary" onClick={() => this.setState({ modalEdit: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
        )
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    render(){
        const columns = [
            {
                Header: 'Name',
                accessor: 'reference_id',
                style: {textAlign: 'center'},
                filterable: true,
                Cell:(row) => (
                    <span>{row.original.consultant.nama}</span>
                ),
                Filter: () => 
                <select value={this.state.filterEmployee || ''} onChange={(e) => this.SetFilterEmployeer(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.userList.map( item => {
                            return <option key={item.id_konsultan} value={item.code_konsultan}>{item.nama}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Time',
                accessor: 'time_type',
                style: {textAlign: 'center'},
                filterable: true,
                Cell:(row) => (
                    <>{row.original.time_type === 1 ? 'Weekly' : row.original.time_type === 2 ? 'Monthly' : row.original.time_type === 3 ? 'Quarterly' : 'Yearly'}</>
                ),
                Filter: () => 
                <select value={this.state.filterTime || ''} onChange={(e) => this.SetFilterTime(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.timeType.map( item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Bobot',
                accessor: 'bobot',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Target',
                accessor: 'total_target',
                Cell:(row) => (
                    row.original.type_target === 79 || row.original.type_target === 78 || row.original.type_target === 67 || row.original.type_target === 60 || row.original.type_target === 5 || row.original.type_target === 25 ?
                    <span> {this.formatRupiah(row.original.total_target)}</span> :<span>{row.original.total_target}</span>
                ),
                style: {textAlign: 'center'},
            }
        ]

        if (this.props.dataUser.code_roles === 'RL001' || this.props.dataUser.code_roles === 'RL026') {
            columns.push(
                {
                    Header: 'Action',
                    id: 'delete',
                    accessor: str => "delete",
                    style: {textAlign: 'center'},
                    Cell:(row) => (
                        <button onClick={() => this.handleEditBobot(row.original)}  style={{border:'none'}} className="btn btn-outline-primary btn-xs"><i className='fa fa-edit' style={{fontSize:16}}></i></button>
                    )
                }
            )
        } 
        return (
            <Fragment>
                <div className="page-wrapper">
                    {/* <!-- Page Body Start--> */}
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <select name='dateType' value={this.state.jobdeskType} onChange={this.handleJobDesk} className='form-control'>
                                                {
                                                this.state.jobdesk.map(job => (
                                                    <option key={job.code_jobdesk} value={job.code_jobdesk}>{job.jobdesk}</option>
                                                ))
                                                }
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <select name='indikatorId' value={this.state.indikatorId} onChange={this.handleIndikator} className='form-control'>
                                                    <option key={0} value='0'>--Select--</option>
                                                {
                                                this.state.IndikatorKPI.map(indikator => (
                                                    <option key={indikator.id} value={indikator.id}>{indikator.name}</option>
                                                ))
                                                }
                                                </select>
                                            </div>
                                            {
                                            this.props.dataUser.code_roles === 'RL001' || this.props.dataUser.code_roles === 'RL003' ?
                                            <div className='col-md-3'>
                                            <button onClick={this.showModalRevisi} className='btn btn-success'>Hitung Ulang KPI</button> 
                                        </div> :
                                            <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <ReactTable
                                            data={this.state.masterPersonal}
                                            filtered={this.state.filtered}
                                            columns={columns}   
                                            defaultPageSize={16}
                                            className="-striped -highlight"
                                            onFilteredChange={(filtered, column, value) => {
                                                this.onFilteredChangeCustom(value, column.id || column.accessor);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalEdit()}
                {this.renderModalRevisi()}
                <ToastContainer />
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
  })

export default connect(reduxState, null)(DataIndikatorKPI);