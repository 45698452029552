import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import { ListGroup, ListGroupItem } from 'reactstrap';

export class DataTableReportTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filtered:[],
            filterDate: '',
            persentase: '',
            month: Moment().format('MM'),
        }
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
  
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
              insertNewFilter = 0;
            }
          });
        }
  
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    }


  render() {
    const {myData} = this.props
    const columns = [
        {
          Header: 'Name',
          accessor: 'nama_lengkap',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
          filterAll: true
        },
        {
          Header: 'Department',
          accessor: 'jobdesk.jobdesk',
          width: 200,
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["jobdesk.jobdesk"] }),
            filterAll: true          
        },
        {
          Header: 'Total Training Attended',
          accessor: 'Training Attended',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["training_attended"] }),
          filterAll: true,
          Cell: (row) => {
            const {training, jobdesk} = row.original
            let c = training.filter((k) => k.is_training === true)
            return (<><p>{c.length}/{jobdesk.sub_kategori_count}</p></>)
          }
        },
        {
          Header: 'Action',
          accessor: 'action',
          width: 200,
          style: {textAlign: 'center'},
          Cell: (row) => {
            return (<button style={{border:'none', fontSize: 15 }} onClick={() => this.props.openDetailReport(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className="fa fa-eye"></i></button> )
          }
        },
      ]
    return (
        <Fragment>
            <ReactTable
            data={myData}
            filtered={this.state.filtered}
            columns={columns}
            pageSize={15}
            filterable
            showPagination={true}
            onFilteredChange={(filtered, column, value) => {
                this.onFilteredChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            SubComponent={row => {
                const {training} = row.original
                let filter = training.filter((t)=> t.is_training === true)
                return ( 
                    <>
                    { 
                    (filter.length < 1)? (<h5 className='p-5'>No training has been attended</h5>) :
                        <ListGroup>{
                            row.original.training?.map((m) => {
                                return m.training.length < 1 ? '' :
                                m.training.map((t) => {
                                    return (<ListGroupItem>{t.training_name}</ListGroupItem>)
                                }
                                )
                            })
                        }</ListGroup>
                    }
                    </>
                )
            }}
            />
      </Fragment>
    )
  }
}

export default DataTableReportTraining