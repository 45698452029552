/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import DatePicker from "react-datepicker";

class FeedbackSchoolActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                id_school_activities: "",
                code_feedback  : "",
                code_school    : "",
                code_activities: "",
                start_date     : "",
                end_date       : "",
                zona_waktu     : "",
                start_time     : "",
                end_time       : "",
                total_kelas    : "",
                total_siswa    : "",
                tipe_activities: "",
                judul          : "",
                sub_judul      : "",
                link_zoom      : "",
            },
            image: "",
            filename: "",
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listFeedback: [],
            listActivities: []
        }
    }

    componentDidMount() {
        this.getFeedback();
        this.getActivities();
    }

    getFeedback(){
        const url = API_URL.superStudent+'/school-activities/feedback/'+this.props.match.params.codeSchool;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({listFeedback:data.data});
        });
    }

    getActivities(){
        const url = API_URL.superStudent+'/school-activities/activities/'+this.props.match.params.codeSchool;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({listActivities:data.data});
        });
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            id_school_activities: "",
            code_feedback       : "",
            code_school         : "",
            code_activities     : "",
            start_date          : "",
            end_date            : "",
            zona_waktu          : "",
            start_time          : "",
            end_time            : "",
            total_kelas         : "",
            total_siswa         : "",
            tipe_activities     : "",
            judul               : "",
            sub_judul           : "",
            link_zoom           : "",
        },
        errors: {},
        });
    }

    // handle on change   
    onChangesetActivitiesName = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.code_activities = newData; 
            return { model };                   
        }, () => {
            this.validateActivitiesName();
        })
    }
    onChangesetStartDate = date => {
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.start_date = Moment(date).format('YYYY-MM-DD');
            return { model };                   
        }, () => {
            this.validateStartDate();
        })
    }
    onChangesetEndDate = date => {
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.end_date = Moment(date).format('YYYY-MM-DD');; 
            return { model };                   
        }, () => {
            this.validateEndDate();
        })
    }
    onChangesetStartTime = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.start_time = newData; 
            return { model };                   
        }, () => {
            this.validateStartTime();
        })
    }
    onChangesetEndTime = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.end_time = newData; 
            return { model };                   
        }, () => {
            this.validateEndTime();
        })
    }
    onChangesetTimeZone = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.zona_waktu = newData; 
        return { model };                   
        }, () => {
            this.validateTimeZone();
        })
    }
    onChangesetTotalKelas = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.total_kelas = newData; 
            return { model };                   
        }, () => {
            this.validateTotalKelas();
        })
    }
    onChangesetTotalSiswa = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.total_siswa = newData; 
            return { model };                   
        }, () => {
            this.validateTotalSiswa();
        })
    }
    onChangesetTipeActivities = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.tipe_activities = newData; 
            return { model };                   
        }, () => {
            this.validateTipeActivities();
        })
    }
    onChangesetJudul = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.judul = newData; 
            return { model };                   
        }, () => {
            this.validateJudul();
        })
    }
    onChangesetSubJudul = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.sub_judul = newData; 
            return { model };                   
        }, () => {
            this.validateSubJudul();
        })
    }
    onChangesetLinkZoom = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
            model.link_zoom = newData; 
            return { model };                   
        })
    }
    onChangeFileName = (e) => { 
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        
        if(this.state.errors.code_activities != ""){
            formIsValid = false;
        }
        if(this.state.errors.start_date != ""){
            formIsValid = false;
        }
        if(this.state.errors.end_date != ""){
            formIsValid = false;
        } 
        if(this.state.errors.start_time != ""){
            formIsValid = false;
        }
        if(this.state.errors.end_time != ""){
            formIsValid = false;
        }
        if(this.state.errors.zona_waktu != ""){
            formIsValid = false;
        }
        if(this.state.errors.total_kelas != ""){
            formIsValid = false;
        } 
        if(this.state.errors.total_siswa != ""){
            formIsValid = false;
        } 
        if(this.state.errors.tipe_activities != ""){
            formIsValid = false;
        }
        if(this.state.errors.judul != ""){
            formIsValid = false;
        }
        if(this.state.errors.sub_judul != ""){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateActivitiesName = () => {
        const data = this.state.model;
        if(data.code_activities === "" || data.code_activities === 0 || data.code_activities === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_activities = 'Activities Name Must Entered'; 
                errors.code_activitiesStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_activities = '';    
                errors.code_activitiesStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateStartDate = () => {
        const data = this.state.model;
        if(data.start_date === "" || data.start_date === 0 || data.start_date === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.start_date = 'Start Date Must Entered'; 
                errors.start_dateStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.start_date = '';    
                errors.start_dateStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateEndDate = () => {
        const data = this.state.model;
        if(data.end_date === "" || data.end_date === 0 || data.end_date === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.end_date = 'Tipe Pembayaran Harus Diisi'; 
                errors.end_dateStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.end_date = '';    
                errors.end_dateStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateStartTime = () => {
        const data = this.state.model;
        if(data.start_time === "" || data.start_time === 0 || data.start_time === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.start_time = 'Start Time Must Entered'; 
                errors.start_timeStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.start_time = '';    
                errors.start_timeStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateEndTime = () => {
        const data = this.state.model;
        if(data.end_time === "" || data.end_time === 0 || data.end_time === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.end_time = 'End Time Must Entered'; 
                errors.end_timeStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.end_time = '';    
                errors.end_timeStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateTimeZone = () => {
        const data = this.state.model;
        if(data.zona_waktu === "" || data.zona_waktu === 0 || data.zona_waktu === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.zona_waktu = 'Time Zone Must Entered'; 
                errors.zona_waktuStatus = false;                                 
                return { errors };
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.zona_waktu = '';    
                errors.zona_waktuStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateTotalKelas = () => {
        const data = this.state.model;
        if(data.total_kelas === "" || data.total_kelas === 0 || data.total_kelas === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.total_kelas = 'Total Kelas Must Entered'; 
                errors.total_kelasStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.total_kelas = '';    
                errors.total_kelasStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateTotalSiswa = () => {
        const data = this.state.model;
        if(data.total_siswa === "" || data.total_siswa === 0 || data.total_siswa === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.total_siswa = 'Total Siswa Must Entered'; 
                errors.total_siswaStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.total_siswa = '';    
                errors.total_siswaStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateTipeActivities = () => {
        const data = this.state.model;
        if(data.tipe_activities === "" || data.tipe_activities === 0 || data.tipe_activities === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_activities = 'Total Activities Must Entered'; 
                errors.tipe_activitiesStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_activities = '';    
                errors.tipe_activitiesStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateJudul = () => {
        const data = this.state.model;
        if(data.judul === "" || data.judul === 0 || data.judul === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.judul = 'Judul Must Entered'; 
                errors.judulStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.judul = '';    
                errors.judulStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateSubJudul = () => {
        const data = this.state.model;
        if(data.sub_judul === "" || data.sub_judul === 0 || data.sub_judul === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.sub_judul = 'Sub Judul Must Entered'; 
                errors.sub_judulStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.sub_judul = '';    
                errors.sub_judulStatus = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field


    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Feedback School',
            operation: 'add',
            modal: true,
            logName: 'ADD-FEEDBACK-SCHOOL'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Feedback School',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-FEEDBACK-SCHOOL'
        });
    }

    deleteRow(row) {
        this.setState({
            deletingRow: row,
            model: Object.assign({}, row),
            title: 'Delete Feedback School',
            operation: 'delete',
            modalDelete: true,
            logName: 'DELETE-FEEDBACK-SCHOOL'
        });
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateActivitiesName();
            this.validateStartDate();
            this.validateEndDate();
            this.validateStartTime();
            this.validateEndTime();
            this.validateTimeZone();
            this.validateTotalKelas();
            this.validateTotalSiswa();
            this.validateTipeActivities();
            this.validateJudul();
            this.validateSubJudul();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('code_feedback', data[0].code_feedback);
        formData.append('code_school', this.props.match.params.codeSchool);
        formData.append('code_activities', data[0].code_activities);
        formData.append('start_date', data[0].start_date);
        formData.append('end_date', data[0].end_date);
        formData.append('start_time', data[0].start_time);
        formData.append('end_time', data[0].end_time);
        formData.append('zona_waktu', data[0].zona_waktu);
        formData.append('total_kelas', data[0].total_kelas);
        formData.append('total_siswa', data[0].total_siswa);
        formData.append('tipe_activities', data[0].tipe_activities);
        formData.append('judul', data[0].judul);
        formData.append('sub_judul', data[0].sub_judul);
        formData.append('link_zoom', data[0].link_zoom);

        let url = '/school-activities/feedback';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/school-activities/feedback/'+data[0].code_feedback
        } else if (this.state.operation == 'delete') {
            url = '/school-activities/feedback/delete'
        }

        axios.post(API_URL.superStudent+url, formData, { 
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            if(reply.data.code === 200){
                toast.update(this.state.toastId, {
                    render: "Input Success !",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                    className: 'rotateY animated'
                })
                this.closeDialog();
                this.getFeedback();
                this.setState({ btnDisabled: false });
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="code_activities">Activities Name</label>
                                <select className="form-control" name="code_activities" type="text" id="code_activities"  value={row.code_activities}
                                    onChange={(e) => this.onChangesetActivitiesName(e)}
                                    onBlur={(e) => this.validateActivitiesName}
                                    ref={e => this.inputActivitiesName = e}
                                >
                                    <option value="0">--Pilih Activities--</option>
                                    {this.state.listActivities.map((activities)=>(
                                        <option key={activities.code_activities} value={activities.code_activities}>{activities.name_activities}</option>
                                    ))}
                                </select>
                                <span className="text-danger">{this.state.errors.code_activities}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="start_date">Start Date</label>
                                <DatePicker className="form-control" name="start_date" type="text" id="start_date"  
                                    value={row.start_date}
                                    dateFormat="yyyy-MM-dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={this.onChangesetStartDate}
                                    onBlur={(e) => this.validateStartDate()}
                                    ref={e => this.inputStartDate = e}
                                />
                                <span className="text-danger">{this.state.errors.start_date}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="end_date">End Date</label>
                                <DatePicker className="form-control" name="end_date" type="text" id="end_date"  
                                    value={row.end_date}
                                    dateFormat="yyyy-MM-dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={this.onChangesetEndDate}
                                    onBlur={(e) => this.validateEndDate()}
                                    ref={e => this.inputStartDate = e}
                                />
                                <span className='text-danger'>{this.state.errors.end_date}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="start_time">Start Time </label>
                                <input className="form-control" name="start_time" type="time" id="start_time"  value={row.start_time}
                                    onChange={(e) => this.onChangesetStartTime(e)}
                                    onBlur={(e) => this.validateStartTime}
                                    ref={e => this.inputStartTime = e}
                                />
                                <span className='text-danger'>{this.state.errors.start_time}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="end_time">End Time </label>
                                <input className="form-control" name="end_time" type="time" id="end_time"  value={row.end_time}
                                    onChange={(e) => this.onChangesetEndTime(e)}
                                    onBlur={(e) => this.validateEndTime}
                                    ref={e => this.inputEndTime = e}
                                />
                                <span className='text-danger'>{this.state.errors.end_time}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="zona_waktu">Time Zone</label>
                                <select className="form-control" name="zona_waktu" type="text" id="zona_waktu"  value={row.zona_waktu}
                                    onChange={(e) => this.onChangesetTimeZone(e)}
                                    onBlur={(e) => this.validateTimeZone}
                                    ref={e => this.inputTimeZone = e}
                                >
                                    <option value="0">--Pilih Time Zone--</option>
                                    <option value="WIB">WIB</option>
                                    <option value="WITA">WITA</option>
                                    <option value="WIT">WIT</option>
                                </select>
                                <span className='text-danger'>{this.state.errors.zona_waktu}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="total_kelas">Total Kelas </label>
                                <input className="form-control" name="total_kelas" type="number" id="total_kelas"  value={row.total_kelas}
                                    onChange={(e) => this.onChangesetTotalKelas(e)}
                                    onBlur={(e) => this.validateTotalKelas}
                                    ref={e => this.inputTotalKelas = e}
                                />
                                <span className='text-danger'>{this.state.errors.total_kelas}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="total_siswa">Total Siswa </label>
                                <input className="form-control" name="total_siswa" type="number" id="total_siswa"  value={row.total_siswa}
                                    onChange={(e) => this.onChangesetTotalSiswa(e)}
                                    onBlur={(e) => this.validateTotalSiswa}
                                    ref={e => this.inputTotalSiswa = e}
                                />
                                <span className='text-danger'>{this.state.errors.total_siswa}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="tipe_activities">Tipe Activities</label>
                                <select className="form-control" name="tipe_activities" type="text" id="tipe_activities"  value={row.tipe_activities}
                                    onChange={(e) => this.onChangesetTipeActivities(e)}
                                    onBlur={(e) => this.validateTipeActivities}
                                    ref={e => this.inputTipeActivities = e}
                                >
                                    <option value="">--Pilih Activities--</option>
                                    <option value="0">OFFLINE</option>
                                    <option value="1">ONLINE</option>
                                </select>
                                <span className='text-danger'>{this.state.errors.tipe_activities}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="judul">Judul </label>
                                <input className="form-control" name="judul" type="text" id="judul"  value={row.judul}
                                    onChange={(e) => this.onChangesetJudul(e)}
                                    onBlur={(e) => this.validateJudul}
                                    ref={e => this.inputJudul = e}
                                />
                                <span className='text-danger'>{this.state.errors.judul}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="sub_judul">Sub Judul </label>
                                <input className="form-control" name="sub_judul" type="text" id="sub_judul"  value={row.sub_judul}
                                    onChange={(e) => this.onChangesetSubJudul(e)}
                                    onBlur={(e) => this.validateSubJudul}
                                    ref={e => this.inputSubJudul = e}
                                />
                                <span className='text-danger'>{this.state.errors.sub_judul}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="link_zoom">Link Zoom </label>
                                <input className="form-control" name="link_zoom" type="text" id="link_zoom"  value={row.link_zoom}
                                    onChange={(e) => this.onChangesetLinkZoom(e)}
                                    ref={e => this.inputLinkZoom = e}
                                />
                                <span className='text-danger'>{this.state.errors.link_zoom}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
                Header: 'Activities Name',
                accessor: 'activities.name_activities',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["activities.name_activities"] }),
                  filterAll: true
            },
            {
                Header: 'Tipe Activities',
                accessor: 'tipe_activities',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["tipe_activities"] }),
                  filterAll: true,
                Cell: (row) => (
                    <div>
                        {row.tipe_activities == 0 ? 'OFFLINE' : 'ONLINE'}
                    </div>
                )
            },
            {
                Header: 'Judul',
                accessor: 'judul',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["judul"] }),
                  filterAll: true
            },
            {
                Header: 'Sub Judul',
                accessor: 'sub_judul',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["sub_judul"] }),
                  filterAll: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className="col-md-6">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}}><i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i></button>
                                    </div>
                                    <ReactTable 
                                        data={this.state.listFeedback}
                                        columns={columns}
                                        pageSize={pageSize}
                                        showPagination={false}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        style={{
                                            height: "700px"
                                        }}
                                        SubComponent={row => {
                                            return(
                                                <div style={{ padding: "20px" }}>
                                                    <div style={{paddingLeft:"10px", fontStyle:"bold"}}>
                                                        <table>
                                                            <tr>
                                                                <td>Start Date</td>
                                                                <td>:</td>
                                                                <td>{Moment(row.original.start_date).format('DD MMMM YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>End Date</td>
                                                                <td>:</td>
                                                                <td>{Moment(row.original.end_date).format('DD MMMM YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Start Time</td>
                                                                <td>:</td>
                                                                <td>{row.original.start_time}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>End Time</td>
                                                                <td>:</td>
                                                                <td>{row.original.end_time}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Time Zone</td>
                                                                <td>:</td>
                                                                <td>{row.original.zona_waktu}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Kelas</td>
                                                                <td>:</td>
                                                                <td>{row.original.total_kelas}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total Siswa</td>
                                                                <td>:</td>
                                                                <td>{row.original.total_siswa}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                    <ToastContainer />
                                    {this.renderDialog()}
                                    {this.renderDeleteDialog()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(FeedbackSchoolActivities);