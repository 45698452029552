import React, { Fragment } from 'react';
import DataTablesTipePembayaran from '../../components/tables/dataTablesTipePembayaran';


const Currency = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <DataTablesTipePembayaran />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Currency;