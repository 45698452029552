/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';

class DatatableTipePembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                id_tipe_pembayaran:"",
                tipe_pembayaran: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listCountry: [],
            listMataUang: []
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    componentDidMount(){
        this.setState({
            data: this.props.myData
        });
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            id_tipe_pembayaran:"",
            tipe_pembayaran:"",
        },
        errors: {},
        });
    }

    // handle on change   
    onChangesetTipePembayaran = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.tipe_pembayaran = newData; 
        return { model };                   
        }, () => {
        this.validateTipePembayaran();
        })
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        //nama document
        if(!this.state.errors.tipe_pembayaranStatus){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateTipePembayaran = () => {
        const data = this.state.model;
        // if(data.code_leads_source.length < 3){
            if(data.tipe_pembayaran === "" || data.tipe_pembayaran === 0 || data.tipe_pembayaran === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_pembayaran = 'Tipe Pembayaran Harus Diisi'; 
                errors.tipe_pembayaranStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.tipe_pembayaran = '';    
                errors.tipe_pembayaranStatus = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field


    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Tipe Pembayaran',
            operation: 'add',
            modal: true,
            logName: 'ADD-TIPE-PEMBAYARAN'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Tipe Pembayaran',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-TIPE-PEMBAYARAN'
        });
    }

    deleteRow(row) {
        this.setState({
          deletingRow: row,
          model: Object.assign({}, row),
          title: 'Delete Tipe Pembayaran',
          operation: 'delete',
          modalDelete: true,
          logName: 'DELETE-TIPE-PEMBAYARAN'
        });
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateTipePembayaran();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('id_tipe_pembayaran',data[0].id_tipe_pembayaran);
        formData.append('tipe_pembayaran',data[0].tipe_pembayaran);

        let url = '/finance/master-tipe';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/finance/master-tipe/'+data[0].id_tipe_pembayaran
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/finance/master-tipe/'+data[0].id_tipe_pembayaran
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            if(reply.status === 200){
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                } 
                window.location.reload(false);
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="name">Tipe Pembayaran {row.tipe_pembayaran}</label>
                                <input className="form-control" name="tipe_pembayaran" type="text" id="tipe_pembayaran"  value={row.tipe_pembayaran}
                                    onChange={(e) => this.onChangesetTipePembayaran(e)}
                                    onBlur={(e) => this.validateTipePembayaran}
                                    ref={e => this.inputTipePembayaran = e}
                                />
                                <span>{this.state.errors.tipe_pembayaran}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
              Header: 'Tipe Pembayaran',
              accessor: 'tipe_pembayaran',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["tipe_pembayaran"] }),
                filterAll: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div>
                  <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                      </span>
                    </div>
                  </button>
                </div>
                <ReactTable 
                    data={myData}
                    columns={columns}
                    pageSize={pageSize}
                    showPagination={false}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    style={{
                        height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    SubComponent={row => {
                        const data = row.original;
                        return(
                            <div style={{ padding: "20px" }}>
                                <div style={{paddingLeft:"10px", fontStyle:"italic", color:"#A6A6A6"}}>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Tipe Pembayaran</div>
                                        <div className="col-md-9" align="left">{data.tipe_pembayaran}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                />
                <ToastContainer />
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(DatatableTipePembayaran);