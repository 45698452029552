import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { List, Home, FileText } from 'react-feather';
 
const TaskList = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- Page Body Start--> */}
                <div className="container-fluid p-0">
                    <Breadcrumb title="Task List" parent="Social Media" />
                    <div className="container-fluid">
                        <div className="row theme-tab">
                            <div className="col-sm-12">
                                <RoutedTabs>
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/task-list/home`} >
                                            <Home/> Home
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/task-list/list-data`}>
                                            <List/> List Data
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`}>
                                            <FileText/> Meeting Note
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/task-list`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/task-list/home`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list/home`}/>
                                    <Route path={`${process.env.PUBLIC_URL}/pages/task-list/list-periode`}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TaskList;