/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { Component,Fragment } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import API_URL from '../../../ApiUrl'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {matchSorter} from 'match-sorter';
import Swal from 'sweetalert2';

class DataTablesExpo extends Component{

    constructor(props){
        super(props)
        this.state = {
            data: [],
            dataExpoAttandance: [],
            editingRow: {},
            title: "",
            operation: "",
            modal: false,
            logName: "",
            errors: {},
            toastId: "",
            model: {
                id_register:"",
                kode_register:"",
                noted: "",
                nama_lengkap:"",
                no_wa:"",
                email:"",
                potential:"Cold" 
            },
        }
    }

    componentDidMount(){
        this.getDataAttandance();
        setInterval(this.getDataAttandance, 10000);
    }

    getDataAttandance = async () => {
        axios.get(API_URL.eventUrl+'load-data-checkin/'+this.props.id_event, {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataExpoAttandance: result })
        })
        .catch(error => {
        })
    }

    resetState() {
        this.setState({
            editingRow: null,
            deletingRow: null,
            model: {
                id_register:"",
                kode_register:"",
                nama_lengkap:"",
                noted:'',
                no_wa:"",
                email:"",
                potential:"" 
            },
            errors: {},
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Potential Student',
            operation: 'edit',
            logName: 'EDIT-POTENTIAL-STUDENT'
        }, () => {
            Swal.fire({
                title: 'Apakah kamu ingin menambahkan antrian ?',
                text: this.state.editingRow.name,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Ya',
                denyButtonText: 'Batal',
            }).then((result) => {
                if (result.value) {
                    this.sendData()
                } else {
                    console.log('save')
                }
            })
        });
    }

    sendData() {
        let formData = new FormData()

        formData.append('code_school_rep', this.props.userableData.userable.code)
        formData.append('event_registrasi_id', this.state.editingRow.pivot.id)
        
        axios.post(API_URL.eventUrl+'world-education-festival/pilih-rep', formData, {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data)
        .then(result => {
            switch (result.data) {
                case 'error':
                case 'gagal':
                    Swal.fire({
                        icon: 'error',
                        title: 'Maaf Simpan Data Gagal',
                        text: result.message
                    })
                break;
            
                default:
                    Swal.fire({
                        icon: 'success',
                        title: 'Simpan Data Berhasil',
                        text: result.message
                    })
                break;
            }
        })
        .catch(error => console.log(error))
    }

    onChangeStatusPotential = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.potential = newData; 
        return { model };                   
        }, () => {
            this.validateStatusPotential();
        })
    }

    onChangeNoted = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.noted = newData; 
        return { model };                   
        })
    }

    validateStatusPotential() {
        const data = this.state.model;
        if(data.potential.length < 3){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.inputStatusPotential = 'Potential is required'; 
                errors.inputStatusPotential = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.inputStatusPotential = '';    
                errors.inputStatusPotential = true;                             
                return { errors };                   
            })
        }
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Potential Student</label>
                                    <select className="form-control" name="status_payment" type="text" id="status_payment"
                                        onChange={(e) => this.onChangeStatusPotential(e)}
                                        onBlur={(e) => this.validateStatusPotential}
                                        ref={e => this.inputStatusPotential = e}
                                    >
                                        <option value="">--Choose Status--</option>
                                        <option value="Cold">Cold</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Hot">Hot</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    handleStatus(data){
        var formData = new FormData();
        formData.append('code_users',this.props.dataUsers.code_users);
        formData.append('id',data.id);
        formData.append('status',data.status);
        axios.post(API_URL.superStudent+'/university-programs-status', formData ,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.getDataProgram()
        })
        .catch(error => {
            toast.error("Opps,something error");
        })
    }

    render() {
        const columns = [
            {
                Header: 'Register Codee',
                accessor: 'register_code',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["register_code"] }),
                filterAll: true
            },
            {
                Header: 'Full Name',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Phone Number',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button onClick={() => this.editRow(row.original)} className="btn btn-success btn-pill btn-sm" type="button" style={{border:'none'}}><i className='fa fa-pencil'></i></button>
                      </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className='card'>
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.dataExpoAttandance}
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                        /> 
                    </div>
                </div>
                <ToastContainer/>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null) (withRouter(DataTablesExpo))