/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import { Typeahead } from 'react-bootstrap-typeahead';

class DatatableCurrency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
              countrycode:"",
              id_mata_uang: ""
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listCountry: [],
            listMataUang: []
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    componentDidMount(){
        this.setState({
            data: this.props.myData
        });
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            countrycode:"",
            id_mata_uang:"",
        },
        errors: {},
        });
    }

    // handle on change   
    setCountryCode = (e) => {
        const newData = e.length > 0 ? e[0].code : "";
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.countrycode = newData; 
            return { model };                   
        }, () => {
            this.validateCountryCode();
        })
    }

    setMataUang = (e) => {
        const newData = e.length > 0 ? e[0].id_mata_uang : "";
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);  
            model.id_mata_uang = newData; 
            return { model };                   
        }, () => {
            this.validateMataUang();
        })
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        //nama document
        if(!this.state.errors.countrycodeStatus){
            formIsValid = false;
        }
        if(!this.state.errors.id_mata_uangStatus){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateCountryCode = () => {
        const data = this.state.model;
        // if(data.code_leads_source.length < 3){
            if(data.countrycode === "" || data.countrycode === 0 || data.countrycode === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.countrycode = 'Pilihan Kode Negara Harus Diisi'; 
                errors.countrycodeStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.countrycode = '';    
                errors.countrycodeStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateMataUang = () => {
        const data = this.state.model;
        // if(data.code_leads_source.length < 3){
            if(data.id_mata_uang === "" || data.id_mata_uang === 0 || data.id_mata_uang === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.id_mata_uang = 'Pilihan Mata Uang Harus Diisi'; 
                errors.id_mata_uangStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.id_mata_uang = '';    
                errors.id_mata_uangStatus = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field


    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Currency Country',
            operation: 'add',
            modal: true,
            logName: 'ADD-CURRENCY-COUNTRY'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Currency Data',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-CURRENCY'
        });
    }

    deleteRow(row) {
        this.setState({
          deletingRow: row,
          model: Object.assign({}, row),
          title: 'Delete Currency Data',
          operation: 'delete',
          modalDelete: true,
          logName: 'DELETE-CURRENCY'
        });
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateCountryCode();
            this.validateMataUang();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });
        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('id_currency_country',data[0].id_currency_country);
        formData.append('countrycode',data[0].countrycode);
        formData.append('id_mata_uang',data[0].id_mata_uang);

        let url = '/finance/master';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/finance/master/'+data[0].id_currency_country
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/finance/master/'+data[0].id_currency_country
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            if(reply.status === 200){
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                } 
                window.location.reload(false);
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="code_leads_source">Country</label>
                                <Typeahead
                                    id="basic-typeahead"
                                    labelKey="name"
                                    name="countrycode"  
                                    value={this.props.country.code}
                                    options={this.props.country}
                                    onChange={(e) => this.setCountryCode(e)}
                                    onBlur={(e) => this.validateCountryCode()}
                                    ref={e => this.inputCountryCode = e}
                                    placeholder="--Negara--"
                                />
                                <span style={{color:"#FF5370"}}>{this.state.errors.code_leads_source}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="code_leads_source">Mata Uang</label>
                                <Typeahead
                                    id="basic-typeahead"
                                    labelKey="code_mata_uang"
                                    name="id_mata_uang"  
                                    value={this.props.mataUang.id_mata_uang}
                                    options={this.props.mataUang}
                                    onChange={(e) => this.setMataUang(e)}
                                    onBlur={(e) => this.validateMataUang()}
                                    ref={e => this.inputMataUang = e}
                                    placeholder="--Mata Uang--"
                                />
                                <span style={{color:"#FF5370"}}>{this.state.errors.code_leads_source}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
              Header: 'Kode Negara',
              accessor: 'countrycode',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["countrycode"] }),
                filterAll: true
            },
            {
                Header: 'Nama Negara',
                accessor: 'countryname',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["countryname"] }),
                  filterAll: true
            },
            {
                Header: 'Mata Uang',
                accessor: 'code_mata_uang',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["code_mata_uang"] }),
                  filterAll: true
            },
            {
                Header: 'Jenis',
                accessor: 'code_mata_uang',
                Cell: (row) => (
                    <div>
                        {
                            row.original.jenis == 'J' ? 
                            'J1 Visa'
                            :
                            row.original.jenis == 'T' ?
                            'Tourist'
                            :
                            'Student'
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div>
                  <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                      </span>
                    </div>
                  </button>
                </div>
                <ReactTable 
                    data={myData}
                    columns={columns}
                    pageSize={pageSize}
                    showPagination={false}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    style={{
                        height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    SubComponent={row => {
                        const data = row.original;
                        return(
                            <div style={{ padding: "20px" }}>
                                <div style={{paddingLeft:"10px", fontStyle:"italic", color:"#A6A6A6"}}>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Kode Negara</div>
                                        <div className="col-md-9" align="left">{data.countrycode}</div>
                                    </div>
                                    <div className="row">
                                        <div className="" align="left" style={{ color:"black" }}>Kode Mata Uang</div>
                                        <div className="col-md-9" align="left">{data.code_mata_uang}</div>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                />
                <ToastContainer />
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  

export default connect(reduxState, null)(DatatableCurrency);