import React, { Component } from "react"
import ReactTable from 'react-table';
import API_URL from "../../ApiUrl"
import axios from 'axios';
import Moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class KeyOpinionLeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listData: [],
            kol_name: '',
            date_post: '',
            textSave: 'Save',
            modalAdd:false,
            btnDisabled: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () => {
        axios.get(API_URL.superStudent+'/key-opinion-leader', {
            headers: { Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => {
            this.setState({
                listData: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    deletePost = (id) => {
        axios.delete(API_URL.superStudent+`/key-opinion-leader/${id}`, {
            headers: {
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(() => {
            toast.success("Data Berhasil di perbaharui")
            this.loadData()
        })
        .catch(error => console.log(error))
      }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addRow = () => {
        this.setState({
            kol_name: '',
            date_post:'',
            modalAdd:true
        })
    }

    saveRow = () => {
        this.setState({
            btnDisabled:true,
            textSave:'Saving...',
        })
        let formData = new FormData()
        formData.append('kol_name', this.state.kol_name)
        formData.append('date_post', this.state.date_post)
        axios.post(API_URL.superStudent+'/key-opinion-leader',formData,{
            headers:{ Authorization: `Bearer `+this.props.dataToken }
        })
        .then(() => {
            this.setState({
                btnDisabled:false,
                textSave:'Save Data',
                modalAdd: false,
            })
            toast.success("Data Berhasil diperbaharui")
            this.loadData()
        })
        .catch(error => {
        })
    }

    renderModal() {
        return (
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalAdd} toggle={() => this.setState({ modalAdd: false })}>
                <ModalHeader toggle={() => this.setState({ modalAdd: false })}>Tambah Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="form-group"> 
                            <label className='control-label'>Title<span className="text-danger">*</span></label>
                            <input className="form-control" value={this.state.kol_name} onChange={this.handleInput} name="kol_name" type="text" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Total View<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.date_post} onChange={this.handleInput} name="date_post" type="date" />
                            </div>
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>{this.state.textSave}</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalAdd: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          )
    }

    render() {
        const columns = [
            {
                Header: 'Key Opinion Name',
                accessor: 'kol_name',
            },
            {
                Header: 'Date Post',
                accessor: 'date_post',
                width:120,
                Cell:(row) => (
                    <>{Moment(row.original.date_post).format('DD MMM YYYY')}</>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                width:80,
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        <button
                        onClick={()=> this.deletePost(row.original.id)} 
                          className="btn btn-outline-danger btn-xs mr-1" type="button" style={{border:'none'}} ><i className="fa fa-trash fa-2x"></i>
                        </button>
                    </>
                ),
                style: {textAlign: 'center'},
                filterable: false,
                sortable: false
            }
        ]
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='mb-2'>
                                        <button onClick={this.addRow} className="btn btn-success" type="button" style={{border:'none'}} >Add Data </button>
                                    </div>
                                    <ReactTable
                                        data={this.state.listData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(KeyOpinionLeader);