/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ProfileData from "./profileData";
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import StudentData from './studentData';
import ActivitiesData from './activitiesData';

class DetailProfileGa extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            code_ambassador: this.props.match.params.codeAmbassador,
            idCategory: this.props.match.params.idCategory,
            showComponent:false,
            dataProfile: [],
            profilePic:""
        }
    }

    componentDidMount() {
        this.getDataProfile()
    }

    getDataProfile(){
        const url = API_URL.superStudent+'/ambassadors/'+this.state.code_ambassador;
        axios.get(url,{
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataProfile: data, showComponent:true })
        })
        .catch(error => {
            console.log(error)
        })
    }

    render(){
        return (
            <Fragment>
                <Breadcrumb title="Profile Detail" parent="Ambassador" />
                <div className="container-fluid">
                    <div className="row justify-content-md-center justify-content-xs-center">
                        <div className="col-md-10 col-lg-8">
                            {this.state.showComponent === true ? <ProfileData data={this.state.dataProfile }/> : ''}
                        </div>
                        <div className="col-md-12">
                            {this.props.dataUser.code_roles != 'RL008'? <StudentData/> : '' }
                        </div>
                        <div className="col-md-12">
                            {this.props.dataUser.code_roles != 'RL008'? <ActivitiesData/> : '' }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(DetailProfileGa));