import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import CourseComponent from './coruse-component';
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";

class CourseList extends Component {

    constructor(props){
        super(props)
        this.state = {
            listCourses:[]
        }
    }

    componentDidMount(){
        this.loadCourses()
    }

    loadCourses = () => {
        axios.get(API_URL.superStudent+'/course/course-list?partner_id='+this.props.userable.id,{
            headers: {"Authorization": `Bearer ${this.props.dataToken}`},
        })
        .then(response => this.setState({ listCourses: response.data.data}))
        .catch(error => console.log(error))
    }

    render(){
        return(
            <Fragment>
                <Breadcrumb parent="User" title="Course List" />
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-12 mb-4'>
                                <Link className="btn btn-success float-right" style={{color:'white'}} to={'/pages/course/course-list/form-course-component'}> Add Data</Link>
                            </div>
                            {
                                this.state.listCourses.length > 0 ?
                                this.state.listCourses.map(course => {
                                    return <CourseComponent key={course.id} data={course} />
                                })
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


const reduxState = (state) => ({
    userable: state.Login.userableData.userable,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null)(withRouter(CourseList))