import React, { Fragment, Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';


class DatatableOffice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_kantor:'',
            id_kantor:'',
            kota:'',
            alamat:'',
            no_telp:'',
            operation: '',
            modal:false,
            modalDelete:false,
            btnDisabled: false,
            textSave:'Save'
        }
    }
  

      resetState(){
        this.setState({
            id_kantor:'',
            nama_kantor:'',
            kota:'',
            alamat:'',
            no_telp:'',
        })
      }

      handleChange = (e) => {
        this.setState({
          [e.target.name]:e.target.value
        })
      }

      addRow() {
        this.setState({
          operation: 'add',
          modal: true
        });
      }

      editRow(row) {
        this.setState({
            operation: 'edit',
            id_kantor: row.id_kantor,
            nama_kantor: row.nama_kantor,
            kota: row.kota,
            no_telp: row.no_telp,
            alamat: row.alamat,
            modal: true
        })
      }

      deleteRow(row) {
        this.setState({
            id_kantor:row.id_kantor,
            modalDelete: true
        })
      }


      closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        })
        this.resetState();
      }

      sendData() {
        this.setState({
          btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_kantor', this.state.id_kantor)
        formData.append('nama_kantor',this.state.nama_kantor)
        formData.append('kota',this.state.kota)
        formData.append('alamat',this.state.alamat)
        formData.append('no_telp', this.state.no_telp)

        axios.post(API_URL.superStudent+'/offices', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
             window.location.reload(false)
        })
        .catch(error => console.log(error))
      }


      saveDeleteRow() {
        this.setState({
            btnDisabled: true
        })

        axios.delete(API_URL.superStudent+'/offices/'+this.state.id_kantor,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            window.location.reload(false)
        })
        .catch(error => console.log(error))

      }

      renderDialog() {
        return (
          <div className="card-body btn-showcase">
            <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
              <ModalHeader toggle={() => this.setState({ modal: false })}>Form Document</ModalHeader>
              <ModalBody>
              <form className="needs-validation" noValidate="" >
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name">Nama Kantor </label>
                      <input className="form-control" name="nama_kantor" type="text" onChange={this.handleChange}  value={this.state.nama_kantor}  />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name">Kab / Kota</label>
                      <input className="form-control" name="kota" type="text" value={this.state.kota} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name">Alamat</label>
                      <input className="form-control" name="alamat" type="text" value={this.state.alamat} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="name">No Telp</label>
                      <input className="form-control" name="no_telp" type="text"  value={this.state.no_telp} onChange={this.handleChange} />
                  </div>
                </div>
              </form>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>{this.state.textSave}</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </div>
        )
      }

      renderDeleteDialog() {
          return (
            <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
              <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete</ModalHeader>
              <ModalBody>
                Delete this data ?
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
      }

    render() {
      const { pageSize, myData } = this.props;
      const columns = [
        {
          Header: 'Nama Kantor',
          accessor: 'nama_kantor',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["nama_kantor"] }),
            filterAll: true
        },
        {
          Header: 'Kab / Kota',
          accessor: 'kota',
          style: {textAlign: 'center'},
          filterable: false
        },
        {
          Header: 'No Telp',
          accessor: 'no_telp',
          style: {textAlign: 'center'},
          filterable: false
        },
        {
          Header: <b>Action</b>,
          id: 'delete',
          accessor: str => "delete",
          Cell: (row) => (
            <div>                     
                <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil"></i></button> &nbsp;
                <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash"></i></button>
            </div>
          ),
          style: {
              textAlign: 'center'
          },
          sortable: false,
          filterable: false
      }
      ]

        return (
            <Fragment>
                <div style={{marginBottom:'5px'}}>
                  <button onClick={() => this.addRow()} className="btn btn-success btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus fa-2x"></i>
                      </span>
                    </div>
                  </button>
                </div>

              <ReactTable
                data={myData}
                columns={columns}
                pageSize={pageSize}
                showPagination={false}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value}
              />
              <ToastContainer />
              {this.renderDialog()}
              {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
  dataId: state.Login.detailUser.id_users,
  dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(DatatableOffice);