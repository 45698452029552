/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import Moment from 'moment';
import DatePicker from "react-datepicker";

class SchoolActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                code_activities:"",
                code_master_act:"",
                name_activities:"",
                send_by: "",
                receive_by: "",
                receive_contact: "",
                send_date: "",
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete:false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listCountry: [],
            listMataUang: [],
            listProposalVisit: [],
            listStaff: [],
            listMasterAct: [],
        }
    }

    getActivities(){
        const url = API_URL.superStudent+'/school-activities/activities/'+this.props.match.params.codeSchool;
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({listProposalVisit:data.data});
        });
    }
    
    getUserStaff() {
        const url = API_URL.superStudent+'/consultant/list-all';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({listStaff:data.data});
        });
    }

    getMasterActivities() {
        const url = API_URL.superStudent+'/master/school-act';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({listMasterAct:data.data});
        });
    }

    componentDidMount() {
        this.getActivities();
        this.getUserStaff();
        this.getMasterActivities();
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    resetState(){
        this.setState({
        editingRow: null,
        deletingRow: null,
        model: {
            name_activities:"",
            send_by: "",
            receive_by: "",
            reveice_contact: "",
            send_date: "",
        },
        errors: {},
        });
    }

    // handle on change   
    onChangesetCodeAct = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.code_master_act = newData; 
        return { model };                   
        }, () => {
            this.validateCodeAct();
        })
    }
    onChangesetNameActivities = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.name_activities = newData; 
        return { model };                   
        }, () => {
            this.validateNameActivities();
        })
    }
    onChangesetSendBy = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.send_by = newData; 
        return { model };                   
        }, () => {
            this.validateSendBy();
        })
    }
    onChangesetReceiveBy = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.receive_by = newData; 
        return { model };                   
        }, () => {
            this.validateReceiveBy();
        })
    }
    onChangesetReceiveContact = (e) => { 
        const newData = e.target.value;
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.receive_contact = newData; 
        return { model };                   
        }, () => {
            this.validateReceiveContact();
        })
    }
    onChangesetSendDate = date => { 
        this.setState(prevState => {
        let model = Object.assign({}, prevState.model);  
        model.send_date = Moment(date).format('YYYY-MM-DD');
        return { model };                   
        }, () => {
            this.validateSendDate();
        })
    }
    // end handle on change

    // validasi submit form
    handleValidation(){
        let formIsValid = true;
        if(this.state.errors.code_master_act != ""){
            formIsValid = false;
        }
        if(this.state.errors.name_activities != ""){
            formIsValid = false;
        }
        if(this.state.errors.send_by != ""){
            formIsValid = false;
        }
        if(this.state.errors.receive_by != ""){
            formIsValid = false;
        }
        if(this.state.errors.receive_contact != ""){
            formIsValid = false;
        }
        if(this.state.errors.send_date != ""){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateCodeAct = () => {
        const data = this.state.model;
        if(data.code_master_act === "" || data.code_master_act === 0 || data.code_master_act === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_master_act = 'Jenis Activities Name Must Entered'; 
                errors.code_master_actStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.code_master_act = '';    
                errors.code_master_actStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateNameActivities = () => {
        const data = this.state.model;
        if(data.name_activities === "" || data.name_activities === 0 || data.name_activities === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.name_activities = 'Activities Name Must Entered'; 
                errors.name_activitiesStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.name_activities = '';    
                errors.name_activitiesStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateSendBy = () => {
        const data = this.state.model;
        if(data.send_by === "" || data.send_by === 0 || data.send_by === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.send_by = 'Send By Mush Entered'; 
                errors.send_byStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.send_by = '';    
                errors.send_byStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateReceiveBy = () => {
        const data = this.state.model;
        if(data.receive_by === "" || data.receive_by === 0 || data.receive_by === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.receive_by = 'Receive Must Entered'; 
                errors.receive_byStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.receive_by = '';    
                errors.receive_byStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateReceiveContact = () => {
        const data = this.state.model;
        if(data.receive_contact === "" || data.receive_contact === 0 || data.receive_contact === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.receive_contact = 'Recieve Contact Must Entered'; 
                errors.receive_contactStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.receive_contact = '';    
                errors.receive_contactStatus = true;                             
                return { errors };                   
            })
        }
    }
    validateSendDate = () => {
        const data = this.state.model;
        if(data.send_date === "" || data.send_date === 0 || data.send_date === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.send_date = 'Send Date Must Entered'; 
                errors.send_dateStatus = false;                                 
                return { errors };                   
            })
        }else{
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);  
                errors.send_date = '';    
                errors.send_dateStatus = true;                             
                return { errors };                   
            })
        }
    }
    // end handle validation per field


    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Activities',
            operation: 'add',
            modal: true,
            logName: 'ADD-ACTIVITIES'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Activities',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-ACTIVITIES'
        });
    }

    deleteRow(row) {
        this.setState({
          deletingRow: row,
          model: Object.assign({}, row),
          title: 'Delete Activities',
          operation: 'delete',
          modalDelete: true,
          logName: 'DELETE-ACTIVITIES'
        });
    }

    saveRow() {
        if(this.handleValidation()){
            this.sendData();
        }else{
            this.validateNameActivities();
            this.validateSendBy();
            this.validateReceiveBy();
            this.validateReceiveContact();
            this.validateSendDate();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('code_school', this.props.match.params.codeSchool);
        formData.append('code_master_act', data[0].code_master_act);
        formData.append('code_activities', data[0].code_activities);
        formData.append('name_activities', data[0].name_activities);
        formData.append('receive_by', data[0].receive_by);
        formData.append('receive_contact', data[0].receive_contact);
        formData.append('send_by', data[0].send_by);
        formData.append('send_date', data[0].send_date);

        let url = '/school-activities/list';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/school-activities/list/'+data[0].code_activities
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/school-activities/list/'+data[0].code_activities
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        }).then((reply)=>{
            if(reply.data.code === 200){
                toast.update(this.state.toastId, {
                    render: "Input Success !",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true,
                    className: 'rotateY animated'
                })
                this.closeDialog();
                this.getActivities();
                this.setState({ btnDisabled: false });
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    saveDeleteRow() {
        this.sendData();
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="name_activities">Activities Name</label>
                                <input className="form-control" name="name_activities" type="text" id="name_activities"  value={row.name_activities}
                                    onChange={(e) => this.onChangesetNameActivities(e)}
                                    onBlur={(e) => this.validateNameActivities}
                                    ref={e => this.inputNameActivities = e}
                                />
                                <span>{this.state.errors.name_activities}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="code_master_act">Jenis Activities</label>
                                <select className="form-control" name="code_master_act" type="text" id="code_master_act"  value={row.code_master_activities}
                                    onChange={(e) => this.onChangesetCodeAct(e)}
                                    onBlur={(e) => this.validateCodeAct}
                                    ref={e => this.inputCodeAct = e}
                                >
                                <option value="0">--Pilih Send By--</option>
                                {this.state.listMasterAct.map((act)=>(
                                    <option key={act.code_master_activities} value={act.code_master_activities}>{act.name_activites}</option>
                                ))}
                                </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.send_by}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="code">Send By</label>
                                <select className="form-control" name="code" type="text" id="code"  value={row.code_master_activities}
                                    onChange={(e) => this.onChangesetSendBy(e)}
                                    onBlur={(e) => this.validateSendBy}
                                    ref={e => this.inputSendBy = e}
                                >
                                <option value="0">--Pilih Send By--</option>
                                {this.state.listStaff.map((staff)=>(
                                    <option key={staff.code} value={staff.code}>{staff.name}</option>
                                ))}
                                </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.send_by}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="receive_by">Receive By </label>
                                <input className="form-control" name="receive_by" type="text" id="receive_by"  value={row.receive_by}
                                    onChange={(e) => this.onChangesetReceiveBy(e)}
                                    onBlur={(e) => this.validateReceiveBy}
                                    ref={e => this.inputReceiveBy = e}
                                />
                                <span>{this.state.errors.receive_by}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="receive_contact">Receive Contact </label>
                                <input className="form-control" name="receive_contact" type="text" id="receive_contact"  value={row.receive_contact}
                                    onChange={(e) => this.onChangesetReceiveContact(e)}
                                    onBlur={(e) => this.validateReceiveContact}
                                    ref={e => this.inputReceiveContact = e}
                                />
                                <span>{this.state.errors.receive_contact}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="send_date">Send Date</label>
                                <DatePicker className="form-control" name="send_date" type="text" id="send_date"  
                                    value={row.send_date}
                                    dateFormat="yyyy-MM-dd"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onChange={this.onChangesetSendDate}
                                    onBlur={(e) => this.validateSendDate()}
                                    ref={e => this.inputSendDate = e}
                                />
                                <span>{this.state.errors.send_date}</span>
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                        </div>
                    </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        console.log(this.props.codeUsers)
        const { pageSize } = this.props;
        const columns = [
            {
                Header: 'Code Activities',
                accessor: 'code_activities',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["code_activities"] }),
                  filterAll: true
            }, 
            {
                Header: 'Jenis Activities',
                accessor: 'name_activites',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["name_activites"] }),
                  filterAll: true
            }, 
            {
                Header: 'Activities Name',
                accessor: 'name_activities',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["name_activities"] }),
                  filterAll: true
            },
            {
                Header: 'PIC Send',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["nama"] }),
                  filterAll: true
            },
            {
                Header: 'PIC School',
                accessor: 'receive_by',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["receive_by"] }),
                  filterAll: true
            },
            {
                Header: 'PIC School Contact',
                accessor: 'receive_contact',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["receive_contact"] }),
                  filterAll: true
            },
            {
                Header: 'Send Date',
                accessor: 'send_date_format',
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["send_date_format"] }),
                  filterAll: true
            },
            {
                Header: 'Status Activities',
                accessor: 'status_activities',
                Cell: (row) => (
                    <div>
                        {row.original.status_activities == 0 ? 'SENT' : row.original.status_activities == 1 ? 'FEEDBACK' : row.original.status_activities == 2 ? 'SCHEDULLED' : row.original.status_activities == 3 ? 'VISITED' : 'CANCEL'}
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className="col-md-6">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}}><i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i></button>
                                    </div>
                                    <ReactTable 
                                        data={this.state.listProposalVisit}
                                        columns={columns}
                                        pageSize={pageSize}
                                        showPagination={false}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        style={{
                                            height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                    />
                                    <ToastContainer />
                                    {this.renderDialog()}
                                    {this.renderDeleteDialog()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(SchoolActivities);