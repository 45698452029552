import React, { Component } from 'react'
import Breadcrumb from '../../components/common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import { connect } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FormEduGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            id:'',
            name:'',
            logo:'',
            start_date:'',
            end_date:'',
            country_coode:'',
            city:'',
            address:'',
            email:'',
            phone:'',
            website:'',
            about:'',
            disableBtn:false,
            btnSave:'Save',
            action:'',
            listCountry:[]
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getListCountry(){
        axios.get(API_URL.superStudent+'/master/country')
        .then(response => this.setState({ listCountry: response.data.data}))
        .catch(error => console.log(error))
    }

    handleLogo = (e) => {
        this.setState({
            logo: e.target.files[0]
        })
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            about: newContent
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            disableBtn: true,
            btnSave: 'Saving...'
        })

        let formData = new FormData()
        formData.append('name', this.state.name)
        formData.append('logo', this.state.logo)
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        formData.append('country_code', this.state.country_coode)
        formData.append('city', this.state.city)
        formData.append('address', this.state.address)
        formData.append('email', this.state.email)
        formData.append('phone', this.state.phone)
        formData.append('website', this.state.website)
        formData.append('about', this.state.about)

        let url = '/edu-group'
        if (this.state.action === 'update'){
            formData.append('_method','PATCH');
            url = '/edu-group/'+this.state.id
        }

        axios({
            method: 'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            console.log(response.data)
            this.setState({
                btnSave: 'Save',
                disableBtn:false
            })
            toast.success("Education Group successfully updated...")
            this.props.history.push('/pages/schoolrep/data-edugroup')
        })
        .catch(err => [
            this.setState({
                btnSave:'Save',
                disableBtn:false
            })
        ])
    }

    componentDidMount(){
        this.getListCountry()
        if(this.props.location.state === undefined)
            this.setState({ action: 'tambah' })
        else{
            let data = this.props.location.state
            this.setState({
                id: data.id,
                name: data.name,
                start_date: data.start_date,
                end_date: data.end_date,
                country_coode: data.country_code,
                city: data.city,
                address: data.address,
                email: data.email,
                phone: data.phone,
                website: data.website,
                about: data.about,
                action: 'update'
            })
        }
    }

    render(){
        return(
        <>
        <div className='page-wrapper'>
            <div className='container-fluid p-0'>
                <Breadcrumb title="Form Education Group" parent="SchoolRep" />
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={this.handleSubmit}>
                            <div className='row'>
                                <div className="col-md-5 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className='f-w-600'>Name</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.name} name="name" type="text" placeholder="example group" />
                                    </div>
                                </div>
                                <div className='col-md-3 col-sm-6 col-xs-12'>
                                    <div className="form-group">
                                        <label className='f-w-600'>Email</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.email} name="email" type="email" placeholder="example@gmail.com" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <div className="form-group">
                                        <label className='f-w-600'>Website</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.website} name="website" type="text" placeholder="www.google.com" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <div className="form-group">
                                        <label className='f-w-600'>Country</label>
                                        <select name='country_coode' value={this.state.country_coode} onChange={this.handleInput} className="form-control btn-square">
                                            <option value="0">--Select Country--</option>
                                            {
                                            this.state.listCountry.length > 0 ?
                                            this.state.listCountry.map(country => {
                                                return <option key={country.code} value={country.code}>{country.name}</option>
                                            }): ''
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <div className="form-group">
                                        <label className='f-w-600'>City</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.city} name="city" type="text" placeholder="perth" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xs-12'>
                                    <div className="form-group">
                                        <label className='f-w-600'>Address</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.address} name="address" type="text" placeholder="perth" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="f-w-600">Tanggal Kontrak</label>
                                        <div className="input-group">
                                            <input type="date" value={this.state.start_date} onChange={this.handleInput} name="start_date" className="form-control"/>
                                            <input type="date" value={this.state.end_date} onChange={this.handleInput} name="end_date" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label className='f-w-600'>Phone</label>
                                        <input className="form-control" onChange={this.handleInput} value={this.state.phone} name="phone" type="text" placeholder="0219898999" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label className='f-w-600'>Logo</label>
                                        <input className="form-control" type="file" onChange={this.handleLogo} />
                                        {
                                            this.state.action === 'update' ? 
                                            <span style={{fontSize:'10px'}} className='text-danger'>tambahkan file jika ingin diubah </span> : ''
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <label className='f-w-600'>About</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.about}
                                            events={{
                                                "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                            toolbar:[
                                                ["Bold","Italic",'Strike','RemoveFormat'],
                                                ['NumberedList','BulletedList','Indent','Outdent'],
                                                ['Link','Unlink'],
                                                ['Styles','Format','Table','Blockquote','Maximize'],
                                                ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                            ]
                                            }}     
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type='submit' className='btn btn-success mt-3' >{this.state.btnSave}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null) (withRouter(FormEduGroup));