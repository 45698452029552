const scoreListening = (score) => {
    switch (parseInt(score)) {
        case 0:
            return 24;
        case 1:
            return 25;
        case 2:
            return 26;
        case 3:
            return 27;
        case 4:
            return 28;
        case 5:
            return 29;
        case 6:
            return 30;
        case 7:
            return 31;
        case 8:
        case 9:
            return 32;
        case 10:
            return 33;
        case 11:
            return 35;
        case 12:
            return 37;
        case 13:
            return 38;
        case 14:
            return 39;
        case 15:
        case 16:
            return 41;
        case 17:
            return 42;
        case 18:
            return 43;
        case 19:
            return 44;
        case 20:
        case 21:
            return 45;
        case 22:
            return 46;
        case 23:
        case 24:
            return 47;
        case 25:
        case 26:
            return 48;
        case 27:
            return 49;
        case 28:
        case 29:
            return 50;
        case 30:
        case 31:
            return 51;
        case 32:
        case 33:
            return 52;
        case 34:
            return 53;
        case 35:
        case 36:
            return 54;
        case 37:
            return 55;
        case 38:
            return 56;
        case 39:
        case 40:
            return 57;
        case 41:
            return 58;
        case 42:
            return 59;
        case 43:
            return 60;
        case 44:
            return 61;
        case 45:
            return 62;
        case 46:
            return 63;
        case 47:
            return 65;
        case 48:
            return 66;
        case 49:
            return 67;
        case 50:
            return 68;
        default:
            return 0;
    }
}

const scoreReading = (score) => {
    switch (parseInt(score)) {
        case 0:
            return 21;
        case 1:
            return 22;
        case 2:
        case 3:
            return 23;
        case 4:
            return 24;
        case 5:
            return 25;
        case 6:
            return 26;
        case 7:
            return 27;
        case 8:
        case 9:
            return 28;
        case 10:
            return 29;
        case 11:
            return 30;
        case 12:
            return 31;
        case 13:
            return 32;
        case 14:
            return 34;
        case 15:
            return 35;
        case 16:
            return 36;
        case 17:
            return 37;
        case 18:
            return 38;
        case 19:
            return 39;
        case 20:
            return 40;
        case 21:
            return 41;
        case 22:
            return 42;
        case 23:
        case 24:
            return 43;
        case 25:
            return 44;
        case 26:
            return 45;
        case 27:
        case 28:
            return 46;
        case 29:
            return 47;
        case 30:
        case 31:
            return 48;
        case 32:
            return 49;
        case 33:
            return 50;
        case 34:
            return 51;
        case 35:
        case 36:
            return 52;
        case 37:
            return 53;
        case 38:
        case 39:
            return 54;
        case 40:
            return 55;
        case 41:
            return 56;
        case 42:
            return 57;
        case 43:
            return 58;
        case 44:
            return 59;
        case 45:
            return 60;
        case 46:
            return 61;
        case 47:
            return 63;
        case 48:
            return 65;
        case 49:
            return 66;
        case 50:
            return 67;
        default:
            return 0;
    }
}

const scoreStructure = (score) => {
    switch (parseInt(score)) {
        case 0:
        case 1:
            return 20;
        case 2:
            return 21;
        case 3:
            return 22;
        case 4:
            return 23;
        case 5:
            return 25;
        case 6:
            return 26;
        case 7:
            return 27;
        case 8:
            return 29;
        case 9:
            return 31;
        case 10:
            return 33;
        case 11:
            return 35;
        case 12:
            return 36;
        case 13:
            return 37;
        case 14:
            return 38;
        case 15:
        case 16:
            return 40;
        case 17:
            return 41;
        case 18:
            return 42;
        case 19:
            return 43;
        case 20:
            return 44;
        case 21:
            return 45;
        case 22:
            return 46;
        case 23:
            return 47;
        case 24:
            return 48;
        case 25:
            return 49;
        case 26:
            return 50;
        case 27:
            return 51;
        case 28:
            return 52;
        case 29:
            return 53;
        case 30:
            return 54;
        case 31:
            return 55;
        case 32:
            return 56;
        case 33:
            return 57;
        case 34:
            return 58;
        case 35:
            return 60;
        case 36:
            return 61;
        case 37:
            return 63;
        case 38:
            return 65;
        case 39:
            return 67;
        case 40:
            return 68;
        default:
            return 0;
    }
}

const scoreTOEFL = {
    scoreListening,
    scoreReading,
    scoreStructure
}

export default scoreTOEFL;