/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import Moment from 'moment';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class DataBudgetingJobdesk extends Component {

    constructor(props){
        super(props)
        this.state = {
            office_id: "",
            periode: "",
            budget_periode: "3",
            filter_indikator_id: "",
            tipe_budget: "",
            dataKonsultan: [],
            indiktorBudget:[],
            dataBudget:[],
            dataOffice:[],
            dataDivisi:[],
            handleDivisi:[],
        }
    }

    componentDidMount() {
        this.loadOffice()
        this.loadDivisi()
        this.loadConsultantJobdesk()
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currencyDisplay:'narrowSymbol',
            currency: 'IDR',
        }).format(nominal);
    }

    filterBudget = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.loadBudgetData()
            this.loadIndikator()
        })
    }

    loadBudgetData = () => {
        axios.get(API_URL.superStudent+'/accounting/budgets-jobdesk?office_id='+this.state.office_id+'&periode='+this.state.periode+'&budget_periode='+this.state.budget_periode+'&indikator='+this.state.filter_indikator_id+'&jobdesk='+this.state.dataKonsultan.code_jobdesk+'&tipe_budget='+this.state.tipe_budget, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({ dataBudget: response.data.data})
        })
        .catch(error => console.log(error))
    }

    loadOffice = () => {
        axios.get(API_URL.superStudent+'/master/office',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataOffice: response.data.data}))
        .catch(error => console.log(error))
    }

    loadDivisi = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({dataDivisi:response.data.data}))
        .catch(error => console.log(error))
    }

    loadIndikator = () => {
        var formData = new FormData();
        formData.append('code_jobdesk', this.state.dataKonsultan.code_jobdesk);
        formData.append('tipe_budget', this.state.tipe_budget);

        axios.post(API_URL.superStudent+'/accounting/indikator-budget-jobdesk', formData, {
            headers:{  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => this.setState({indiktorBudget:response.data.data}))
        .catch(error => console.log(error))
    }

    loadConsultantJobdesk = () => {
        axios.get(API_URL.superStudent+'/consultant/detail/'+this.props.dataUser.code_konsultan, {
            headers:{  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => 
            this.setState({
                dataKonsultan:response.data.data
            },() => {
                this.loadIndikator();
                this.loadBudgetData();
            })
        )
        .catch(error => console.log(error))
    }

    render() {
        const column_budget = [
            {
                Header: 'Office',
                accessor: 'office_id',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    <>{row.original.office.nama_kantor}</>
                )
            },
            {
                Header: 'Indikator',
                accessor: 'indikator_id',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    <>{row.original.indikator.name}</>
                ),
            },
            {
                Header: 'Budget',
                accessor: 'budget',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.state.tipe_budget == 1 ?
                    this.formatRupiah(row.original.budget / 12)
                    :
                    this.formatRupiah(row.original.budget)
                )
            },
            {
                Header: 'Terpakai',
                accessor: 'budget',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.terpakai)
                )
            },
            {
                Header: '%',
                accessor: 'persion',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    (100 - (row.original.sisa/row.original.budget * 100)).toFixed(3) + '%'
                ),
                width:70,
            },
            {
                Header: 'Sisa',
                accessor: 'sisa',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.indikator.tipe_budget == 1 ?
                    this.formatRupiah((row.original.budget / 12) - row.original.terpakai)
                    :
                    this.formatRupiah(row.original.sisa)
                )
            },
        ]

        const detail_budget = [
            {
                Header: 'Description',
                accessor: 'keterangan',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                width:130,
                style: {textAlign: 'center'},
                Cell:(row) => (
                    this.formatRupiah(row.original.nominal)
                )
            },
            {
                Header: 'Periode',
                accessor: 'periode',
                width:100,
                style: {textAlign: 'center'},
                Cell : (row) => (
                    <>{Moment(row.original.periode).format('MMM YYYY')}</>
                )   
            },
            {
                Header: 'Divisi',
                accessor: 'divisi',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    row.original.divisi.length > 0 ? row.original.divisi.map(item => (
                        <span key={item.code_jobdesk} className='badge badge-dark'>{item.jobdesk}</span>
                                                    )) : 'Kosong'
                )
            },
        ]

        return(
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='row m-2'>
                                    <div className='col-sm-2'>
                                        <select onChange={this.filterBudget} className='form-control' name='office_id' value={this.state.office_id}>
                                                <option value=''>All Office</option>
                                                { 
                                                    this.state.dataOffice.length > 0 ?
                                                    this.state.dataOffice.map((item, index) => (
                                                        <option key={index} value={item.id_kantor}>{item.nama_kantor}</option>
                                                    ))
                                                    : '' 
                                                }
                                        </select>
                                    </div>
                                    <div className='col-sm-2'>
                                        <select className='form-control' onChange={this.filterBudget} name='tipe_budget' value={this.state.tipe_budget}>
                                            <option key={0} value=''>--All Tipe Budget--</option>
                                            <option key={1} value={1}>Bulanan</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-2'>
                                        <select className='form-control' onChange={this.filterBudget} name='filter_indikator_id' value={this.state.filter_indikator_id}>
                                        <option key={0} value=''>--All Indikator--</option>
                                            {
                                                this.state.indiktorBudget.length > 0 ?
                                                this.state.indiktorBudget.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                                :
                                                ''
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.tipe_budget == 1 ?
                                        <div className='col-sm-2'>
                                            <div className='form-group'>
                                                <input className='form-control' type='month' onChange={this.filterBudget} value={this.state.periode} name='periode' />
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                    <div className='col-sm-2'>
                                        <select onChange={this.filterBudget} className='form-control' name='budget_periode' value={this.state.budget_periode}>
                                            <option value='1'> Juli 2022 - Juni 2023</option>
                                            <option value='2'> Juli 2023 - Juni 2024</option>
                                            <option value='3'> Juli 2024 - Juni 2025</option>
                                        </select>
                                    </div>
                                </div>
                                <ReactTable
                                    data={this.state.dataBudget[0]}
                                    columns={column_budget}
                                    defaultPageSize={10}
                                    showPagination={true}
                                    className="-striped -highlight"
                                    SubComponent={row =>{
                                        return (
                                            <div style={{ padding: "20px"}}>
                                                <ReactTable
                                                    data={row.original.detail}
                                                    columns={detail_budget}
                                                    defaultPageSize={5}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(DataBudgetingJobdesk);