/* eslint-disable eqeqeq, no-unused-vars, no-useless-concat */

import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Breadcrumb from '../../components/common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';
import { matchSorter } from 'match-sorter';

class BisdevPerform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            ihms_register:[],
        }
    }

    componentDidMount() {
        this.getDataRegister();
    }

    getDataRegister(){
        const url = API_URL.superStudent+'/ihms/list';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ ihms_register: data })
        })
    }

    render() {

        let idr = Intl.NumberFormat('id-ID');

        const columns = [
            {
                Header: 'Kode Register',
                accessor: 'code_register',
                filterable: false
            },
            {
                Header: 'Fullname',
                accessor: 'fullname',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["fullname"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_wa"] }),
                filterAll: true,
                Cell: (row) => ( 
                    <>
                        {
                            row.original.no_wa !== null ?
                            row.original.no_wa.substring(0,2) == '62' || row.original.no_wa.substring(0,2) != '08' ?
                            <a href={'https://wa.me/'+row.original.no_wa} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                            :
                            <a href={'https://wa.me/'+'62'+row.original.no_wa.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                            : ''
                        }
                    </>
                )
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                filterable: false,
                Cell:(row) => (
                    <span>{moment(row.original.created_at).format('DD MMMM YYYY')}</span>
                )
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterable: false,
                Cell:(row) => (
                    <div>
                        {
                            row.original.status_payment == 'N' ? 
                            <span className={'badge'} style={{backgroundColor: 'rgb(99, 204, 255)', color: 'white'}}>New</span>
                            :
                            row.original.status_payment == 'Y' ?
                            <span className={'badge'} style={{backgroundColor: 'green', color: 'white'}}>Paid</span>
                            :
                            row.original.status_payment == 'P' ?
                            <span className={'badge'} style={{backgroundColor: 'rgb(255, 152, 0)', color: 'white'}}>Pending</span>
                            :
                            row.original.status_payment == 'D' ?
                            <span className={'badge'} style={{backgroundColor: 'rgb(242, 60, 6)', color: 'white'}}>Failed</span>
                            :
                            row.original.status_payment == 'E' ?
                            <span className={'badge'} style={{backgroundColor: 'rgb(0, 91, 159)', color: 'white'}}>Expired</span>
                            :
                            <span className={'badge'} style={{backgroundColor: 'rgb(255, 214, 0)', color: 'white'}}>Outstanding</span>
                        }
                    </div>
                )
            }
        ]

        return (
            <Fragment>
                <Breadcrumb title="IHMS Register" parent="IHMS Register" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.ihms_register.length > 0 ?
                                            <ReactTable
                                                data={this.state.ihms_register}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                defaultPageSize={50}
                                                className="-striped -highlight text-center"
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(BisdevPerform));