/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { Switch, Route, Redirect } from 'react-router-dom';
import "./styles/react-router-tabs.css";
import UniversityExpoNone from './university-none';

class Expo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSchool: [],
        }
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="University Event" parent="University Event" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <div className="col-sm-12">
                            { 
                                this.props.userableData.userable.status_expo == '0' ?
                                <UniversityExpoNone></UniversityExpoNone>
                                :
                                <>
                                <RoutedTabs tabClassName="tab-link" activeTabClassName="active">
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/expo/`+this.props.match.params.idEvents+`/attandance`}>
                                            Event Attendance
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/expo/`+this.props.match.params.idEvents+`/queue`}>
                                            Event Queue
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/university/expo/`+this.props.match.params.idEvents+`/potential`}>
                                            Event Potential
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/university/expo/`+this.props.match.params.idEvents}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/university/expo/`+this.props.match.params.idEvents+`/attandance`} />}
                                    />
                                </Switch>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    userableData: state.Login.userableData
})
  
export default connect(reduxState, null)(withRouter(Expo));