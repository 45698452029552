/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars */

import React, { useEffect, useState } from "react";
import { Progress } from 'reactstrap';
import setRangeDate from "../../../helper/general-function";
import axios from 'axios'
import API_URL from '../../../ApiUrl';
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import DataKPI from "./component/data-kpi";
import { color1, color2, color3 } from "../../../constant/variable";
import DetailKPI from "./component/detail-kpi";


const KPIMarketingManager = () => {

    const[kpi,setKPI] = useState({
        result:0,
        granted:0,
        visit:0,
        member_income:0,
        income_all:0,
        selisih:0,
        last_result:0
    });

    const [total,setTotal] = useState(0)
    const[indikator,setIndikator] = useState([]);
    const[master,setMaster] = useState([]);
    const[index,setIndex] = useState(0);
    const[report,setReport] = useState([index])
    const[date,setDate] = useState(Moment().endOf('month'))
    const [typeDate,setTypeDate] = useState('this_week')
    const[filterDate,setFilterDate] = useState({start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'), end_date:Moment().endOf('isoWeek').format('YYYY-MM-DD')})

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    })); 
    
    useEffect(() => {
        loadDataPeformance()
    },[date]);

    useEffect(() => {
        rangeDate()
    },[typeDate])


    const loadDataPeformance = () => {
        let formData = new FormData()
        formData.append('end_date', date.format('YYYY-MM-DD'))
        formData.append('time_type',2)
        formData.append('reference_type',1)
        axios.post(API_URL.superStudent+'/branch-peformance/recap-kpi-marketing-manager', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            let result = response.data.data
            let kpi = result[0].bobot+result[1].bobot+result[2].bobot
            let last_kpi = result[0].last_bobot+result[1].last_bobot+result[2].last_bobot
            let selisih = 0
            if(kpi < 100){
                selisih = 100 - kpi
            }
            setKPI({
                result:kpi,
                granted: result[0].bobot,
                visit: result[1].bobot,
                income_all: result[2].bobot,
                selisih,
                last_result: kpi - last_kpi
            })
            setIndikator(result)
            // setReport(result.grafik)
            // setTotal(result.grafik[index][1].reduce((a,b) => a + b,0))

        })
        .catch(error => console.log(error))
    }

    const handlePickerMonth = (e) => {
        setDate( Moment(e).endOf('month'))
    }

    const rangeDate =() => {
        let data = setRangeDate(typeDate);
        setFilterDate({start_date:data.start, end_date: data.end })
    }

    return (
        <>
        <div className="container-fluid">
            <div className="row justify-content-center mb-2">
                <div className="col-md-3">
                    <DatePicker dateFormat='MMMM yyyy' selected={date._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={handlePickerMonth} />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-sm-6">
                    <div className="card">
                        <div style={{padding:'11px'}}>
                            <DataKPI kpi={kpi} />
                            <div className="progress-showcase">
                                    <Progress multi style={{ height: "15px"}}>
                                        <Progress animated style={{backgroundColor:color1}} bar value={kpi.granted}>{kpi.granted} %</Progress>
                                        <Progress animated style={{backgroundColor:color2}} bar value={kpi.visit}>{kpi.visit} %</Progress>
                                        <Progress animated style={{backgroundColor:color3}} bar value={kpi.income_all}>
                                        {kpi.income_all} %
                                        </Progress>
                                        <Progress animated bar style={{backgroundColor:'#d6d6c2'}} value={kpi.selisih} />
                                    </Progress>
                                </div>
                                <div style={{color:'white'}} className="mt-1">
                                    <span style={{fontSize:'8px',backgroundColor:color1}} className="badge badge-pill">Granted</span>
                                    <span style={{fontSize:'8px',backgroundColor:color2}} className="badge badge-pill">Visit</span>
                                    <span style={{fontSize:'8px',backgroundColor:color3}} className="badge badge-pill">Income</span>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-sm-12">
                    <DetailKPI indikator={indikator}/>
                </div>   
            </div>
        </div>
        </>
    )
}

export default KPIMarketingManager