import React, { Fragment, Component } from 'react';
import DatatableEnglishCourse from '../common/datatable/datatableEnglishCourse';
import { connect } from 'react-redux';
import { withRouter } from "react-router";


class DataTablesEnglish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listEnglish: []
        }
    }

    render() {
        const data = this.state.listEnglish;
        const pgsize = data.length;
        return(
            <Fragment>
                <div className="container-fluid">
                <DatatableEnglishCourse
                                        multiSelectOption={false}
                                        title="English Course"
                                        pageSize={pgsize}
                                        pagination={true}
                                        className="-striped -highlight"
                                    />
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null)(withRouter(DataTablesEnglish));