import React, { Fragment, Component } from 'react';
import DatatableMembership from '../common/datatable/datatableMembership';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { resettoken } from '../../actions/login.action';

class DataTablesMemberData extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableMembership title="Users Data" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};


const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
  })

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
   })
  
export default connect(reduxState, reduxDispatch)(withRouter(DataTablesMemberData));