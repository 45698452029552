import React, { Component } from 'react';
import one from '../../assets/images/user/background.webp';
import images from '../../assets/images/user/7.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';

class LeftProfile extends Component{

    constructor(props){
        super(props)
        this.state = {
            modalProfile:false,
            modalStatus:false,
            profile_picture:'',
            nama_lengkap:'',
            username:'',
            job_title:'',
            id_karyawan:'',
            status_active:'',
        }
    }

    handleChangeProfile = (e) => {
        this.setState({
            profile_picture:e.target.files[0]
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveData = (e) => {
        let formData = new FormData()
        formData.append('code_konsultan',this.props.data.code_konsultan)
        formData.append('nama_lengkap', this.state.nama_lengkap)
        formData.append('job_title', this.state.job_title)
        formData.append('nama', this.state.username)
        formData.append('id_karyawan', this.state.id_karyawan)
        formData.append('profile_update',true)
        formData.append('foto',this.state.profile_picture)
        this.props.saveFile(formData)
        this.closeModal()
    }

    saveStatus = (e) => {
        let formData = new FormData()
        formData.append('id_konsultan', this.props.data.id_konsultan)
        formData.append('status', this.state.status_active)
        this.props.saveStatus(formData)
        this.closeModal()
    }

    closeModal = () => {
        this.setState({
            modalProfile:false,
            modalStatus:false
        })
    }

    normalisasi = (count) => {
        let cuti =  count > 0 ? Math.floor(count/8) : Math.ceil(count/8);
        let sisa = count%8;
        if(sisa === 4 || sisa === -4)
            return  <> {count === -4 ? '-0' : count === 4 ? '0' : cuti} &#189;</>
        return cuti;
    }

    handleStatus = () => {
        this.setState({
            modalStatus:true,
            status_active:this.props.data.status,
        })
    }

    handleModalProfile = () => {
        this.setState({
            modalProfile:true,
            nama_lengkap: this.props.data.nama_lengkap,
            username: this.props.data.nama,
            job_title: this.props.data.job_title,
            id_karyawan: this.props.data.id_karyawan
        })
    }

    renderModalStatus = () => {
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalStatus} toggle={() => this.closeModal()} size="md">
              <ModalHeader toggle={() => this.closeModal()}>Set Status Karyawan</ModalHeader>
              <ModalBody style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto'
              }}>
              <div className='form-group'>
                  <label className='f-w-600'>Status Kariawan</label>
                    <select className='form-control' name='status_active' onChange={this.handleInput} value={this.state.status_active}>
                        <option value={1}>AKTIF</option>
                        <option value={0}>NON AKTIF</option>
                    </select>
              </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.saveStatus()}>Save Changes</Button>
                <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
          )
    }

    renderProfileDialog() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalProfile} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Edit Profile</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
            <div className='form-group'>
                <label className='f-w-600'>Nama Lengkap</label>
                <input name='nama_lengkap' onChange={this.handleInput} value={this.state.nama_lengkap} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='f-w-600'>Username</label>
                <input name='username' onChange={this.handleInput} value={this.state.username} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='f-w-600'>ID Karyawan</label>
                <input name='id_karyawan' onChange={this.handleInput} value={this.state.id_karyawan} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='f-w-600'>Job Title</label>
                <input name='job_title' onChange={this.handleInput} value={this.state.job_title} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='f-w-600'>Foto Profile</label>
                <input type='file' className='form-control' onChange={this.handleChangeProfile}/>
            </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.saveData()}>Save Changes</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    render(){
        return(
            <>
               <div className="card custom-card">
                    <div className="card-header">
                        <img className="img-fluid" src={one} alt="" />
                    </div>
                    <div className="card-profile">
                        <img className="rounded-circle" src={this.props.data.foto === null ? images : this.props.data.foto} alt="" />
                        {
                            this.props.data.code_konsultan === this.props.dataLogin.code_konsultan || this.props.dataLogin.code_roles === 'RL004' ?
                            <div>
                            <i style={{cursor:'pointer'}} onClick={this.handleModalProfile} className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" ></i>
                            </div>
                            :
                            ''
                        }
                    </div>
                    <div className="text-center profile-details">
                        <h4>{this.props.data.nama_lengkap}</h4>
                        <div>{this.props.data.job_title}</div>
                        <div>({this.props.data.jobdesk.jobdesk})</div>
                    </div>
                    <div className="form-group">
                        <input readOnly className='form-control mt-3' value={this.props.data.office.nama_kantor} placeholder='NIEC Denpasar'/>
                        <input readOnly className="form-control mt-3" value={this.props.data.email} placeholder="your-email@domain.com" />
                        <input readOnly className="form-control mt-3" value={this.props.data.nama} placeholder="username" />
                        <input readOnly className="form-control mt-3" value={this.props.data.id_karyawan} placeholder="ID Karryawan" />
                    </div> 
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Cuti Tahunan</th>
                                    <th>: {this.normalisasi(this.props.data.cuti)} Hari</th>
                                </tr>
                                <tr>
                                    <th>Day Payment</th>
                                    <th>: {this.normalisasi(this.props.data.day_payment)} Hari</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <Alert color={this.props.data.status === 1 ? 'success' : 'dark'}>
                  Status Karyawan <strong>{this.props.data.status === 1 ? 'Active': 'In-Active'}</strong> {this.props.dataLogin.code_roles === 'RL004' ? <i style={{float:'right',cursor:'pointer'}} onClick={this.handleStatus} className='fa fa-pencil'></i> : ''} 
                </Alert>
                {this.renderProfileDialog()}
                {this.renderModalStatus()}
            </>
        )
    }

}

export default LeftProfile;