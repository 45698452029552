/* eslint-disable no-unused-vars, jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import logo from '../../../assets/images/niec-logo.png';
import UserMenu from './userMenu';
import Notification from './notification';
import { Link } from 'react-router-dom';
import { AlignLeft, Maximize, MoreHorizontal } from 'react-feather';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';

const Header = ({dataUser, dataToken}) => {
  const [sidebar, setSidebar] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const [dataNotifikasi, setDataNotifikasi] = useState([]);
  const [totalNotifikasi, setTotalNotifikasi] = useState(0);

  const styles = {
    notificationContainer: {
      position: "relative",
      display: "inline-block",
    },
    bellIcon: {
      position: "relative",
      width: "24px",
      height: "24px",
      cursor: "pointer",
    },
    icon: {
      width: "100%",
      height: "100%",
      color: "#4466f2",
    },
    notificationBadge: {
      position: "absolute",
      top: "-8px",
      right: "-8px",
      backgroundColor: "#ee161f",
      color: "white",
      fontSize: "12px",
      fontWeight: "bold",
      padding: "2px 6px",
      borderRadius: "50%",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    },
  };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open'); 
    }
  }

  const loadNotifikasi = useCallback(() => {
    axios.get(API_URL.superStudent+'/master/notifikasi/'+dataUser.code_konsultan, {
      headers: { 'Authorization': `Bearer ${dataToken}` }
    })
    .then(response => {
      setDataNotifikasi(response.data.data[0].notifikasi_all);
      setTotalNotifikasi(response.data.data[0].notifikasi_new);
    })
    .catch(error => console.log)
  },[dataToken, dataUser])

  useEffect(() => {
    loadNotifikasi()
    Pusher.logToConsole = true;
    const pusher = new Pusher('7332a6f71d522e88ed0b', {
      cluster: 'ap1',
      encrypted: true,
    });

    const channel = pusher.subscribe('tasklist');
    channel.bind('message', function (data) {
      loadNotifikasi()
    });
    
    return () => {
      channel.unsubscribe();
      pusher.disconnect();
    };
  },[loadNotifikasi])

  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li style={{width: '100%'}}>
                <a onClick={goFull} className="text-dark" href="#">
                  <Maximize />
                </a>
              </li>
              <li className="onhover-dropdown">
                <Notification dataToken={dataToken} dataUser={dataUser} notification={dataNotifikasi} triggerLoadNotifikasi={loadNotifikasi} />
                <div style={styles.notificationContainer}>
                  <div style={styles.bellIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      style={styles.icon}
                    >
                      <path d="M12 2C10.34 2 9 3.34 9 5v0.29C6.29 5.86 4.5 8.36 4.5 11v4l-1.5 1.5V18h18v-1.5L19.5 15v-4c0-2.64-1.79-5.14-4.5-5.71V5c0-1.66-1.34-3-3-3zM12 22c1.1 0 2-0.9 2-2h-4c0 1.1 0.9 2 2 2z" />
                    </svg>
                    {totalNotifikasi > 0 && (
                      <span style={styles.notificationBadge}>{totalNotifikasi}</span>
                    )}
                  </div>
                </div>
              </li>
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal/></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is down, yikes!</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};

const reduxState = (state) => ({
  loginStatus: state.Login.lgnsts,
  dataToken: state.Login.lgnInitialize,
  dataId: state.Login.detailUser.id_users,
  dataUser: state.Login.detailUser,
})

export default connect(reduxState, null) (withRouter(Header));