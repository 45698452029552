import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from "react-router";
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import {matchSorter} from 'match-sorter';

class ListUniversity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSchoolRep: [],
        }
    }

    componentDidMount() {
        this.getDataSchoolRep()
    }

    getDataSchoolRep(){
        axios.get(API_URL.superStudent+'/universities?education_group_id='+this.props.dataUser.userable_id,{
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`
            }
        }) 
        .then(response => {
            this.setState({
                listSchoolRep:response.data.data
            })
        })
        .catch(err => { })
    }

    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true,
                Cell: (row) => (
                    <span style={{display:this.state.showAction}}>{row.original.email}</span>
                ),
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true,
            },
            {
                Header: <b>Action</b>,
                id:'action',
                accessor: str => 'delete',
                width:150,
                Cell:  (row) => (
                    <>                
                    <button onClick={() => this.props.history.push({
                            pathname: `${process.env.PUBLIC_URL}/pages/education-group/university/`+row.original.code,
                            state: row.original,
                        })}
                        className="btn btn-outline-success btn-xs" type="button" style={{border:'none',display:this.state.showAction}} ><i className="fa fa-pencil fa-2x"></i>{row.id}
                    </button>
                    </>
                ),
                style: {
                  textAlign: 'center'
                },
                sortable: false,
                filterable: false
              }
        ];

        return ( 
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                <ReactTable
                                    data={this.state.listSchoolRep}
                                    columns={columns}
                                    defaultPageSize={50}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser
})
  
export default connect(reduxState, null)(withRouter(ListUniversity));