import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Breadcrumb from '../../../components/common/breadcrumb';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { Home } from 'react-feather';

export class PlacementTestHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Placement Test" parent="Dashboard" />
                <div className="container-fluid">
                        <div className="row theme-tab">
                            <div className="col-sm-12">
                                <RoutedTabs tabClassName="tab-link"activeTabClassName="active">
                                    <div className="tabs tab-title">
                                        {/* <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`}>
                                            <Home /> Bank Soal
                                        </NavTab> */}
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/placement-test/result`}>
                                            <Home /> Result    
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    {/* <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/placement-test/`}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/placement-test/bank-soal`} />}
                                    /> */}
                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/placement-test/`}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/placement-test/result`} />}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(PlacementTestHome));