/* eslint-disable eqeqeq, no-unused-vars, react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';

const ActivitiesData = (props) => {

    const[data, setData] = useState([]);
    const[bisdev, setBisdev] = useState([]);
    const[alertState, setAlert] = useState(null)
    const[showState, setShow] = useState(false)
    const[action, setAction] = useState('')
    const[showDeleteState, setDeleteShow] = useState(false)
    const[title, setTitle] = useState(false)
    const[id_ambassador_activities, setIdAmbassadorAct] = useState('')
    const[codeProduct, setCodeProduct] = useState('')
    const[codeBisdev, setCodeBisdev] = useState('')
    const[date, setDate] = useState('')
    const {codeAmbassador, idCategory} = useParams()
    const { dataToken } = useSelector(state => ({ dataToken:state.Login.lgnInitialize }));

    const changeProduk = (e) => {
        setCodeProduct(e);
    }

    const changeKodeBisdev = (e) => {
        setCodeBisdev(e[0].code_konsultan)
    }

    const changeDate = (e) => {
        setDate(e);
    }

    const addRow = () => {
        setAction('add');
        setShow(true);
        setTitle('Tambah Activities Ambassador');
    }

    const saveRow = () => {
        let formData = new FormData()

        formData.append('code_ambassador', codeAmbassador)
        formData.append('kode_bisdev_produk', codeProduct)
        formData.append('kode_bisdev', codeBisdev) 
        formData.append('date', date)

        let url = '/ambassador/ambassador-activities';

        if (action == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/ambassador/ambassador-activities/'+id_ambassador_activities
        } else if (action == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/ambassador/ambassador-activities/'+id_ambassador_activities
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.status == 'Success') {
                closeRow();
                loadData();
                if (action == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }   
            }
        });
    }

    const editRow = (row) => {
        setAction('edit');
        setIdAmbassadorAct(row.id_ambassador_activities);
        setShow(true);
        setTitle('Edit Activities Ambassador');
        setDate(row.date);
        setCodeProduct(row.kode_bisdev_produk);
        setCodeBisdev(row.bisdev.code_konsultan);
    }

    const deleteRow = (row) => {
        setAction('delete');
        setIdAmbassadorAct(row.id_ambassador_activities)
        setDeleteShow(true);
        setTitle('Delete Activities Ambassador')
    }

    const closeRow = () => {
        setShow(false);
        setDeleteShow(false);
    }

    const loadData = async () => {
        await axios.get(API_URL.superStudent+'/ambassador/activities-data/'+codeAmbassador,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            setData(data)
        })
    }

    const loadBisdev = async () => {
        let formData = new FormData()

        formData.append('jobdesk',JSON.stringify(['JD007']))
        axios.post(API_URL.superStudent+'/consultant/jobdesk', formData, {
            headers:{
                Authorization: `Bearer ${dataToken}`,
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            setBisdev(data)
        })
    }

    const deleteActivities = (row) => {

    }

    const showAlert = (row) => {
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, Paid!"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                title="Anda yakin?"
                onConfirm={() => deleteActivities(row.code_student)}
                onCancel={ () => setAlert(null)}
                focusCancelBtn
            />
        )
    }

    const renderDialogEdit = () => {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={showState} toggle={closeRow}>
                    <ModalHeader toggle={closeRow}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Produk</label>
                                    <input className="form-control" type="text" onChange={(e) => changeProduk(e.target.value)} value={codeProduct} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">PIC</label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="nama"
                                        name="kode_bisdev"  
                                        options={bisdev}
                                        onChange={(e) => changeKodeBisdev(e)}
                                        placeholder="Pilih Bisdev"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Date</label>
                                    <input className="form-control" type="date" onChange={(e) => changeDate(e.target.value)} value={date} />
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => saveRow()}>Save Changes</Button>
                        <Button color="secondary" onClick={() => closeRow()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    const renderDialogDelete = () => {
        return (
            <Modal isOpen={showDeleteState} toggle={setDeleteShow}>
                <ModalHeader toggle={setDeleteShow}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => saveRow()}>Save Changes</Button>
                    <Button color="secondary" onClick={() => closeRow()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    useEffect(() => {
        loadData()
        loadBisdev()
    },[])

    const columns = [
        {
            Header: 'Produk',
            accessor: 'kode_bisdev_produk',
            style: {textAlign: 'center'},
            filterable: true
        },
        {
            Header: 'PIC',
            accessor: 'bisdev.nama_lengkap',
            style: {textAlign: 'center'},
            filterable: true
        },
        {
            Header: 'Date',
            accessor: 'date',
            style: {textAlign: 'center'},
            filterable: true,
            Cell: (row) => (
                <div>
                    {Moment(row.original.date).format('DD MMM YYYY')}
                </div>
            ),
        },
        {
            Header: <b>Action</b>,
            id: 'delete',
            accessor: str => "delete",
            Cell: (row) => (
                <div>                     
                    <button onClick={() => editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    <button onClick={() => deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                </div>
            ),
            style: {
                textAlign: 'center'
            },
            sortable: false,
            filterable: false
        }
    ]

    return(
        <>
            {alertState}
            <div className="card">
                <div className="card-header">
                    <h5>Data Activities</h5>
                </div>
                <div className="card-body">
                    <button onClick={() => addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                        <span>
                            <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                        </span>
                    </button>
                    <ReactTable
                        data={data}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                </div>
                {renderDialogEdit()}
                {renderDialogDelete()}
            </div>
        </>
    )
}

export default ActivitiesData;