/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from 'react-table';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { saveAs } from "file-saver";
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';



class ProgramStudent extends Component {    
  constructor(props) {
    super(props);
    this.state = {
      dataCampus:[],
      dataProgram:[],
      dataStudentDoc:[],
      code_students:'',
      id_campus:'',
      id_program:'',
      status:0,
      date_program_start:'',
      date_program_finish:'',
      modal:false,
      modalDelete:false,
      modalPreview:false,
      btnDisabled:false,
      display:'',
      title:'',
    }
  }

  getCampus(){
    const url = API_URL.superStudent+'/universities';
    axios.get(url,{
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
    .then(response => response.data)
    .then((data) => {
      this.setState({ dataCampus: data.data })
    })
  }

  getPrograms(){
    axios.get(API_URL.superStudent+'/programs-by-university/'+this.state.id_campus,{
      headers:{
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
    .then(response => response.data.data)
    .then(result => this.setState({dataProgram: result}))
    .catch(error => console.log(error))
  }

  getStudentDocByCode() {
    const url = API_URL.studentUrl+'students-documents-code/'+this.props.codeStudents+'/DT023';
    axios.get(url,{
        headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
    })
    .then(response => response.data.data)
    .then((result) => {
        this.setState({ 
            title:"Preview",
            modalPreview: true,
            dataStudentDoc: result
        })
    })
}

  handleInput = (e) => {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  setCampus = (e) => {
    const newData = e.length > 0 ? e[0].id : "";
    this.setState(prevState => {
      let id_campus = Object.assign({}, prevState.id_campus);  
      id_campus = newData; 
      return { id_campus };                   
    },() => this.getPrograms())
  }

  setProgram = (e) => { 
    const newData = e.length > 0 ? e[0].id : "";
    this.setState(prevState => {
      let id_program = Object.assign({}, prevState.id_program);  
      id_program= newData; 
      return { id_program };                   
    })
  }

  addProgram() {
    this.getCampus()
    this.setState({
      modal: true,
      display:'',
      id_campus:'',
      id_program:'',
      date_program_start: '',
      date_program_finish: '',
    });
  }

  editProgram(row) {
    this.setState({
      modal:true,
      id_campus:row.school_rep_id,
      id_program:row.pivot.id_program,
      status:row.pivot.status,
      display:'none',
      date_program_start: row.pivot.date_program_start,
      date_program_finish: row.pivot.date_program_finish
    })
  }

  deleteRow(row) {
    this.setState({
      id_program:row.pivot.id_program,
      modalDelete:true
    })
  }

  closeModal(){
    this.setState({
      modal: false,
      modalDelete: false,
    });
  }

  saveData(){
    let formData = new FormData()
    formData.append('code_students', this.props.codeStudents)
    formData.append('id_program', this.state.id_program)
    formData.append('status', this.state.status)
    formData.append('date_program_start', this.state.date_program_start)
    formData.append('date_program_finish', this.state.date_program_finish)
    axios.post(API_URL.superStudent+'/student/programs', formData,{
      headers:{
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
    .then(response => {
      toast.success('Data Student Program updated sucessfully')
      this.closeModal();
      this.props.onReloadProgram();
    })
    .catch(error => console.log(error))
  }

  sendData() {
    this.setState({
      btnDisabled: true
    });
  }

  saveDeleteRow() {
    let formData = new FormData()
    formData.append('code_students', this.props.codeStudents)
    formData.append('id_program', this.state.id_program)
    axios.post(API_URL.superStudent+'/student/program-delete', formData, {
      headers:{
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
    .then(response => {
      this.closeModal()
      toast.success('Data Student Program updated sucessfully')
      this.closeModal();
      this.props.onReloadProgram();
    })
    .catch(error => console.log(error))
  }

  downloadFile = (e, url, filename) => {
    saveAs(url,filename.substring(filename.lastIndexOf('/')));
  }

  renderEditDialog() {
    const modal = this.state.modal;
    return(
      <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}>Data Program</ModalHeader>
        <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
          }}>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-end'>
              <button className='btn' onClick={() => this.getStudentDocByCode()}>Show LOA</button>
            </div>
          </div>
          <form className="needs-validation" noValidate="" >
            <div style={{display:this.state.display}} className="form-row">
              <div className="col-md-12 mb-3">
                  <label>Kampus</label>
                  <Typeahead
                    id="basic-typeahead"
                    labelKey="name"
                    name="id"
                    value={this.state.id_campus}
                    options={this.state.dataCampus}
                    onChange={this.setCampus}
                    placeholder="--Pilih Kampus--"
                  />
              </div>
            </div>
            <div style={{display:this.state.display}} className="form-row">
                <div className="col-md-12 mb-3">
                <label>Program</label>
                <Typeahead
                    id="basic-typeahead"
                    labelKey="program_name"
                    name="id"
                    value={this.state.id_program}
                    options={this.state.dataProgram}
                    onChange={this.setProgram}
                    placeholder="--Pilih Program--"
                  />
                </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label >Status</label>
                <select type='date' className='form-control' onChange={this.handleInput} value={this.state.status} name='status'>
                  <option key={0} value='0'>Pre-Register</option>
                  <option key={3} value='3'>LOA</option>
                  <option key={1} value='1'>Confirm</option>
                  <option key={2} value='2'>Cancel</option>
                </select>
                
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label >Tanggal Mulai Program</label>
                <input type='date' className='form-control' onChange={this.handleInput} value={this.state.date_program_start} name='date_program_start'/>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <label>Tanggal Selesai Program</label>
                <input type='date' className='form-control' onChange={this.handleInput} value={this.state.date_program_finish} name='date_program_finish'/>
              </div>
            </div>
            
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Save Changes</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderDeleteDialog() {
    const { modalDelete } = this.state;
      return (
        <Modal className="modal-dialog" isOpen={modalDelete} toggle={() => this.closeModal()} size="md">
          <ModalHeader toggle={() => this.closeModal()}>Delete Program</ModalHeader>
            <ModalBody style={{
                maxHeight: 'calc(100vh - 210px)',
                overflowY: 'auto'
            }}>
              Delete this data ?
            </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
            <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
          </ModalFooter>
        </Modal>
    )
  }

  renderPreview(){
    const modalPreview = this.state.modalPreview;
    const { title } = this.state;
    return(
        <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.setState({ modalPreview: false })} size="lg">
          <ModalHeader toggle={() => this.setState({ modalPreview: false })}>{title}</ModalHeader>
          <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
            {this.state.dataStudentDoc.map((doc, i) => {
                const url_doc = [{ uri:doc.file_name }]
                const url_download = doc.file_name;
                return  <div key={i}>
                            <h5>{doc.code_students_document}</h5>
                            {
                                 url_download.split('.').pop().toLowerCase == 'png' || url_download.split('.').pop().toLowerCase == 'jpg' || 
                                 url_download.split('.').pop().toLowerCase == 'jpeg' ?
                                 <img className='image' src={this.state.urlPreview} alt="" /> 
                                 :
                                 <DocViewer 
                                 pluginRenderers={DocViewerRenderers} 
                                 documents={url_doc}
                                 config={{
                                     header: {
                                     disableHeader: true,
                                     disableFileName: false,
                                     retainURLParams: false
                                     }
                                 }}
                             />
                            }
                            <div className="form-row" style={{textAlign: "end"}}>
                                <div className="col-md-12">
                                    <button onClick={(e) => this.downloadFile(e, url_download, doc.file_name)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                                </div>
                            </div>
                        </div>
            })}
          </ModalBody>
        </Modal>
    )
  }

  render(){
    const data = this.props.data
    const columns = [
      {
        Header: 'Code',
        accessor: 'pivot.code_student_program',
        style: {textAlign: 'center'},
      },
      {
        Header: 'Campus',
        accessor: 'university.nama_sekolah',
        style: {textAlign: 'center'},
        filterable: false
      },
      {
        Header: 'Program',
        accessor: 'program_name',
        style: {textAlign: 'center'},
        filterable: false
      },
      {
        Header: 'Status',
        accessor: 'pivot.status',
        style: {textAlign: 'center'},
        filterable: false,
        Cell:(row) => (
          row.original.pivot.status == '0' ? 
          <span className='badge bg-info'>Pre-Register</span> 
          :
          row.original.pivot.status == '1' ? 
          <span className='badge bg-success'>Confirm</span> 
          : 
          row.original.pivot.status == '3' ? 
          <span className='badge bg-success'>LOA</span> 
          : 
          <span className='badge bg-danger'>Cancel</span>
        )
      },
      {
        Header: 'Date Start',
        accessor: 'pivot.date_program_start',
        Cell: (row) => (
          <span>{Moment(row.original.pivot.date_program_start).format('DD/MM/yyyy')}</span>
        ),
        style: {textAlign: 'center'},
        filterable: false
      },
      {
        Header: 'Program Finish',
        accessor: 'pivot.date_program_finish',
        Cell: (row) => (
          <span>{Moment(row.original.pivot.date_program_finish).format('DD/MM/yyyy')}</span>
        ),
        style: {textAlign: 'center'},
        filterable: false
      },
      {
          Header: <b>Action</b>,
          id: 'delete',
          accessor: str => "delete",
          Cell: (row) => (
              <div className='row justify-content-center'>
                <div className='col-md-3'>
                    <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none',display:this.props.display}} ><i className="fa fa-trash"></i></button>
                </div>
                <div className='col-md-3'>
                    <button onClick={() => this.editProgram(row.original)} className="btn btn-success btn-xs" type="button" style={{border:'none', display: this.props.display}} ><i className="fa fa-pencil"></i>{row.id}</button>
                </div>               
              </div>
          ),
          style: { textAlign: 'center' },
          sortable: false,
          filterable: false
      }
    ]
    return (
      <Fragment>
        <div>
          <div className="card scroll">
            <div className="card-header bg-red-color">
              <div className="row">
                <div className="col-10">
                  <h5 style={{fontSize:15}}>Program</h5>
                </div>
                <div className="col-2">
                  <button onClick={() => this.addProgram()} className="btn btn-light btn-xs" type="button" style={{marginLeft: 'auto', border:'none',display:this.props.display}} ><i className="fa fa-plus color-red"></i></button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ReactTable
                  data={data}
                  columns={columns}
                  defaultPageSize={10}
                  className="-striped -highlight"
                  showPaginationBottom={false}
                  style={{
                    height: "300px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderEditDialog()}
        {this.renderDeleteDialog()}
        {this.renderPreview()}
        <ToastContainer/>
      </Fragment>
    );
  }
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users|| state.Login.detailUser.code_students,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(ProgramStudent));