import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { FileText, Users, Calendar, Home } from 'react-feather';
import Breadcrumb from '../../../components/common/breadcrumb';

class IndexEnglishStudent extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <>
                <Breadcrumb title="Students" parent="Students" />
                <div className='container-fluid'>
                    <div className='row theme-tab'>
                        <div className='col-sm-12'>
                            <RoutedTabs tabClassName="tab-link" activeTabClassName="active" >
                                <div className='tabs tab-title'>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-student/home`}>
                                        <Home /> Report
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-student/data`}>
                                        <Users /> Data Student
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-student/group`}>
                                        <FileText /> Teaching Notes
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/english-student/attendance`}>
                                        <Calendar /> Student Attandance
                                    </NavTab>
                                </div>
                            </RoutedTabs>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/english-student`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/english-student/home`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/english-student/home`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/english-student/data`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/english-student/group`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/english-student/attendance`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default IndexEnglishStudent