/* eslint-disable eqeqeq, no-useless-concat */

import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { matchSorter } from 'match-sorter';

class DetailGoldenTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGoldenTicket: [],
            dataCategory: [],
            rowGoldenTicket: {},
            rowLogGoldenTicket: {},
            modalAddFollowUp: false,
            modalViewFollowUp: false,
            noteFollowUp: '',
            potensial: '',
        }
    }

    componentDidMount() {
        this.getDetailGoldenTicket();
        this.getDataCategory();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.code_konsultan !== this.props.code_konsultan) {
            this.getDetailGoldenTicket();
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    getDetailGoldenTicket() {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.code_konsultan)
        formData.append('start_date', this.props.start_date)
        formData.append('end_date', this.props.end_date)
        formData.append('type', 'golden_tiket')
        axios.post(API_URL.superStudent+'/list-data-follow-up', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                dataGoldenTicket: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    getDataCategory() {
        const url = API_URL.superStudent+'/master/student-category';
        axios.get(url,{
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataCategory: data })
        })
    }

    saveData() {
        let formData = new FormData()
        formData.append('type', 'golden_tiket')
        formData.append('id', this.state.rowGoldenTicket.id_sekolah_siswa)
        formData.append('code_konsultan', this.props.dataUser.code_konsultan)
        formData.append('code_students_category', this.state.potensial)
        formData.append('data_log', this.state.noteFollowUp)
        axios.post(API_URL.superStudent+'/log-follow-up', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                modalAddFollowUp: false
            },() => {
                this.getDetailGoldenTicket();
            })
        })
        .catch(error => console.log(error))
    }

    openModalAddFollowUp(row) {
        this.setState({
            modalAddFollowUp: true,
            rowGoldenTicket: row,
            potensial: row.code_students_category,
        })
    }

    openModalViewFollowUp(row) {
        this.setState({
            modalViewFollowUp: true,
            rowGoldenTicket: row,
            rowLogGoldenTicket: row.log,
        })
    }

    closeModalAddFollowUp() {
        this.setState({
            modalAddFollowUp: false
        })
    }

    closeModalViewFollowUp() {
        this.setState({
            modalViewFollowUp: false
        })
    }

    renderAddFollowUp() {
        const { modalAddFollowUp, rowGoldenTicket } = this.state;
        return (
            <Modal isOpen={modalAddFollowUp} toggle={() => this.closeModalAddFollowUp()} >
                <ModalHeader toggle={() => this.closeModalAddFollowUp()}>Add Follow Up Golden Ticket</ModalHeader>
                <ModalBody>
                    <h5>{rowGoldenTicket.nama_siswa}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className='form-row'>
                            <div className='col-md-12 mt-3'>
                                <label>Potensial</label>
                                <select className="form-control" name='potensial' value={this.state.potensial} onChange={(e) => this.handleInput(e)}>
                                    <option key={0} value=''>--Pilih Potensial--</option>
                                    {
                                        this.state.dataCategory.map( item => {
                                        return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-md-12">
                                <label>Note Follow Up</label>
                                <textarea type='text' className='form-control' onChange={(e) => this.handleInput(e)} value={this.state.noteFollowUp} name='noteFollowUp'></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalAddFollowUp()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderViewFollowUp() {
        const { modalViewFollowUp, rowGoldenTicket, rowLogGoldenTicket } = this.state;
        return (
            <Modal isOpen={modalViewFollowUp} toggle={() => this.closeModalViewFollowUp()} >
                <ModalHeader toggle={() => this.closeModalViewFollowUp()}>View History Follow Up Golden Ticket</ModalHeader>
                <ModalBody>
                    <h5>{rowGoldenTicket.nama_siswa}</h5>
                    <div className="card-body scroll scrool recent-notification mt-3">
                        {
                            rowLogGoldenTicket.length > 0 ?
                            rowLogGoldenTicket.map((dataLogFollowUp, index)=>(
                                <div className="media" key={index}>
                                    <div className=""><span>{moment(dataLogFollowUp.created_at).format('DD MMM YY')}</span>
                                        <p>{moment(dataLogFollowUp.created_at).format('HH:mm')}</p>
                                    </div>
                                    <div className="media-body">
                                        <div>
                                            <span>Already Follow Up with notes <p style={{fontSize:'1em', color:'#000', fontWeight:'bold'}}>{dataLogFollowUp.data_log}</p></span>
                                            <p className="f-12">{dataLogFollowUp.consultant != null ? dataLogFollowUp.consultant.nama :''}</p>
                                        </div> 
                                    </div>
                                </div>
                            ))
                            :
                            ''
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModalViewFollowUp()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const column = [
            {
                Header: 'Name',
                accessor: 'nama_siswa',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama_siswa"] }),
                filterAll: true,
            },
            {
                Header: 'No. Hp.',
                accessor: 'no_wa_siswa',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_wa_siswa"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.no_wa_siswa.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.no_wa_siswa} rel='noreferrer noopener' target='_blank'>{row.original.no_wa_siswa}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_wa_siswa.substring(1)} rel='noreferrer noopener' target='_blank'>{row.original.no_wa_siswa}</a>
                )
            },
            {
                Header: 'Email',
                accessor: 'email_siswa',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email_siswa"] }),
                filterAll: true,
            },
            {
                Header: 'Sekolah',
                accessor: 'sekolah.nama_sekolah',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["sekolah.nama_sekolah"] }),
                filterAll: true,
            },
            {
                Header: 'Status Follow Up',
                accessor: 'log',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        {
                            row.original.log.length > 0 ?
                            <span className={'badge bg-success'}>Sudah Follow Up</span>
                            :
                            <span className={'badge bg-danger'}>Need Follow Up</span>
                        }
                    </>
                )
            },
            {
                Header: 'Action',
                filterable: false,
                Cell: (row) => (
                    <div className='row justify-content-center'>
                        {
                            this.props.dataUser.code_roles != 'RL001' && this.props.code_roles != 'RL003' ? 
                            <div className='col-md-3'>
                                <button onClick={() => this.openModalAddFollowUp(row.original)} className="btn btn-success btn-xs" type="button"><i className="fa fa-pencil"></i></button>
                            </div>
                            :
                            ''
                        }
                        <div className='col-md-3'>
                            <button onClick={() => this.openModalViewFollowUp(row.original)} className="btn btn-info btn-xs" type="button"><i className="fa fa-list"></i></button>
                        </div>
                    </div>
                )
            },
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-sm-11'>
                                                <h5>Detail Golden Ticket {this.props.nama}</h5>
                                            </div>
                                            <div className='col-sm-1'>
                                                <button className='btn-danger' onClick={() => this.props.closeDetailAll()}><i className='fa fa-close'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <ReactTable
                                            data={this.state.dataGoldenTicket}
                                            columns={column}
                                            defaultPageSize={20}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderAddFollowUp()}
                {this.renderViewFollowUp()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(DetailGoldenTicket))