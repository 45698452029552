import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";


class RegisterDetailEventExpo extends Component {

    constructor(props){
        super(props)
        this.state = {
            
        }
    }
    
    render() {
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Medan </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.medan.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Tangerang </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.tangerang.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Makassar </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.makassar.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Yogyakarta </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.yogya.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Singaraja </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.singaraja.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Denpasar </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.denpasar.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Online </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={this.props.online.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (RegisterDetailEventExpo);