import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import API_URL from '../../../../ApiUrl'
import { toast } from 'react-toastify';


export class ListeningTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnLoading: false,
            modal:false,
            addModal: false,
            title: 'Listening Answer',
            data: {
                question: '',
                opt_a: '',
                opt_b: '',
                opt_c: '',
                opt_d: '',
                id_question_type: 3,
                id_question: 'null'
            },
            audio_question: '',
            }
        }

    handleFile(e){
        const {name, files} = e.target
        this.setState({[name]: files[0]})
    }

    handleChange(e){
        const {name, value} = e.target
        this.setState({data:{
            ...this.state.data, [name]: value
        }})
    }

    openModalAddAudio(){
        this.setState({modal: true})
    }
    
    addSoal(){
        this.setState({addModal: true})
    }

    editSoal(row){
        this.setState({
            addModal: true, 
            data:{...this.state.data, 
                question: row.question,
                opt_a: row.opt_a,
                opt_b: row.opt_b,
                opt_c: row.opt_c,
                opt_d: row.opt_d,
                id_question: row.id
            }
            })
    }

    closeModal(){
        this.setState({addModal: false})
    }
    
    closeModalAddAudio(){
        this.setState({modal: false})
    }

    saveAudio(){
        // console.log(this.state.audio_question)
        this.setState({btnLoading: true})
        let formData = new FormData();
        formData.append("file", this.state.audio_question);
        formData.append("file_name", this.state.audio_question.name);
        axios.post(API_URL.placementTest + 'addAudio', formData, {
            headers: {
                'Authorization': `Bearer ` + API_URL.placementTestToken,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res=>{
            this.setState({btnLoading: false})
            this.closeModalAddAudio()
            toast.success("Question Added")
            // console.log(res)
        }).catch(err => console.log(err))
    }

    sendData(){
        console.log(this.state.data)
        axios({
            method: 'POST',
            url: API_URL.placementTest + 'updateStructure',
            data: this.state.data,
            headers: {
                Authorization: `Bearer ${API_URL.placementTestToken}`
            }
        }).then(res=>{
            toast.success("Question Updated")
            this.closeModal()
            this.props.loadListening()
            // console.log(res);    
        }).catch(err => console.log(err))
    }

    renderAddAudioModal(){
        return(
            <Modal isOpen={this.state.modal} toggle={() => this.closeModalAddAudio()} size='lg'>
                <ModalHeader toggle={() => this.closeModalAddAudio()}>Add Audio Question</ModalHeader>
                <ModalBody>
                    <form>  
                        <div class="form-group">
                            <label for="exampleFormControlFile1">Audio Question</label>
                            <input type="file" name='audio_question' onChange={(e) => this.handleFile(e)} class="form-control-file" id="exampleFormControlFile1" />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={saveAudio}>{loading ? 'Add Audio Question....' : 'Add Audio Question'}</Button> */}
                    <Button disabled={this.state.btnLoading && true} color="primary" onClick={() => this.saveAudio()}>Add Audio Question</Button>
                    <Button color="secondary" onClick={() => this.closeModalAddAudio()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderAddSoalModal(){
        const {addModal,title, data} = this.state
        return (
            <>
            <Modal isOpen={addModal} toggle={() => this.closeModal()} size='lg'>
                <ModalHeader toggle={() => this.closeModal()}>{title}</ModalHeader>
                <ModalBody>
                <form>
                    <div class="form-group">
                        <label htmlFor="exampleInputEmail1">Correct Answer</label>
                        <input type="text" name='opt_a' className="form-control" id="exampleInputEmail1" placeholder="Enter correct answer" value={data?.opt_a} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_b' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_b} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_c' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_c} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Other Option</label>
                        <input type="text" name='opt_d' className="form-control" id="exampleInputEmail1" placeholder="Enter other answer" value={data?.opt_d} onChange={(e)=>this.handleChange(e)} />
                    </div>
                    
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.sendData()}>Add</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </>
        )
    }

  render() {
    const {data} = this.props
    let k = 0
    return (
        <>
        <button onClick={() => this.openModalAddAudio()} type="button" className='btn btn-success mb-2'>Add Audio Question</button>
        <p>"Direction: Choose the option to complete these sentence below"</p>
        {
        data?.map((s, i)=>{
            return(
                <>
                <div className="audio-question mb-3" style={{padding: '20px 0'}} key={i}>
                    <div className="d-flex align-items-center">
                        <audio controls controlsList="nodownload" className='audio-listening mb-2 mr-2'>
                            <source src={s.audio_question} type="audio/mp3" />
                        </audio>
                        {/* <i className="fa fa-pencil mr-2"></i>
                        <i className="fa fa-plus mr-2"></i> */}
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    {/* <th scope="col">Question</th> */}
                                    <th scope="col">Correct Answer</th>
                                    <th scope="col">Other Options</th>
                                    <th scope="col">Other Options</th>
                                    <th scope="col">Other Options</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                s.tb_question?.map((s, d)=>{
                                    return(
                                        <tr key={d}>
                                            <th scope="row">{k++ + 1 }</th>
                                            <td>{s.opt_a}</td>
                                            <td>{s.opt_b}</td>
                                            <td>{s.opt_c}</td>
                                            <td>{s.opt_d}</td>
                                            <td><button onClick={() => this.editSoal(s)} class="btn btn-outline-success btn-xs" type="button" style={{border:'none', fontSize: 15}}><i className="fa fa-pencil"></i></button></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <hr />
                </div>
                </>
            )
        })
        }
        {this.renderAddAudioModal()}
        {this.renderAddSoalModal()}
        </>
      )
  }
}

export default ListeningTable

