import React ,{Component} from 'react';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from "react-router";
import image from '../../../assets/images/user/6.jpg'
import CKEditors from "react-ckeditor-component";

class StepOne extends Component{

    constructor(props){
        super(props)
        this.state =  {
            id:'',
            title:'',
            category_id:'',
            content:'',
            cover:'',
            view_cover:'',
            price:'',
            textSave:'Save',
            btnDisable: false,
            componentStyle:'',
            errors:[],
            listCategory:[],
            action:''
        }
    }

    componentDidMount(){
        this.loadCategory()
        if(this.props.location.state === undefined)
            this.setState({ action: 'tambah' })
        else {
            let data = this.props.location.state
            this.setState({
                action: 'edit',
                id:data.id,
                title: data.course_name,
                category_id: data.category_id,
                content: data.description,
                price: data.price,
                cover: data.cover,
                view_cover: data.cover
            })
        }
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFile = (e) => {
        this.setState({ 
            cover: e.target.files[0]
        })
    }

    loadCategory = () => {
        axios.get(API_URL.superStudent+'/course/list-category',{
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => this.setState({ listCategory: response.data.data }))
        .catch(error => console.log(error))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            textSave:'Saving...',
            btnDisable:true 
        })
        let formData = new FormData()
        formData.append('id', this.state.id)
        formData.append('title', this.state.title)
        formData.append('partner_id', this.props.userable.id)
        formData.append('category', this.state.category_id)
        formData.append('description', this.state.content)
        formData.append('price', this.state.price)
        formData.append('cover', this.state.cover)

        let url = '/course/course-list'

        if (this.state.action === 'edit'){
            formData.append('_method','PATCH');
            url = '/course/course-list/'+this.state.id
        }
        axios({
            method:'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({ componentStyle:'none' })
            this.props.SaveFormOne(response.data.data)
            toast.success('data saved successfully')
        })
        .catch(error => {
            console.log(error)
            if(error.response.status === 422){
                this.setState({
                    errors:error.response.data.data,
                    btnDisable:false,
                    textSave:'Save'
                })
            }
        })  
    }

    render(){
        return(
            <>
               <form style={{display:this.state.componentStyle}} onSubmit={this.handleSubmit}>
                    <div className='form-row'>
                        <div className="col-md-6 mb-3">
                            <label className='f-w-600'>Title<span className="text-danger">*</span></label>
                            <input className="form-control" value={this.state.title} onChange={this.handleInput} name="title" type="text" placeholder="Course title"/>
                            <div className="text-danger">{this.state.errors.title}</div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className='f-w-600'>Cateogy<span className="text-danger">*</span></label>
                            <select className='form-control' onChange={this.handleInput} value={this.state.category_id} name='category_id'>
                                <option> --Select--</option>
                                {
                                    this.state.listCategory.length > 0 ?
                                    this.state.listCategory.map(category => (
                                        <option key={category.id} value={category.id} >{category.category_name}</option>
                                    ))
                                    :
                                    ''
                                }
                            </select>
                            <div className="text-danger">{this.state.errors.category}</div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label className='f-w-600' >Price<span className="text-danger">*</span></label>
                            <input type='number' className='form-control' onChange={this.handleInput} placeholder='Course price' name='price' value={this.state.price}/>
                            <div className="text-danger">{this.state.errors.price}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-md-8 mb-4'>
                            <label className='f-w-600'>Description<span className="text-danger">*</span></label>
                            <CKEditors
                                activeclassName="p10"
                                content={this.state.content}
                                    events={{
                                        "change": this.onChange.bind(this),
                                    }}
                                    config={{
                                    toolbar:[
                                        ["Bold","Italic",'Strike','RemoveFormat'],
                                        ['NumberedList','BulletedList','Indent','Outdent'],
                                        ['Link','Unlink'],
                                        ['Styles','Format','Table','Blockquote','Maximize'],
                                        ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                    ]
                                    }}     
                            />
                             <div className="text-danger">{this.state.errors.description}</div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className='f-w-600'>Cover</label>
                            <input className="form-control" onChange={this.handleFile} type="file" placeholder="input here."/>
                            {
                                this.state.action === 'edit' ? 
                                <figure className="col-md-12 mt-2">
                                    <img src={this.state.view_cover !== null ? this.state.view_cover : image} alt="images" className="img-thumbnail" />
                                </figure>
                                :
                            ''
                            }
                        </div>
                    </div>
                    <div className='form-row'>
                        <button disabled={this.state.btnDisable} type='submit' className='btn btn-success float-right'>{this.state.textSave}</button>
                    </div>
                </form>
            </>
        )
    }
}

const reduxState = (state) => ({
    userable: state.Login.userableData.userable,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(StepOne));