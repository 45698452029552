import React, { Fragment } from 'react';
import { Chart } from "react-google-charts";

const ChartData = ({grafik}) => {

    return(
        <Fragment>
            <Chart
                height={'250px'}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={grafik}
                options={{
                    colors: ["#7c9ed9", "red"],
                    legend: { position: "none" },
                    bars: "horizontal",
                    axes: {
                        x: {
                            0: { side: 'bottom', label: 'Total'}
                        }
                    }
                }}
            />
        </Fragment>
    )
}

export default ChartData;