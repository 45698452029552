import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Breadcrumb from '../../components/common/breadcrumb';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { Home } from 'react-feather';

class PaymentCoworking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operation: "",
            modal: false,
            modalDetail: false,
            errors: {},
            row: [],
            filtered:[],
            booking:[]
        }
    }

  render() {
    return (
        <Fragment>
            <Breadcrumb title="Co-Working Space" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/coworking-space/data-booking`}>
                                    <Home /> Home
                                </NavTab>
                                {/* <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/coworking-space/harga`}>
                                    <DollarSign /> Harga
                                </NavTab> */}
                                </div>
                        </RoutedTabs>

                        <Switch>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/pages/coworking-space`}
                            render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/coworking-space/data-booking`} />}
                        />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coworking-space/harga`}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    )
  }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(PaymentCoworking));

