import React, { Component } from 'react';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import Loader from '../../../components/common/loader';

class SchoolVisitSchedule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            schedulle_all:[],
            id_kantor: '',
            code_consultant: '',
        }
    }

    componentDidMount() {
        this.getActivities();
    }

    getActivities(){
        this.setState({
            schedulle_all: [],
            showLoading: true
        });

        var formData = new FormData();
        formData.append('id_kantor',this.state.id_kantor);
        formData.append('code_consultant',this.state.code_consultant);

        const url = API_URL.superStudent+'/school-activities/visit-all';
        axios.post(url, formData, 
            { 
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}`, 
                    'Content-Type': 'multipart/form-data' 
                }
            })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading: false
            }, () => {
                data.data.map(async (jadwal, i) => {
                    this.state.schedulle_all.push({
                        id: i+1, 
                        title: jadwal.title,
                        detail: jadwal.detail,
                        allDay: true,
                        start: new Date(jadwal.start_date),
                        end: new Date(jadwal.end_date),
                        borderColor: jadwal.border
                    })
                });
            });
        });
    }

    render() {

        const localizer = momentLocalizer(moment);
        let allViews = Object.keys(Views).map(k => Views[k])

        return(
            <>
                <div className="card height-equal">
                    <div className="card-header">
                        <h6 className='f-w-600'>School Visit Schedule</h6>
                    </div>
                    <div style={{height:450}} className="card-body scroll recent-notification">
                        <Calendar
                            localizer={localizer}
                            scrollToTime={new Date("2019-01-01")}
                            defaultDate={new Date()}
                            onSelectEvent={event => alert(event.detail)}
                            views={allViews}
                            events={this.state.schedulle_all}
                            eventOverlap
                            dragRevertDuration={500}
                            dragScroll
                            showMultiDayTimes
                            step={30}
                            startAccessor="start"
                            endAccessor="end"
                            eventPropGetter={myList =>({
                                style:{
                                    color: '#fff',
                                    backgroundColor: myList.borderColor
                                }
                            })}
                        />
                        <Loader showLoading={this.state.showLoading} />         
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataId: state.Login.detailUser.id_users,
    codeUsers: state.Login.detailUser.code_users,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(SchoolVisitSchedule);