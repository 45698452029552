import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTablesRoles from '../../components/tables/dataTableRoles';
import DataTableRolesList from '../../components/tables/dataTableRolesList';


const Roles = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- Page Body Start--> */}
                <div className="container-fluid p-0">
                    <Breadcrumb title="Roles" parent="Roles" />
                    <div className="row">
                        <div className="col-md-12">
                            <DataTablesRoles />
                        </div>
                        <div className="col-md-12">
                            <DataTableRolesList />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Roles;