/* eslint-disable eqeqeq, no-unused-vars, array-callback-return */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';
import { withRouter } from "react-router";
import Breadcrumb from '../../components/common/breadcrumb';
import Loader from '../../components/common/loader';
import Moment from 'moment';
import setRangeDate from '../../helper/general-function';

class DatatableSchool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataJenPen: [],
            employeeProfile: [],
            dataMasterArea: [],
            showLoading: false,
            showLoadingArea: false,
            editingRow: null,
            deletingRow: null,
            code_jenjang:"",
            id_area: "",
            id_propinsi: "",
            id_kabkota:"",
            nama_sekolah:"",
            alamat_sekolah:"",
            notlp_sekolah:"",
            top_school:"",
            errors: [],
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal:false,
            modalDelete: false,
            modalDetail: false,
            btnDisabled: false,
            logName: '',
            selectedDocument:'',
            listSchool: [],
            list_propinsi: [],
            list_kabkota: [],
            list_area_visit: [],
            list_sekolah_detail: [],
            kode_sekolah: '',
            staticFilter: '',
            dinamicFilter: 'none',
            dinamicMonth: 'none',
            month: Moment().format('YYYY-MM'),
            startDate: '',
            endDate: '',
            date:'this_year',
            bintang1: [],
            bintang3: [],
            bintang5: [],
            bintang0: [],
        }
    }

    componentDidMount() {
        this.getDataJenPen();
        this.getEmailStudent();
        this.loadProfile();
        this.getArea();
        this.setRangeDate();
    }

    handleChangeArea = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getDataPropinsi();
        })
    }

    handleChangePropinsi = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getDataSchool()
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value,
            showLoading: true
        },() => {
            if(this.state.date === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    dinamicMonth:'none',
                    staticFilter:'none'
                })
            } else if(this.state.date === 'dinammic_month'){
                this.setState({
                    dinamicFilter:'none',
                    dinamicMonth:'',
                    staticFilter:'none'
                })
            } else {
                this.setRangeDate()
            }
        })
    }

    handleChangeMonth = (e) => {
        this.setState({
            [e.target.name]:e.target.value,
            showLoading: true
        }, () => {
            this.setRangeMonth()
        })
    }

    setRangeDate(){ 
        let data = setRangeDate(this.state.date);
        this.setState({ 
            startDate: data.start,
            endDate: data.end
        },() => {
            this.getActivitiesArea();
        })
    }

    setRangeMonth() {
        let startDate = Moment(this.state.month+"-01");
        let endDate  = Moment(startDate).endOf('month');

        this.setState({ 
            startDate,
            endDate
        })
    }

    loadProfile = () => {
        axios.get(API_URL.superStudent+'/consultant/detail/'+this.props.dataUsers.code_konsultan,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ 
                employeeProfile: result,
                id_area: result.id_area
            }, () => {
                this.getDataPropinsi();
            })
        })
        .catch(error => console.log(error))
    }

    getDataPropinsi() {
        const url = API_URL.superStudent+'/master/provinces-by-area/'+this.state.id_area;
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                list_propinsi:data.data,
                id_propinsi:data.data[0].id
            },() => {
                this.getDataSchool();
            });
        })
        .catch(error => {
            console.log(error)
        }); 
    }
    
    getDataSchool() {
        this.setState({
            showLoading: true
        })

        let data = setRangeDate('this_year');

        let formData = new FormData();
        formData.append('id_provinsi', this.state.id_propinsi)
        formData.append('start_date', Moment(data.start).format('YYYY-MM-DD'))
        formData.append('end_date', Moment(data.end).format('YYYY-MM-DD'))

        const url = API_URL.superStudent+'/sekolah-indonesia-range';
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            if(data.code === 400) {
                const config = {
                    headers: { Authorization: `Bearer `+this.props.dataToken }
                };
                var formData = new FormData();
                formData.append('id_users',this.props.dataId);
                formData.append('api_token',this.props.dataToken);
                this.props.resettoken(formData, config);
            }else{
                this.setState({
                    listSchool:data.data,
                    showLoading: false
                });
            }
        });
    }

    getDataKabkota() {
        const url = API_URL.superStudent+'/master/kabupaten/'+this.state.id_propinsi;
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({list_kabkota:data.data});
        })
        .catch(error => {
            console.log(error);
        });
    }

    getActivitiesArea() {
        this.setState({
            showLoadingArea: true
        });

        let formData = new FormData();
        formData.append('start_date', this.state.date != "all_time" ? Moment(this.state.startDate).format('YYYY-MM-DD') : '')
        formData.append('end_date', this.state.date != "all_time" ? Moment(this.state.endDate).format('YYYY-MM-DD') : '')

        const url = API_URL.superStudent+'/activities-area';
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                list_area_visit: data.data,
                showLoadingArea: false
            },() => {
                this.getDataPropinsi();
            })
        });
    }

    getEmailStudent() {
        const url = API_URL.superStudent+'/email/peserta';
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            console.log(data);
        });
    }

    getDataJenPen(){
        const url = API_URL.superStudent+'/master/jenjang';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataJenPen: data })
        })
    }

    getArea(){
        const url = API_URL.superStudent+'/master/area';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataMasterArea: data })
        })
    }

    resetState(){
        this.setState({
            editingRow: null,
            deletingRow: null,
            code_jenjang:"",
            id_kabkota:"",
            nama_sekolah:"",
            alamat_sekolah:"",
            notlp_sekolah:"",
            top_school:"",
            errors: {},
        });
    }

    // handle on change   
    onChangeHandle = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        }, () => {
            this.getDataKabkota();
        })
    }
    // end handle on change

    renderStart = (number) => {
        const row = [];
        for (var i = 0; i < number; i++) {
          row.push(<i style={{color:'gold'}} className='fa fa-star' key={i}></i>);
        }
        return row;
    }

    addRow() {
        this.resetState();
        this.setState({
            title: 'Add School Data',
            operation: 'add',
            modal: true,
            logName: 'ADD-SCHOOL'
        });
    }

    editRow(row) {
        this.getDataKabkota();
        this.setState({
            title: 'Edit School',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-SCHOOL',
            code_jenjang: row.code_jenjang,
            id_propinsi: row.id_propinsi,
            id_kabkota: row.id_kabkota,
            nama_sekolah: row.nama_sekolah,
            alamat_sekolah: row.alamat_sekolah,
            notlp_sekolah: row.notlp_sekolah,
            top_school: row.top_school,
            kode_sekolah: row.kode_sekolah,
            errors: []
        });
    }

    deleteRow(row) {
        this.setState({
            deletingRow: row,
            model: Object.assign({}, row),
            title: 'Delete School',
            operation: 'delete',
            modalDelete: true,
            logName: 'DELETE-SCHOOL',
            kode_sekolah: row.kode_sekolah
        });
    }

    detailRow(row) {
        this.props.history.push({
            pathname: `${process.env.PUBLIC_URL}/pages/schooldetail/`+row.kode_sekolah+`/activities`
        })
    }

    saveRow(){
        this.sendData();
    }

    destroyRow() {
        axios.delete(API_URL.superStudent+'/sekolah-indonesia/'+this.state.kode_sekolah, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataSchool();
            this.setState({ btnDisabled: false });
            toast.success("Save Sucsessfully");
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('kode_sekolah',this.state.kode_sekolah); 
        formData.append('code_jenjang',this.state.code_jenjang);
        formData.append('id_propinsi',this.state.id_propinsi);
        formData.append('id_kabkota',this.state.id_kabkota);
        formData.append('nama_sekolah',this.state.nama_sekolah);
        formData.append('alamat_sekolah',this.state.alamat_sekolah);
        formData.append('notlp_sekolah',this.state.notlp_sekolah);
        formData.append('top_school',this.state.top_school);

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/sekolah-indonesia';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/sekolah-indonesia/'+this.state.kode_sekolah
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataSchool();
            this.setState({ btnDisabled: false });
            toast.success("Save Sucsessfully");
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    showDetail(area) {
        this.setState({
            showLoadingArea: true,
            bintang1: [],
            bintang3: [],
            bintang5: [],
            bintang0: [],
        });

        let formData = new FormData();
        formData.append('start_date', this.state.date != "all_time" ? Moment(this.state.startDate).format('YYYY-MM-DD') : '')
        formData.append('end_date', this.state.date != "all_time" ? Moment(this.state.endDate).format('YYYY-MM-DD') : '')
        formData.append('id_area', area.id_area)

        const url = API_URL.superStudent+'/activities-area-detail';
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                modalDetail: true,
                title: "Detail Sekolah",
                list_sekolah_detail: data.data,
                showLoadingArea: false,
            },() => {
                this.state.list_sekolah_detail.map((act) => {
                    if (act.sekolah != null) {
                        if (act.sekolah.student_granted > 0) {
                            this.setState(prevState => ({
                                bintang5: [...prevState.bintang5, act]
                            }))
                        } else if(act.sekolah.student_member > 0) {
                            this.setState(prevState => ({
                                bintang3: [...prevState.bintang3, act]
                            }))
                        } else if(act.sekolah.top_school == 1) {
                            this.setState(prevState => ({
                                bintang1: [...prevState.bintang1, act]
                            }))
                        } else {
                            this.setState(prevState => ({
                                bintang0: [...prevState.bintang0, act]
                            }))
                        }
                    }
                })
            })
        });
    }

    showSingleDetail(area, type_detail) {
        this.setState({
            showLoadingArea: true,
        });

        let formData = new FormData();
        formData.append('start_date', this.state.date != "all_time" ? Moment(this.state.startDate).format('YYYY-MM-DD') : '')
        formData.append('end_date', this.state.date != "all_time" ? Moment(this.state.endDate).format('YYYY-MM-DD') : '')
        formData.append('id_area', area.id_area)
        formData.append('type_detail', type_detail)
        
        const url = API_URL.superStudent+'/activities-area-detail-single';
        axios.post(url, formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                showLoadingArea: false,
            }, () => {
                console.log(data.data);
            });
        });

    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false,
            modalDetail: false,
        });
        this.resetState();
    }

    renderEditDialog() {
        const row = this.state;
        const { title } = this.state;
        const modal1 = this.state.modal;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                <label htmlFor="code_jenjang">Jenjang Pendidikan</label>
                                    <select className="form-control" name="code_jenjang" type="text" id="code_jenjang"  value={row.code_jenjang}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputJenPen = e}
                                    >
                                    <option value="0">--Pilih Jenjang Pendidikan--</option>
                                        {this.state.dataJenPen.map((jenjang_pendidikan)=>(
                                            <option key={jenjang_pendidikan.code_jenjang} value={jenjang_pendidikan.code_jenjang}>{jenjang_pendidikan.jenjang_pendidikan}</option>
                                        ))} 
                                    </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.code_jenjang}</span>
                                <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="id_propinsi">Propinsi</label>
                                    <select className="form-control" name="id_propinsi" type="text" id="id_propinsi"  value={row.id_propinsi}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputPropinsi = e}
                                    >
                                        <option value="0">--Pilih Propinsi--</option>
                                        {this.state.list_propinsi.map((propinsi)=> (
                                            <option key={propinsi.id} value={propinsi.id}>{propinsi.province_name}</option>
                                        ))} 
                                    </select>
                                    <span style={{color:"#FF5370"}}>{this.state.errors.id_propinsi}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                <label htmlFor="id_kabkota">Kabupaten / Kota</label>
                                    <select className="form-control" name="id_kabkota" type="text" id="id_kabkota"  value={row.id_kabkota}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputKabkota = e}
                                    >
                                        <option value="0">--Pilih Kabupaten--</option>
                                        {this.state.list_kabkota.map((kabkota)=>(
                                            <option key={kabkota.id} value={kabkota.id}>{kabkota.kabupaten_name}</option>
                                        ))} 
                                    </select>
                                <span style={{color:"#FF5370"}}>{this.state.errors.id_kabkota}</span>
                                <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="nama_sekolah">School Name</label>
                                    <input className="form-control" name="nama_sekolah" type="text" id="nama_sekolah"  value={row.nama_sekolah}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputSchoolName = e}
                                    />
                                    <span>{this.state.errors.nama_sekolah}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="alamat_sekolah">School Address</label>
                                    <input className="form-control" name="alamat_sekolah" type="text" id="alamat_sekolah"  value={row.alamat_sekolah}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputSchoolAddres = e}
                                    />
                                    <span>{this.state.errors.alamat_sekolah}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="notlp_sekolah">School Phone</label>
                                    <input className="form-control" name="notlp_sekolah" type="text" id="notlp_sekolah"  value={row.notlp_sekolah}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputSchoolPhone = e}
                                    />
                                    <span>{this.state.errors.notlp_sekolah}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="top_school">Top School</label>
                                    <select className="form-control" name="top_school" type="text" id="top_school"  value={row.top_school}
                                        onChange={(e) => this.onChangeHandle(e)}
                                        ref={e => this.inputtopSchool = e}
                                    >
                                        <option value="0">Tidak</option>
                                        <option value="1">Ya</option>
                                    </select>
                                    <span>{this.state.errors.top_school}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderLoading() {
        return (
            <Fragment>
                <div className={`loader-wrapper2`}>
                    <div className="loader-box">
                        <div className="loader">
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                            <div className="line bg-primary"></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.destroyRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderSekolahDetail() {
        const { title } = this.state;
        const modal2 = this.state.modalDetail;

        return (
            <div className="card-body btn-showcase">
                <Modal size="lg" isOpen={modal2} toggle={() => this.setState({ modalDetail: false })}>
                    <ModalHeader toggle={() => this.setState({ modalDetail: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            {
                                this.state.bintang5.length > 0 ?
                                <div className='col-lg-6 col-md-12 mt-3'>
                                    <ul>
                                        <li className='f-w-600'>{this.renderStart(5)}</li>
                                        {
                                            this.state.bintang5.map((items, index) => (
                                                <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i>({items.code_school}) - {items.sekolah.nama_sekolah}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                :
                                ''
                            }
                            <br/>
                            {
                                this.state.bintang3.length > 0 ?
                                <div className='col-lg-6 col-md-12 mt-3'>
                                    <ul>
                                        <li className='f-w-600'>{this.renderStart(3)}</li>
                                        {
                                            this.state.bintang3.map((items, index) => (
                                                <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i>({items.code_school}) - {items.sekolah.nama_sekolah}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                :
                                ''
                            }
                            <br/>
                            {
                                this.state.bintang1.length > 0 ?
                                <div className='col-lg-6 col-md-12 mt-3'>
                                    <ul>
                                        <li className='f-w-600'>{this.renderStart(1)}</li>
                                        {
                                            this.state.bintang1.map((items, index) => (
                                                <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i>({items.code_school}) - {items.sekolah.nama_sekolah}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                :
                                ''
                            }
                            <br/>
                            {
                                this.state.bintang0.length > 0 ?
                                <div className='col-lg-6 col-md-12 mt-3'>
                                    <ul>
                                        <li className='f-w-600'>No. Bintang</li>
                                        {
                                            this.state.bintang0.map((items, index) => (
                                                <li key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i>({items.code_school}) - {items.sekolah.nama_sekolah}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                :
                                ''
                            }
                            <br/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {
        const { pageSize, multiSelectOption } = this.props;
        const columns = [
            {
                Header: 'School Name',
                accessor: 'nama_sekolah',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_sekolah"] }),
                    filterAll: true
            },
            {
                Header: 'Total Loa',
                accessor: 'student_loa',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student_loa"] }),
                    filterAll: true
            },
            {
                Header: 'Total Grandted',
                accessor: 'student_granted',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student_granted"] }),
                    filterAll: true
            },
            {
                Header: 'Total Member',
                accessor: 'student_member',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student_member"] }),
                    filterAll: true
            },
            {
                Header: 'Last Visit',
                accessor: 'last_visit',
                Cell:(row) => (
                    row.original.last_visit != null ? 
                    Moment(row.original.last_visit).format('DD MMM YY')
                    : 
                    row.original.student_granted > 0 || row.original.student_member > 0 || row.original.top_school > 0 ?
                    <span className={'badge bg-danger'}>Need Follow Up</span>
                    :
                    ''
                )
            },
            {
                Header: 'Last Meeting',
                accessor: 'last_visit',
                Cell:(row) => (
                    row.original.last_meeting != null ? Moment(row.original.last_meeting).format('DD MMM YY') : ''
                )
            },
            {
                Header: 'Rating',
                accessor: 'rating',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div>
                        {this.renderStart(row.original.rating)}
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.detailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>                
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none', display: this.props.display}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none',display:this.props.display}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        const area_columns = [
            {
                Header: 'Area',
                style: {textAlign: 'center'},
                accessor: 'area',
            },
            {
                Header: 'Total Visit',
                style: {textAlign: 'center'},
                accessor: 'total_visit',
                Cell: (row) => (
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => this.showDetail(row.original)}>{row.original.total_visit}</span> <span>/</span> <span>{row.original.target}</span>
                    </div>
                )
            },
            {
                Header: this.renderStart(5),
                style: {textAlign: 'center'},
                accessor: 'bintang_5',
                Cell: (row) => (
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'visit_bintang_5')}>{row.original.bintang_5}</span> <span>/</span> <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'total_bintang_5')}>{row.original.total_school_granted}</span>
                    </div>
                )
            },
            {
                Header: this.renderStart(3),
                style: {textAlign: 'center'},
                accessor: 'bintang_3',
                Cell: (row) => (
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'visit_bintang_3')}>{row.original.bintang_3}</span> <span>/</span> <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'total_bintang_3')}>{row.original.total_school_member}</span>
                    </div>
                )
            },
            {
                Header: this.renderStart(1),
                style: {textAlign: 'center'},
                accessor: 'bintang_1',
                Cell: (row) => (
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'visit_bintang_1')}>{row.original.bintang_1}</span> <span>/</span> <span style={{cursor: 'pointer'}} onClick={() => this.showSingleDetail(row.original, 'total_bintang_1')}>{row.original.total_school_top}</span>
                    </div>
                )
            },
            {
                Header: 'No Bintang',
                style: {textAlign: 'center'},
                accessor: 'bintang_0',
            },
        ]

        return (
            <Fragment>
                <div className="container-fluid">
                    <Breadcrumb title="School" parent="School" />
                    <div className="row m-1">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body justify-content-center text-center">
                                    <div className="row">
                                        <div className='col-sm-12 text-center'>
                                            <h5>Date Range</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div style={{display:this.state.staticFilter}} className="row justify-content-md-left justify-content-xs-left">
                                                <select name='date' value={this.state.date} onChange={this.handleInput} className='form-control'>
                                                    <option value='all_time'>All Time</option>
                                                    <option value='this_week'>This Week</option>
                                                    <option value='last_week'>Last Week</option>
                                                    <option value='this_month'>This Month</option>
                                                    <option value='last_month'>Last Month</option>
                                                    <option value='this_year'>This Year</option>
                                                    <option value='last_year'>Last Year</option>
                                                        <optgroup label='Quartal'>
                                                            <option value='1'>Quartal I</option>
                                                            <option value='2'>Quartal II</option>
                                                            <option value='3'>Quartal III</option>
                                                            <option value='4'>Quartal IV</option>
                                                    </optgroup>
                                                    <option value='dinammic_date'> --Dinammic Date-- </option>
                                                    <option value='dinammic_month'> --Dinammic Month-- </option>
                                                </select>
                                            </div>
                                            <div style={{display:this.state.dinamicMonth}} className="row justify-content-md-left justify-content-xs-left">
                                                <div className="col-sm-10">
                                                    <input className='form-control digits' name='month' type='month' defaultValue={this.state.month} onChange={this.handleChangeMonth}></input>
                                                </div>
                                                <div className="col-md-2 btn-group" role='group'>
                                                    <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicMonth:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                                                </div>
                                            </div>
                                            <div style={{display:this.state.dinamicFilter}} className="row justify-content-md-left justify-content-xs-left">
                                                <div className="col-md-5">
                                                    <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value)})} name="startDate" />
                                                </div>
                                                <div className="col-md-5">
                                                    <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value)}, () => {this.getDataSchool()})} name="endDate" />
                                                </div>
                                                <div className="col-md-2 btn-group" role='group'>
                                                    <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-1'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body datatable-react'>
                                    <div className='row'>
                                        <div className='col-sm-12 mb-5 text-center'>
                                            <span style={{fontSize:'1.5em'}} className='f-w-600'>{this.state.date == "all_time" ? "ALL" : Moment(this.state.startDate).format('DD MMMM yyyy')} - {this.state.date == "all_time" ? "TIME" : Moment(this.state.endDate).format('DD MMMM yyyy')}</span> 
                                        </div>
                                    </div>
                                    {
                                        this.state.list_area_visit.length > 0 ?
                                        <ReactTable 
                                            data={this.state.list_area_visit}
                                            columns={area_columns}
                                            showPagination={true}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                        />
                                        :
                                        <Loader showLoading={this.state.showLoadingArea} />
                                    }
                                    <Loader showLoading={this.state.showLoadingArea} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-1'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-body datatable-react'>
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <h5>Area</h5>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5>Provinsi</h5>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <select className="form-control" onChange={this.handleChangeArea} name="id_area" value={this.state.id_area}>
                                                {
                                                    this.state.dataMasterArea.map(area => (
                                                        <option value={area.id_area} key={area.id_area}>{area.area}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-right">
                                                <select className="form-control" onChange={this.handleChangePropinsi} name="id_propinsi" value={this.state.id_propinsi}>
                                                    {
                                                        this.state.list_propinsi.map(propinsi => {
                                                            return <option value={propinsi.id} key={propinsi.id}>{propinsi.province_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <div className="col-md-6">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-plus" style={{ width: 40, fontSize: 30 }}></i></button>
                                    </div>
                                    {
                                        this.state.listSchool.length > 0 ?
                                        <ReactTable 
                                            data={this.state.listSchool}
                                            columns={columns}
                                            showPagination={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                            defaultPageSize={100}
                                            className="-striped -highlight"
                                        />
                                        : 
                                        <Loader showLoading={this.state.showLoading} />
                                    }
                                    <Loader showLoading={this.state.showLoading} />
                                    <ToastContainer />
                                    {this.renderEditDialog()}
                                    {this.renderDeleteDialog()}
                                    {this.renderSekolahDetail()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
    dataUsers: state.Login.detailUser
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(DatatableSchool));