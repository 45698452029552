import React, { Fragment, Component } from 'react';
import DatatableDocument from '../common/datatable/datatableDocument';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listDocument: []
        }
    }

    componentDidMount() {
        this.getDataDocument();
    }

    getDataDocument(){
        axios.get(API_URL.superStudent+'/document',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.setState({
                listDocument : response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    render() {
        const data = this.state.listDocument;
        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DatatableDocument
                                        multiSelectOption={false}
                                        myData={data}
                                        title="Document Data"
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(DataTablesDocument));