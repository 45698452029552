/* eslint-disable eqeqeq */

import React, {Component} from "react"
import ReactTable from 'react-table'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import CKEditors from "react-ckeditor-component"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import moment from "moment"
import { withRouter } from "react-router";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class UserDonate extends Component {

    constructor(props){
        super(props)
        this.state  = {
            listDonate: []
        }
    }

    componentDidMount(){
        this.listDataDonate()
    }

    listDataDonate = () => {
        axios.get(API_URL.superStudent+'/list-donate', {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => { this.setState({ listDonate: response.data.data})})
        .catch(error => console.log(error))
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    renderModalPoint(){
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalPoint} toggle={() => this.setState({ modalPoint: false })} >
                    <ModalHeader toggle={() => this.setState({ modalPoint: false })}>Form Point</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group'>
                                        <label className='control-label'>Title</label>
                                        <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Nominal</label>
                                        <input className='form-control' type='text' name='nominal' value={this.state.nominal} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Periode</label>
                                        <input type='date' className='form-control' name='periode' value={this.state.periode} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Description</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.description}
                                            events={{
                                                    "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Syarat dan Ketentuan</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.syarat_ketentuan}
                                            events={{
                                                    "change": this.onChangeSyarat.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Image</label>
                                        <input className='form-control' type='file' onChange={this.handleImages}/>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Thumbnail</label>
                                        <input className='form-control' type='file' onChange={this.handleThumbnail}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                    <Button  color="secondary" onClick={() => this.setState({ modalPoint: false })}>Cancel</Button>
                    </ModalFooter>
              </Modal>
            </div>
        )
    }

    renderAction = (id, status) => {
        let formData = new FormData ()
        formData.append('id', id)
        formData.append('status_pembayaran', status)
        axios.post(API_URL.superStudent+'/handle-donate', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
            this.listDataDonate()
            toast.success('successfully approved task list')
        })
        .catch(error => {
            toast.error('there is an error')
        })
    }

    render(){

        const columns = [
            {
                Header: '#',
                accessor: 'created_at',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>{moment(row.original.created_at).format('DD MMM YYYY')}</>
                )
            },
            {
                Header: 'Name',
                accessor: 'user.nama_lengkap',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Nominal',
                accessor: 'harga',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    this.formatRupiah(row.original.harga)
                )
            },
            {
                Header: 'Status',
                accessor: 'status_pembayaran.status_pembayaran',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    <span className={row.original.status_pembayaran.code_status_pembayaran == 'PM00006' ? 'badge bg-danger' : row.original.status_pembayaran.code_status_pembayaran == 'PM00001' ? 'badge bg-success' : 'badge bg-primary'}>{row.original.status_pembayaran.status_pembayaran}</span>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    row.original.status_pembayaran.code_status_pembayaran == 'PM00005' ? 
                    <div className='row justify-content-center'>
                        <div className='col-md-3' style={{display: this.props.dataUser.code_roles === 'RL005' || this.props.dataUser.code_roles === 'RL001' ? '' : 'none'}}>                     
                        <button onClick={() => this.renderAction(row.original.id, 'PM00001')} className="btn btn-success btn-xs" type="button"  ><i className="fa fa-check"></i>{row.id_member}</button>
                        </div>
                        <div className='col-md-3'>
                        <button  onClick={() => this.renderAction(row.original.id,'PM00006')} className="btn btn-danger btn-xs"  ><i className='fa fa-times'></i></button>
                        </div>
                    </div> : ''
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]
        return (
            <>
                <div className='card' >
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.listDonate}
                            filterable
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            SubComponent={row => {
                                return(
                                  <div style={{padding:'20px'}} className='row'>
                                     <div dangerouslySetInnerHTML={{ __html: row.original.message }} />             
                                  </div>
                                )
                              }}
                        /> 
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})


export default connect(reduxState,null)(withRouter(UserDonate));