/* eslint-disable eqeqeq, no-unused-vars, array-callback-return */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import { 
    Row, Col,Card, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';

class PotensialEventExpoNew extends Component {

    constructor(props){
        super(props)
        this.state = {
            event_id: 0,
            registrasi_data: [],
            event_data: [],
            university: [],
            potensial_data: [],
            potensial_city: [],
            potensial_rep: [],
            potensial_detail: [],
            list_consultants: [],
            currentActiveTab:"1",
            activeTab:"1",
            university_name: "",
            city: "medan",
            id_kabkota: 104,
            modalSet: false,
            consultant: "",
            noted: "",
            potensial: "",
            dataDownload:[],
            register_kota: [],
        }
    }

    componentDidMount() {
        this.getDataEvent();
        this.loadConsultant();
    }

    loadConsultant = () => {
        axios.get(API_URL.superStudent + '/master/consultant/list-priotas', {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                list_consultants: result
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getDataEvent = () => {
        const url = API_URL.superStudent+'/event/jenis-event/3';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    getDataRegisterEvent = () => {
        axios.get(API_URL.eventUrl+'load-data/'+this.state.event_id+'/3', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ 
            registrasi_data: result
        }, () => {
            this.getPotensialRegister();
        }))
        .catch(error => console.log(error))
    }

    getDataRegisterEventByKota = () => {
        axios.get(API_URL.eventUrl+'load-data-kabkota/'+this.state.event_id+'/3', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({
            register_kota: result.event_lokasi
        }))
        .catch(error => console.log(error))
    }

    getPotensialRegister = () => {
        axios.get(API_URL.superStudent+'/event/potensial-register-new/'+this.state.event_id, {
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({
            potensial_data: result
        }, () => {
            this.getDataByCity(result, this.state.id_kabkota)
        }))
        .catch(error => console.log(error))
    }

    getDataByCity(data, city) {
        this.setState({
            potensial_city: []
        })

        const registerCity = data.filter(function (university) {
                university.registers_event = university.registers_event.filter(function (register) {
                return register.pivot.id_kabkota == city;
            })

            return university.registers_event.length > 0;
        })

        this.setState({
            potensial_city: registerCity
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => {
            this.getDataRegisterEvent()
            this.getDataRegisterEventByKota()
        })
    }

    handleChangeSet = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setTab(tab) {
        this.setState({ currentActiveTab: tab });
    }

    showDetail(detail, nama_sekolah) {
        this.setState({
            show: true,
            potensial_rep: [],
            university_name: nama_sekolah
        })

        detail.map(item => {
            this.setState(prevState => ({
                potensial_rep: [
                    ...prevState.potensial_rep, 
                    item
                ]
            }))
        })
    }

    renderModalSet = () => {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalSet} toggle={() => this.setState({ modalSet: false })}>
                    <ModalHeader toggle={() => this.setState({ modalSet: false })}>Set Handle</ModalHeader>
                    <ModalBody>
                        <div className='form-group'>
                            <select onChange={this.handleChangeSet} name='consultant' className='form-control'>
                                <option value=''>Pilih Consultant</option>
                                {
                                    this.state.list_consultants.map(function (consultan) {
                                        return (<option key={consultan.id} value={consultan.code}>{consultan.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <select onChange={this.handleChangeSet} name='potensial' className='form-control'>
                                <option value=''>Pilih Potensial</option>
                                <option value="Cold">Cold</option>
                                <option value="Warm">Warm</option>
                                <option value="Hot">Hot</option>
                            </select>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12 mb-3'>
                                <label htmlFor="name">Noted</label>
                                <textarea name='noted' onChange={this.handleChangeSet} className='form-control'  />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Set</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalSet: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    saveData() {
        let formData = new FormData()
        formData.append('id_register_rep', this.state.potensial_detail.pivot.id)
        formData.append('nama_lengkap', this.state.potensial_detail.name)
        formData.append('email', this.state.potensial_detail.email)
        formData.append('no_wa', this.state.potensial_detail.phone)
        formData.append('code_konsultan', this.state.consultant)
        formData.append('potensial', this.state.potensial)
        formData.append('code_students_category', 'SC00003')
        formData.append('noted', this.state.noted)

        axios.post(API_URL.superStudent+'/event/set-consultant-potensial', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500
            })
            this.getDataRegisterEvent()
            this.getDataRegisterEventByKota()
            this.setState({ 
                modalSet: false,
                show: false, 
            })
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    render(){
        const columns = [
            {
                Header: 'University',
                accessor: 'nama_sekolah',
                style: {textAlign: 'center'},
                filterable: true,
                Cell: (row) => (
                    <div>                     
                        <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill' onClick={() => this.showDetail(row.original.registers_event, row.original.nama_sekolah)}>{row.original.nama_sekolah}</button>
                    </div>
                ),
            },
            {
                Header: 'Hot',
                accessor: 'count_hot',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Warm',
                accessor: 'count_warm',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Cold',
                accessor: 'count_cold',
                style: {textAlign: 'center'},
            },
        ]

        const columns_detail = [
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Contulstant',
                accessor: 'student.consultant.nama',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Progress',
                accessor: 'student.progress.name_progress',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Consultant Tag',
                accessor: 'pivot.code_konsultan',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Potensial',
                accessor: 'pivot.status_potensial',
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Handle By',
                accessor: 'handle_by',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <button className='btn btn-success btn-sm btn-successs' onClick={() => this.setState({ modalSet: true, potensial_detail: row.original })}>Set</button>
                ),
                filterable: false
            }
        ];

        const tab = this.state.currentActiveTab;
        const Activetab = this.state.currentActiveTab;

        let total_hot = [];
        let total_warm = [];
        let total_cold = [];

        this.state.potensial_city.map(item => {
            item.count_hot = 0
            item.count_warm = 0
            item.count_cold = 0
            item.registers_event.map(arr => {
                switch (arr.pivot.status_potensial) {
                    case 'Hot':
                        total_hot.push(arr)
                        item.count_hot += 1
                    break;
                    case 'Warm':
                        total_warm.push(arr)
                        item.count_warm += 1
                    break;
                    case 'Cold':
                        total_cold.push(arr)
                        item.count_cold += 1
                    break;
                
                    default:
                        break;
                }
            })
        })

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-left">
                                                <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.registrasi_data.length} /></span> </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="text-right">
                                                    <select className="form-control" onChange={this.handleChange} name="event_id" value={this.state.event_id}>
                                                        <option value='0'> --Filter Event-- </option>
                                                        {
                                                            this.state.event_data.map(event => {
                                                                return <option value={event.id} key={event.id}>{event.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.register_kota.length > 0 ?
                            <div className='row m-1 mb-5 justify-content-center'>
                                <div className='col-xl-3 col-sm-3'>
                                    <select className='form-control' name="id_kabkota" onChange={this.handleChange} value={this.state.id_kabkota}>
                                        {
                                            this.state.register_kota.map((kota) => (
                                                <option value={kota.kabupaten.id_kabkota}>{kota.kabupaten.nama_kabkota}</option>
                                            ))
                                        }
                                        <option value={0}>ONLINE</option>
                                    </select>
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Potensial</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={total_hot.length + total_warm.length + total_cold.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Hot</h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={total_hot.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Warm </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={total_warm.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="mb-0" >Total Cold </h5>
                                        </div>
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={total_cold.length} />
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1 justify-content-center">
                            <div className="col-xl-12 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                    <Row>
                                        <Col sm="6">
                                            <h5 className="mb-0" >Potensial University</h5>
                                        </Col>
                                        {/* <Col sm='6'>
                                        <ExportCSV csvData={this.state.dataDownload} fileName={"data-potensial-wef"} /> 
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <Card body>
                                                <ReactTable
                                                    data={this.state.potensial_city}
                                                    columns={columns}
                                                    defaultPageSize={10}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={true}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.show == true ?
                            <div className="row m-1 justify-content-center">
                                <div className="col-xl-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Row>
                                                <Col sm="12">
                                                    <h5 className="mb-0" >Potensial Detail {this.state.university_name}</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <ReactTable
                                                            data={this.state.potensial_rep}
                                                            columns={columns_detail}
                                                            defaultPageSize={10}
                                                            className="-striped -highlight"
                                                            showPaginationBottom={true}
                                                            SubComponent={row => {
                                                                return (
                                                                    <div className='container m-5'>
                                                                        Note: {row.original.pivot.noted}
                                                                        <br />
                                                                        Note Konsultan: {row.original.pivot.consultant_noted}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
                {this.renderModalSet()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (PotensialEventExpoNew);