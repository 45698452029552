/* eslint-disable array-callback-return */

import axios from 'axios'
import React, { Component,Fragment } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import API_URL from '../../../ApiUrl'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {matchSorter} from 'match-sorter';
import { ExportCSV } from '../../../services/ExportCSV';

class DataTablesExpo extends Component{

    constructor(props){
        super(props)
        this.state = {
            disableButton:false,
            dataExpoPotential: [],
            dataExpoPotentialFormat: [],
            modal: false,
            register_id:'',
            status_potensial:'',
            university_id: this.props.userableData.userable.id,
            event_id: this.props.id_event,
            noted:'',
            keterangan: '',
            id_kabkota: '',
        }
    }

    componentDidMount(){
        this.getDataPotential()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getDataPotential = async () => {
        var formData = new FormData();
        formData.append('university_id', this.props.userableData.userable.id);
        formData.append('event_id', this.props.id_event);
        
        axios.post(API_URL.superStudent+'/expo2022/students-rep-event', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ 
                dataExpoPotential: result.registers_event 
            }, () => this.formatExcel())
        })
        .catch(error => {
        })
    }

    formatExcel() {
        let data = []
        this.state.dataExpoPotential.map((detail) => {
            let obj = {
                register_code : detail.register_code,
                nama : detail.name,
                email : detail.email,
                phone : detail.phone,
                potensial : detail.pivot.status_potensial,
                city_event: detail.registration_rep[0].kabkota != null ? detail.registration_rep[0].kabkota.nama_kabkota : ''
            }
            data.push(obj);
        });
        this.setState({
            dataExpoPotentialFormat: data
        });
    }

    closeDialog(){
        this.setState({
            modal: false,
        })
    }

    editRow(row) {
        this.setState({
            register_id: row.pivot.register_id,
            noted: row.pivot.noted,
            status_potensial: row.pivot.status_potensial,
            keterangan: row.pivot.keterangan,
            id_kabkota: row.pivot.id_kabkota,
            modal:true
        })
    }

    saveRow(){
        this.setState({
            disableButton:true
        })
        let formData = new FormData()
        formData.append('register_id', this.state.register_id)
        formData.append('university_id', this.state.university_id)
        formData.append('status_potensial', this.state.status_potensial)
        formData.append('id_kabkota', this.state.id_kabkota)
        formData.append('noted', this.state.noted)
        formData.append('event_id', this.state.event_id)
        formData.append('keterangan', this.state.keterangan)
        formData.append('id_queue', 0)

        axios.post(API_URL.superStudent+'/expo2022/set-status',formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
            this.closeDialog()
            this.getDataPotential()
            toast.success('successfully updated data')
        })
        .catch(error => {
            console.log(error)
            this.closeDialog()
            toast.error('something when wrong')
        })
    }

    renderDialog() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal} toggle={() => this.closeDialog()}>
                    <ModalHeader toggle={() => this.closeDialog()}>Edit Data</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Potential Student</label>
                                    <select className="form-control" name="status_potensial" value={this.state.status_potensial} onChange={this.handleInput} type="text" >
                                        <option value="">--Choose Status--</option>
                                        <option value="Cold">Cold</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Hot">Hot</option>
                                    </select>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor="name">Noted</label>
                                    <textarea name='noted' value={this.state.noted} onChange={this.handleInput} className='form-control'  />
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const columns = [
            {
                Header: 'Register Code',
                accessor: 'register_code',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["register_code"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: 'Full Name',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: 'Phone Number',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: 'Potential',
                accessor: 'pivot.status_potensial', 
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: 'Event City',
                accessor: 'registration_rep[0].kabkota.nama_kabkota', 
                style: {textAlign: 'center'},
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button onClick={() => this.editRow(row.original)} className="btn btn-success btn-pill btn-sm" type="button" style={{border:'none'}}><i className='fa fa-pencil'></i></button>
                      </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className='card'>
                    <div className='card-header'>
                        <div className="text-right">
                            <ExportCSV csvData={this.state.dataExpoPotentialFormat} fileName={"data-potensial"} />
                        </div>
                    </div>
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.dataExpoPotential}
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            SubComponent={row => {
                                const data = row.original.pivot;
                                return (
                                  <div style={{ padding: "20px" }}>
                                    <div style={{paddingLeft:"10px", fontStyle:"", color:"#A6A6A6"}}>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div align="right" style={{ color:"black" }}>Noted</div>
                                            </div>
                                            <div className="col-md-1">
                                              <div align="center">:</div>
                                            </div>
                                            <div className="col-md-6">
                                              <div align="left">{data.noted}</div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                        /> 
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null) (withRouter(DataTablesExpo))