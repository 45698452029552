import {CLEAR_STUDENTS, GET_STUDENTS, ADD_ROWS_STUDENTS, GET_STUDENTS_AMBASSADOR} from "../constant/actionTypes";
const initialState = {
    status: false,
    dataStudents: {},
}

const Students = (state=initialState , action) => {
    if(action.type === GET_STUDENTS){
        return {
            ...state,
            status: true,
            dataStudents: action.value
        }
    }
    if(action.type === GET_STUDENTS_AMBASSADOR){
        return {
            ...state,
            status: true,
            dataStudents: action.value
        }
    }
    if(action.type === CLEAR_STUDENTS){
        return {
            ...state,
            status: false,
            dataStudents: {}
        }
    }
    if(action.type === ADD_ROWS_STUDENTS){
        // let newRowData = [...state.dataStudents];
        // newRowData = action.value.concat(newRowData);
            return { 
                ...state,
                // dataStudents: state.dataStudents.concat(action.value)
                dataStudents: [...state.dataStudents, action.value]
            }
    }

    return state;
}

export default Students;