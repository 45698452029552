/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Breadcrumb from '../../components/common/breadcrumb';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';

class KonsultasiCeo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: {},
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataKonsultasi: [],
            id_konsultasi: "",
            name: "",
            paid_date: "",
        }
    }

    componentDidMount() {
        this.getKonsultasi();
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    getKonsultasi() {
        const url = API_URL.superStudent+'/website/konsultasi';
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataKonsultasi: data })
        })
    }

    sendData() {
        var formData = new FormData();
        formData.append('id_konsultasi', this.state.id_konsultasi);
        formData.append('paid_date', this.state.paid_date);
        formData.append('_method', 'PATCH');

        let url = '/website/konsultasi/'+this.state.id_konsultasi;

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.status == 'Success') {
                this.closeDialog();
                this.getKonsultasi();
                this.setState({ btnDisabled: false });
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }   
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    editRow(row) {
        this.setState({
            modal: true,
            title: 'Update Payment Data',
            operation: 'edit',
            logName: 'EDIT-KONSULTASI',
            id_konsultasi: row.id_konsultasi,
            name: row.name,
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal className="modal-dialog"  size="lg" isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <h2>{this.state.name}</h2>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Paid Date</label>
                                    <input className="form-control" name="paid_date" type="date" id="paid_date" onChange={(e) => this.handleInput(e)} value={this.state.paid_date} />
                                    <span>{this.state.errors.paid_date}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {

        const columns = [
            {
                Header: 'name',
                accessor: 'name',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'Phone Number',
                accessor: 'phone',
                filterable: false
            },
            {
                Header: 'Payment',
                accessor: 'status_payment',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_payment == 'N' ?
                            <span className={'badge bg-danger'}>Not Paid</span>
                            :
                            <span className={'badge bg-success'}>Paid</span>
                        }
                    </div>
                )
            },
            {
                Header: 'Registration Date',
                accessor: 'created_at',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {Moment(row.original.created_at).format("DD MMMM YYYY")}
                    </div>
                )
            },
            {
                Header: 'Payment Date',
                accessor: 'created_at',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {Moment(row.original.paid_date).format("DD MMMM YYYY")}
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ];

        return(
            <Fragment>
                <Breadcrumb title="Konsultasi" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.dataKonsultasi.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataKonsultasi}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                            />
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(KonsultasiCeo));
