import React, { Fragment } from 'react';
import DataTablesConsultant from '../../components/tables/dataTablesConsultant';
import CalendarEmployee from './calendar-employee'
import { useSelector } from "react-redux";


const DataEmployee = () => {

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));


    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">

                    <div className="row">
                        <div className="col-md-12">
                            <DataTablesConsultant />
                        </div>
                        <div style={{display:dataUser.code_roles === 'RL003' || dataUser.code_roles === 'RL004' ? '' : 'none'}} className='col-md-12'>
                            <CalendarEmployee/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>


    );
};

export default DataEmployee;