/* eslint-disable react-hooks/exhaustive-deps */

import React ,{ Fragment, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import ProgressBar from './component/progress-bar'
import ChartBar from './component/chart'
import API_URL from '../../../ApiUrl';
import axios from 'axios'


const ProgressSchoolVisit = ({start_date, end_date}) => {

    const[grafik,setGrafik]= useState([]);
    const[progress,setProgress] = useState({tercapai:0,target:100});
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const loadData = () => {
        let formData = new FormData();
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('type_target','6')
        formData.append('refrence_type','2')
        axios.post(API_URL.superStudent+'/consultant-peformance/count-school-activities-by-date', formData, {
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            setGrafik(result)
            result = result.map(function(item){
                return item.slice(-2);
            })
            result.shift()
            let total =  result.reduce(function(array1, array2) {
                return array1.map(function(value, index) {
                    return value + array2[index];
                });
            });
            let tercapai = total[0] * 100 / total[1]
            let target = 100 - tercapai;
            setProgress({tercapai: tercapai.toFixed(2), target: tercapai > 100 ? 0 : target})
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {
        loadData()
    },[start_date, end_date])

    return(
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <h6 className='f-w-600'>Office School Activities</h6>
                </div>
                <div className="card-body">
                    <ProgressBar progress_data={progress}/>
                    <ChartBar grafik={grafik} />
                </div>
            </div>
        </Fragment>
    )
}

export default ProgressSchoolVisit;
