import React ,{Component} from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios'
import ReactTable from 'react-table';
import setRangeDate from '../../helper/general-function';

class HomeTaskList extends Component {

    constructor(props){
        super(props)
        this.state = {
            date:'this_week',
            todoListData:[],
            start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'),
            end_date: Moment().endOf('isoWeek').format('YYYY-MM-DD'),
        }
    }

    componentDidMount(){
        this.setDate()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setDate()
        })
    }

    setDate(){   
        let data = setRangeDate(this.state.date);      
        let formData = new FormData();
        formData.append('start_date', data.start)
        formData.append('end_date', data.end)
        axios.post(API_URL.superStudent+'/todo/count-task-log', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({todoListData: result}))
        .catch(error => {
            console.log(error)
        })
    }

    render(){
        const columns =[
            {
                Header: 'Name',
                accessor: 'nama',
                style: {textAlign: 'center'},
            },
            {
                Header: 'To do',
                accessor: 'todo',
                width:70,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Progress',
                accessor: 'progress',
                width:90,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Pending',
                accessor: 'pending',
                width:80,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Done',
                accessor: 'done',
                width:70,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Priority',
                accessor: 'priority',
                width:70,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Cancel',
                accessor: 'cancel',
                width:70,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Revisi',
                accessor: 'revisi',
                width:70,
                style: {textAlign: 'center'},
            },
            {
                Header: 'Total',
                accessor: 'total',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Peformance',
                accessor: 'peformance',
                style: {textAlign: 'center'},
                Cell:(row) => (
                    row.original.peformance+ ' %'
                )
            },
        ]
        return( 
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row justify-content-center m-1">
                        <div className='col-md-3 col-sm-4 mb-4'>
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                            </select>
                        </div>
                        <div className='col-md-12'>
                            <div className='card'>
                                <ReactTable
                                    data={this.state.todoListData}
                                    columns={columns} 
                                    defaultPageSize={20}
                                    className="-striped -highlight"
                                    showPaginationBottom={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null)(HomeTaskList);