import React, { Fragment, Component } from 'react';
import DataTableTipePembayaran from '../common/datatable/datatableTipePembayaran';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { resettoken } from '../../actions/login.action';

class DataTablesTipePembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listTipe: []
        }
    }

    componentDidMount() {
        this.getDataTipe();
    }

    getDataTipe(){
        const url = API_URL.superStudent+'/finance/master-tipe';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ listTipe : data });
        });
    }

    render() {
        const data = this.state.listTipe;
        const pgsize = data.length;
        
        return(
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <DataTableTipePembayaran
                                        multiSelectOption={false}
                                        myData={data}
                                        title="Currency Data"
                                        pageSize={pgsize}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    dataId: state.Login.detailUser.id_users,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(withRouter(DataTablesTipePembayaran));