import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, List, User, UserPlus } from 'react-feather';
// import { Tabs as ReactTabs, TabList as ReactTablist } from 'react-tabs';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

import "./styles/react-router-tabs.css";

const Students = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            {/* <Breadcrumb title="Students" parent="Students" /> */}
            <Breadcrumb title="Students" parent="Students" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs
                            tabClassName="tab-link"
                            activeTabClassName="active"
                        >
                            <div className="tabs tab-title">
                                        {roles === 'RL008' ?
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/home`} style={{display:"none"}} />
                                        :
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/home`}>
                                            {/* <ReactTab> */}
                                                <Home /> Home
                                            {/* </ReactTab> */}
                                        </NavTab>
                                        }

                                        <NavTab style={{display: roles === 'RL008' ? 'none' : '' }} activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/peformance`}>
                                            {/* <ReactTab> */}
                                                <List /> Consultant Peformance
                                            {/* </ReactTab> */}
                                        </NavTab>
                                        
                                        <NavTab  style={{display: roles === 'RL008' ? '' : 'none' }}  activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/consultant`}>
                                                <User /> Personal Information
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/data`}>
                                            {/* <ReactTab> */}
                                                <UserPlus /> Students Data
                                            {/* </ReactTab> */}
                                        </NavTab>
                                        <NavTab style={{display: roles === 'RL008' ? 'none' : '' }} activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/taskList`}>
                                            {/* <ReactTab> */}
                                                <List /> Admission Task List
                                            {/* </ReactTab> */}
                                        </NavTab>
                                        {/* <NavTab style={{display: roles === 'RL008' ? 'none' : '' }} activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/students/follow-up`}>                                   
                                                <List /> Follow Up Students
                                        </NavTab> */}
                                        
                                </div>
                        </RoutedTabs>

                        {roles === 'RL008' ?
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/students`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/students/consultant`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} />
                            </Switch>
                        :
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/students`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/students/home`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/home`}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/consultant`}/>
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/data`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/taskList`} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/students/peformance`} />
                            </Switch>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
  })
  
  export default connect(reduxState, null)(withRouter(Students));