/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import setRangeDate from '../../helper/general-function';
import { Bar } from 'react-chartjs-2';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css';
import { matchSorter } from 'match-sorter';
import Moment from 'moment';

const ReactTableFixedColumns = withFixedColumns(ReactTable)

class GlobalAmbassadorHome extends Component {    
    constructor(props) {
      super(props);
      this.state = {
        date: 'this_year',
        start_date: '',
        end_date: '',
        id_kategori: 0,
        id_area: 0,
        per_page: 15,
        code_ambassador: '',
        dataReportAmbassador: [],
        dataStudentAmbassador: [],
        dataArea: [],
        detailDataStudent: [],
        filtered: [],
        showDetailStudent: 'none',
        name_ambassador: '',
      }
    }

    componentDidMount() {
        this.setDate()
        this.loadDataArea()
    }

    loadDataArea() {
        axios.get(API_URL.superStudent + '/master/area', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ dataArea: data })
        }).catch(error => console.log(error))
    }

    getReportGrantedAmbassador = () => {
        const url = API_URL.superStudent+'/ambassador/report-granted';

        var formData = new FormData();
        formData.append('start_date', this.state.date == 'all_time' ? '' : this.state.start_date)
        formData.append('end_date', this.state.date == 'all_time' ? '' : this.state.end_date)

        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataReportAmbassador: data })
        })        
    }

    getReportStudentAmbassador = () => {
        const url = API_URL.superStudent+'/ambassador/report-students';

        var formData = new FormData();
        formData.append('start_date', this.state.date == 'all_time' ? '' : this.state.start_date)
        formData.append('end_date', this.state.date == 'all_time' ? '' : this.state.end_date)
        formData.append('id_area', this.state.id_area)
        formData.append('id_kategori', this.state.id_kategori)

        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataStudentAmbassador: data })
        })        
    }

    setDate(){
        let data = setRangeDate(this.state.date);
  
        this.setState({ 
          start_date : data.start,
          end_date : data.end,
        },() => {
            this.getReportGrantedAmbassador();
            this.getReportStudentAmbassador();
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        },() => {
            this.setDate();
        })
    }

    showDetail = (code_ambassador) => {
        let filterData = new FormData();
        filterData.append('start_date', this.state.date == 'all_time' ? '' : this.state.start_date)
        filterData.append('end_date', this.state.date == 'all_time' ? '' : this.state.end_date)
        filterData.append('code_ambassador', code_ambassador)

        axios.post(API_URL.superStudent + '/ambassador/report-students-detail', filterData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({
                detailDataStudent: data.students,
                code_ambassador: code_ambassador,
                showDetailStudent: '',
                name_ambassador: data.nama_lengkap
            })
        })
        .catch(error => console.log(error))

    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    calculateDifferenceInDays(end_date) {
        const date_now = Moment()
        return date_now.diff(end_date, 'days');
    }

    editNoted(row) {
        console.log(row)
    }

    render() {
        const dataArr = this.state.dataReportAmbassador;

        const ambassador = dataArr.map(data => {
            return data.nama_lengkap;
        });

        const totalGranted = dataArr.map(data => {
            return data.students_count;
        });

        const dataGrafik = {
            labels: ambassador,
            datasets: [
                {
                    label: 'Student Granted',
                    data: totalGranted,
                    borderColor: 'blue',
                    backgroundColor: 'blue',
                    borderWidth: 1,
                },
            ],
        };

        const columns = [
            {
                Header: '#',
                accessor: 'nama_lengkap',
                fixed: 'left',
                width: 170,
                Cell: (row) => (
                    <button style={{ border: 'none' }} className={`btn btn-outline-primary btn-sm btn-pill`} onClick={() => this.showDetail(row.original.code_ambassador)}>{row.original.nama_lengkap}</button>
                ),
                style: { textAlign: 'center' },
            },
            {
                id: 'new',
                Header: 'New(hot)',
                accessor: d => Number(d.new),
                style: { textAlign: 'center' },
            },
            {
                id: 'persiapan_dokumen',
                Header: 'Collect Doc',
                accessor: d => Number(d.persiapan_dokumen),
                style: { textAlign: 'center' },
                filterable: false,
            },
            {
                Header: 'Translate Doc',
                accessor: d => Number(d.translate_dokumen),
                id: 'translate_dokumen',
                style: { textAlign: 'center' },
                filterable: false,
            },
            {
                id: 'pendaftaran_sekolah',
                Header: 'Apply Campus',
                accessor: d => Number(d.pendaftaran_sekolah),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'LOA',
                id: 'loa_simulasi_biaya',
                style: { textAlign: 'center' },
                accessor: d => Number(d.loa_simulasi_biaya),
                filterable: false,
                width: 70
            },
            {
                Header: 'Payment',
                id: 'pembayaran_uang_sekolah',
                style: { textAlign: 'center' },
                accessor: d => Number(d.pembayaran_uang_sekolah),
                filterable: false
            },
            {
                Header: 'Lodge Visa',
                id: 'lodge_visa',
                accessor: d => Number(d.lodge_visa),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Granted',
                id: 'granted',
                accessor: d => Number(d.granted),
                style: { textAlign: 'center' },
                width: 140,
                filterable: false
            },
            {
                Header: 'Rejection',
                id: 'rejection',
                accessor: d => Number(d.rejection),
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Cancel',
                id: 'cancel',
                accessor: d => Number(d.cancel),
                style: { textAlign: 'center' },
                filterable: false
            },
        ]

        const columns_student_detail = [
            {
                Header: 'Name',
                accessor: 'nama',
                width: 200,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <>
                    <button className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none', color: 'black' }} onClick={() =>
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)
                    } >{row.original.nama}</button>
                    </>
                )
            },
            {
                Header: 'Status',
                accessor: 'code_students_category',
                style: { textAlign: 'center' },
                filterable: true,
                width: 90,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "Potensial")}>{row.original.category == null ? '-'
                        :
                        row.original.code_students_category == 'SC00001' ?
                            <span className={'badge bg-info'}>{row.original.category.category}</span>
                            :
                            row.original.code_students_category == 'SC00002' ?
                                <span className={'badge bg-success'}>{row.original.category.category}</span>
                                :
                                row.original.code_students_category == 'SC00004' ?
                                    <span className={'badge bg-dark'}>{row.original.category.category}</span>
                                    :
                                    <span className={'badge bg-danger'}>{row.original.category.category}</span>}</div>
                ),
                // Filter: () =>
                //     <select value={this.state.filterPotensial || ''} onChange={(e) => this.SetFilterPotensial(e)}>
                //         <option key={0} value=''>Show All</option>
                //         {
                //             this.state.dataCategory.map(item => {
                //                 return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                //             })
                //         }
                //     </select>,
            },
            {
                Header: 'Progress',
                accessor: 'code_progress',
                style: { textAlign: 'center' },
                width: 140,
                filterable: true,
                Cell: (row) => (
                    <>
                        <div>{row.original.progress == null ? '' : row.original.progress.name_progress}</div>
                        <div style={{ fontSize: '11px', color: 'grey' }}>{row.original.last_progress_log == null ? this.calculateDifferenceInDays(row.original.input_date) : row.original.last_progress_log.total_date} days ago</div>
                    </>
                ),
                // Filter: () =>
                //     <select value={this.state.filterProgress || ''} onChange={(e) => this.SetFilterProgress(e)}>
                //         <option key={0} value=''>Show All</option>
                //         {
                //             this.state.dataProgress.map(item => {
                //                 return <option key={item.code} value={item.code}>{item.name}</option>
                //             })
                //         }
                //     </select>,
            },
            {
                Header: 'Kategori',
                accessor: 'code_status',
                style: { textAlign: 'center' },
                width: 140,
                filterable: true,
                Cell: (row) => (
                    <>{row.original.status.name_status}</>
                ),
                // Filter: () =>
                //     <select value={this.state.filterKategori || ''} onChange={(e) => this.SetFilterStatus(e)}>
                //         <option key={0} value=''>Show All</option>
                //         {
                //             this.state.dataStatus.map(item => {
                //                 return <option key={item.code} value={item.code}>{item.name}</option>
                //             })
                //         }
                //     </select>,
            },
            {
                Header: 'Source',
                accessor: 'code_leads_source',
                style: { textAlign: 'center' },
                width: 120,
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, 'Source')}>{row.original.source == null ? '-' : row.original.source.source}</div>
                ),
                // Filter: () =>
                //     <select value={this.state.filterSource || ''} onChange={(e) => this.SetFilterSource(e)}>
                //         <option key={0} value=''>Show All</option>
                //         {
                //             this.state.dataSource.map(item => {
                //                 return <option key={item.code_leads_source} value={item.code_leads_source}>{item.source}</option>
                //             })
                //         }
                //     </select>,
            },
            {
                Header: 'Programs',
                accessor: 'programs',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <ul style={{ overflow: 'auto' }} className='row'>
                        {
                            row.original.programs.length > 0 ?
                                row.original.programs.map((item, index) => (
                                    <li style={{ fontSize: '10px' }} className='col' key={index}>({item.university.countrycode}) {item.university.nama_sekolah} - {item.program_name} - ({Moment(item.pivot.date_program_start).format('MM/YY')}-{Moment(item.pivot.date_program_finish).format('MM/YY')})</li>
                                ))
                                : <></>
                        }
                    </ul>
                ),
            },
            {
                Header: 'Tasks',
                accessor: 'tasks',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <div>
                        {/* <button onClick={() => this.addTodo(row.original.code_students)} className="btn btn-warning btn-xs mr-1"><i className='fa fa-file-text-o'></i></button> */}
                        {/* <button onClick={() => this.showModalTask(row.original)} className="btn btn-info btn-xs "><i className="fa fa-plus"></i></button> */}
                        <ul style={{ overflow: 'auto' }} className='row'>
                            {
                                row.original.tasks.length > 0 ?
                                    row.original.tasks.map((item, index) => (
                                        <li style={{ fontSize: '10px' }} className='col mb-1' key={index}> ({Moment(item.created_at).format('DD/MM/YY')}) {item.description}</li>
                                    ))
                                    : <></>
                            }
                        </ul>
                    </div>
                ),
            },
        ];

        return (
            <Fragment>
                <div className="row m-1">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className='row d-flex justify-content-center'>
                                    <div className="col-md-3 col-sm-3">
                                        <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                            <option value='all_time'>All Time</option>
                                            <option value='this_week'>This Week</option>
                                            <option value='last_week'>Last Week</option>
                                            <option value='this_month'>This Month</option>
                                            <option value='last_month'>Last Month</option>
                                            <option value='this_year'>This Year</option>
                                            <option value='last_year'>Last Year</option>
                                            <optgroup label='Quartal'>
                                                <option value='1'>Quartal I</option>
                                                <option value='2'>Quartal II</option>
                                                <option value='3'>Quartal III</option>
                                                <option value='4'>Quartal IV</option>
                                            </optgroup>
                                            <option value='dinammic_date'> --Dinammic Date-- </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row d-flex justify-content-center mt-5'>
                                    <div className="col-lg-12">
                                        <h5>Total Granted</h5>
                                        <Bar data={dataGrafik} options={{ maintainAspectRatio: true }} />
                                    </div>
                                </div>
                                <div className='row d-flex justify-content-center mt-5'>
                                    <div className='col-lg-6'>
                                        <h5>List Ambassador</h5>
                                    </div>
                                    <div className="col-lg-3">
                                        <select className='form-control' onChange={this.handleChange} value={this.state.id_kategori} name='id_kategori'>
                                            <option value='0'>All Kategori Student</option>
                                            <option value='ST001'>Student Visa</option>
                                            <option value='ST015'>J1 Visa</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-3 col-sm-4'>
                                        <select className='form-control' onChange={this.handleChange} value={this.state.id_area} name='id_area'>
                                            <option value='0'>All Branch</option>
                                            {
                                                this.state.dataArea.length > 0 ?
                                                    this.state.dataArea.map((item, index) => (
                                                        <option key={index} value={item.id_area}>{item.area}</option>
                                                    )) : <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='row d-flex justify-content-center mt-5'>
                                    <div className='col-lg-12'>
                                        <ReactTableFixedColumns
                                            data={this.state.dataStudentAmbassador}
                                            showPaginationBottom={true}
                                            pageSize={this.state.per_page}
                                            columns={columns}
                                        >
                                        </ReactTableFixedColumns>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: this.state.showDetailStudent }} className='mt-4'>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className='row d-flex justify-content-center'>
                                    <div className='col-lg-12'>
                                        <h5>Detail Student Ambassador {this.state.name_ambassador}</h5>
                                        {
                                            this.state.detailDataStudent.length > 0 ?
                                            <ReactTable
                                                className="-striped"
                                                columns={columns_student_detail}
                                                data={this.state.detailDataStudent}
                                                filterable
                                                collapseOnDataChange={false}
                                                filtered={this.state.filtered}
                                                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                                }}
                                                // SubComponent={row => {
                                                //     return (
                                                //         <div style={{ padding: "20px" }}>
                                                //             <div className='row'>
                                                //                 <div className='col'>
                                                //                     <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.progress_log.length > 0 ?
                                                //                         <>
                                                //                             {row.original.progress_log[0].code}
                                                //                         </>
                                                //                         : ''}</span>
                                                //                 </div>
                                                //             </div>
                                                //         </div>
                                                //     )
                                                // }}
                                            >
                                            </ReactTable>
                                            :''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataToken: state.Login.lgnInitialize,
})
    
export default connect(reduxState, null)(withRouter(GlobalAmbassadorHome));