import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import LeftProfile from './left-profile';
import RightProfile from './rigth-profile';
import FileProfile from './file-profile';
import RewardProfile from './reward-profile';
import TrainingProfile from '../consultant/training-profile';

class ProfileEmployee extends Component{

    constructor(props){
        super(props)
        this.state = {
            employeeProfile:[],
            masterDocument:[],
            showComponent:false,

        }
    }

    componentDidMount(){
        this.loadProfile()
        this.loadMasterDocument()
    }

    handleSaveFile = (data) =>{
        axios.post(API_URL.superStudent+'/consultant/save-file',data, {
            headers:{  Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.loadProfile()
            this.loadMasterDocument()
            toast.success("data updated successfully...");
        })
        .catch(error => {
            toast.error("There is an error...");
        })
    }

    handleSaveProfile = (data) => {
        axios.post(API_URL.superStudent+'/consultant/save-profile', data, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.loadProfile()
            toast.success("data updated successfully...");
        })
        .catch(error => {
            toast.error("There is an error..");
        })
    }

    loadProfile = () => {
        axios.get(API_URL.superStudent+'/consultant/detail/'+this.props.dataUser.code_konsultan,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ employeeProfile: result, showComponent:true})
        })
        .catch(error => console.log(error))
    }

    loadMasterDocument = () => {
        axios.get(API_URL.superStudent+'/consultant/document-employee/'+this.props.dataUser.code_konsultan,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ masterDocument: response.data.data }))
        .catch(error => console.log(error))
    }


    insertHistoryKontrak = (data) => {
        axios.post(API_URL.superStudent+'/consultant/insert-history-kontrak', data,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.loadProfile()
            toast.success("data updated successfully...")
        })
        .catch(error => {
            toast.error("There is an error...")
        })
    }

    render(){
        return(
            <>
                <div className='container-fluid'>
                    <div className='edit-profile'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                {this.state.showComponent  === true ? <LeftProfile dataLogin={this.props.dataUser} saveFile={this.handleSaveFile} data={this.state.employeeProfile}/> : '' }
                            </div>
                            <div className='col-lg-8'>
                                {this.state.showComponent  === true ? <RightProfile historyKontrak={this.insertHistoryKontrak} dataLogin={this.props.dataUser} saveProfile={this.handleSaveProfile} data={this.state.employeeProfile}/> : '' }
                            </div>
                            <div className='col-lg-12'>
                                {this.state.showComponent  === true ? <FileProfile  dataLogin={this.props.dataUser} saveFile={this.handleSaveFile} dataFile={this.state.masterDocument}  data={this.state.employeeProfile}/> : '' }
                            </div>
                            <div className='col-lg-12'>
                                {this.state.showComponent  === true ? <RewardProfile dataLogin={this.props.dataUser} data={this.state.employeeProfile}/> : '' }
                            </div>
                            <div className='col-lg-12'>
                                {this.state.showComponent  === true ? <TrainingProfile dataLogin={this.props.dataUser} data={this.state.employeeProfile}/> : '' }
                            </div>
                            
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState)(ProfileEmployee);