import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import "./styles/react-router-tabs.css";
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SchoolDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listSchool: [],
            dataProvinces: [],
            dataKabkota: [],
            dataJenjang: [],
            modalEdit: false,
            btnDisabled:false,
            textSubmit:'Update',
            kode_sekolah: '',
            code_jenjang: '',
            id_provinsi: '',
            id_kabkota: '',
            school_name: '',
            address: '',
            phone: '',
            top_school: 0,
        }
    }

    componentDidMount() {
        this.getDataSchool();
    }

    getDataJenPen() {
        axios.get(API_URL.superStudent + '/master/jenjang', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataJenjang: data })
            })
    }

    getDataPropinsi() {
        const url = API_URL.superStudent + '/master/provinces'
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataProvinces: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    getDataKabkota() {
        const url = API_URL.superStudent + '/master/kabupaten/' + this.state.id_provinsi;
        axios.get(url, { headers: { 'Authorization': `Bearer ${this.props.dataToken}` } })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataKabkota: data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    editRow = (data) =>  {
        this.getDataPropinsi()
        this.getDataJenPen()
        this.setState({
            modalEdit: true,
            kode_sekolah: data.kode_sekolah,
            code_jenjang: data.code_jenjang,
            id_provinsi: data.id_propinsi,
            id_kabkota: data.id_kabkota,
            school_name: data.nama_sekolah,
            address: data.alamat_sekolah,
            phone: data.notlp_sekolah,
            top_school: data.top_school,
        }, () => {
            this.getDataKabkota()
        })
    }

    closeDialog(){
        this.setState({
            modalEdit:false,
        })
    }

    submitData(){
        this.setState({
            btnDisabled:true,
            textSubmit:'Saving...'
        })
        let formData = new FormData()
        formData.append('kode_sekolah',this.state.kode_sekolah); 
        formData.append('code_jenjang',this.state.code_jenjang);
        formData.append('id_propinsi',this.state.id_provinsi);
        formData.append('id_kabkota',this.state.id_kabkota);
        formData.append('nama_sekolah',this.state.school_name);
        formData.append('alamat_sekolah',this.state.address);
        formData.append('notlp_sekolah',this.state.phone);
        formData.append('top_school',this.state.top_school);
        formData.append('_method', 'PATCH');

        axios.post(API_URL.superStudent+'/sekolah-indonesia/'+this.state.kode_sekolah,formData, { 
            headers: {  'Authorization': `Bearer ${this.props.dataToken}`, }
        }).then((reply)=>{
            this.closeDialog();
            this.getDataSchool();
            this.setState({ btnDisabled: false });
            toast.success("Update Sucsessfully");
        }).catch(err => {
        });
    }

    getDataSchool() {
        const url = API_URL.superStudent + '/school-activities/detail/' + this.props.match.params.codeSchool;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ listSchool: data });
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleProvince = (e) => {
        this.setState({
            id_provinsi:e.target.value
        },() => this.getDataKabkota())
    }

    renderEditDialog() {

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalEdit} toggle={() => this.setState({ modalEdit: false })}>
                    <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Update Data</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="code_jenjang">Jenjang Pendidikan</label>
                                    <select className="form-control" name="code_jenjang" value={this.state.code_jenjang}
                                        onChange={this.handleChange}
                                    >
                                        <option value="0">--Pilih Jenjang Pendidikan--</option>
                                        {
                                        this.state.dataJenjang.length > 0 ?
                                        this.state.dataJenjang.map((jenjang_pendidikan) => (
                                            <option key={jenjang_pendidikan.code_jenjang} value={jenjang_pendidikan.code_jenjang}>{jenjang_pendidikan.jenjang_pendidikan}</option>
                                        )): ''}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="id_propinsi">Provinsi</label>
                                    <select className="form-control" name="id_provinsi" value={this.state.id_provinsi}
                                        onChange={this.handleProvince} >
                                        <option value="0">--Pilih Provinsi--</option>
                                        {
                                            this.state.dataProvinces.length > 0 ?
                                        this.state.dataProvinces.map((propinsi) => (
                                            <option key={propinsi.id} value={propinsi.id}>{propinsi.province_name}</option>
                                        ))
                                    :''}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="id_kabkota">Kabupaten / Kota</label>
                                    <select className="form-control" name="id_kabkota" value={this.state.id_kabkota}
                                        onChange={this.handleChange}  >
                                        <option value="0">--Pilih Kabupaten--</option>
                                        {
                                        this.state.dataKabkota.length > 0 ?
                                        this.state.dataKabkota.map((kabkota) => (
                                            <option key={kabkota.id} value={kabkota.id}>{kabkota.kabupaten_name}</option>
                                        )) : ''}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="nama_sekolah">School Name</label>
                                    <input className="form-control" name="school_name" type="text" value={this.state.school_name}
                                        onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="alamat_sekolah">School Address</label>
                                    <input className="form-control" name="address" type="text" value={this.state.address}
                                        onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="notlp_sekolah">School Phone</label>
                                    <input className="form-control" name="phone" type="text"value={this.state.phone}
                                        onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="top_school">Top School</label>
                                    <select className="form-control" >
                                        <option value="0">Tidak</option>
                                        <option value="1">Ya</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.submitData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <Breadcrumb title="School Detail" parent="School Detail" />
                    <div className='card'>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-md-11'>
                                    <h3 className='text-center'>{this.state.listSchool.nama_sekolah}</h3>
                                </div>
                                <div className='col-md-1'>
                                    <button onClick={() => this.editRow(this.state.listSchool)} className="btn btn-primary" type="button" style={{ border: 'none' }} ><i className="fa fa-pencil"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row m-1'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>School Code</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.kode_sekolah}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>Jenjang Pendidikan</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.jenjang_pendidikan}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-1'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>No. Telp</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.notlp_sekolah}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <h6>Propinsi</h6>
                                        </div>
                                        <div className='col-lg-1'>
                                            :
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>{this.state.listSchool.nama_propinsi}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-1 mt-5'>
                                <div className='col-lg-12'>
                                    <h6>Alamat : {this.state.listSchool.alamat_sekolah}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-md-12">
                            <RoutedTabs tabClassName="tab-link" activeTabClassName="active">
                                <div className="tabs tab-title">
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/` + this.props.match.params.codeSchool + `/activities`}>
                                        School Activities
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/` + this.props.match.params.codeSchool + `/feedback`}>
                                        School Feedback
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/` + this.props.match.params.codeSchool + `/schedulle`}>
                                        School Schedule
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/` + this.props.match.params.codeSchool + `/granted`}>
                                        School Granted
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/schooldetail/` + this.props.match.params.codeSchool + `/member`}>
                                        School Member
                                    </NavTab>
                                </div>
                            </RoutedTabs>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/schooldetail/`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/schooldetail/activities/` + this.props.match.params.codeSchool} />}
                                />
                            </Switch>
                        </div>
                        <hr />
                    </div>
                </div>
                {this.renderEditDialog()}
            </Fragment>
        );
    }
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(withRouter(SchoolDetail));