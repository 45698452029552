import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo/Logo_NIEC_large.png';
import API_URL from '../ApiUrl';
import axios from 'axios';

class ForgetPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textSave: 'Send',
            btnDisabled:false,
            email:'',
            message:'',
            classMessage:''
        }
    }

    sendEmail = (e) => {
        e.preventDefault()
        this.setState({
            textSave:'Sending...',
            btnDisabled:true
        })
        var formData = new FormData();
        formData.append('email', this.state.email);
        axios.post(API_URL.superStudent+'/auth/reset-password', formData)
        .then(response => {
            this.setState({ message: response.data.message, classMessage:'text-success',textSave:'Send', btnDisabled:false})
        })
        .catch(error => {
            this.setState({ message: 'Opps, your email was not found', classMessage:'text-danger', textSave:'Send', btnDisabled:false})
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    render(){
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="reset-password-box">
                                            <div className="text-center"><img width='100' src={logo} alt="NIEC Logo" /></div>
                                            <div className="text-center card mt-4 mb-0">
                                                <h4>Reset Your Password</h4>
                                                <form onSubmit={this.sendEmail} className="theme-form">
                                                    <h6 className="f-14 mt-4 mb-3">ENTER YOUR EMAIL</h6>
                                                    <div className="form-group">
                                                        <input name='email' value={this.state.email} onChange={this.handleInput} className="form-control" placeholder="Email" type="email" 
                                                        />
                                                        <span className={this.state.classMessage}>{this.state.message}</span>
                                                    </div>
                                                    <div className="form-group form-row mb-2">
                                                    <div className="col-md-3">
                                                           <button disabled={this.state.btnDisabled} className='btn btn-primary' type='submit'>{this.state.textSave}</button>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button className="btn btn-default">
                                                                <Link className="btn-link text-capitalize" to={`${process.env.PUBLIC_URL}/pages/login`}>
                                                                    Back
                                                                </Link>    
                                                            </button>
                                                        </div>
                                                        
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default ForgetPwd;