/* eslint-disable eqeqeq, react/no-direct-mutation-state */

import axios from 'axios'
import React, { Component,Fragment } from 'react'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import API_URL from '../../../ApiUrl'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {matchSorter} from 'match-sorter';

class DataTablesExpo extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataAnteran: [],
            dataOngoing: [],
            editingRow: [],
            title: '',
            operation: '',
            logName: '',
            modal: false,
            status_potensial: 'Cold',
            noted: "",
            btnDisabled: false
        }
    }

    componentDidMount() {
        this.getAntreanByRep('Q');
        this.getAntreanByRep('O');
        setInterval(this.getAntreanByRep('Q'), 10000);
        setInterval(this.getAntreanByRep('O'), 10000);
    }

    getAntreanByRep(kode_antrian) {
        let formData = new FormData()

        formData.append('code_school_rep', this.props.userableData.userable.code)
        formData.append('status_code', kode_antrian)
        formData.append('id_event', this.props.match.params.idEvents)

        axios.post(API_URL.superStudent+'/event/list-register-queue-rep', formData, {
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            if (kode_antrian == 'O') {
                this.setState({
                    dataOngoing: result
                })
            } else {
                this.setState({
                    dataAnteran: result
                })
            }
        })
    }

    ongoingRow(row) {
        axios.post(API_URL.superStudent+'/event/update-register-queue-rep/'+row.id+'/O', {
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.getAntreanByRep('Q');
            this.getAntreanByRep('O');
        })
    }

    cancelRow(row) {
        axios.post(API_URL.superStudent+'/event/update-register-queue-rep/'+row.id+'/C', {
            headers:{
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.getAntreanByRep('Q');
            this.getAntreanByRep('O');
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            title: 'Edit Potential Student',
            operation: 'edit',
            logName: 'EDIT-POTENTIAL-STUDENT',
            modal: true
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('university_id', this.props.userableData.userable.id);
        formData.append('event_id', this.state.editingRow.eventregister.event_id);
        formData.append('register_id', this.state.editingRow.eventregister.register.id);
        formData.append('id_kabkota', this.state.editingRow.eventregister.id_kabkota);
        formData.append('status_potensial', this.state.status_potensial);
        formData.append('noted', this.state.noted);
        formData.append('keterangan', this.state.editingRow.eventregister.keterangan);
        formData.append('id_queue', this.state.editingRow.id);

        axios.post(API_URL.superStudent+'/expo2022/set-status', formData, { headers: { 'Authorization': `Bearer ${this.props.dataToken}`, 'Content-Type': 'multipart/form-data' },
            onUploadProgress: p => {
                const progress = p.loaded / p.total;
                // check if we already displayed a toast
                if(this.state.toastId === null){
                    this.state.toastId = toast('Save in Progress', {
                        progress: progress,
                        autoClose: false
                    });
                } else {
                    toast.update(this.state.toastId, {
                        progress: progress
                    })
                }
            }
        }).then((reply)=>{
            toast.update(this.state.toastId, {
                render: "Input Success !",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
                className: 'rotateY animated'
            })
            this.closeDialog();
            this.getAntreanByRep('Q');
            this.getAntreanByRep('O');
            this.setState({ btnDisabled: false });
        }).catch(function(reply){
            console.log(reply)
        });
    }

    resetState() {
        this.setState({
            editingRow: [],
            errors: {},
            status_potensial: 'Cold',
            noted: "",
        });
    }

    closeDialog() {
        this.setState({
            modal: false
        }, () => {
            this.resetState();
        })
    }

    renderDialog() {
        const { title } = this.state;
        const modal1 = this.state.modal;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Potential Student</label>
                                    <select className="form-control" name="status_potensial" type="text" id="status_potensial"
                                        onChange={(e) => this.handleChange(e)}
                                    >
                                        <option value="">--Choose Status--</option>
                                        <option value="Cold">Cold</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Hot">Hot</option>
                                    </select>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12 mb-3'>
                                    <label htmlFor="name">Noted</label>
                                    <textarea  onChange={(e) => this.handleChange(e)} className='form-control' name='noted'  />
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        const columns = [
            {
                Header: 'Register Codee',
                accessor: 'eventregister.register.register_code',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["eventregister.register.register_code"] }),
                filterAll: true
            },
            {
                Header: 'Full Name',
                accessor: 'eventregister.register.name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["eventregister.register.name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'eventregister.register.email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["eventregister.register.email"] }),
                filterAll: true
            },
            {
                Header: 'Phone Number',
                accessor: 'eventregister.register.phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["eventregister.register.phone"] }),
                filterAll: true,
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button onClick={() => this.editRow(row.original)} className="btn btn-success btn-pill btn-sm" type="button" style={{border:'none'}}><i className='fa fa-pencil'></i></button>
                      </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="row m-1">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4>List Konsultasi</h4>
                                <br/>
                                <ReactTable
                                    data={this.state.dataOngoing}
                                    columns={columns}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                /> 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <h4>List Antrian</h4>
                                <br/>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th colSpan={2}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dataAnteran.map((antri) => (
                                                <tr>
                                                    <td>{antri.eventregister.register.name}</td>
                                                    <td><button onClick={() => this.ongoingRow(antri)} className="btn btn-success btn-xs" type="button" style={{border:'none'}}><i className='fa fa-check'></i></button></td>
                                                    <td><button onClick={() => this.cancelRow(antri)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}}><i className='fa fa-ban'></i></button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataUsers: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null) (withRouter(DataTablesExpo))